import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface Assignment extends BaseEntity {
  readonly crewId: string;
  readonly shiftId: string;
}
export type AssignmentCollection = RxCollection<Assignment> | null;
export type AssignmentDocument = RxDocument<Assignment>;

export const assignmentSchema: RxJsonSchema<Assignment> = {
  type: 'object',
  version: 0,
  description: 'describes an assignment employee object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    crewId: { type: 'string' },
    shiftId: { type: 'string', ref: RxdbCollectionName.SHIFT },
  },
};

export const assignmentPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query assignmentFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      assignmentFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        crewId
        id
        isDeleted
        shiftId
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
