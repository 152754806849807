import { ApolloLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createAppSyncSubscriptionWebsocketLink } from "./appSyncSubscriptionWebSocketLink";
import { createAppSyncHttpLink } from "./appSyncHttpLink";

const isSubscriptionOperation = ({ query }) => {
  const { kind, operation } = getMainDefinition(query);
  return kind === "OperationDefinition" && operation === "subscription";
};

export const createAppSyncHybridLink = async ({ appSyncApiUrl, getJwtToken }) =>
  ApolloLink.split(
    isSubscriptionOperation,
    await createAppSyncSubscriptionWebsocketLink({
      appSyncApiUrl,
      getJwtToken
    }),
    createAppSyncHttpLink({ appSyncApiUrl, getJwtToken })
  );
