import dayjs from 'dayjs';
import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface PanelDrawingComment extends BaseEntity {
  readonly comment: string;
  readonly panelDrawingId: string;
}
export type PanelDrawingCommentCollection = RxCollection<PanelDrawingComment> | null;
export type PanelDrawingCommentDocument = RxDocument<PanelDrawingComment>;

export const panelDrawingCommentSchema: RxJsonSchema<PanelDrawingComment> = {
  type: 'object',
  version: 0,
  description: 'describes a PanelDrawingComment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    comment: { type: 'string' },
    panelDrawingId: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

export const panelDrawingCommentPullQueryBuilder = (doc: PanelDrawingComment) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query panelDrawingCommentFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
      panelDrawingCommentFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        isDeleted
        updatedAt
        version
        panelDrawingId
        comment
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const panelDrawingCommentPushQueryBuilder = (doc: PanelDrawingComment) => {
  doc = doc[0];

  const query = print(gql`
    mutation setPanelDrawingComment(
      $id: ID!
      $panelDrawingId: ID!
      $comment: String!
      $isDeleted: Boolean!
      $version: Int!
    ) {
      setPanelDrawingComment(
        input: {
          id: $id
          version: $version
          isDeleted: $isDeleted
          panelDrawingId: $panelDrawingId
          comment: $comment
        }
      )
    }
  `);

  const variables = {
    ...doc,
  };

  return {
    query,
    variables,
  };
};

export const panelDrawingCommentPushModifier = (doc: PanelDrawingComment) => {
  const obj = {
    ...doc,
    modifiedOn: dayjs().toISOString(),
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
