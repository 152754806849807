import { RxDocument } from 'rxdb';

import { EquipmentDeficiencyAttachment } from '@/rxdb/EquipmentDeficiencyAttachments/queryBuilder';
import { GroundHazardAttachment } from '@/rxdb/GroundHazardAttachment/queryBuilder';
import { isRecentUpload } from '@/utilities/isRecentUpload';
import { getImageInUploadQueue } from '@/utilities/useFileStorage';

import { DocumentUploadDocument } from '../../rxdb/Documents/queryBuilder';
import { ImageMetadata } from '../Thumbnail/Thumbnail';
import { getImage } from './cacheDocumentInS3';
import { attachmentStore } from './CacheUpdateHandler';
import { DOCUMENT_RETRY_COUNT } from './getAttachmentWithRetry';

export const retryCountExceeded = (
  document: DocumentUploadDocument | undefined,
  numberOfRetries = DOCUMENT_RETRY_COUNT,
) => {
  if (
    (document?.retryCount || document?.retryCount === 0) &&
    document?.retryCount >= numberOfRetries
  ) {
    return true;
  }
  return false;
};

export const getAttachmentFromIndexDB = async (attachmentId?: string) => {
  if (!attachmentId) return;
  return attachmentStore.getItem(attachmentId);
};

export const attachmentInStorage = async (attachmentId: string) => {
  const inStorage = (await attachmentStore.keys()).indexOf(attachmentId) !== -1;
  return inStorage;
};

export interface ImageMetadataWithUrl extends ImageMetadata {
  url: string;
}

// Check upload queue, then indexedDB, then AWS
export const getFileUrl = async (
  file:
    | RxDocument<EquipmentDeficiencyAttachment, object>
    | RxDocument<GroundHazardAttachment, object>,
  checkAwsStorage = true,
): Promise<ImageMetadataWithUrl> => {
  let data: ImageMetadataWithUrl = {
    fileName: file.fileName,
    url: '',
    isRecentUpload: isRecentUpload(file.updatedAt),
  };
  const attachment = await getImageInUploadQueue(file.fileName);
  if (attachment) {
    data = { ...data, url: URL.createObjectURL(attachment) };
    return data;
  }
  const indexedDbUrl = await getAttachmentFromIndexDB(file.fileName);
  if (indexedDbUrl) {
    data = { ...data, url: indexedDbUrl };
    return data;
  }

  if (checkAwsStorage) {
    const awsUrl = await getImage(file.fileName);
    if (awsUrl) {
      data = { ...data, url: awsUrl };
      return data;
    }
  }
  return data;
};
