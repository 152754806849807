import dayjs from 'dayjs';

import { IAppointment } from '../components/pages/DelaysAndActivitiesV2/DelaysSchedulerView/ActivitiesSchedulerHelpers';
import { BorerActivity, BorerActivityDocument } from '../rxdb/BorerActivity/queryBuilder';
import { AddDelayActivityType, DelayActivitiesBlockType } from './enums';
import { SCHEDULER_DATE_FORMAT, USER_TIMEZONE } from './useDateFormatters';

// eslint-disable-next-line import/prefer-default-export
export class ActivityDelayMapper {
  public static MapActivityDocumentToAppointment(
    activityDocument: BorerActivityDocument | BorerActivity,
    onClick: (appt: IAppointment) => void,
  ): IAppointment {
    return {
      id: activityDocument.id,
      title: activityDocument.typeName,
      comment: activityDocument.comment,
      delayOrActivityTypeId: activityDocument.activityTypeId,
      type: AddDelayActivityType.ACTIVITY,
      typeId: 2,
      blockType: DelayActivitiesBlockType.ACTIVITY,
      startDate: dayjs.tz(activityDocument.start, USER_TIMEZONE).format(SCHEDULER_DATE_FORMAT),
      endDate: dayjs.tz(activityDocument.end, USER_TIMEZONE).format(SCHEDULER_DATE_FORMAT),
      onClick,
      faceChangeCrew: activityDocument.isFaceChangeCrew || false,
      version: activityDocument.version,
      updatedAt: activityDocument.updatedAt,
      isCurrent: false,
    };
  }
}
