import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React from 'react';

import AdvancesCard from '../../AdvancesCard/AdvancesCard';

dayjs.extend(utc);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 144px)',
      overflow: 'scroll',
    },
    card: {
      padding: '16px',
    },
  }),
);

const Advances: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} data-cy="add-advances-section">
        <AdvancesCard />
      </Grid>
    </Grid>
  );
};

export default observer(Advances);
