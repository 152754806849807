import { i18n } from '@nutrien/cxp-components';
import { useEffect, useState } from 'react';

import { Advance } from '../../rxdb/Advance/queryBuilder';

interface ErrorObj {
  startFootage: string;
  endFootage: string;
  footage: string;
  numberOfBuckets: string;
}

const startValidation = (advance: Advance) => {
  if (advance.startDistance === null || advance.startDistance === '') {
    return i18n.t('Required.');
  }
  if (
    Number(advance.startDistance) !== 0 &&
    (!Number(advance.startDistance) || Number(advance.startDistance) < 0)
  ) {
    return i18n.t('Must be numeric');
  }
  if (Number(advance.startDistance) % 1 !== 0) {
    return i18n.t('Must be a whole #');
  }
  return '';
};

const endValidation = (advance: Advance, isEven: boolean) => {
  if (advance.endDistance === null || advance.endDistance === '') {
    return i18n.t('Required');
  }
  if (
    Number(advance.endDistance) !== 0 &&
    (!Number(advance.endDistance) || Number(advance.endDistance) < 0)
  ) {
    return i18n.t('Must be numeric');
  }
  if (Number(advance.endDistance) % 1 !== 0) {
    return i18n.t('Must be a whole #');
  }
  if (
    Number(advance.startDistance) &&
    isEven &&
    Number(advance.endDistance) > Number(advance.startDistance)
  ) {
    return i18n.t('Enter value ≤ start');
  }
  if (
    Number(advance.startDistance) &&
    !isEven &&
    Number(advance.endDistance) < Number(advance.startDistance)
  ) {
    return i18n.t('Enter value ≥ start');
  }

  return '';
};

const isValidDistance = (distance: number | string | undefined | null): boolean => {
  if (distance !== '0' && distance !== 0 && !distance) {
    return false;
  }
  if (Number(distance < 0)) {
    return false;
  }
  if (!Number.isInteger(Number(distance))) return false;

  if (!RegExp('^[0-9]+$').test(`${distance}`)) return false;
  return true;
};

export const useValidation = (
  advance: Advance,
  showStartValidation: boolean,
  showEndValidation: boolean,
  isEven: boolean,
  isChevron: boolean,
): ErrorObj => {
  const [errors, setErrors] = useState<ErrorObj>({
    startFootage: '',
    endFootage: '',
    footage: '',
    numberOfBuckets: '',
  });

  useEffect(() => {
    if (!isChevron && showStartValidation) {
      setErrors(prev => ({ ...prev, startFootage: startValidation(advance) }));
    }
  }, [advance, showStartValidation, advance.endDistance, advance.startDistance, isChevron]);

  useEffect(() => {
    if (!isChevron && showEndValidation) {
      setErrors(prev => ({
        ...prev,
        endFootage: endValidation(advance, isEven),
      }));
    }
  }, [advance, showEndValidation, advance.startDistance, advance.endDistance, isEven, isChevron]);

  useEffect(() => {
    if (isChevron) {
      setErrors(prev => ({
        ...prev,
        footage: isValidDistance(advance.distance) ? '' : 'Must be numeric',
      }));
    }
  }, [advance, advance.distance, isChevron]);

  useEffect(() => {
    setErrors(prev => ({
      ...prev,
      numberOfBuckets: isValidDistance(advance.numberOfBuckets) ? '' : 'Must be a valid number',
    }));
  }, [advance, advance.numberOfBuckets]);

  return errors;
};

export default useValidation;
