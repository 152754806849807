import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { Button, Dialog, i18n, Icons } from '@nutrien/cxp-components';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  onClose: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      padding: '8px !important',
    },
    content: {
      marginTop: 7,
    },
  }),
);

const MissingCrewDialog: React.FC<Props> = ({ onClose }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const onRedirectToAddCrew = () => {
    history.push('/shift-start?addCrew=true');
    onClose();
  };

  return (
    <Dialog
      open
      titleText={i18n.t('Crew required')}
      backButton={false}
      disableEnforceFocus
      onClose={onClose}
      maxWidth="xs"
      maxHeight="225px"
      dialogContentClassName={classes.dialogContent}
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
      dialogActions={
        <Grid container justify="flex-end" spacing={2}>
          <Grid item xs={4}>
            <Button
              onClick={onRedirectToAddCrew}
              variant="contained"
              color="primary"
              noMinHeight
              id="add-crew-button"
              data-testid="add-crew-button"
            >
              {i18n.t('Add crew')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container justify="center" alignItems="center" className={classes.content}>
        <Grid item xs={1}>
          <Icons.InfoFeather color="error" />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1">
            {i18n.t(
              'Crew not specified for this shift. Please add Crew on Shift start before completing any other action',
            )}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default MissingCrewDialog;
