import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { BaseEntity } from '../models/BaseEntity';

interface BaseEntityWithCreatedOn extends BaseEntity {
  createdOn: string;
}

export const getUnixMillisecondTimestamp = () => dayjs().valueOf();

export const generateBaseEntity = (isDeleted = false, initialVersion = 1): BaseEntity => {
  const baseValues = {
    id: uuidv4(),
    updatedAt: getUnixMillisecondTimestamp(),
    isDeleted,
    version: initialVersion,
  };

  return baseValues;
};

export const generateBaseEntityWithCreatedOn = (
  createdOn = dayjs().toISOString(),
  isDeleted = false,
  initialVersion = 1,
): BaseEntityWithCreatedOn => {
  const baseValues: BaseEntityWithCreatedOn = {
    id: uuidv4(),
    updatedAt: getUnixMillisecondTimestamp(),
    isDeleted,
    version: initialVersion,
    createdOn,
  };

  return baseValues;
};

export default {
  generateBaseEntity,
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
};
