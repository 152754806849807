import { Grid, Typography } from '@material-ui/core';
import { Card, ToggleButton } from '@nutrien/cxp-components';
import { default as React } from 'react';

import {
  InspectionCategoryWithOptions,
  InspectionOptionsWithDetails,
} from '../../rxdb/Inspections/useInspections';
import { HazardSeverity } from '../../utilities/enums';
import useModalStyles from '../../utilities/modalStyles';
import SmartToggleButtonGroup from '../SmartToggleButtonGroup/SmartToggleButtonGroup';
import { useStyles } from './CuttingPermitCategory.styles';

interface SeverityModelOption {
  lowSeverity: number;
  highSeverity: number;
  description: string;
}

interface Props {
  category: InspectionCategoryWithOptions;
  selectedOptions: Record<string, InspectionOptionsWithDetails>;
  handleToggleChange: (event: any, value: InspectionOptionsWithDetails) => void;
  viewOnly: boolean;
  borerDisplayCuttingPermitSeverityValue: boolean;
  severityModelOptions?: SeverityModelOption[];
}
const severityToColor: { [key: string | HazardSeverity]: string } = {
  [HazardSeverity.SAFE_TO_CUT]: 'success',
  [HazardSeverity.ADDRESS_AND_PROCEED]: 'warning',
  [HazardSeverity.IMMEDIATE_WORK_STOPPAGE]: 'error',
  [HazardSeverity.CONDITION_OKAY]: 'success',
  [HazardSeverity.CAUTION]: 'warning',
  [HazardSeverity.CORRECTIVE_ACTION_REQUIRED]: 'error',
  [HazardSeverity.A_CATEGORY_1]: 'success',
  [HazardSeverity.A_CATEGORY_2]: 'warning',
  [HazardSeverity.A_CATEGORY_3]: 'error',
  [HazardSeverity.R_CATEGORY_1]: 'success',
  [HazardSeverity.R_CATEGORY_2]: 'warning',
  [HazardSeverity.R_CATEGORY_3]: 'error',
};

const CuttingPermitCategory: React.FC<Props> = React.memo(
  ({
    category,
    selectedOptions,
    handleToggleChange,
    viewOnly,
    borerDisplayCuttingPermitSeverityValue,
    severityModelOptions,
  }: Props) => {
    const classes = useStyles();
    const modalClasses = useModalStyles();

    return (
      <Grid item xs={12} key={category.category.id}>
        <Card className={modalClasses.card}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">{category.category.description}</Typography>
            </Grid>
            <Grid item xs={12} className={modalClasses.buttonGroupContainer}>
              <SmartToggleButtonGroup
                value={selectedOptions[category.category.id] || null}
                onToggleChange={handleToggleChange}
                onDropdownChange={handleToggleChange}
                aria-label=""
                exclusive
                viewOnly={viewOnly}
                list={category.options.sort(
                  (a, b) => a.option.displayOrder - b.option.displayOrder,
                )}
                dropdownClassName={classes.dropdown}
                dropDownOptionLabel={(item: any) => {
                  if (item === '') {
                    return '';
                  }
                  if (borerDisplayCuttingPermitSeverityValue)
                    return `${item.option.description} (${item.option.severityValue})`;
                  return `${item.option.description}`;
                }}
              >
                {category &&
                  category.options &&
                  category.options
                    .sort((a, b) => a.option.displayOrder - b.option.displayOrder)
                    .map(value => {
                      const { option } = value;

                      let buttonColor = 'success';
                      const optionSeverity = severityModelOptions?.find(
                        model => option.severityValue >= model.lowSeverity,
                      );

                      if (
                        optionSeverity?.description &&
                        severityToColor[optionSeverity?.description]
                      ) {
                        buttonColor = severityToColor[optionSeverity?.description];
                      }

                      return (
                        <ToggleButton
                          key={value.option?.id}
                          value={value}
                          aria-label={`${value?.option?.id}-toggle`}
                          color={buttonColor}
                          disabled={viewOnly}
                          showColorOnDisabled
                          disableRipple
                          onTouchEnd={evt => {
                            handleToggleChange(evt, value);
                          }}
                        >
                          <Typography variant="body1" style={{ cursor: 'pointer' }}>
                            {value.option?.description}{' '}
                            {borerDisplayCuttingPermitSeverityValue &&
                              `(${value.option?.severityValue})`}
                          </Typography>
                        </ToggleButton>
                      );
                    })}
              </SmartToggleButtonGroup>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) =>
    prevProps.selectedOptions[prevProps.category.category.id]?.option?.id ===
    nextProps.selectedOptions[nextProps.category.category.id]?.option?.id,
);

CuttingPermitCategory.displayName = 'CuttingPermitCategory';
export default CuttingPermitCategory;
