import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface Room extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly length: number;
  readonly panel: string;
  readonly miningPattern: string;
}
export type RoomCollection = RxCollection<Room> | null;
export type RoomDocument = RxDocument<Room>;

export const roomSchema: RxJsonSchema<Room> = {
  type: 'object',
  version: 1,
  description: 'describes a Room object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    length: { type: 'number' },
    panel: { ref: RxdbCollectionName.PANELS, type: ['string', 'null'] },
    version: { type: 'number' },
    miningPattern: {
      ref: RxdbCollectionName.MINING_PATTERN,
      type: ['string', 'null'],
    },
  },
};

export const roomMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: RoomDocument) => ({ ...oldDoc, miningPattern: null }),
};

export const roomsPullQueryBuilder = (doc: Room) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = `{
    roomFeed(lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}, siteId: "${rootStore.user.siteId}") {
      description
      id
      isDeleted
      isActive
      length
      panel
      updatedAt
      version
      miningPattern
    }
    }`;
  return {
    query,
    variables: {},
  };
};

export default {};
