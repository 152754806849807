import { useEffect, useMemo, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { RxdbCollectionName } from '../rxdbCollectionName';
import RxdbManager from '../RxdbManager';
import { BorerStateType, BorerStateTypeCollection, BorerStateTypeDocument } from './queryBuilder';

export interface BorerStateTypeSummary extends BorerStateType {
  categoryName: string;
  id: string;
}
export const useBorerStateType = (
  includeInactiveStateTypes = false,
  includeItemsWithoutTimeUsageModel = false,
) => {
  // Collection initialization handling
  const borerStateTypeCollection: BorerStateTypeCollection = useRxCollection(
    RxdbCollectionName.BORER_STATE_TYPE,
  );

  const [augmentedStateTypesWithCategories, setAugmentedStateTypesWithCategories] = useState<
    BorerStateTypeSummary[]
  >([]);

  const { result: borerStateTypes } = useRxData<BorerStateType>(
    RxdbCollectionName.BORER_STATE_TYPE,
    collection => collection.find(),
  );

  const [defaultNonRunningStateType, setDefaultNonRunningStateType] = useState<
    BorerStateTypeSummary | undefined
  >();

  useEffect(() => {
    const augmentStateTypesWithCategory = async () => {
      if (borerStateTypes.length) {
        const stateTypesWithCategory: BorerStateTypeSummary[] = await Promise.all(
          borerStateTypes.map(async stateType => {
            const category = await stateType.populate('borerStateTypeCategoryId');

            return {
              ...stateType,
              categoryName: category?.description || 'No Category',
              description: stateType.description,
              id: stateType.id,
              isRunning: stateType.isRunning,
              isActive: stateType.isActive,
              timeUsageTypeId: stateType.timeUsageTypeId,
              isPlanned: stateType.isPlanned,
              isDefault: stateType.isDefault,
            };
          }),
        );

        const defaultNonRunning = stateTypesWithCategory.find(
          stateType => stateType?.isDefault === true && stateType?.isRunning === false,
        );

        setDefaultNonRunningStateType(defaultNonRunning);

        setAugmentedStateTypesWithCategories(
          stateTypesWithCategory
            .filter(i => i?.isActive || includeInactiveStateTypes)
            .filter(
              i => Boolean(i?.timeUsageTypeId) || includeItemsWithoutTimeUsageModel || i.isDefault,
            ),
        );
      } else {
        setAugmentedStateTypesWithCategories([]);
      }
    };
    augmentStateTypesWithCategory();
  }, [borerStateTypes, includeInactiveStateTypes, includeItemsWithoutTimeUsageModel]);

  const borerStateTypeInitialized = useMemo((): boolean => {
    return !!borerStateTypeCollection;
  }, [borerStateTypeCollection]);

  // Get all types
  const [allStateTypes, setAllStateTypes] = useState<BorerStateTypeDocument[]>();
  const [allStateTypesById, setAllStateTypesById] =
    useState<Record<string, BorerStateTypeDocument>>();

  const listAllDelayTypes = () => {
    if (!borerStateTypeCollection) {
      return [];
    }
    return borerStateTypeCollection.find().exec();
  };

  useEffect(() => {
    let subscription: Subscription;
    if (borerStateTypeInitialized && borerStateTypeCollection) {
      const getData = async () => {
        const delays = await listAllDelayTypes();
        const byId = delays.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {} as Record<string, BorerStateTypeDocument>);

        setAllStateTypes(delays);
        setAllStateTypesById(byId);
      };

      subscription = borerStateTypeCollection?.$.subscribe(() => getData());

      getData();
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [borerStateTypeInitialized]);

  return {
    borerStateTypeInitialized,
    listAllDelayTypes,
    allStateTypes,
    allStateTypesById,
    augmentedStateTypesWithCategories,
    defaultNonRunningStateType,
  };
};

export const getStateTypeById = async (id: string) =>
  RxdbManager.instance.db?.collections[RxdbCollectionName.BORER_STATE_TYPE]
    .findOne({
      selector: {
        id,
      },
    })
    .exec();

export default useBorerStateType;
