import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { types } from 'mobx-state-tree';

import { BorerShiftDocument } from '../../rxdb/BorerShift/queryBuilder';
import { BorerShiftCrewDocument } from '../../rxdb/BorerShiftCrew/queryBuilder';
import { ShiftDocument } from '../../rxdb/Shifts/queryBuilder';
import { SHIFT_SESSION_KEY } from '../../utilities/constants';
import { ShiftType } from '../../utilities/enums';
import { HH_MM_FORMAT, USER_TIMEZONE } from '../../utilities/useDateFormatters';
import { ShiftPickerInfo } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(USER_TIMEZONE);

export const DATE_STORAGE_FORMAT = 'YYYY-MM-DD';

const DAY_SHIFT_START_HOURS = 8;
export const NIGHT_SHIFT_START_HOURS_UTC = 14;
export const SHIFT_LENGTH_HOURS = 12;

export const UTC_OFFSET = dayjs().utcOffset() / -60;
export const UTC_START_HOURS = DAY_SHIFT_START_HOURS + UTC_OFFSET;
export const UTC_END_HOURS = UTC_START_HOURS + SHIFT_LENGTH_HOURS;

export const LOCAL_DAY_SHIFT_START_HOURS = NIGHT_SHIFT_START_HOURS_UTC - UTC_OFFSET;
export const LOCAL_NIGHT_SHIFT_START_HOURS =
  NIGHT_SHIFT_START_HOURS_UTC - UTC_OFFSET + SHIFT_LENGTH_HOURS;

const StoreShiftInSession = (shiftPickerInfo: ShiftPickerInfo) => {
  const shiftInfo = {
    dayShiftStartHour: shiftPickerInfo.dayShiftStartHour,
    dayShiftStartMinute: shiftPickerInfo.dayShiftStartMinute,
    nightShiftStartHour: shiftPickerInfo.nightShiftStartHour,
    nightShiftStartMinute: shiftPickerInfo.nightShiftStartMinute,
    Date: shiftPickerInfo.Date,
    Type: shiftPickerInfo.Type,
    shiftId: shiftPickerInfo.currentShiftId,
    currentBorerShiftId: shiftPickerInfo.currentBorerShiftId,
  };

  sessionStorage.setItem(SHIFT_SESSION_KEY, JSON.stringify(shiftInfo));
};
/**
 * Return the details of the current active borer shift
 *
 * @param {*} [overrideStartHours=LOCAL_DAY_SHIFT_START_HOURS]
 * @param {number} [overrideStartMinutes=0]
 * @param {*} [overrideEndHours=LOCAL_NIGHT_SHIFT_START_HOURS]
 * @param {number} [overrideEndMinutes=0]
 * @return {*}
 */

export const getCurrentShift = (
  overrideStartHours = LOCAL_DAY_SHIFT_START_HOURS,
  overrideStartMinutes = 0,
  overrideEndHours = LOCAL_NIGHT_SHIFT_START_HOURS,
  overrideEndMinutes = 0,
) => {
  const date = dayjs();
  const dateFormatted = date.format(DATE_STORAGE_FORMAT);
  const DSStart = dayjs()
    .startOf('day')
    .add(overrideStartHours, 'hours')
    .add(overrideStartMinutes, 'minutes')
    .subtract(1, 'second');
  const DSEnd = dayjs()
    .startOf('day')
    .add(overrideEndHours, 'hours')
    .add(overrideEndMinutes, 'minutes');

  // determine current shift type
  const isDayShift = date.isAfter(DSStart) && date.isBefore(DSEnd);
  const isPreviousNightShift = date.isBefore(DSStart);
  const type = isDayShift ? ShiftType.DAY_SHIFT : ShiftType.NIGHT_SHIFT;

  const baseDateObj = {
    Date: dateFormatted,
    Type: type,
    datejs: date,
    dayShiftStartHour: 8,
    dayShiftStartMinute: 0,
    nightShiftStartHour: 20,
    nightShiftStartMinute: 0,
    shiftStartDateUnix: DSStart.unix(),
    shiftEndDateUnix: DSEnd.unix(),
  };

  if (isPreviousNightShift) {
    return {
      ...baseDateObj,
      Date: date.subtract(1, 'day').format(DATE_STORAGE_FORMAT),
      Type: ShiftType.NIGHT_SHIFT,
      datejs: date.subtract(1, 'day'),
    };
  }

  return baseDateObj;
};

const BorerShiftCrewModel = types.model({
  id: types.identifier,
  crewNumber: types.number,
  start: types.maybeNull(types.string),
  end: types.maybeNull(types.string),
});

export const ShiftPicker = types
  .model({
    Date: types.string,
    Type: types.enumeration('ShiftType', [ShiftType.DAY_SHIFT, ShiftType.NIGHT_SHIFT]),
    currentShiftId: types.maybeNull(types.string),
    currentBorerShiftId: types.maybeNull(types.string),
    currentBorerShiftCrewIds: types.maybeNull(types.array(BorerShiftCrewModel)),
    settingBorerShift: types.maybe(types.boolean),
    dayShiftStartHour: types.optional(types.number, 8),
    dayShiftStartMinute: types.optional(types.number, 0),
    nightShiftStartHour: types.optional(types.number, 20),
    nightShiftStartMinute: types.optional(types.number, 0),
    shiftStartDateUnix: types.maybeNull(types.number),
    shiftEndDateUnix: types.maybeNull(types.number),
  })
  .views(self => ({
    get shiftString() {
      return `${self.Date} ${self.Type}`;
    },
    get selectedShift() {
      if (self.shiftStartDateUnix && self.shiftEndDateUnix) {
        const shiftStart = dayjs.tz(dayjs.unix(self.shiftStartDateUnix), USER_TIMEZONE);
        const shiftEnd = dayjs.tz(dayjs.unix(self.shiftEndDateUnix), USER_TIMEZONE);

        return { shiftStart, shiftEnd };
      }

      const shiftStart = dayjs
        .tz(self.Date, USER_TIMEZONE)
        .set(
          'hours',
          self.Type === ShiftType.DAY_SHIFT ? self.dayShiftStartHour : self.nightShiftStartHour,
        )
        .set(
          'minutes',
          self.Type === ShiftType.DAY_SHIFT ? self.dayShiftStartMinute : self.nightShiftStartMinute,
        );

      const shiftEnd = dayjs
        .tz(self.Date, USER_TIMEZONE)
        .set(
          'hours',
          self.Type === ShiftType.DAY_SHIFT ? self.nightShiftStartHour : self.dayShiftStartHour,
        )
        .set(
          'minutes',
          self.Type === ShiftType.DAY_SHIFT ? self.nightShiftStartMinute : self.dayShiftStartMinute,
        )
        .add(self.Type === ShiftType.DAY_SHIFT ? 0 : 1, 'day');

      return { shiftStart, shiftEnd };
    },
    get selectedShiftStartUTC() {
      if (self.shiftStartDateUnix) {
        return dayjs.tz(dayjs.unix(self.shiftStartDateUnix), USER_TIMEZONE).utc();
      }

      return dayjs
        .tz(self.Date, USER_TIMEZONE)
        .set(
          'hours',
          self.Type === ShiftType.DAY_SHIFT ? self.dayShiftStartHour : self.nightShiftStartHour,
        )
        .set(
          'minutes',
          self.Type === ShiftType.DAY_SHIFT ? self.dayShiftStartMinute : self.nightShiftStartMinute,
        )
        .utc();
    },
    get selectedShiftEndUTC() {
      if (self.shiftEndDateUnix) {
        return dayjs.tz(dayjs.unix(self.shiftEndDateUnix), USER_TIMEZONE).utc();
      }

      return dayjs
        .tz(self.Date, USER_TIMEZONE)
        .set(
          'hours',
          self.Type === ShiftType.DAY_SHIFT ? self.dayShiftStartHour : self.nightShiftStartHour,
        )
        .set(
          'minutes',
          self.Type === ShiftType.DAY_SHIFT ? self.dayShiftStartMinute : self.nightShiftStartMinute,
        )
        .add(self.Type === ShiftType.DAY_SHIFT ? 0 : 1, 'day')
        .utc();
    },
    get shiftLabelText() {
      return `${dayjs(self.Date).startOf('day').format('ddd, MMM D')} - ${self.Type}`;
    },
    get selectedShiftDate() {
      if (self.shiftStartDateUnix) return dayjs.unix(self.shiftStartDateUnix);
      return self.Type === ShiftType.DAY_SHIFT
        ? dayjs(self.Date)
            .set('hours', self.dayShiftStartHour)
            .set('minutes', self.dayShiftStartMinute)
        : dayjs(self.Date)
            .set('hours', self.nightShiftStartHour)
            .set('minutes', self.nightShiftStartMinute);
    },
    get selectedShiftEndDate() {
      if (self.shiftEndDateUnix) return dayjs.unix(self.shiftEndDateUnix);
      return self.Type === ShiftType.DAY_SHIFT
        ? dayjs(self.Date)
            .set('hours', self.nightShiftStartHour)
            .set('minutes', self.nightShiftStartMinute)
        : dayjs(self.Date)
            .add(1, 'days')
            .set('hours', self.dayShiftStartHour)
            .set('minutes', self.dayShiftStartMinute);
    },
    get formattedStartTime() {
      if (self.shiftStartDateUnix) return dayjs.unix(self.shiftStartDateUnix).format(HH_MM_FORMAT);
      return self.Type === ShiftType.DAY_SHIFT
        ? dayjs(self.Date)
            .set('hours', self.dayShiftStartHour)
            .set('minutes', self.dayShiftStartMinute)
            .format(HH_MM_FORMAT)
        : dayjs(self.Date)
            .set('hours', self.nightShiftStartHour)
            .set('minutes', self.nightShiftStartMinute)
            .format(HH_MM_FORMAT);
    },
    get currentDateNightShiftStart() {
      return dayjs(self.Date)
        .set('hours', self.nightShiftStartHour)
        .set('minutes', self.nightShiftStartMinute);
    },
    isCurrentShiftSelected: () => {
      const { Date, Type } = getCurrentShift(
        self.dayShiftStartHour,
        self.dayShiftStartMinute,
        self.nightShiftStartHour,
        self.nightShiftStartMinute,
      );

      return self.Date === Date && self.Type === Type;
    },
    isDayShift: () => {
      return self.Type === ShiftType.DAY_SHIFT;
    },
    isNightShift: () => {
      return self.Type === ShiftType.NIGHT_SHIFT;
    },
    currentShift: () => {
      return getCurrentShift();
    },
  }))
  .actions(self => ({
    hasValidShift: () => {
      return (
        self.currentShiftId &&
        self.currentShiftId !== null &&
        self.currentBorerShiftId &&
        self.currentBorerShiftId !== null
      );
    },
    setShift: (date: Dayjs, type: ShiftType = self.Type) => {
      const dateFormatted = date.format(DATE_STORAGE_FORMAT);

      // current time is before the selected day's shift start throw error
      if (
        dayjs().isBefore(
          dayjs(dateFormatted)
            .set(
              'hours',
              type === ShiftType.DAY_SHIFT ? self.dayShiftStartHour : self.nightShiftStartHour,
            )
            .set(
              'minute',
              type === ShiftType.DAY_SHIFT ? self.dayShiftStartMinute : self.nightShiftStartMinute,
            ),
        )
      ) {
        throw new Error('Cannot select a shift that has not started');
      }

      self.Date = dateFormatted;
      self.Type = type;

      StoreShiftInSession(self);
    },
    resetShiftAndBorerShiftToNull: () => {
      self.currentShiftId = null;
      self.currentBorerShiftId = null;
      self.currentBorerShiftCrewIds = [];
    },
    resetToCurrentShift: () => {
      const { Date, Type } = getCurrentShift(
        self.dayShiftStartHour,
        self.dayShiftStartMinute,
        self.nightShiftStartHour,
        self.nightShiftStartMinute,
      );

      self.Date = Date;
      self.Type = Type;

      StoreShiftInSession(self);
    },
    moveShift: (forwards = true) => {
      let selectedShiftStartTime: Dayjs;
      if (self.shiftStartDateUnix) {
        selectedShiftStartTime = dayjs.unix(self.shiftStartDateUnix);
      } else {
        selectedShiftStartTime =
          self.Type === ShiftType.DAY_SHIFT
            ? dayjs(self.Date)
                .set('hours', self.dayShiftStartHour)
                .set('minutes', self.dayShiftStartMinute)
            : dayjs(self.Date)
                .set('hours', self.nightShiftStartHour)
                .set('minutes', self.nightShiftStartMinute);
      }

      let newShiftStartTime = selectedShiftStartTime.clone();
      if (forwards) {
        newShiftStartTime = newShiftStartTime.add(SHIFT_LENGTH_HOURS, 'hours');
      } else {
        newShiftStartTime = newShiftStartTime.subtract(SHIFT_LENGTH_HOURS, 'hours');
      }

      const dateFormatted = newShiftStartTime.format(DATE_STORAGE_FORMAT);
      const newType =
        self.Type === ShiftType.DAY_SHIFT ? ShiftType.NIGHT_SHIFT : ShiftType.DAY_SHIFT;

      // current time is before the selected day's shift start throw error
      if (
        dayjs().isBefore(
          dayjs(dateFormatted)
            .set(
              'hours',
              newType === ShiftType.DAY_SHIFT ? self.dayShiftStartHour : self.nightShiftStartHour,
            )
            .set(
              'minute',
              newType === ShiftType.DAY_SHIFT
                ? self.dayShiftStartMinute
                : self.nightShiftStartMinute,
            ),
        )
      ) {
        throw new Error('Cannot select a shift that has not started');
      }

      self.Date = dateFormatted;
      self.Type = newType;

      StoreShiftInSession(self);
    },
    setCurrentShift: (shiftDoc: ShiftDocument) => {
      self.currentShiftId = shiftDoc.id;

      self.shiftStartDateUnix = dayjs(shiftDoc.start).unix();
      self.shiftEndDateUnix = dayjs(shiftDoc.end).unix();
      if (shiftDoc.name === ShiftType.DAY_SHIFT) {
        self.dayShiftStartHour = dayjs(shiftDoc.start).tz(USER_TIMEZONE).hour();
        self.dayShiftStartMinute = dayjs(shiftDoc.start).tz(USER_TIMEZONE).minute();
        self.nightShiftStartHour = dayjs(shiftDoc.end).tz(USER_TIMEZONE).hour();
        self.nightShiftStartMinute = dayjs(shiftDoc.end).tz(USER_TIMEZONE).minute();
      } else if (shiftDoc.name === ShiftType.NIGHT_SHIFT) {
        self.dayShiftStartHour = dayjs(shiftDoc.end).tz(USER_TIMEZONE).hour();
        self.dayShiftStartMinute = dayjs(shiftDoc.end).tz(USER_TIMEZONE).minute();
        self.nightShiftStartHour = dayjs(shiftDoc.start).tz(USER_TIMEZONE).hour();
        self.nightShiftStartMinute = dayjs(shiftDoc.start).tz(USER_TIMEZONE).minute();
      }
      StoreShiftInSession(self);
    },
    setCurrentBorerShift: (borerShiftDoc: BorerShiftDocument | null) => {
      self.settingBorerShift = true;
      self.currentBorerShiftId = borerShiftDoc?.id || null;
      StoreShiftInSession(self);
      self.settingBorerShift = false;
    },
    setCurrentBorerShiftCrews: (crews: BorerShiftCrewDocument[]) => {
      self.currentBorerShiftCrewIds = crews.map(crew => {
        return {
          id: crew.id,
          crewNumber: crew.crewNumber,
          start: crew.start,
          end: crew.end,
        };
      });
    },
    getRemainingShiftMinutes: () => {
      if (self.selectedShift?.shiftEnd) {
        return dayjs(self.selectedShift?.shiftEnd).diff(dayjs(), 'minutes');
      }

      // Fn must return number. This ensures we don't show validation early.
      return -721;
    },
  }));

export default ShiftPicker;
