import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, MaterialPalette, TextField } from '@nutrien/cxp-components';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useConstructor } from '../../../utilities';
import GroundControlCheckboxesOption from '../GroundControlCheckboxesOption';
import GroundControlDropdownOption from '../GroundControlDropdownOption';
import GroundControlToggleOption from '../GroundControlToggleOption';

export enum GroundControlOptionDisplayType {
  'TOGGLE' = 'Toggle',
  'CHECKBOXES' = 'CheckBoxes',
  'DROPDOWN' = 'Dropdown',
}

interface Props {
  groundControl: any;
  isSelected: boolean;
  selectedValues: any[];
  quantity: string;
  onGroundControlSelected: (
    groundControlId: string,
    quantityValue: string,
    optionValuePairs: any[],
    requiresQuantity: boolean,
  ) => void;
  onGroundControlUpdated: (
    groundControlId: string,
    quantityValue?: string,
    quantityError?: boolean,
    optionId?: string,
    optionValueId?: string,
  ) => void;
  index: number;
  setValidationError: (index: number, value: boolean) => void;
  validationIndex: number;
  onGroundControlBlur: () => void;
  onGroundControlFocus: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: MaterialPalette.background.paper2,
      '& h6': {
        fontSize: '20px',
        color: MaterialPalette.tertiary.main,
      },
    },
    checkboxLabel: {
      width: '100%',
    },
    optionsContainer: {
      paddingLeft: '30px',
      '& > div > span': {
        color: MaterialPalette.primary.main,
      },
    },
    option: {
      marginBottom: '15px',
    },
    stepper: {
      width: '45%',
    },
  }),
);

const GroundControlType: FC<Props> = ({
  groundControl,
  isSelected,
  selectedValues,
  quantity,
  onGroundControlSelected,
  onGroundControlUpdated,
  index,
  setValidationError,
  validationIndex,
  onGroundControlBlur,
  onGroundControlFocus,
}: Props) => {
  const classes = useStyles();

  const [groundControlChecked, setGroundControlChecked] = useState<boolean>(isSelected);
  const [stepperQuantityValue, setStepperQuantityValue] = useState<string>(quantity || '');
  const [quantityErrorText, setQuantityErrorText] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const groundControlOptions = useMemo(() => {
    return JSON.parse(JSON.parse(groundControl.options));
  }, [groundControl.options]);

  const setDefaultOptionValues = () => {
    const result: any[] = [];

    groundControlOptions.forEach(option => {
      result.push({ optionId: option.id, valueId: option.values[0].id });
    });
    setSelectedOptions(result);
  };

  useConstructor(() => {
    setDefaultOptionValues();
  });

  const handleGroundControlChecked = (checked: boolean) => {
    setGroundControlChecked(checked);
    onGroundControlSelected(
      groundControl.id,
      stepperQuantityValue,
      selectedOptions,
      groundControl.requiresQuantity,
    );
  };
  const validateQuantity = (val: string) => RegExp('^[0-9]+$').test(val) && parseInt(val, 10) > 0;

  useEffect(() => {
    if (groundControl.requiresQuantity && groundControlChecked && validationIndex >= 10) {
      const isValidQuantity = validateQuantity(stepperQuantityValue);

      if (!stepperQuantityValue) {
        setQuantityErrorText('Required');
        setValidationError(index, true);
      } else if (!isValidQuantity) {
        setQuantityErrorText('Invalid quantity');
        setValidationError(index, true);
      } else {
        setQuantityErrorText('');
        setValidationError(index, false);
      }
    } else if (
      groundControl.requiresQuantity &&
      groundControlChecked &&
      stepperQuantityValue === ''
    ) {
      setValidationError(index, true);
    } else {
      setQuantityErrorText('');
      setValidationError(index, false);
    }
  }, [stepperQuantityValue, groundControlChecked, index, validationIndex]);

  const handleQuantityStepperChanged = (val: string) => {
    const isValidQuantity = validateQuantity(val);

    setStepperQuantityValue(val);
    onGroundControlUpdated(groundControl.id, val, !isValidQuantity);
  };

  const handleToggleOptionChanged = (optionId: string, valueId: string) => {
    const quantityInvalid = !validateQuantity(stepperQuantityValue);

    onGroundControlUpdated(
      groundControl.id,
      groundControl.requiresQuantity ? stepperQuantityValue : undefined,
      groundControl.requiresQuantity ? quantityInvalid : false,
      optionId,
      valueId,
    );
  };

  const handleDropdownOptionChanged = (optionId: string, valueId: string) => {
    const quantityInvalid = !validateQuantity(stepperQuantityValue);

    onGroundControlUpdated(
      groundControl.id,
      groundControl.requiresQuantity ? stepperQuantityValue : undefined,
      groundControl.requiresQuantity ? quantityInvalid : false,
      optionId,
      valueId,
    );
  };

  const getSelectedValueId = (optionId: string) => {
    const value = selectedValues.find(x => x.optionId === optionId);
    return value?.valueId;
  };

  return (
    <div>
      <Grid item xs={12} key={groundControl.id}>
        <FormControlLabel
          control={
            <Checkbox
              key={`${groundControl.id}-main-checkbox`}
              onChange={(evt: any) => {
                handleGroundControlChecked(evt.target.checked);
              }}
              name="remediation-complete-checkbox"
              checked={groundControlChecked}
            />
          }
          label={groundControl.description}
          className={classes.checkboxLabel}
        />
      </Grid>
      {groundControlChecked && (
        <>
          {groundControl.requiresQuantity && (
            <Grid item xs={12} className={classes.optionsContainer}>
              <TextField
                key={`${groundControl.id}-quantity-checkbox`}
                label="Quantity"
                required
                onChange={event => handleQuantityStepperChanged(event.target.value)}
                onBlur={onGroundControlBlur}
                onFocus={onGroundControlFocus}
                value={stepperQuantityValue}
                variant="outlined"
                data-cy="quantity-checkbox"
                inputProps={{ inputMode: 'numeric' }}
                error={quantityErrorText !== ''}
                errorText={quantityErrorText}
              />
            </Grid>
          )}
          {groundControlOptions.map((option, gcIndex) => {
            if (option.displayType === GroundControlOptionDisplayType.TOGGLE) {
              return (
                <div key={`${option.id}-${gcIndex}`}>
                  <GroundControlToggleOption
                    option={option}
                    selectedValueId={getSelectedValueId(option.id)}
                    optionClassName={classes.option}
                    handleValueChanged={handleToggleOptionChanged}
                  />
                </div>
              );
            }
            if (option.displayType === GroundControlOptionDisplayType.CHECKBOXES) {
              return (
                <div key={`${option.id}-checkboxes`}>
                  <GroundControlCheckboxesOption
                    key={option.id}
                    option={option}
                    selectedValueId={getSelectedValueId(option.id)}
                    handleValueChanged={handleToggleOptionChanged}
                  />
                </div>
              );
            }
            if (option.displayType === GroundControlOptionDisplayType.DROPDOWN) {
              return (
                <div key={`${option.id}-checkboxes`}>
                  <GroundControlDropdownOption
                    key={option.id}
                    option={option}
                    selectedValueId={getSelectedValueId(option.id)}
                    handleValueChanged={handleDropdownOptionChanged}
                  />
                </div>
              );
            }
            return <div key={`${groundControl.id}-default`} />;
          })}
        </>
      )}
    </div>
  );
};

export default GroundControlType;
