import { Card, i18n, Tab, Tabs, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import AssignedEquipment from '../AssignedEquipment';
import CompleteWOTab from '../CompleteWOTab';
import DeficienciesChecksTab from '../DeficienciesChecksTab';
import DeficienciesTab from '../DeficienciesTab';
import useStyles from './Equipment.styles';

const tabProperties = [
  { label: i18n.t('Deficiencies'), key: 'deficienciesTab' },
  { label: i18n.t('Checks this shift'), key: 'checksTab' },
  { label: i18n.t('Complete WOs'), key: 'woTab' },
  { label: i18n.t('Assigned equipment'), key: 'assignedEq' },
];

const Equipment: React.FC = () => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const onTabChanged = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabIndex(newValue);
  };

  const getUIForTab = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <DeficienciesTab />;
      case 1:
        return <DeficienciesChecksTab />;
      case 2:
        return <CompleteWOTab />;
      case 3:
        return <AssignedEquipment />;
      default:
        return (
          <div>
            <Typography>Unknown Tab</Typography>
          </div>
        );
    }
  }, [tabIndex]);

  return (
    <div className={classes.root}>
      <Card className={classes.tabCard}>
        <Tabs value={tabIndex} variant="standard" onChange={onTabChanged}>
          {tabProperties.map(tab => (
            <Tab label={tab.label} key={tab.key} />
          ))}
        </Tabs>
      </Card>
      <div>{getUIForTab}</div>
    </div>
  );
};

export default observer(Equipment);
