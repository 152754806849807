import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDoc } from '../Shared/defaultDoc';

export interface WorkOrder extends BaseEntity {
  readonly equipmentId: string;
  readonly description: string | null;
  readonly status: string;
  readonly ticketNumber: number;
  readonly priority: string | null;
  readonly scheduledStartDate: string | null;
  readonly completedDate: string | null;
  readonly changedDate: string | null;
  readonly isWorkRequest: boolean;
  readonly assetIdentifier: string;
}
export type WorkOrderCollection = RxCollection<WorkOrder> | null;
export type WorkOrderDocument = RxDocument<WorkOrder>;

export const workOrdersSchema: RxJsonSchema<WorkOrder> = {
  type: 'object',
  version: 1,
  description: 'describes a WorkOrder object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    equipmentId: { type: 'string', ref: RxdbCollectionName.EQUIPMENT },
    description: { type: ['string', 'null'] },
    status: { type: 'string' },
    ticketNumber: { type: ['number'] },
    priority: { type: ['string', 'null'] },
    scheduledStartDate: { type: ['string', 'null'] },
    completedDate: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    changedDate: { type: ['string', 'null'] },
    isWorkRequest: { type: 'boolean' },
    assetIdentifier: { type: 'string' },
  },
};

export const workOrdersMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: WorkOrderDocument) => ({ ...oldDoc, assetIdentifier: '' }),
};

export const workOrdersPullQueryBuilder = (doc: WorkOrderDocument) => {
  let document = doc;
  if (!doc) {
    document = { ...defaultDoc };
  }

  const query = print(gql`
    query workOrderFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      workOrderFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        equipmentId
        isDeleted
        updatedAt
        version
        description
        scheduledStartDate
        completedDate
        changedDate
        status
        ticketNumber
        priority
        isWorkRequest
        assetIdentifier
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: document.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
