import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { v4 } from 'uuid';

dayjs.extend(utc);
dayjs.extend(timezone);

export const USER_TIMEZONE = 'America/Regina';

export const MMM_D_DATE_FORMAT = 'MMM D';
export const YYYYMMDD_DATE_FORMAT = 'YYYY-MM-DD';
export const MMM_D_YYYY_DATE_FORMAT = 'MMM D, YYYY';
export const MMM_D_TIME_FORMAT = 'MMM D, h:mm a';
export const MMM_D_YYYY_TIME_FORMAT = 'MMM D, YYYY h:mm a';
export const MMM_D_YYYY_2_TIME_FORMAT = 'MMM D YYYY h:mm a';
export const TIME_FORMAT = 'h:mm a';
export const HH_MM_FORMAT = 'HH:mm';
export const AWS_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const SCHEDULER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const CALENDAR_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DAY_OF_WEEK_PLUS_DATE_FORMAT = 'ddd, MMM D';
export const TIME_WITH_AM_PM_FORMAT = 'h:mm:ss A';
export const ABBREVIATED_DATE_FORMAT = 'MMM D/YY';

const getTimeFromUnixInLocalTz = (unixTimestamp: number | undefined) => {
  if (!unixTimestamp) return '';

  const date = dayjs.unix(unixTimestamp).tz(USER_TIMEZONE, true);
  return date.format(TIME_FORMAT);
};

const format = (date: Dayjs) => {
  if (!date) return;

  const calendarYearStart = dayjs().startOf('year');
  const calendarYearEnd = dayjs().endOf('year');

  // If date with current calendar year
  // date is same as or between start and end
  if (
    date.isSame(calendarYearStart) ||
    date.isSame(calendarYearEnd) ||
    (date.isAfter(calendarYearStart) && date.isBefore(calendarYearEnd))
  ) {
    return date.format(MMM_D_TIME_FORMAT);
  }

  // If date not within current calendar year
  return date.format('MMM D, YYYY, h:mm a');
};

const formatDateFromUnix = (unixTimestamp: number | undefined) => {
  if (!unixTimestamp) return;

  const date = dayjs.unix(unixTimestamp).tz(USER_TIMEZONE, true);
  return format(date);
};

export const formatDateToUnix = (dateString: string): number => {
  return dayjs(dateString).unix();
};

const formatDateInTZ = (
  date: string | Dayjs | null,
  formatString = YYYYMMDD_DATE_FORMAT,
  utcConversion = true,
) => {
  if (!date) return '';
  if (utcConversion) {
    return dayjs.utc(date).tz(USER_TIMEZONE).format(formatString);
  }
  return dayjs.tz(date, USER_TIMEZONE).format(formatString);
};

const formatDate = (dateString: string) => {
  const date = dayjs(dateString).tz(USER_TIMEZONE);
  return format(date);
};

const getDayInUserTZ = (dateTimeUTC: string) => dayjs.tz(dateTimeUTC, 'UTC').tz(USER_TIMEZONE);

export const formatInLocalTimezone = (
  dateTimeUTC: string,
  dateFormat: string = MMM_D_YYYY_TIME_FORMAT,
): string => {
  return getDayInUserTZ(dateTimeUTC).format(dateFormat);
};

const formatDateWithDisappearingDate = (dateTimeUTC?: string, showTime = true): string => {
  if (!dateTimeUTC) return '';
  const dayInUserTZ = getDayInUserTZ(dateTimeUTC).startOf('day');
  const startOfToday = dayjs().startOf('day').tz(USER_TIMEZONE, true);

  const startOfYear = startOfToday.clone().startOf('year');

  // if is in current day => 6:43 am
  if (dayInUserTZ.isSame(startOfToday)) {
    return formatInLocalTimezone(dateTimeUTC, TIME_FORMAT);
  }

  // if is in current year => Jan 13, 6:43 am
  if (dayInUserTZ.isAfter(startOfYear)) {
    return formatInLocalTimezone(dateTimeUTC, showTime ? MMM_D_TIME_FORMAT : MMM_D_DATE_FORMAT);
  }

  // if is outside current year => Jan 14, 2020, 6:43am
  if (dayInUserTZ.isBefore(startOfYear)) {
    return formatInLocalTimezone(
      dateTimeUTC,
      showTime ? MMM_D_YYYY_TIME_FORMAT : MMM_D_YYYY_DATE_FORMAT,
    );
  }

  return '';
};

const formatFromUnixToDateWithDisappearingDate = (unixTimestamp: number | undefined): string => {
  if (!unixTimestamp) return '';
  return formatDateWithDisappearingDate(dayjs.unix(unixTimestamp).toDate().toISOString());
};

const dateIsBetweenDates = (date: Dayjs, rangeStart: Dayjs, rangeEnd: Dayjs, inclusive = true) => {
  // Check if provided date is between two dates
  if (!inclusive) return date.isAfter(rangeStart) && date.isBefore(rangeEnd);

  // Check if provided date is between two dates including the start and end dates
  return (
    (date.isAfter(rangeStart) && date.isBefore(rangeEnd)) ||
    date.isSame(rangeStart) ||
    date.isSame(rangeEnd)
  );
};

const getTimeFromUnixMilliInLocalTz = (unixMillisecondTimestamp: number | undefined) => {
  if (!unixMillisecondTimestamp) return '';

  const date = dayjs(unixMillisecondTimestamp).tz(USER_TIMEZONE, true);
  return date.format(TIME_FORMAT);
};

const formatDateFromUnixMilliInLocalTz = (unixMillisecondTimestamp: number | undefined) => {
  if (!unixMillisecondTimestamp) return '';

  const date = dayjs(unixMillisecondTimestamp).tz(USER_TIMEZONE, true);
  return format(date);
};

export const formatDateFromUnixInReginaTzForScheduler = (
  unixTimestamp: number,
  dateFormat = SCHEDULER_DATE_FORMAT,
) => {
  return dayjs.unix(unixTimestamp).tz(USER_TIMEZONE).format(dateFormat);
};

const convertMinutesToShortenedTimeString = (minutes: number): string => {
  let hours;
  let displayMinutes;
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    displayMinutes = minutes % 60;
  } else if (!minutes) {
    displayMinutes = 0;
  } else {
    displayMinutes = minutes;
  }
  if (minutes < 0 || (!displayMinutes && displayMinutes !== 0)) return '';
  let str = '';
  if (hours) str += `${hours} hr, `;
  str += `${displayMinutes} min`;
  return str;
};

const stripSecondsAndMilliseconds = (d: Dayjs): Dayjs => {
  return d.clone().second(0).millisecond(0);
};

const getIdAndUTCTimestamp = () => {
  const id = v4();
  const createdOn = dayjs.utc().format(AWS_DATE_TIME_FORMAT);

  return { id, createdOn, modifiedOn: createdOn, remediatedOn: createdOn };
};

export const formatAsAWSDateTime = (date: Dayjs, convertToUTC = false): string =>
  convertToUTC
    ? `${date.tz('UTC').format(AWS_DATE_TIME_FORMAT)}Z`
    : `${date.format(AWS_DATE_TIME_FORMAT)}Z`;

const formatAsDayOfWeekPlusDate = (dateTimeUTC: string, isNightShift: boolean): string => {
  const dayInUserTZ = getDayInUserTZ(dateTimeUTC).startOf('day');
  return `${dayInUserTZ.format(DAY_OF_WEEK_PLUS_DATE_FORMAT)}, ${isNightShift ? 'NS' : 'DS'}`;
};

const isNightShift = (startUtc: string, endUtc: string): boolean => {
  const start = getDayInUserTZ(startUtc);
  const end = getDayInUserTZ(endUtc);
  return end.date() > start.date();
};

export const createDayJsUnix = (unix?: number) => {
  if (!unix) return dayjs();
  return dayjs.unix(unix);
};

export const useDateFormatters = () => {
  return {
    format,
    formatDate,
    formatDateFromUnix,
    formatDateToUnix,
    formatDateWithDisappearingDate,
    formatFromUnixToDateWithDisappearingDate,
    getDayInUserTZ,
    getTimeFromUnixInLocalTz,
    formatDateInTZ,
    dateIsBetweenDates,
    getTimeFromUnixMilliInLocalTz,
    formatDateFromUnixMilliInLocalTz,
    convertMinutesToShortenedTimeString,
    stripSecondsAndMilliseconds,
    getIdAndUTCTimestamp,
    formatAsAWSDateTime,
    formatAsDayOfWeekPlusDate,
    isNightShift,
    formatDateFromUnixInReginaTzForScheduler,
  };
};
