import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

import ChecksThisShiftCard from '../ChecksThisShiftCard';

const AreaCheckChecksTab: React.FC = () => {
  return (
    <Grid container data-cy="AreaCheckConditionsTab">
      <Grid item xs={12}>
        <ChecksThisShiftCard />
      </Grid>
    </Grid>
  );
};

export default observer(AreaCheckChecksTab);
