import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import rxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface PanelLog extends BaseEntity {
  readonly panelId: string;
  readonly borerEquipmentId: string;
  readonly log: string;
  readonly reportedBySiteEmployeeId: string;
  readonly createdOn: string;
  readonly modifiedOn: string;
}

export type PanelLogDocument = RxDocument<PanelLog>;
export type PanelLogCollection = RxCollection<PanelLog> | null;

export const panelLogSchema: RxJsonSchema<PanelLog> = {
  type: 'object',
  version: 2,
  description: 'describes a panel log object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    panelId: { ref: rxdbCollectionName.PANELS, type: 'string', maxLength: 36 },
    borerEquipmentId: { type: 'string', maxLength: 36 },
    log: { type: 'string' },
    reportedBySiteEmployeeId: { ref: rxdbCollectionName.EMPLOYEES, type: 'string', maxLength: 36 },
    modifiedOn: { type: 'string' },
    isDeleted: { type: 'boolean' },
    createdOn: { type: 'string' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
  },
};

export const panelLogsPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = {
      ...defaultDoc,
      updatedAt: dayjs().startOf('day').subtract(90, 'days').valueOf(),
    };
  }

  const query = print(gql`{
      panelLogFeed (borerEquipmentId: "${rootStore.equipment.selectedBorerId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
          id
          panelId
          borerEquipmentId
          log
          createdOn
          modifiedOn
          reportedBySiteEmployeeId
          isDeleted
          version
          updatedAt
        }
      }`);

  return {
    query,
    variables: {},
  };
};

export const panelLogMigrationStrategies: MigrationStrategies = {
  2: (oldDoc: PanelLogDocument) => {
    return {
      ...oldDoc,
    };
  },
  1: (oldDoc: PanelLogDocument) => {
    return {
      ...oldDoc,
      modifiedOn: dayjs().toISOString(),
      createdOn: dayjs().toISOString(),
      reportedBySiteEmployeeId: '00000000-0000-0000-0000-000000000000',
    };
  },
};

export const panelLogPushQueryBuilder = (doc: PanelLogDocument[]) => {
  const firstDoc = doc[0];

  const query = `
      mutation setPanelLog($panelLog: SetPanelLogInput) {
        setPanelLog(input: $panelLog) {
          borerEquipmentId
          createdOn
          id
          isDeleted
          log
          modifiedOn
          panel {
            description
            id
          }
          siteEmployee {
            description
            id
          }
          siteId
          version
        }
      }`;

  const variables = {
    panelLog: firstDoc,
  };

  return {
    query,
    variables,
  };
};

export const panelLogPushModifier = (doc: any) => {
  const result = doc;

  const propertyBlackList = [
    'modifiedOn',
    'updatedAt',
    '_deleted',
    '_attachments',
    '_rev',
    '_meta',
  ];
  propertyBlackList.forEach(property => {
    result[property] = undefined;
  });

  return result;
};
