import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { rootStore, useMst } from '../../mobx-models/Root';
import { useDateFormatters } from '../../utilities';
import { DeficiencyStatus } from '../../utilities/enums';
import { sortDescendingByCreatedOn } from '../../utilities/sortHelper';
import { EquipmentDocument } from '../Equipment/queryBuilder';
import {
  EquipmentDeficiencyLogCollection,
  EquipmentDeficiencyLogDocument,
} from '../EquipmentDeficiencyLog/queryBuilder';
import { EquipmentTypeDocument } from '../EquipmentType/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../rxdbUtilityFunctions';
import {
  EquipmentDeficiency,
  EquipmentDeficiencyCollection,
  EquipmentDeficiencyDocument,
} from './queryBuilder';

export interface EquipmentDeficiencyWithChildren {
  deficiency: EquipmentDeficiencyDocument;
  equipment: EquipmentDocument;
  equipmentType: EquipmentTypeDocument;
  logs: EquipmentDeficiencyLogDocument[];
}

const useEquipmentDeficiency = () => {
  const { equipment, shiftPicker } = useMst();
  const { dateIsBetweenDates } = useDateFormatters();
  const equipmentDeficiencyCollection: EquipmentDeficiencyCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_DEFICIENCY,
  );
  const equipmentDeficiencyLogCollection: EquipmentDeficiencyLogCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_LOG,
  );

  const [deficiencyCollectionsInitialized, setCollectionsInitialized] = useState(false);

  useEffect(() => {
    if (equipmentDeficiencyCollection) setCollectionsInitialized(true);
  }, [equipmentDeficiencyCollection]);

  const createEquipmentDeficiency = (
    description: string,
    equipmentId: string,
    isWorkOrderRequired: boolean,
    fullyAddressed = false,
  ) => {
    if (!equipment.selectedBorerId) throw new Error('Missing selectedBorerId');

    let fullyAddressedDate = null;
    if (fullyAddressed) fullyAddressedDate = dayjs().toISOString();

    const doc: EquipmentDeficiency = {
      ...generateBaseEntityWithCreatedOn(),
      description,
      equipmentId,
      isWorkOrderRequired,
      borerEquipmentId: equipment.selectedBorerId,
      siteId: rootStore.user.siteId,
      fullyAddressedDate,
      approvalDate: null,
      denialDate: null,
    };

    return equipmentDeficiencyCollection?.insert(doc);
  };

  const listEquipmentDeficienciesLogs = async (
    equipmentDeficiencyId: string,
  ): Promise<EquipmentDeficiencyLogDocument[]> => {
    if (!equipmentDeficiencyLogCollection) return [];

    try {
      const results: EquipmentDeficiencyLogDocument[] = await equipmentDeficiencyLogCollection
        .find({
          selector: {
            equipmentDeficiencyId,
          },
        })
        .sort({
          updatedAt: 'desc',
        })
        .exec();

      return results;
    } catch (error) {
      console.log(
        '🚀 ~ file: useEquipmentDeficiency.ts ~ line 91 ~ useEquipmentDeficiency ~ error',
        error,
      );
      throw error;
    }
  };

  const listActiveEquipmentDeficienciesForThisShift = async (): Promise<
    EquipmentDeficiencyWithChildren[]
  > => {
    if (!equipmentDeficiencyCollection) return [];

    const { selectedShiftEndUTC } = shiftPicker;

    let deficiencyDocs: EquipmentDeficiencyDocument[];
    try {
      deficiencyDocs = await equipmentDeficiencyCollection
        .find({
          selector: {
            $and: [
              { fullyAddressedDate: { $eq: null } },
              { approvalDate: { $eq: null } },
              { denialDate: { $eq: null } },
            ],
          },
        })
        .exec();

      // Filter out docs completed this shift
      deficiencyDocs = deficiencyDocs
        .filter(doc => {
          const docDate = doc.createdOn;
          if (!docDate) return false;

          return dayjs.utc(docDate).isBefore(selectedShiftEndUTC);
        })
        .sort(sortDescendingByCreatedOn);
    } catch (error) {
      console.log(
        '🚀 ~ file: useEquipmentDeficiency.ts ~ line 60 ~ useEquipmentDeficiency ~ error',
        error,
      );
      throw error;
    }

    const result = await Promise.all(
      deficiencyDocs.map(async (doc: EquipmentDeficiencyDocument) => {
        const logs = await listEquipmentDeficienciesLogs(doc.id);
        const equipmentDoc = await doc.populate('equipmentId');
        const equipmentType = await equipmentDoc.populate('equipmentTypeId');
        if (equipmentDoc.isActive) {
          return { deficiency: doc, equipmentType, logs, equipment: equipmentDoc };
        }
      }),
    );

    return result.filter(deficiency => !!deficiency);
  };

  /**
   * Get the set of documents for the current shift where the fullyAddressedDate, approvalDate, or deniedDate lands between the current shifts start and end dates
   *
   * @return {*}  {Promise<EquipmentDeficiencyWithChildren[]>}
   */
  const listNonActiveEquipmentDeficienciesThisShift = async (): Promise<
    EquipmentDeficiencyWithChildren[]
  > => {
    if (!equipmentDeficiencyCollection) return [];
    const { selectedShiftStartUTC, selectedShiftEndUTC } = shiftPicker;
    const docs = await equipmentDeficiencyCollection
      .find({
        selector: {
          $or: [
            { fullyAddressedDate: { $ne: null } },
            { approvalDate: { $ne: null } },
            { denialDate: { $ne: null } },
          ],
        },
      })
      .sort({ updatedAt: 'desc' })
      .exec();
    const deficiencyDocs = docs
      .filter(doc => {
        const docDate = doc.approvalDate || doc.denialDate || doc.fullyAddressedDate;
        if (!docDate) return false;

        const docDateDayJs = dayjs.utc(docDate);
        return dateIsBetweenDates(docDateDayJs, selectedShiftStartUTC, selectedShiftEndUTC);
      })
      .sort((a, b) => {
        return b.updatedAt - a.updatedAt;
      });

    const result = await Promise.all(
      deficiencyDocs.map(async (doc: EquipmentDeficiencyDocument) => {
        const logs = await listEquipmentDeficienciesLogs(doc.id);
        const equipmentDoc = await doc.populate('equipmentId');
        const equipmentType = await equipmentDoc.populate('equipmentTypeId');

        if (equipmentDoc.isActive) {
          return { deficiency: doc, equipmentType, logs, equipment: equipmentDoc };
        }
      }),
    );

    return result.filter(deficiency => !!deficiency);
  };

  const getEquipmentDeficiency = async (
    deficiencyId: string,
  ): Promise<EquipmentDeficiencyDocument | null> => {
    if (!equipmentDeficiencyCollection) return null;

    return equipmentDeficiencyCollection
      .findOne({
        selector: {
          id: deficiencyId,
        },
      })
      .exec();
  };

  const updateEquipmentDeficiency = async (
    deficiencyId: string,
    status: DeficiencyStatus = DeficiencyStatus.ActiveDeficiency,
    isWorkOrderRequired = false,
  ): Promise<any> => {
    if (!equipmentDeficiencyCollection) return;

    try {
      const doc = await getEquipmentDeficiency(deficiencyId);

      if (!doc) throw new Error('Document with id not found');

      let fullyAddressedDate = null;
      if (status === DeficiencyStatus.FullyAddressed) fullyAddressedDate = dayjs().toISOString();

      await doc.update({
        $set: {
          isWorkOrderRequired,
          fullyAddressedDate,
          isDeleted: doc.deleted,
        },
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: useEquipmentDeficiency.ts ~ line 240 ~ useEquipmentDeficiency ~ error',
        error,
      );
      throw error;
    }
  };

  const listEquipmentDeficienciesForIds = async (
    equipmentDeficiencyIds: string[],
  ): Promise<Map<string, EquipmentDeficiencyWithChildren>> => {
    let result = new Map();
    if (!equipmentDeficiencyCollection) return result;

    let deficiencyDocs: EquipmentDeficiencyDocument[];
    try {
      result = await equipmentDeficiencyCollection.findByIds(equipmentDeficiencyIds);
      deficiencyDocs = Array.from(result.values());
    } catch (error) {
      console.log(
        '🚀 ~ file: useEquipmentDeficiency.ts ~ line 60 ~ useEquipmentDeficiency ~ error',
        error,
      );
      throw error;
    }

    const populatedRecords = await Promise.all(
      deficiencyDocs.map(async (doc: EquipmentDeficiencyDocument) => {
        const logs = await listEquipmentDeficienciesLogs(doc.id);
        const equipmentDoc = await doc.populate('equipmentId');
        const equipmentType = await equipmentDoc.populate('equipmentTypeId');

        return { deficiency: doc, equipmentType, logs, equipment: equipmentDoc };
      }),
    );

    populatedRecords.forEach(record => result.set(record.deficiency.id, record));

    return result;
  };

  return {
    deficiencyCollectionsInitialized,
    createEquipmentDeficiency,
    listActiveEquipmentDeficienciesForThisShift,
    equipmentDeficiencyCollection,
    listNonActiveEquipmentDeficienciesThisShift,
    updateEquipmentDeficiency,
    getEquipmentDeficiency,
    listEquipmentDeficienciesForIds,
  };
};

export { useEquipmentDeficiency };
export default useEquipmentDeficiency;
