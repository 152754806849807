import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { Card, i18n, TextField, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import { useBorerShiftInfo } from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';
import { useNotification } from '../../utilities';
import { FLOATING_POINT_REGEX } from '../../utilities/mathHelper';
import DiscardDraftModal from '../DiscardDraftModal';
import GenericSidePanel from '../GenericSidePanel';

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
  isCory: boolean;
  isVanscoy: boolean;
}

const EditMeterHoursSidePanel = ({ open, onClose, onOpen, onCancel, isCory, isVanscoy }: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  const { setMeterHours, startMeterHours, endMeterHours, borerShiftInfoInitialized } =
    useBorerShiftInfo(shiftPicker.currentBorerShiftId);

  const { successNotification } = useNotification();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    meterHours: '',
  });
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [meterHoursValue, setMeterHoursValue] = useState<string>('0');

  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setErrors({
        meterHours: '',
      });
      setHasEdits(false);
      setCanSave(false);
      setIsSaving(false);
      setHasEdits(false);
      if (endMeterHours) setMeterHoursValue(endMeterHours.toString());
      else if (startMeterHours) setMeterHoursValue(startMeterHours.toString());
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open, borerShiftInfoInitialized, startMeterHours, endMeterHours]);

  // Input Validation
  const validateSave = useCallback(() => {
    let validSave = true;

    if (Object.values(errors).find(error => error !== '')) {
      validSave = false;
    }

    setCanSave(validSave && hasEdits);
  }, [errors]);

  useEffect(() => {
    validateSave();
  }, [validateSave]);

  const onMeterHoursChanged = (value: string) => {
    setMeterHoursValue(value);
    setHasEdits(true);

    const meterHoursNumber = parseFloat(value);

    let meterHoursGreaterThanStart = true;
    if (startMeterHours && startMeterHours > meterHoursNumber) meterHoursGreaterThanStart = false;

    if (RegExp(FLOATING_POINT_REGEX).test(value) && meterHoursGreaterThanStart) {
      setErrors(prev => ({ ...prev, meterHours: '' }));
    } else if (!meterHoursGreaterThanStart) {
      setErrors(prev => ({
        ...prev,
        meterHours: i18n.t('Must be greater than start.'),
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        meterHours: i18n.t('Invalid meter hours.'),
      }));
    }
  };

  // Save
  const onSave = async () => {
    const meterHoursNumber = parseFloat(meterHoursValue);

    if (
      !warningOpen &&
      (startMeterHours || startMeterHours === 0) &&
      meterHoursNumber - startMeterHours > 6
    ) {
      setWarningOpen(true);
      return;
    }
    // update the Prediction
    setIsSaving(true);
    try {
      await setMeterHours(meterHoursNumber);

      successNotification('Meter hours updated');
      onClose();
    } catch (error) {
      console.log('🚀 ~ file: EditMeterHoursSidePanel.tsx ~ line 337 ~ onSave ~ error', error);
    }
    setIsSaving(false);
    setWarningOpen(false);
  };

  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={
          isCory
            ? i18n.t('Edit end Head motor hours')
            : isVanscoy
            ? i18n.t('Edit end rotor hours')
            : i18n.t('Edit end meter hours')
        }
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Meter hours draft discarded"
      >
        <Card className={classes.cardRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                {isCory
                  ? i18n.t('End Head motor hours')
                  : isVanscoy
                  ? i18n.t('End rotor hours')
                  : i18n.t('End meter hours')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={i18n.t('End')}
                required
                unitText={i18n.t('hrs')}
                onChange={event => onMeterHoursChanged(event.target.value)}
                value={meterHoursValue}
                data-cy="EndMeterHours"
                inputProps={{ inputMode: 'numeric' }}
                error={errors.meterHours !== ''}
                errorText={errors.meterHours}
              />
            </Grid>
          </Grid>
        </Card>
      </GenericSidePanel>
      <DiscardDraftModal
        open={warningOpen}
        titleText={i18n.t('Meter Hours Limit Reached')}
        discardDraftButtonText={i18n.t('Save')}
        continueEditingButtonText={i18n.t('Cancel')}
        areYouSureText={''}
        onCancel={() => {
          setWarningOpen(false);
        }}
        cancelText={i18n.t('Your meter hours have surpassed 6 hours. Would you like to proceed?')}
        onDiscard={() => onSave()}
        bothPrimaryColors={true}
        hideAreYouSureText={true}
        maxHeight={230}
      />
    </>
  );
};

export default observer(EditMeterHoursSidePanel);
