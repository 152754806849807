import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { AutoComplete, ExpansionPanel, i18n, TextField } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type { RxDocument } from 'rxdb';

import GenericSidePanel from '@/components/GenericSidePanel';
import { useMst } from '@/mobx-models/Root';
import useEmployees from '@/rxdb/Employees/useEmployees';
import { PanelLog } from '@/rxdb/PanelLog/queryBuilder';
import { usePanels } from '@/rxdb/Panels/usePanels';
import useProduction, { ProductionLocationInfo } from '@/rxdb/Productions/useProduction';
import { useNotification } from '@/utilities';

import useStyles from './RoomConditionCommentEditDrawer.styles';
import type {
  DescriptionWithId,
  RoomConditionCommentSchemaType,
} from './RoomConditionCommentSchema';
import { RoomConditionCommentSchema } from './RoomConditionCommentSchema';

export interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
  createPanelLog: (
    log: string,
    reportedBySiteEmployeeId: string,
    panelId: string,
  ) => Promise<RxDocument<PanelLog, any> | undefined>;
}

const RoomConditionCommentEditDrawer: React.FC<Props> = ({
  open,
  onClose,
  onOpen,
  onCancel,
  createPanelLog,
}: Props) => {
  const classes = useStyles();
  const defaultValues: RoomConditionCommentSchemaType = { panel: null, name: null, comment: '' };
  const { errorNotification, successNotification } = useNotification();

  const [isSaving, setIsSaving] = useState(false);
  const [undo, setUndo] = useState(false);

  const { equipment } = useMst();

  const { panelListPopulated: panelDocsList } = usePanels();
  const { mostRecentProductionLocationInformation } = useProduction();
  const { employeesList: employeesDocsList } = useEmployees({
    onlyConstructionAndProduction: false,
  });

  const { useDefaultBlock } = useSiteFeatures();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    delayError: 100,
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(RoomConditionCommentSchema),
  });

  const panelList = useMemo(() => {
    return panelDocsList?.map(val => {
      const description = useDefaultBlock
        ? `${val?.panel?.description || ''}`
        : `${val?.block?.description || ''}${val?.panel?.description || ''}`;

      return {
        description,
        id: val?.panel?.id,
      };
    });
  }, [panelDocsList, useDefaultBlock]);

  const employeesList = useMemo(() => {
    return employeesDocsList
      ?.map(val => {
        return { fullName: `${val.firstName} ${val.lastName}`, id: val?.id };
      })
      .sort((a, b) => {
        return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase());
      });
  }, [employeesDocsList]);

  useEffect(() => {
    if (open && !undo) {
      reset(defaultValues);
    } else if (open) {
      setUndo(false);
    }
  }, [reset, open]);

  useEffect(() => {
    async function updateSelectedPanel() {
      const { panel, block } = mostRecentProductionLocationInformation as ProductionLocationInfo;
      const assignedPanel = equipment.selectedBorerAssignedPanel;

      if (panel && block) {
        const panelDesc = useDefaultBlock
          ? `${panel?.description || ''}`
          : `${block?.description || ''}${panel?.description || ''}`;

        setValue('panel', {
          description: panelDesc,
          id: panel.id,
        });
      } else if (assignedPanel) {
        const panelDesc = useDefaultBlock
          ? `${assignedPanel?.description || ''}`
          : `${assignedPanel?.block?.description || ''}${assignedPanel?.description || ''}`;

        setValue('panel', {
          description: panelDesc,
          id: assignedPanel.id,
        });
      }
    }

    if (panelList && open && !undo) {
      updateSelectedPanel();
    }
  }, [
    panelList,
    setValue,
    mostRecentProductionLocationInformation,
    open,
    undo,
    equipment,
    useDefaultBlock,
  ]);

  const handleErrorClose = (message: string) => {
    errorNotification(message);
    onClose();
    setIsSaving(false);
  };

  const onSave = async (formValues: RoomConditionCommentSchemaType) => {
    setIsSaving(true);
    try {
      await createPanelLog(formValues.comment as string, formValues.name.id, formValues.panel.id);
      successNotification(i18n.t('Room condition comment saved.'));
    } catch (error) {
      console.error('🚀 ~ file: RoomConditionCommentEditDrawer.tsx ~ line 109 ~ error', error);
      return handleErrorClose(i18n.t('Error saving room condition comment.'));
    }
    onClose();
    setIsSaving(false);
  };

  if (!open) return null;
  return (
    <GenericSidePanel
      open={open}
      onClose={onClose}
      title={i18n.t('Add room condition comment')}
      onOpen={onOpen}
      hasEdits={isDirty}
      canSave={isDirty && isValid}
      isSaving={isSaving}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      discardNotificationText="Room condition comment discarded"
      setUndo={setUndo}
    >
      <ExpansionPanel
        className={classes.panel}
        key="room-condition-comment-panel"
        data-cy="room-condition-comment-panel"
        TransitionProps={{ unmountOnExit: true }}
        expansionPanelSummaryProps={{
          expandIcon: undefined,
          classes: {
            root: classes.panelSummary,
          },
        }}
        expansionPanelDetailProps={{
          classes: {
            root: classes.panelContent,
          },
        }}
        expanded
        panelContent={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="panel"
                control={control}
                render={({ field }) => (
                  <AutoComplete
                    id="room-condition-comment-panel-input"
                    list={panelList}
                    label={i18n.t('Panel')}
                    data-testid="room-condition-comment-panel-input"
                    getOptionLabel={(item: DescriptionWithId) => item.description}
                    onChange={(evt, val) => field.onChange(val)}
                    value={field.value}
                    error={!!errors.panel}
                    errorText={errors.panel?.message as string}
                    showCaret
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <AutoComplete
                    id="room-condition-comment-name-input"
                    list={employeesList}
                    label={i18n.t('Your name')}
                    data-testid="room-condition-comment-employee-name-input"
                    getOptionLabel={(item: DescriptionWithId) => item.fullName}
                    onChange={(evt, val) => field.onChange(val)}
                    value={field.value}
                    error={!!errors.name}
                    errorText={errors.name?.message as string}
                    showCaret
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="room-condition-comment-comment-input"
                    label={i18n.t('Comment')}
                    onChange={field.onChange}
                    value={field.value}
                    data-testid="room-condition-comment-comment-input"
                    error={!!errors.comment}
                    errorText={errors.comment?.message}
                    multiline
                    rows={4}
                    rowsMax={Infinity}
                  />
                )}
              />
            </Grid>
          </Grid>
        }
      />
    </GenericSidePanel>
  );
};

export default observer(RoomConditionCommentEditDrawer);
