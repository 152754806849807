import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '@/mobx-models/Root';
import { BaseEntity } from '@/models/BaseEntity';

import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface EquipmentStatus extends BaseEntity {
  readonly description: string;
}
export type EquipmentStatusCollection = RxCollection<EquipmentStatus> | null;
export type EquipmentStatusDocument = RxDocument<EquipmentStatus>;

export const equipmentStatusSchema: RxJsonSchema<EquipmentStatus> = {
  type: 'object',
  version: 0,
  description: 'describes a EquipmentStatus object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
  },
};

export const equipmentMigrationStrategies: MigrationStrategies = {};

export const equipmentStatusPullQueryBuilder = (doc: EquipmentStatusDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query equipmentStatusFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      equipmentStatusFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
        id
        description
        isDeleted
        version
        updatedAt
      }
    }
  `);
  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
