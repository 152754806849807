import { DocumentUploadDocument } from '../../rxdb/Documents/queryBuilder';
import { EquipmentDeficiencyAttachment } from '../../rxdb/EquipmentDeficiencyAttachments/queryBuilder';
import { GroundHazardAttachment } from '../../rxdb/GroundHazardAttachment/queryBuilder';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import RxdbManager from '../../rxdb/RxdbManager';

// Filter out other types of attachments
export const isDocumentUpload = (doc: DocumentUploadDocument) =>
  (doc?.borerDocumentTypeId || doc?.miningMethod) && doc.attachmentId;

export const getDocumentFromDocumentUploadCollection = async (
  document: DocumentUploadDocument | GroundHazardAttachment | EquipmentDeficiencyAttachment,
): Promise<DocumentUploadDocument | undefined> => {
  // RxDocument if in document upload collection
  const rxdbDoc = isDocumentUpload(document)
    ? await RxdbManager.instance.db?.collections[RxdbCollectionName.DOCUMENT_UPLOAD]
        .findOne({
          selector: {
            attachmentId: document.attachmentId,
          },
        })
        .exec()
    : null;

  return rxdbDoc;
};
