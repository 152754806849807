import type { Theme } from '@material-ui/core';
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CustomPalette, i18n, Icons, SidePanel, Typography } from '@nutrien/cxp-components';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftCrewMemberEmployee } from '../../models/models';
import { BorerShiftCrewType } from '../../rxdb/BorerShift/queryBuilder';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';
import useEmployees from '../../rxdb/Employees/useEmployees';
import { useNotification } from '../../utilities';
import DiscardDraftModal from '../DiscardDraftModal';
import EditCrewDetailsPanel from '../EditCrewDetailsPanel';
import { CrewEmployeePanelOperation } from '../EditCrewDetailsPanel/EditCrewDetailsPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
      backgroundColor: CustomPalette.elevation.dp4Solid,
      minWidth: '274px',
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    headerContainer: {
      margin: '0',
      height: '48px',
    },
    rightButtonContainer: {
      position: 'absolute',
      top: '8px',
      right: 0,
      height: '48px',
    },
    dialogContent: {
      padding: '16px',
      width: '400px',
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    dialogActions: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    backdrop: {
      touchAction: 'none',
    },
  }),
);

interface Props {
  open: boolean;
  setModifyCrewSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  crewToEdit?: BorerShiftCrewType | null;
  crewNumber?: number;
  triggerRefresh: () => void;
}

export const generateDefaultEmployee = (): BorerShiftCrewMemberEmployee => {
  return {
    isActive: true,
    firstName: '',
    lastName: '',
    id: '',
    crew: '',
    department: '',
    email: '',
    phoneNumber: '',
    position: '',
    site: '',
    updatedAt: 0,
    version: 1,
    isDeleted: false,
    reactKey: v4(),
  };
};

const ModifyCrewSidePanel = ({
  open,
  setModifyCrewSidePanelOpen,
  crewToEdit,
  crewNumber,
  triggerRefresh,
}: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  const { employeesList } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: true,
  });
  const { successNotification, errorNotification, closeSnackbar } = useNotification();

  const generateDefaultCrew = (): BorerShiftCrewType => {
    return {
      borerShiftCrewMemberInput: [
        generateDefaultEmployee(),
        generateDefaultEmployee(),
        generateDefaultEmployee(),
      ],
      borerShiftId: shiftPicker.currentBorerShiftId || '',
      endDateTime: shiftPicker.selectedShift?.shiftEnd || '',
      startDateTime: shiftPicker.selectedShift?.shiftStart || '',
      id: v4(),
      version: 1,
      crewNumber: crewNumber || 1,
    };
  };
  const [crewDetails, setCrewDetails] = useState<BorerShiftCrewType>(
    crewToEdit || generateDefaultCrew(),
  );
  const [validationIndex, setValidationIndex] = useState(0);
  const [originalCrewDetails, setOriginalCrewDetails] = useState<BorerShiftCrewType>(
    crewToEdit || generateDefaultCrew(),
  );
  const [validationError, setValidationError] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const deleteMode = useRef(false);
  const {
    updateBorerShiftCrew,
    deleteBorerShiftCrew,
    softDeleteBorerShiftCrew,
    unSoftDeleteBorerShiftCrew,
  } = useBorerShiftCrew();

  const onCrewEmployeeChange = (
    index: number,
    value: BorerShiftCrewMemberEmployee | CrewEmployeePanelOperation,
  ) => {
    setValidationIndex(prev => (prev < index + 1 ? index + 1 : prev));
    if (value === CrewEmployeePanelOperation.AddEmployee) {
      setCrewDetails(prev => {
        const crewMemberCopy = prev.borerShiftCrewMemberInput.slice();
        crewMemberCopy.push(generateDefaultEmployee());
        return { ...prev, borerShiftCrewMemberInput: crewMemberCopy };
      });
    } else if (value === CrewEmployeePanelOperation.DeleteEmployee) {
      setCrewDetails(prev => {
        const crewMemberCopy = prev.borerShiftCrewMemberInput.slice();
        crewMemberCopy.splice(index, 1);
        return { ...prev, borerShiftCrewMemberInput: crewMemberCopy };
      });
    } else if (value === null) {
      setCrewDetails(prev => {
        const crewMemberCopy = prev.borerShiftCrewMemberInput.slice();
        crewMemberCopy[index] = generateDefaultEmployee();
        return { ...prev, borerShiftCrewMemberInput: crewMemberCopy };
      });
    } else {
      setCrewDetails(prev => {
        const crewMemberCopy = prev.borerShiftCrewMemberInput.slice();
        crewMemberCopy[index] = value;
        return { ...prev, borerShiftCrewMemberInput: crewMemberCopy };
      });
    }
  };

  const errorSavingCrew = () => {
    errorNotification(i18n.t('Crew could not be saved. Please try again.'));
    setSaving(false);
  };

  const onCrewDetailsChange = (
    key: keyof BorerShiftCrewType,
    value: BorerShiftCrewMemberEmployee | Dayjs | string,
  ) => {
    if (key === 'startDateTime') {
      setValidationIndex(prev => (prev < 1 ? 1 : prev));
    }
    if (key === 'endDateTime') {
      setValidationIndex(prev => (prev < 2 ? 2 : prev));
    }
    setCrewDetails(prev => {
      return { ...prev, [key]: value };
    });
  };

  const hasEdits = useMemo(() => {
    if (originalCrewDetails.startDateTime !== crewDetails.startDateTime) {
      return true;
    }
    if (originalCrewDetails.endDateTime?.hour() !== crewDetails.endDateTime?.hour()) {
      return true;
    }
    if (originalCrewDetails.endDateTime?.minute() !== crewDetails.endDateTime?.minute()) {
      return true;
    }
    if (
      crewDetails?.borerShiftCrewMemberInput?.find((crewMember, index) => {
        return crewMember?.id !== originalCrewDetails?.borerShiftCrewMemberInput[index]?.id;
      })
    ) {
      return true;
    }
    if (
      crewDetails.borerShiftCrewMemberInput.length !==
      originalCrewDetails.borerShiftCrewMemberInput.length
    ) {
      return true;
    }

    return false;
  }, [crewDetails, originalCrewDetails]);

  const filteredEmployeesList = useMemo(() => {
    return employeesList.filter(employee => {
      return !crewDetails.borerShiftCrewMemberInput
        .map(crewMember => crewMember.id)
        .find(val => employee.id === val);
    });
  }, [employeesList, crewDetails]);

  useEffect(() => {
    if (open && !undo) {
      setCrewDetails(crewToEdit || generateDefaultCrew());
      setOriginalCrewDetails(crewToEdit || generateDefaultCrew());
      setValidationIndex(0);
    }

    if (open) {
      setUndo(false);
    }
  }, [open, crewToEdit]);

  const onSave = async () => {
    try {
      await updateBorerShiftCrew(crewDetails, originalCrewDetails);
      triggerRefresh();
      successNotification(
        crewDetails.crewNumber === 2 ? i18n.t('Crew 2 updated') : i18n.t('Crew updated'),
      );
      setSaving(false);
      setModifyCrewSidePanelOpen(false);
    } catch (error) {
      console.log('🚀 ~ file: ModifyCrewSidePanel.tsx ~ line 329 ~ error', error);

      errorSavingCrew();
    }
  };

  const openDiscardEditsModel = () => {
    if (hasEdits) {
      setDiscardOpen(true);
    } else {
      setModifyCrewSidePanelOpen(false);
    }
  };

  const onDelete = useCallback(() => {
    deleteMode.current = true;
    setDiscardOpen(true);
  }, []);

  const validateCanSave = useMemo(() => {
    if (!hasEdits) return false;
    if (validationError) return false;
    if (!crewDetails.borerShiftCrewMemberInput[0]?.id) return false;
    return true;
  }, [hasEdits, validationError, crewDetails.borerShiftCrewMemberInput]);

  if (!open) return null;
  return (
    <>
      <SidePanel
        open={open}
        onClose={openDiscardEditsModel}
        transitionDuration={200}
        disableBackdropClick
        ModalProps={{
          BackdropProps: {
            onClick: event => {
              event.preventDefault();
            },
            onTouchStart: event => {
              event.preventDefault();
            },
            style: { touchAction: 'none' },
          },
        }}
        classes={{ container: classes.backdrop }}
      >
        <DialogTitle disableTypography className={classes.root}>
          <div className={classes.headerContainer}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item style={{ width: '36px', height: '36px' }}>
                <div />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="inherit">
                  {crewToEdit ? i18n.t('Edit crew') : i18n.t('Add crew')}
                  {crewDetails.crewNumber === 2 ? ' 2' : ''}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label={i18n.t('close')}
                  onClick={openDiscardEditsModel}
                  id="close-crew-modal"
                >
                  <Icons.XFeather strokeWidth={1} color="primary" />
                </IconButton>
              </Grid>
            </Grid>
            <div className={`${classes.rightButtonContainer} `} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <EditCrewDetailsPanel
            crewDetails={crewDetails}
            onCrewEmployeeChange={onCrewEmployeeChange}
            onCrewDetailsChange={onCrewDetailsChange}
            setValidationError={setValidationError}
            validationIndex={validationIndex}
            employeesList={filteredEmployeesList}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container justify="space-between">
            <Grid container item xs={8}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openDiscardEditsModel}
                  data-cy="cancelComment"
                  noMinHeight
                  id="cancel-crew-modal"
                >
                  {i18n.t('Cancel')}
                </Button>
              </Grid>
              {crewToEdit?.index === 1 && (
                <Grid item>
                  <Button
                    variant="text"
                    color="error"
                    noMinHeight
                    isLoading={saving}
                    onClick={onDelete}
                    id="delete-crew-modal"
                  >
                    {i18n.t('Delete crew')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                data-cy="onSave"
                noMinHeight
                isLoading={saving}
                onClick={onSave}
                disabled={!validateCanSave}
                id="save-crew-modal"
              >
                {i18n.t('Save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </SidePanel>
      <DiscardDraftModal
        open={discardOpen}
        titleText={deleteMode.current ? i18n.t('Delete crew 2') : undefined}
        cancelText={
          deleteMode
            ? i18n.t('Deleting this crew will lose any information that has been added')
            : undefined
        }
        discardDraftButtonText={deleteMode.current ? i18n.t('Delete crew 2') : undefined}
        onCancel={() => {
          setDiscardOpen(false);
          deleteMode.current = false;
          setUndo(false);
        }}
        onDiscard={async () => {
          setDiscardOpen(false);

          if (deleteMode.current) {
            try {
              await softDeleteBorerShiftCrew(originalCrewDetails);
              setModifyCrewSidePanelOpen(false);
              errorNotification(i18n.t('Crew 2 deleted'), {
                action: key => (
                  <Button
                    noMinHeight
                    color="primary"
                    variant="text"
                    onClick={async () => {
                      setUndo(true);
                      deleteMode.current = false;
                      closeSnackbar(key);
                      setModifyCrewSidePanelOpen(true);
                      await unSoftDeleteBorerShiftCrew(originalCrewDetails);
                    }}
                    id="undo-delete-crew-modal"
                  >
                    {i18n.t('Undo')}
                  </Button>
                ),
                onExited: async () => {
                  if (deleteMode.current) {
                    await deleteBorerShiftCrew(originalCrewDetails);
                  }
                  triggerRefresh();
                  deleteMode.current = false;
                },
                autoHideDuration: 5000,
              });
            } catch (err) {
              console.error('🚀 ~ file: ModifyCrewSidePanel.tsx ~ line 404 ~ err', err);
              errorNotification('Error deleting crew');
            }
          } else {
            setModifyCrewSidePanelOpen(false);
            errorNotification(
              crewDetails.crewNumber === 2
                ? i18n.t('Crew 2 draft discarded')
                : i18n.t('Crew draft discarded'),
              {
                action: key => (
                  <Button
                    noMinHeight
                    color="primary"
                    variant="text"
                    onClick={() => {
                      setUndo(true);
                      closeSnackbar(key);
                      setModifyCrewSidePanelOpen(true);
                    }}
                    id="undo-discard-crew-modal"
                  >
                    {i18n.t('Undo')}
                  </Button>
                ),
              },
            );
          }
        }}
      />
    </>
  );
};

export default observer(ModifyCrewSidePanel);
