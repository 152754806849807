import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPalette } from '@nutrien/cxp-components';

const useStyles = makeStyles(() =>
  createStyles({
    categoryButtonsContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      '& #AddAnotherCheckBtn': {
        width: '216px',
      },
      '& #AddCommentBtn': {
        width: '167px',
      },
      '& > div > div': {
        alignItems: 'flex-end',
      },
    },
    content: {
      overflow: 'scroll',
      height: 'calc(100vh - 123px)',
      alignContent: 'flex-start',
    },
    categoryTitle: {
      maxWidth: '100%',
      paddingLeft: '3px',
    },
    locationDetails: {
      minWidth: '300px',
      paddingTop: '10px',
      paddingLeft: '10px',
    },
    card: {
      padding: '16px',
      '& h3': {
        paddingLeft: '5px',
        paddingBottom: '5px',
      },
    },
    commentsContainer: {
      padding: '15px 15px 15px 5px',
    },
    buttonGroupContainer: {
      marginTop: '8px',
    },
    toggleDisabledButSelected: {
      color: '#2AAB3F',
      backgroundColor: '#2AAB3F14',
    },
    dropdown: {
      width: '30%',
    },
    panelWithoutLeftPadding: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      margin: '8px 8px 0px 8px',
      borderRadius: '8px',
      padding: '16px 16px 16px 0px',
    },
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      margin: '8px 8px 0px 8px',
      borderRadius: '8px',
      padding: '16px 16px 16px 16px',
    },
    panelWithoutTopMargin: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      margin: '0px 8px 0px 8px',
      borderRadius: '8px',
      padding: '16px 16px 16px 16px',
    },
    noTextTransform: {
      textTransform: 'none !important',
    },
  }),
);

export default useStyles;
