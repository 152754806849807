import { cloneDeep } from '@apollo/client/utilities';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { useCurrentBorer } from '../Equipment/useCurrentBorer';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity, getUnixMillisecondTimestamp } from '../rxdbUtilityFunctions';
import { PanelDrawing, PanelDrawingCollection, PanelDrawingDocument } from './queryBuilder';

const usePanelDrawings = () => {
  const { shiftPicker } = useMst();
  const { miningMethod } = useCurrentBorer();

  const panelDrawingCollection: PanelDrawingCollection = useRxCollection(
    RxdbCollectionName.PANEL_DRAWINGS,
  );
  const [currentPanelDrawing, setCurrentPanelDrawing] = useState<PanelDrawingDocument | null>(null);
  const [panelDrawingsLoaded, setPanelDrawingsLoaded] = useState(false);
  const [recentPanelDrawing, setRecentPanelDrawing] = useState<PanelDrawingDocument | null>(null);

  const createNewPanelDrawing = async () => {
    if (panelDrawingCollection && shiftPicker.currentBorerShiftId && miningMethod) {
      const doc: PanelDrawing = {
        ...generateBaseEntity(),
        borerShiftId: shiftPicker.currentBorerShiftId,
        drawingData: '{}',
        miningMethod,
      };

      await panelDrawingCollection.insert(doc);
    }
  };

  const [panelDrawingCollectionInitialized, setPanelDrawingCollectionInitialized] = useState(false);

  useEffect(() => {
    if (panelDrawingCollection) setPanelDrawingCollectionInitialized(true);
  }, [panelDrawingCollection]);

  useEffect(() => {
    let sub: Subscription | undefined;

    const getCurrentPanelDrawing = async () => {
      setPanelDrawingsLoaded(false);
      setCurrentPanelDrawing(null);
      setRecentPanelDrawing(null);
      if (panelDrawingCollectionInitialized && shiftPicker.currentBorerShiftId && miningMethod) {
        setPanelDrawingsLoaded(false);
        sub = panelDrawingCollection
          ?.find({
            selector: {
              borerShiftId: shiftPicker.currentBorerShiftId,
              miningMethod,
            },
            sort: [
              {
                updatedAt: 'desc',
              },
            ],
          })
          .$.subscribe(async panelDrawings => {
            setPanelDrawingsLoaded(false);
            if (panelDrawings.length) {
              setCurrentPanelDrawing(cloneDeep(panelDrawings[0]));

              if (panelDrawings.length >= 2) {
                setRecentPanelDrawing(cloneDeep(panelDrawings[1]));
              } else {
                setRecentPanelDrawing(null);
              }
            } else {
              setCurrentPanelDrawing(null);
              setRecentPanelDrawing(null);
            }
            setPanelDrawingsLoaded(true);
          });
      } else {
        setPanelDrawingsLoaded(true);
        setCurrentPanelDrawing(null);
        setRecentPanelDrawing(null);
      }
    };

    getCurrentPanelDrawing();

    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
    };
  }, [
    miningMethod,
    panelDrawingCollectionInitialized,
    panelDrawingCollection,
    shiftPicker.currentBorerShiftId,
    shiftPicker.settingBorerShift,
  ]);

  const updatePanelDrawing = async (
    drawingState: string,
    existingPanelDrawing: PanelDrawingDocument,
  ) => {
    if (!panelDrawingCollection) return undefined;

    const state = JSON.parse(drawingState);
    // Ensure that panelDrawing is returned by feed
    const newState = { ...state, rand: Math.random() };
    return existingPanelDrawing.update({
      $set: {
        drawingData: JSON.stringify(newState),
        updatedAt: getUnixMillisecondTimestamp(),
      },
    });
  };

  return {
    updatePanelDrawing,
    panelDrawingCollection,
    currentPanelDrawing,
    recentPanelDrawing,
    panelDrawingsLoaded,
    createNewPanelDrawing,
  };
};

export { usePanelDrawings };
export default usePanelDrawings;
