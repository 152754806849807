import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useBorerShiftInfo from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import useSite from '../../rxdb/Site/useSite';
import { BORER_SHIFT_WRITE_PERMISSION } from '../../utilities/constants';
import EditShiftInfoSidePanel from '../EditShiftInfoSidePanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    label: {
      color: theme.palette.text.secondary,
    },
    buttonDiv: {
      width: 'fit-content',
      minWidth: '125px',
    },
  }),
);

interface Props {
  borerShiftOpen: boolean;
}

const ShiftEndInfoCard: React.FC<Props> = ({ borerShiftOpen }: Props) => {
  const classes = useStyles();
  const { shiftPicker, user } = useMst();
  const {
    rotorBits,
    trimBits,
    cornerBits,
    belt,
    cable,
    serviceStatus,
    initialLoadComplete,
    shearPins,
    bottomChainShearPinsReplaced,
    topChainShearPinsReplaced,
    cableSlackEnd,
    oreLineHeight,
    breakthroughFaceFootage,
    oreGrade,
    getBorerShiftInfo,
  } = useBorerShiftInfo(shiftPicker.currentBorerShiftId);
  const { isVanscoy, isAllan, isRocanville } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();

  const { miningMethod, isSandvikBorer } = useCurrentBorer();

  const [infoEditOpen, setInfoEditOpen] = useState(false);

  const showBits = !isRocanville;

  return (
    <>
      <Card className={classes.cardRoot} onClick={() => {}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">{i18n.t('Info')}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} container justify="flex-end">
            {borerShiftOpen && user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
              <div className={classes.buttonDiv}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setInfoEditOpen(true)}
                  noMinHeight
                  startAdornment={
                    <Icons.EditFeather color={initialLoadComplete ? 'primary' : 'disabled'} />
                  }
                  disabled={!initialLoadComplete}
                  id="edit-shift-info"
                >
                  Edit info
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {translate('Borer status')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {serviceStatus || ''}
          </Grid>
          {showBits && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Rotor bits')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {rotorBits}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {isSandvikBorer ? i18n.t('Drum bits') : i18n.t('Trim bits')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {trimBits}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Corner bits')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {cornerBits}
              </Grid>
            </>
          )}
          {isVanscoy && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Shear pins')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {shearPins}
              </Grid>
            </>
          )}
          {miningMethod === 'Long Room' && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Belt')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {belt !== null
                  ? !isVanscoy
                    ? `${belt} ${distanceUnitAbbreviation}`
                    : `${belt}`
                  : ''}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Cable')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {cable !== null
                  ? !isVanscoy
                    ? `${cable} ${distanceUnitAbbreviation}`
                    : `${cable}`
                  : ''}
              </Grid>
            </>
          )}
          {isAllan && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Bottom chain shear pins replaced')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {bottomChainShearPinsReplaced}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Top chain shear pins replaced')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {topChainShearPinsReplaced}
              </Grid>
            </>
          )}
          {isRocanville && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Ore line height')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {oreLineHeight}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Grade')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {oreGrade}
                {oreGrade && '%'}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('BT footage (face)')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {breakthroughFaceFootage}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" className={classes.label}>
                  {i18n.t('Cable slack end')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {cableSlackEnd}
              </Grid>
            </>
          )}
        </Grid>
      </Card>
      <EditShiftInfoSidePanel
        open={infoEditOpen}
        onClose={() => {
          setInfoEditOpen(false);
          if (shiftPicker.currentBorerShiftId) getBorerShiftInfo(shiftPicker.currentBorerShiftId);
        }}
        onOpen={() => {
          setInfoEditOpen(true);
        }}
        onCancel={() => {
          setInfoEditOpen(false);
          if (shiftPicker.currentBorerShiftId) getBorerShiftInfo(shiftPicker.currentBorerShiftId);
        }}
      />
    </>
  );
};

export default observer(ShiftEndInfoCard);
