import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CustomPalette,
  ExpansionPanel,
  i18n,
  Icons,
  MaterialPalette,
  TimePicker2,
} from '@nutrien/cxp-components';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftCrewMemberEmployee } from '../../models/models';
import { BorerShiftCrewType } from '../../rxdb/BorerShift/queryBuilder';
import { getShiftCorrectedTime } from '../../utilities/shiftTimeUtilities';
import { USER_TIMEZONE } from '../../utilities/useDateFormatters';
import CrewMember from '../CrewMember';

dayjs.extend(utc);
export enum CrewEmployeePanelOperation {
  AddEmployee,
  DeleteEmployee,
}

export enum ValidationOperation {
  Add,
  Remove,
}

export interface AugmentedEmployee {
  id: string;
  firstName?: string;
  lastName?: string;
  positionName?: string;
  crewName?: string;
}
interface Props {
  crewDetails: BorerShiftCrewType;
  onCrewEmployeeChange: (
    index: number,
    value: BorerShiftCrewMemberEmployee | CrewEmployeePanelOperation,
  ) => void;
  onCrewDetailsChange: (
    key: keyof BorerShiftCrewType,
    value: BorerShiftCrewMemberEmployee | Dayjs | string,
  ) => void;
  setValidationError: Dispatch<SetStateAction<boolean>>;
  validationIndex: number;
  employeesList: AugmentedEmployee[];
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
      marginTop: 20,
      width: 'auto',
    },
    iconSpacer: {
      marginRight: 8,
    },
    listbox: {
      '& li': {
        padding: '6px 6px',
      },
    },
    divider: {
      color: MaterialPalette.text.primary,
      opacity: 0.9,
      fontSize: '14px',
      margin: '0px',
      padding: '0px',
    },
    spacer: {
      marginTop: 10,
    },
    buttonSpacer: {
      marginTop: 10,
      width: 'auto',
    },
  }),
);

const EditCrewDetailsPanel: React.FC<Props> = ({
  crewDetails,
  onCrewEmployeeChange,
  onCrewDetailsChange,
  setValidationError,
  validationIndex,
  employeesList,
}: Props) => {
  const [crewValidationErrors, setCrewValidationErrors] = useState(
    new Array(crewDetails?.borerShiftCrewMemberInput?.length),
  );

  const classes = useStyles();
  const { shiftPicker } = useMst();

  const [errors, setErrors] = useState({
    startTime: '',
    endTime: '',
  });
  useEffect(() => {
    setValidationError(
      crewValidationErrors.find(e => !!e) || !!errors.startTime || !!errors.endTime,
    );
  }, [errors, crewValidationErrors]);

  useEffect(() => {
    // Start time validation
    if (validationIndex >= 1) {
      if (
        dayjs
          .tz(crewDetails.startDateTime, USER_TIMEZONE)
          .diff(shiftPicker.selectedShift?.shiftStart) < 0 ||
        dayjs
          .tz(crewDetails.startDateTime, USER_TIMEZONE)
          .diff(shiftPicker.selectedShift?.shiftEnd) > 0
      ) {
        setErrors(prev => {
          return { ...prev, startTime: 'Must be within shift hours' };
        });
      } else {
        setErrors(prev => {
          return { ...prev, startTime: '' };
        });
      }
    }
  }, [validationIndex, crewDetails.startDateTime]);

  useEffect(() => {
    // End time validation
    if (validationIndex >= 1) {
      if (dayjs(crewDetails.endDateTime).diff(crewDetails.startDateTime) <= 0) {
        setErrors(prev => {
          return { ...prev, endTime: 'Must be after start time' };
        });
      } else if (
        dayjs(crewDetails.endDateTime).diff(shiftPicker.selectedShift?.shiftStart) < 0 ||
        dayjs(crewDetails.endDateTime).diff(shiftPicker.selectedShift?.shiftEnd) > 0
      ) {
        setErrors(prev => {
          return { ...prev, endTime: 'Must be within shift hours' };
        });
      } else {
        setErrors(prev => {
          return { ...prev, endTime: '' };
        });
      }
    }
  }, [validationIndex, crewDetails.endDateTime, crewDetails.startDateTime]);

  const setCrewValidationError = (index: number, error: boolean | ValidationOperation) => {
    if (error === ValidationOperation.Add) {
      setCrewValidationErrors(prev => {
        const copy = prev.slice();
        copy.push(true);
        return copy;
      });
    } else if (error === ValidationOperation.Remove) {
      setCrewValidationErrors(prev => {
        const copy = prev.slice();
        copy.splice(index, 1);
        return copy;
      });
    } else {
      setCrewValidationErrors(prev => {
        const copy = prev.slice();
        copy[index] = error;
        return copy;
      });
    }
  };

  const onAddCrew = () => {
    setCrewValidationError(-1, ValidationOperation.Add);
    onCrewEmployeeChange(-1, CrewEmployeePanelOperation.AddEmployee);
  };

  const onStartTimeChanged = useCallback(
    (date: Dayjs) => {
      const adjustedDate = getShiftCorrectedTime(date, shiftPicker.Date, shiftPicker.Type);
      onCrewDetailsChange('startDateTime', adjustedDate);
    },
    [shiftPicker.Date, shiftPicker.Type],
  );

  const onEndTimeChanged = useCallback(
    (date: Dayjs) => {
      const adjustedDate = getShiftCorrectedTime(date, shiftPicker.Date, shiftPicker.Type);
      onCrewDetailsChange('endDateTime', adjustedDate);
    },
    [shiftPicker.Date, shiftPicker.Type],
  );

  return (
    <ExpansionPanel
      className={classes.panel}
      title={`${i18n.t('Crew')}${crewDetails.crewNumber === 2 ? ' 2' : ''}`}
      key="hazard-details"
      data-cy="hazard-details"
      TransitionProps={{ unmountOnExit: true }}
      expansionPanelSummaryProps={{
        expandIcon: undefined,
      }}
      expanded
      panelContent={
        <Grid container>
          <Grid item container direction="column" xs={12}>
            <Grid container item spacing={1}>
              <Grid item xs={8}>
                <TimePicker2
                  value={dayjs(crewDetails.startDateTime).tz(USER_TIMEZONE)}
                  label={i18n.t('Start time')}
                  required
                  onTimeChanged={onStartTimeChanged}
                  error={!!errors.startTime}
                  errorText={errors.startTime}
                  useBrowserTimePicker
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={8}>
                <TimePicker2
                  value={dayjs(crewDetails.endDateTime).tz(USER_TIMEZONE)}
                  label={i18n.t('End time')}
                  required
                  onTimeChanged={onEndTimeChanged}
                  error={!!errors.endTime}
                  errorText={errors.endTime}
                  useBrowserTimePicker
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {crewDetails.borerShiftCrewMemberInput.map((crewMemberDetails, crewMemberIndex) => {
                return (
                  <CrewMember
                    crewMemberDetails={crewMemberDetails}
                    crewMemberIndex={crewMemberIndex}
                    crewMemberList={crewDetails.borerShiftCrewMemberInput}
                    employeeList={employeesList}
                    onCrewEmployeeChange={onCrewEmployeeChange}
                    validationIndex={validationIndex}
                    setValidationError={setCrewValidationError}
                    key={crewMemberDetails?.reactKey || crewMemberDetails?.id}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid item className={classes.buttonSpacer}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              noMinHeight
              startAdornment={<Icons.PlusFeather className={classes.iconSpacer} />}
              onClick={onAddCrew}
              data-cy="add-another-crew-button"
              id="add-another-crew-button"
            >
              {i18n.t('Add another operator')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

export default EditCrewDetailsPanel;
