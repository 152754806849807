import type { PopoverProps, Theme } from '@material-ui/core';
import { createStyles, Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { i18n, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useMemo } from 'react';

import { InspectionType } from '../../utilities/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      borderRadius: '8px !important',
    },
    div: { backgroundColor: theme.palette.background.default },
    container: {
      padding: '16px',
      backgroundColor: 'rgba(56,56,56,1)',
    },
    title: {
      paddingBottom: '24px',
      color: theme.palette.text.secondary,
    },
    link: {
      padding: '10px 0px',
    },
  }),
);

interface Props extends PopoverProps {
  openAddDeficiency: () => void;
  onAddPreOpCheck: (checkType: InspectionType) => void;
}

const laniganDeficiencys = [{ name: i18n.t('Pre-op check'), type: InspectionType.PRE_OP }];

const vanscoyDeficiencys = [
  {
    name: i18n.t('Borer & flexiveyor pre-op check'),
    type: InspectionType.BORER_FLEXIVEYOR_PREOP_CHECK,
  },
  {
    name: i18n.t('Borer cutting check'),
    type: InspectionType.BORER_CUTTING_CHECK,
  },
  {
    name: i18n.t('Post-cutting check'),
    type: InspectionType.POST_CUTTING_CHECK,
  },
];

const coryDeficiencys = [
  {
    name: i18n.t('Miner pre-op inspection'),
    type: InspectionType.MINER_PRE_OP,
  },
  {
    name: i18n.t('Miner running inspection'),
    type: InspectionType.MINER_RUNNING,
  },
  {
    name: i18n.t('Bridges pre-op inspection'),
    type: InspectionType.BRIDGES_PRE_OP,
  },
  {
    name: i18n.t('Bridges running inspection'),
    type: InspectionType.BRIDGES_RUNNING,
  },
];

const rocanvilleDeficiencys = [
  {
    name: i18n.t('Equipment check'),
    type: InspectionType.EQUIPMENT_CHECK,
  },
];
const allanDeficiencys = [
  {
    name: i18n.t('Borer inspection'),
    type: InspectionType.BORER_INSPECTION,
  },
  {
    name: i18n.t('Mineveyor inspection'),
    type: InspectionType.MINEVEYOR_INSPECTION,
  },
];

const DeficienciesMenu = ({
  open,
  anchorEl,
  onClose,
  openAddDeficiency,
  onAddPreOpCheck,
}: Props) => {
  const classes = useStyles();
  const { isVanscoy, isCory, isLanigan, isRocanville, isAllan } = useSiteFeatures();

  const deficiencyList = useMemo(() => {
    if (isLanigan) return laniganDeficiencys;
    if (isCory) return coryDeficiencys;
    if (isVanscoy) return vanscoyDeficiencys;
    if (isRocanville) return rocanvilleDeficiencys;
    if (isAllan) return allanDeficiencys;

    return [];
  }, [isLanigan, isCory, isVanscoy, isRocanville, isAllan]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.popover}
    >
      <div className={classes.div}>
        <Grid container className={classes.container} style={{ width: isVanscoy ? 425 : 375 }}>
          <Grid item container xs={isVanscoy || isCory ? 8 : 6}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">{i18n.t('Checklist')}</Typography>
            </Grid>
            {deficiencyList?.map(deficiency => (
              <Grid
                item
                xs={12}
                className={classes.link}
                onClick={() => onAddPreOpCheck(deficiency.type)}
                id={`add-${deficiency.type}`}
                key={deficiency.type}
              >
                <Typography variant="body1">{deficiency.name}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={isVanscoy || isCory ? 4 : 6}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h6">{i18n.t('Single entry')}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.link}
              onClick={openAddDeficiency}
              id="add-deficiency"
            >
              <Typography variant="body1">{i18n.t('Deficiency')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
};

export default DeficienciesMenu;
