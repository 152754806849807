export enum MiningMethodAllCap {
  LONG_ROOM = 'LONG ROOM',
  CHEVRON = 'CHEVRON',
}

export enum HazardPanelTypes {
  DETAILS,
  LOCATION,
  REMEDIATION,
  PHOTOS,
  GROUND_CONTROLS,
}

export enum PermissionFeatures {
  GROUND_HAZARD = 'Ground Hazard',
  REFERENCE_DATA = 'Reference Data',
  SIGNATURE = 'Signature',
}

export enum PermissionAttributes {
  GROUND_HAZARDS = 'Ground Hazards',
  LOCATIONS = 'Locations',
  EMPLOYEES = 'Employees',
  SIGNATURE = 'Signature',
}

export enum WorkFaceAreaInspectionType {
  PRODUCTION = 'production',
  OTHER = 'other',
}
export enum ShiftType {
  DAY_SHIFT = 'DS',
  NIGHT_SHIFT = 'NS',
}

export enum SignatureType {
  VISITOR = 'Visitor',
  SUPERVISOR = 'Supervisor',
  MAINTENANCE = 'Maintenance',
  MINE_STAFF = 'Mine Staff',
  AREA_CHECK = 'Area Check',
  CUTTING_PERMIT = 'Cutting Permit',
  PRE_OP = 'PreOp',
  OPERATOR = 'Operator',
}

export enum AddDelayActivityType {
  DELAY = 'DELAY',
  ACTIVITY = 'ACTIVITY',
  RUNNING = 'RUNNING',
}

export enum HazardSeverity {
  ADDRESS_AND_PROCEED = 'Address and proceed',
  IMMEDIATE_WORK_STOPPAGE = 'Immediate work stoppage',
  SAFE_TO_CUT = 'Safe to cut',
  STOP_AND_ADDRESS = 'Stop and Address',
  CORRECTIVE_ACTION_REQUIRED = 'Corrective action required',
  CAUTION = 'Caution',
  CONDITION_OKAY = 'Condition okay',
  CONDITIONS_OKAY = 'Conditions okay',
  // Allan/Rocanville specific
  A_CATEGORY_3 = 'Category 3 - Stop mining, ensure safety of crew and contact supervisor immediately',
  A_CATEGORY_2 = 'Category 2 - Stop mining and call supervisor to come to job site',
  A_CATEGORY_1 = 'Category 1 - Contact supervisor for recommendations/discussion',
  // Rocanville specific
  GOOD_TO_CUT = 'Good to Cut',

  R_CATEGORY_1 = 'Category 1 conditions - Safe to cut',
  R_CATEGORY_2 = 'Category 2 conditions - Address and proceed',
  R_CATEGORY_3 = 'Category 3 conditions - Stop and address',
}

export enum AreaCheckTab {
  CONDITIONS = 'Conditions',
  CHECKS_THIS_SHIFT = 'ChecksThisShift',
  HISTORICAL = 'Historical',
}

export enum DeficiencyPanelTypes {
  DETAILS,
  PHOTOS,
  STATUS,
}

export enum DeficiencyStatus {
  WOPendingReview = 'WO Pending Review',
  ActiveDeficiency = 'Active deficiency',
  FullyAddressed = 'Fully addressed',
}

export enum DelayActivitiesBlockType {
  RUNNING,
  NOT_RUNNING,
  DELAY,
  ACTIVITY,
  MANUAL_DELAY,
  ERROR,
}

export enum DocumentTypeDescriptions {
  OTHER = 'Other',
  REQUIRED = 'Required',
}
