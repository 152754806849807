import type { Theme } from '@material-ui/core';
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CustomPalette, i18n, Icons, SidePanel, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';

import { PopulatedLocation } from '@/rxdb/Locations/useLocations';
import useSite from '@/rxdb/Site/useSite';

import { Location } from '../../models/models';
import { BlockDocument } from '../../rxdb/Blocks/queryBuilder';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import useEmployees from '../../rxdb/Employees/useEmployees';
import { GroundControlSetCollection } from '../../rxdb/GroundControlSet/queryBuilder';
import { GroundHazardCollection, GroundHazardDocument } from '../../rxdb/GroundHazard/queryBuilder';
import {
  GroundHazardAttachment,
  GroundHazardAttachmentCollection,
} from '../../rxdb/GroundHazardAttachment/queryBuilder';
import { HazardLogDocument } from '../../rxdb/GroundHazardLog/queryBuilder';
import useHazardLog from '../../rxdb/GroundHazardLog/useHazardLog';
import { GroundHazardConditionType } from '../../rxdb/HazardConditionType/queryBuilder';
import { LocationCollection, LocationDocument } from '../../rxdb/Locations/queryBuilder';
import { PanelDocument } from '../../rxdb/Panels/queryBuilder';
import usePanels from '../../rxdb/Panels/usePanels';
import { PassDocument } from '../../rxdb/Passes/queryBuilder';
import usePasses from '../../rxdb/Passes/usePasses';
import { RoomDocument } from '../../rxdb/Rooms/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import {
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../../rxdb/rxdbUtilityFunctions';
import { SequenceDocument } from '../../rxdb/Sequences/queryBuilder';
import useSequences from '../../rxdb/Sequences/useSequences';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import useSurveyPoints from '../../rxdb/SurveyPoints/useSurveyPoints';
import { HazardPanelTypes, useNotification } from '../../utilities';
import { FEET_PER_METERS } from '../../utilities/constants';
import useFileStorage from '../../utilities/useFileStorage';
import {
  convertListOfFilesAndFileListsToArray,
  formatFileNamesToBeUnique,
  getFormattedImages,
} from '../../utilities/utilityFunctions';
import AddCommentDetailsPanel from '../AddCommentDetailsPanel/AddCommentDetailsPanel';
import DiscardDraftModal from '../DiscardDraftModal';
import GroundControlExpansionPanel from '../GroundControl/GroundControlExpansionPanel';
import HazardPhotosPanel from '../HazardPhotosPanel/HazardPhotosPanel';
import HazardStatusPanel from '../HazardStatusPanel';
import { ImageMetadata } from '../Thumbnail/Thumbnail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
      backgroundColor: CustomPalette.elevation.dp4Solid,
      minWidth: '274px',
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    headerContainer: {
      margin: '0',
      height: '48px',
    },
    rightButtonContainer: {
      position: 'absolute',
      top: '8px',
      right: 0,
      height: '48px',
    },
    dialogContent: {
      padding: '16px',
      width: '400px',
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    dialogActions: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
  }),
);

interface Props {
  open: boolean;
  onClose: (newHazardLogId?: string) => void;
  onOpen: () => void;
  conditionId: string;
  groundHazard: GroundHazardDocument;
  onHazardUpdated?: (hazard: GroundHazardDocument) => void;
  attachmentPrefix?: string;
  existingAttachmentFileInfo?: ImageMetadata[];
}

const AddCommentSidePanel = ({
  open,
  onClose,
  onOpen,
  conditionId,
  groundHazard,
  onHazardUpdated,
  attachmentPrefix,
  existingAttachmentFileInfo,
}: Props) => {
  const { uploadImage } = useFileStorage();
  const classes = useStyles();

  const { getRoomOptions } = usePanels();
  const { getSurveyPointsForRoom } = useSurveyPoints();
  const { getSequencesForMiningMethod } = useSequences();
  const { getPassesForMiningMethod } = usePasses();
  const { saveHazardLog, hazardLogsInitialized } = useHazardLog();
  const { isLanigan } = useSiteFeatures();
  const { useMeters } = useSite();

  const [undo, setUndo] = useState(false);
  const [hasExistingGroundControls, setHasExistingGroundControls] = useState(false);

  // RXDB - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // collections
  const locationCollection: LocationCollection = useRxCollection(RxdbCollectionName.LOCATIONS);
  const attachmentCollection: GroundHazardAttachmentCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
  );
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );
  const groundControlSetCollection: GroundControlSetCollection = useRxCollection(
    RxdbCollectionName.GROUND_CONTROL_SETS,
  );

  const { employeesList: employees } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: false,
  });
  const conditionTypesQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: conditionTypes }: { result: GroundHazardConditionType[] } = useRxData(
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
    conditionTypesQueryConstructor,
  );

  const populate = async () => {
    if (groundHazard) {
      const logs = await groundHazard.populate('hazardLogs');
      let foundLogWithGroundControls = false;
      logs.map((log: HazardLogDocument) => {
        if (
          !foundLogWithGroundControls &&
          log.groundControlSets &&
          log.groundControlSets.length > 0
        ) {
          foundLogWithGroundControls = true;
        }
      });
      setHasExistingGroundControls(foundLogWithGroundControls);
    }
  };

  useEffect(() => {
    populate();
  }, [groundHazard, hazardLogsInitialized]);

  const [validationIndex, setValidationIndex] = useState(0);
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);

  const [canSave, setCanSave] = useState<boolean>(false);

  const [selectedConditionType, setSelectedConditionType] = useState<GroundHazardConditionType>({});
  const [selectedUser, setSelectedUser] = useState<Employee>({});
  const [comment, setComment] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [expandedPanelId, setExpandedPanel] = useState<HazardPanelTypes | undefined>(
    HazardPanelTypes.DETAILS,
  );

  // Ground Control
  const [selectedGroundControls, setSelectedGroundControls] = useState<any[]>([]);
  const [groundControlErrors, setGroundControlErrors] = useState<boolean>(false);

  // Photo Details
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  // End Location data
  const [roomOptions, setRoomOptions] = useState<PopulatedLocation[]>([]);
  const [endBlock, setEndBlock] = useState<BlockDocument>();
  const [endPanel, setEndPanel] = useState<PanelDocument>();
  const [endRoom, setEndRoom] = useState<RoomDocument>();
  const [endSurveyPoints, setEndSurveyPoints] = useState<SurveyPointDocument[]>([]);
  const [endSurveyPoint, setEndSurveyPoint] = useState<SurveyPointDocument | null>();
  const [endSequences, setEndSequences] = useState<SequenceDocument[]>();
  const [endSequence, setEndSequence] = useState<SequenceDocument | null>();
  const [endPasses, setEndPasses] = useState<PassDocument[]>();
  const [endPass, setEndPass] = useState<PassDocument | null>();
  const [endDistance, setEndDistance] = useState<string>('');
  const [endDistanceErrorText, setEndDistanceErrorText] = useState<string>('');
  const [isFullyRemediated, setIsFullyRemediated] = useState<boolean>(false);
  const [reportEndLocation, setReportEndLocation] = useState<boolean>(false);
  const [endHazardLandmark, setEndHazardLandmark] = useState<string>('');

  const {
    successNotification,
    errorNotification,

    closeSnackbar,
  } = useNotification();

  const populateSelectedConditionType = async () => {
    setSelectedConditionType(await groundHazard.populate('conditionType'));
  };

  const getEndSequences = async (panelDoc: PanelDocument) => {
    const { miningMethod } = panelDoc;

    const endSequenceOptions = await getSequencesForMiningMethod(miningMethod);
    setEndSequences(endSequenceOptions);
  };

  const getEndSurveyPoints = async (roomDoc: RoomDocument) => {
    const options = await getSurveyPointsForRoom(roomDoc.id);
    setEndSurveyPoints(options);
  };

  const getEndPasses = async (panelDoc: PanelDocument) => {
    const { miningMethod } = panelDoc;

    const endPassOptions = await getPassesForMiningMethod(miningMethod);
    setEndPasses(endPassOptions);
  };

  // End Location
  const onEndRoomChanged = (newRoom: PopulatedLocation) => {
    setEndBlock(newRoom.block);
    setEndPanel(newRoom.panel);
    setEndRoom(newRoom.room);

    getEndSequences(newRoom.panel);
    getEndSurveyPoints(newRoom.room);
    getEndPasses(newRoom.panel);
  };

  const getLocationData = async () => {
    if (!locationCollection) return;
    try {
      const locationDocument: LocationDocument = await locationCollection
        .findOne()
        .where('id')
        .eq(groundHazard.startLocation)
        .exec();

      if (!locationDocument) return;

      // Panel
      const panelDocument = (await locationDocument.populate('panel')) as PanelDocument;
      if (!panelDocument) return;

      // Block
      const blockDocument = (await panelDocument.populate('block')) as BlockDocument;
      if (!blockDocument) return;

      // Room
      const roomDocument = (await locationDocument.populate('room')) as RoomDocument;
      if (!roomDocument) return;

      setEndBlock(blockDocument);
      setEndPanel(panelDocument);
      setEndRoom(roomDocument);

      // Call this way to trigger reference data pulls for all drop downs
      onEndRoomChanged({
        block: blockDocument,
        panel: panelDocument,
        room: roomDocument,
      });

      // SurveyPoint
      const surveyPointDocument = (await locationDocument.populate(
        'surveyPoint',
      )) as SurveyPointDocument;
      if (surveyPointDocument) setEndSurveyPoint(surveyPointDocument);

      // Sequence
      const sequenceDocument = (await locationDocument.populate('sequence')) as SequenceDocument;
      if (sequenceDocument) setEndSequence(sequenceDocument);

      // Pass
      const passDocument = (await locationDocument.populate('pass')) as PassDocument;
      if (passDocument) setEndPass(passDocument);

      // Distance
      if (locationDocument.startMeters) {
        let convertedDistance = locationDocument.startMeters;

        if (!useMeters) {
          convertedDistance = Math.round(locationDocument.startMeters * FEET_PER_METERS);
        }

        setEndDistance(convertedDistance.toString());
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: LocationDetailsComponent.tsx ~ line 50 ~ getLocationData ~ error',
        error,
      );
    }
  };

  const createAttachment = (hazardId: string, fileName: string) => {
    const attachment: GroundHazardAttachment = {
      id: uuidv4(),
      fileName,
      groundHazardId: hazardId,
      createdBy: selectedUser.id,
      isDeleted: false,
      updatedAt: getUnixMillisecondTimestamp(),
      version: 1,
    };
    if (attachmentCollection) attachmentCollection.insert(attachment);

    return attachment.id;
  };

  const uploadImages = async (hazardId: string) => {
    try {
      if (!hazardId) throw new Error('Hazard ID is required');
      const formattedImages = attachmentPrefix
        ? getFormattedImages(attachmentPrefix, selectedImages, existingAttachmentFileInfo)
        : selectedImages;

      const imageResults = await Promise.all(
        formattedImages.map(file => uploadImage(hazardId, file)),
      );

      const attachmentIds = await Promise.all(
        imageResults.map(imgResult => createAttachment(hazardId, imgResult.key)),
      );

      return attachmentIds;
    } catch (error) {
      console.log('🚀 ~ file: AddHazardsSidePanel.tsx ~ line 186 ~ uploadImages ~ error', error);
      // TODO: Image upload error
    }
  };

  const saveGroundControl = async (groundControlSet: any, groundHazardId: string) => {
    const quantity =
      groundControlSet.quantity && groundControlSet.quantity !== ''
        ? parseInt(groundControlSet.quantity, 10)
        : 1;

    const newGroundControlSet: GroundControlSet = {
      id: uuidv4(),
      type: groundControlSet.typeId,
      quantity,
      optionValues: groundControlSet.optionValues,
      updatedAt: getUnixMillisecondTimestamp(),
      groundHazardId,
    };

    if (groundControlSetCollection !== null) {
      await groundControlSetCollection.insert(newGroundControlSet);
    }

    return newGroundControlSet.id;
  };

  const saveEndLocation = async () => {
    let endDistanceInMeters;

    if (endDistance === '') {
      endDistanceInMeters = null;
    } else if (useMeters) {
      endDistanceInMeters = parseInt(endDistance, 10);
    } else {
      endDistanceInMeters = parseInt(endDistance, 10) / FEET_PER_METERS;
    }

    const newEndLocation = {
      id: uuidv4(),
      startMeters: endDistanceInMeters,
      endMeters: undefined,
      panel: endPanel?.id || '',
      room: endRoom?.id || null,
      surveyPoint: endSurveyPoint?.id || null,
      sequence: endSequence?.id || null,
      pass: endPass?.id || null,
      isDeleted: false,
      updatedAt: getUnixMillisecondTimestamp(),
      version: 1,
    };
    if (locationCollection !== null) {
      await locationCollection.insert(newEndLocation);
    }
    return newEndLocation;
  };

  const updateGroundHazard = async (
    hazardLogId: string,
    endLocationId: string | undefined,
    attachments: string[],
  ) => {
    try {
      if (groundHazardCollection) {
        const updated = await groundHazardCollection
          .findOne()
          .where('id')
          .eq(groundHazard.id)
          .update({
            $set: {
              updatedAt: getUnixMillisecondTimestamp(),
              endLocation: endLocationId,
              attachments,
              hazardLogs: [...groundHazard.hazardLogs, hazardLogId],
              remediatedOn: isFullyRemediated ? dayjs().toISOString() : null,
              remediatedOnUnix: getUnixMillisecondTimestamp(),
              endHazardLandmark: endHazardLandmark !== '' ? endHazardLandmark : null,
            },
          });
        return updated;
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: AddCommentSidePanel.tsx ~ line 425 ~ updateConditionType ~ error',
        error,
      );
    }
  };

  const saveComment = async () => {
    try {
      let newEndLocation: Location;
      setSaving(true);

      if (isFullyRemediated && reportEndLocation) {
        newEndLocation = await saveEndLocation();
      }

      const groundControlSets: string[] = await Promise.all(
        selectedGroundControls.map(gc => saveGroundControl(gc, groundHazard.id)),
      );

      const hazardLog: HazardLog = {
        ...generateBaseEntityWithCreatedOn(),
        groundHazard: groundHazard.id,
        reportedBy: selectedUser.id,
        comment,
        groundControlSets,
      };
      await saveHazardLog(hazardLog);

      const attachmentIds = await uploadImages(conditionId);
      const updatedHazard = await updateGroundHazard(
        hazardLog.id,
        newEndLocation?.id,
        attachmentIds,
      );
      if (updatedHazard && onHazardUpdated) {
        onHazardUpdated(updatedHazard);
      }

      successNotification(i18n.t('Comment Added'));
      setSaving(false);
      onClose(hazardLog.id);
    } catch (error) {
      console.log('🚀 ~ file: AddComentSidePanel.tsx ~ line 420 ~ saveComment ~ error', error);
    }
  };

  const openDiscardEditsModel = () => {
    if (hasEdits || selectedGroundControls.length > 0) {
      setDiscardOpen(true);
    } else {
      onClose();
    }
  };

  const onConditionSelected = (condition: any) => {
    setSelectedConditionType(condition);
    setHasEdits(true);
  };

  const onEmployeeSelected = (user: any) => {
    setSelectedUser(user);
    setHasEdits(true);
  };

  const onCommentTextChange = (commentText: string) => {
    setComment(commentText);
    setHasEdits(true);
  };

  const onExpanded = (expanded: boolean, expansionPanelId: HazardPanelTypes) => {
    if (expanded) setExpandedPanel(expansionPanelId);
    else setExpandedPanel(undefined);
  };

  const onFilesSelected = useCallback((filesInput: File[] | FileList[]) => {
    const files = convertListOfFilesAndFileListsToArray(filesInput);
    const formattedFiles = formatFileNamesToBeUnique(files, 'hazard', true);

    setSelectedImages(formattedFiles);
    setHasEdits(true);
    onExpanded(true, HazardPanelTypes.PHOTOS);
  }, []);

  const handleGroundControlError = (val: boolean) => {
    setGroundControlErrors(val);
  };

  const handleGroundControlSelected = (
    groundControlId: string,
    quantityValue: string,
    optionValuePairs: any[],
  ) => {
    const currentGroundControls = selectedGroundControls;
    const index = currentGroundControls.findIndex(x => x.typeId === groundControlId);
    if (index !== -1) {
      currentGroundControls.splice(index, 1);
    } else {
      currentGroundControls.push({
        typeId: groundControlId,
        quantity: quantityValue,
        optionValues: optionValuePairs,
      });
    }
    setSelectedGroundControls([...currentGroundControls]);
  };

  const handleGroundControlUpdated = (
    groundControlId: string,
    quantityValue?: string,
    optionId?: string,
    optionValueId?: string,
  ) => {
    const currentGroundControls = selectedGroundControls;
    const index = currentGroundControls.findIndex(x => x.typeId === groundControlId);
    const gcToUpdate = currentGroundControls[index];

    gcToUpdate.quantity = quantityValue;

    if (optionId && optionValueId) {
      const optionIndex = gcToUpdate.optionValues.findIndex(x => x.optionId === optionId);
      if (optionIndex !== -1) {
        gcToUpdate.optionValues[optionIndex].valueId = optionValueId;
      } else {
        gcToUpdate.optionValues.push({
          optionId,
          valueId: optionValueId,
        });
      }
    }

    currentGroundControls[index] = gcToUpdate;
    setSelectedGroundControls([...currentGroundControls]);
  };

  // Block Panel Room
  const getPanelRoomOptions = async () => {
    const rooms = await getRoomOptions();
    setRoomOptions(rooms);
  };

  useEffect(() => {
    getPanelRoomOptions();
  }, [open]);

  // Survey Points (Sequences In UI)

  const onEndSurveyPointChanged = (newSurveyPoint: SurveyPointDocument) => {
    setEndSurveyPoint(newSurveyPoint);
  };

  // Sequences (Step In UI)

  const onEndSequenceChanged = (newSeqeunce: SequenceDocument) => {
    setEndSequence(newSeqeunce);
  };

  // Pass

  const onEndPassChanged = (newPass: PassDocument) => {
    setEndPass(newPass);
  };

  const onEndDistanceChanged = (value: string) => {
    setEndDistance(value);
    setHasEdits(true);

    if (value === '' || RegExp('^[0-9]+$').test(value)) {
      setEndDistanceErrorText('');
    } else {
      setEndDistanceErrorText(i18n.t('Invalid End Distance.'));
    }
  };

  // Save Validation
  const validateCanSave = useCallback(() => {
    let hasRequireData = true;

    if (selectedUser === '' || selectedUser === null) hasRequireData = false;

    // Can only save a fully remediated item if it has a historic or current ground control.
    if (selectedGroundControls.length === 0 && !hasExistingGroundControls && isFullyRemediated)
      hasRequireData = false;
    if (groundControlErrors) hasRequireData = false;

    if (reportEndLocation) {
      if (!endPanel) hasRequireData = false; // This covers block, panel, and room selection
      if (endSurveyPoints.length > 0 && !endSurveyPoint) hasRequireData = false;
      if (isLanigan && !endSequence) hasRequireData = false;
      if (endDistanceErrorText !== '') hasRequireData = false;
    }

    setCanSave(hasRequireData);
  }, [
    selectedUser,
    reportEndLocation,
    endPanel,
    endSurveyPoints,
    endSurveyPoint,
    endSequence,
    groundControlErrors,
    selectedGroundControls,
    endDistanceErrorText,
    hasExistingGroundControls,
    isFullyRemediated,
    isLanigan,
  ]);

  useEffect(() => {
    validateCanSave();
  }, [
    validateCanSave,
    selectedUser,
    comment,
    reportEndLocation,
    endPanel,
    endSurveyPoints,
    endSurveyPoint,
    endSequence,
    groundControlErrors,
    selectedGroundControls,
    isFullyRemediated,
  ]);

  useEffect(() => {
    if (open) {
      getLocationData();
      populate();
    }
    if (open === true && undo === false) {
      if (groundHazard) {
        populateSelectedConditionType();
      }
      setSelectedUser('');
      setComment('');
      setEndRoom(undefined);
      setEndPass(undefined);
      setEndSurveyPoint(undefined);
      setEndSequence(undefined);
      setEndDistance('');
      setEndDistanceErrorText('');
      setExpandedPanel(HazardPanelTypes.DETAILS);
      setIsFullyRemediated(groundHazard?.remediatedOn !== null);
      setReportEndLocation(
        groundHazard?.endLocation !== undefined && groundHazard?.endLocation !== null,
      );
      setHasEdits(false);
      setSelectedImages([]);
      setSelectedGroundControls([]);
      setValidationIndex(0);
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open]);

  return (
    <>
      <SidePanel
        open={open}
        onClose={openDiscardEditsModel}
        transitionDuration={200}
        disableBackdropClick
        ModalProps={{
          BackdropProps: {
            onClick: event => {
              event.preventDefault();
            },
            onTouchStart: event => {
              event.preventDefault();
            },
            style: { touchAction: 'none' },
          },
        }}
      >
        <DialogTitle disableTypography className={classes.root}>
          <div className={classes.headerContainer}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item style={{ width: '36px', height: '36px' }}>
                <div />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="inherit">
                  {i18n.t('Update hazard')}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label={i18n.t('close')}
                  onClick={openDiscardEditsModel}
                  id="close-button"
                >
                  <Icons.XFeather strokeWidth={1} color="primary" />
                </IconButton>
              </Grid>
            </Grid>
            <div className={`${classes.rightButtonContainer} `} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <AddCommentDetailsPanel
            names={employees}
            onNameSelected={onEmployeeSelected}
            selectedName={selectedUser}
            conditions={conditionTypes}
            onConditionSelected={onConditionSelected}
            selectedCondition={selectedConditionType}
            onCommentTextChanged={onCommentTextChange}
            commentText={comment}
            isExistingHazard
            validationIndex={validationIndex}
            setValidationIndex={setValidationIndex}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.DETAILS}
            currentExpandedPanelId={expandedPanelId}
          />
          <GroundControlExpansionPanel
            title={i18n.t('Ground control')}
            onGroundControlSelected={handleGroundControlSelected}
            onGroundControlUpdated={handleGroundControlUpdated}
            updateQuantityErrors={handleGroundControlError}
            currentSelectedGroundControls={selectedGroundControls}
            validationIndex={validationIndex}
            setValidationIndex={setValidationIndex}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.GROUND_CONTROLS}
          />
          <HazardPhotosPanel
            expansionPanelId={HazardPanelTypes.PHOTOS}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.PHOTOS}
            selectedImages={selectedImages}
            onFilesSelected={onFilesSelected}
            setValidationIndex={setValidationIndex}
          />
          {groundHazard !== undefined && (
            <HazardStatusPanel
              hasGroundControls={hasExistingGroundControls || selectedGroundControls.length > 0}
              isFullyRemediated={isFullyRemediated}
              onFullyRemediatedChanged={(val: boolean) => {
                setIsFullyRemediated(val);
                setHasEdits(true);
              }}
              reportEndLocation={reportEndLocation}
              onReportEndLocationChanged={async val => {
                setReportEndLocation(val);
                if (val) {
                  await getLocationData();
                }
              }}
              roomOptions={roomOptions}
              onRoomChanged={onEndRoomChanged}
              selectedRoom={
                endBlock && endPanel && endRoom
                  ? { block: endBlock, panel: endPanel, room: endRoom }
                  : undefined
              }
              surveyPointOptions={endSurveyPoints}
              onSurveyPointChanged={onEndSurveyPointChanged}
              selectedSurveyPoint={endSurveyPoint}
              sequences={endSequences}
              selectedSequence={endSequence}
              onSequenceChanged={onEndSequenceChanged}
              passes={endPasses}
              selectedPass={endPass}
              onPassChanged={onEndPassChanged}
              endDistance={endDistance}
              onEndDistanceChanged={onEndDistanceChanged}
              endDistanceErrorText={endDistanceErrorText}
              validationIndex={validationIndex}
              setValidationIndex={setValidationIndex}
              onExpanded={onExpanded}
              expanded={expandedPanelId === HazardPanelTypes.REMEDIATION}
              endHazardLandmark={endHazardLandmark}
              setEndHazardLandmark={setEndHazardLandmark}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={openDiscardEditsModel}
                data-cy="cancel-add-comment"
                noMinHeight
                id="cancel-add-comment"
              >
                {i18n.t('Cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                data-cy="save-add-comment"
                id="save-add-comment"
                noMinHeight
                isLoading={saving}
                onClick={saveComment}
                disabled={!canSave}
              >
                {i18n.t('Save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </SidePanel>
      <DiscardDraftModal
        open={discardOpen}
        onCancel={() => {
          setDiscardOpen(false);
          setUndo(false);
        }}
        onDiscard={() => {
          onClose();
          setDiscardOpen(false);
          errorNotification(i18n.t('Draft comment discarded'), {
            action: key => (
              <Button
                noMinHeight
                color="primary"
                variant="text"
                onClick={() => {
                  setUndo(true);
                  closeSnackbar(key);
                  onOpen();
                }}
                id="undo-discard-comment"
              >
                {i18n.t('Undo')}
              </Button>
            ),
          });
        }}
      />
    </>
  );
};

export default observer(AddCommentSidePanel);
