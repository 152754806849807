import { createStyles, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@nutrien/cxp-components';
import postal from 'postal';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subscription } from 'rxjs';

import RxdbManager from '../../rxdb/RxdbManager';
import useRxDB from '../../rxdb/useRxDB';
import { useOnlineStatus } from '../../utilities';
import { SYNC_COMPLETE_KEY } from '../../utilities/constants';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';
import { saveSyncTimeToLocalStorage } from '../../utilities/syncHelpers';
import OfflineStatusDialog from '../OfflineStatusDialog';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
    },
    card: {
      maxWidth: '450px',
      padding: '70px 40px',
      borderRadius: '10px',
    },
    imageContainer: {
      marginBottom: '50px',
      textAlign: 'center',
      position: 'relative',
    },
    icon: {
      '& svg': {
        marginTop: '5px',
      },
    },
    progressBar: {
      width: '188px',
      position: 'absolute',
      top: '84px',
      left: 'calc(50% - 95px)',
      '& > div': {
        backgroundColor: '#fff',
        height: '2px',
      },
    },
    buttonContainer: {
      width: '200px',
      position: 'absolute',
      right: '16px',
    },
  }),
);

const Loading: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const { initialLoad } = queryString.parse(search);
  const isOnline = useOnlineStatus();
  const { trackEvent } = useEventTracking();

  const { db } = useRxDB();

  const [syncCount, setSyncCount] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [showLogout, setShowLogout] = useState(false);
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const onlineCount = useRef<number>(0);

  const increaseCount = () => {
    setSyncCount(prev => prev + 1);
  };

  const logoutUser = async () => {
    setLogoutInProgress(true);
    await trackEvent(TrackingEventType.LOGOUT_CLICKED, {
      location: 'LoadingScreen',
    });
    history.push('/logout');
    setLogoutInProgress(false);
  };

  // Show logout button if more than 30 seconds have passed
  useEffect(() => {
    setTimeout(() => {
      setShowLogout(true);
    }, 30000);
  }, []);

  useEffect(() => {
    if (syncCount > 0) {
      const percent = Math.round((syncCount / db?.syncStates?.length) * 100);
      setProgress(percent);
    }
    if (isOnline && !!db?.syncStates.length && syncCount >= db?.syncStates.length) {
      saveSyncTimeToLocalStorage();

      window.postMessage(SYNC_COMPLETE_KEY, '*');

      history.push('/signatures');
    }
  }, [syncCount, db?.isInitialized, isOnline, db?.syncStates]);

  useEffect(() => {
    const subArray: Subscription[] = [];

    const postalSub = postal.subscribe({
      channel: 'sync',
      topic: 'sync.online',
      callback: () => {
        RxdbManager.instance.syncStates.forEach(state => {
          const sub = state.initialReplicationComplete$.subscribe(
            (initialReplicationComplete: boolean) => {
              if (initialReplicationComplete === true) increaseCount();
            },
          );
          subArray.push(sub);
        });
      },
    });

    return () => {
      postalSub.unsubscribe();
      subArray.forEach(sub => sub.unsubscribe());
    };
  }, []);

  useEffect(() => {
    if (!isOnline && !initialLoad) {
      // If we are not online and not initial load, take them to signatures
      history.push('/signatures');
    }
  }, [isOnline, initialLoad]);

  useEffect(() => {
    if (isOnline) {
      onlineCount.current += 1;
      if (onlineCount.current > 1) {
        setSyncCount(0);
      }
    } else {
      setSyncCount(0);
    }
  }, [isOnline]);

  return (
    <>
      {showLogout && (
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="outlined"
            onClick={logoutUser}
            id="logout-user"
            isLoading={logoutInProgress}
          >
            Logout
          </Button>
        </div>
      )}
      <Grid container className={classes.root} justify="center" alignItems="center">
        <Grid item xs={8} md={6} className={classes.card}>
          <div className={classes.imageContainer}>
            <img src="../../minesight_borer_noline.svg" alt="MineSight Borer" />
            <div className={classes.progressBar}>
              {isOnline && <LinearProgress variant="determinate" value={progress} />}
            </div>
          </div>
        </Grid>
      </Grid>
      <OfflineStatusDialog open={!!(!isOnline && initialLoad)} />
    </>
  );
};

export default Loading;
