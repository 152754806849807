import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React from 'react';

import ShiftEndCard from '../ShiftEndCard';

dayjs.extend(utc);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 144px)',
      overflow: 'scroll',
    },
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
  }),
);

const ShiftEnd: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} data-cy="close-shift-section">
        <ShiftEndCard />
      </Grid>
    </Grid>
  );
};

export default observer(ShiftEnd);
