import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface Panel extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly block: string;
  readonly miningMethod: string;
  readonly completedOn: string | null;
}
export type PanelCollection = RxCollection<Panel> | null;
export type PanelDocument = RxDocument<Panel>;

export const panelMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: PanelDocument) => {
    return {
      ...oldDoc,
      completedOn: null,
    };
  },
};

export const panelSchema: RxJsonSchema<Panel> = {
  type: 'object',
  version: 1,
  description: 'describes a Panel object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    block: {
      ref: RxdbCollectionName.BLOCKS,
      type: 'string',
    },
    miningMethod: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    completedOn: { type: ['string', 'null'] },
  },
};

export const panelsPullQueryBuilder = (doc: Panel) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query panelFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      panelFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
        id
        description
        block
        miningMethod
        isDeleted
        updatedAt
        version
        completedOn
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
