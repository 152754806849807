import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Pass extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly miningMethod: string;
  readonly miningStyle: string;
}
export type PassesCollection = RxCollection<Pass> | null;
export type PassDocument = RxDocument<Pass>;

export const passesSchema: RxJsonSchema<Pass> = {
  type: 'object',
  version: 0,
  description: 'describes a Block object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    miningMethod: { type: 'string' },
    miningStyle: { type: 'string' },
    version: { type: 'number' },
  },
};

export const passesPullQueryBuilder = (doc: Pass) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = `{
    passFeed (siteId: "${rootStore.user.siteId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
      description
      id
      isActive
      isDeleted
      miningMethod
      miningStyle
      updatedAt
      version
        }
      }`;
  return {
    query,
    variables: {},
  };
};

export default {};
