import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { DocumentTypeDocument } from '../DocumentTypes/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface DocumentUpload extends BaseEntity {
  attachmentId: string;
  siteId: string;
  roomId: string | null;
  surveyPointId: string | null;
  borerDocumentTypeId: string | null;
  stopcarryforwardRoomId: string | null;
  stopcarryforwardSurveyPointId: string | null;
  miningMethod: string | null;
  fileName: string | null;
  description: string | null;
  isActive: boolean;
  isStopCarryForward: boolean;
  createdOn: string;
  // Local properties
  isCached: boolean;
  isLoading: boolean;
  retryCount: number;
  documentTypeDescription: string | null;
  displayOrder: number | null;
}
export type DocumentUploadCollection = RxCollection<DocumentUpload> | null;
export type DocumentUploadDocument = RxDocument<DocumentUpload>;

export interface EnrichedDocument extends DocumentUploadDocument {
  documentTypeInformation: DocumentTypeDocument;
  surveyPointDesc?: number;
  getDocumentFromStorage: () => Promise<string | null>;
  documentInStorage: boolean;
  displayOrder?: number | null;
}

export const documentUploadSchema: RxJsonSchema<DocumentUpload> = {
  type: 'object',
  version: 2,
  description: 'describes a documentUpload object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    siteId: { type: 'string' },
    attachmentId: { type: 'string' },
    roomId: { type: ['string', 'null'] },
    surveyPointId: { type: ['string', 'null'] },
    borerDocumentTypeId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.DOCUMENT_TYPES,
    },
    stopcarryforwardRoomId: { type: ['string', 'null'] },
    stopcarryforwardSurveyPointId: { type: ['string', 'null'] },
    miningMethod: { type: ['string', 'null'] },
    fileName: { type: ['string', 'null'] },
    description: { type: ['string', 'null'] },
    isActive: { type: 'boolean' },
    isStopCarryForward: { type: 'boolean' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    createdOn: { type: ['string', 'null'] },
    // Local properties
    isCached: { type: 'boolean' },
    isLoading: { type: 'boolean' },
    retryCount: { type: 'number' },
    documentTypeDescription: { type: ['string', 'null'] },
    displayOrder: { type: ['number', 'null'] },
  },
};

export const documentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: DocumentUploadDocument) => {
    return { ...oldDoc, createdOn: null };
  },
  2: async (oldDoc: DocumentUploadDocument) => {
    return {
      ...oldDoc,
      isCached: false,
      isLoading: false,
      retryCount: 0,
      documentTypeDescription: null,
      displayOrder: null,
    };
  },
};

export const documentUploadPullQueryBuilder = (doc: DocumentUpload) => {
  if (!doc) {
    doc = {
      ...defaultDoc,
      updatedAt: 0,
    };
  }

  const query = print(gql`
    query documentFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      documentFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
        id
        isDeleted
        updatedAt
        version
        attachmentId
        borerDocumentTypeId
        description
        fileName
        isActive
        isStopCarryForward
        miningMethod
        roomId
        siteId
        stopcarryforwardRoomId
        stopcarryforwardSurveyPointId
        surveyPointId
        createdOn
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const documentUploadPullModifier = (doc: DocumentUpload) => {
  const result = {
    ...doc,
    isCached: false,
    isLoading: false,
    retryCount: 0,
  };
  return result;
};

export default {};
