import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export const borerShiftCommentPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftCommentFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftCommentFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        borerShiftCommentTypeId
        borerShiftId
        id
        comment
        isDeleted
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export interface BorerShiftComment extends BaseEntity {
  readonly borerShiftCommentTypeId: string;
  readonly borerShiftId: string;
  readonly comment: string;
}

export interface ExtendedBorerShiftComment extends BorerShiftComment {
  readonly parsedComment?: string;
}

export const borerShiftCommentSchema: RxJsonSchema<BorerShiftComment> = {
  type: 'object',
  version: 3,
  description: 'describes a borer shift comment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    borerShiftId: { type: 'string' },
    borerShiftCommentTypeId: {
      type: 'string',
      ref: RxdbCollectionName.BORER_SHIFT_COMMENT_TYPE,
    },
    comment: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

export const borerShiftCommentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCommentDocument) => ({
    ...oldDoc,
  }),
  2: (oldDoc: BorerShiftCommentDocument) => ({
    ...oldDoc,
  }),
  3: (oldDoc: BorerShiftCommentDocument) => ({
    ...oldDoc,
  }),
};

export type BorerShiftCommentCollection = RxCollection<BorerShiftComment> | null;
export type BorerShiftCommentDocument = RxDocument<BorerShiftComment>;

export const borerShiftCommentPushQueryBuilder = (doc: ExtendedBorerShiftComment) => {
  doc = doc[0];

  delete doc.parsedComment;
  const query = print(gql`
    mutation setBorerShiftComment($setBorerShiftCommentInput: SetBorerShiftCommentInput) {
      setBorerShiftComment(input: $setBorerShiftCommentInput) {
        id
        siteId
        shiftId
        borerEquipmentId
        borerShiftId
      }
    }
  `);

  const variables = {
    setBorerShiftCommentInput: {
      borerShiftId: doc.borerShiftId,
      borerShiftCommentTypeId: doc.borerShiftCommentTypeId,
      comment: doc.comment,
      version: doc.version,
      id: doc.id,
    },
  };

  return {
    query,
    variables,
  };
};

export const borerShiftCommentPullModifier = (doc: any) => {
  return doc;
};

export default {};
