import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete, MaterialPalette } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import { sortAlphabeticallyByProperty } from '../../../utilities/sortHelper';

interface Props {
  selectedValueId?: string;
  option: any;
  handleValueChanged: (optionId: string, valueId: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: MaterialPalette.background.paper2,
      '& h6': {
        fontSize: '20px',
        color: MaterialPalette.tertiary.main,
      },
    },
    checkboxLabel: {
      width: '100%',
    },
    optionsContainer: {
      paddingLeft: '30px',
      '& > div > span': {
        color: MaterialPalette.primary.main,
      },
    },
    nested: {
      paddingLeft: '30px',
    },
  }),
);

const GroundControlDropdownOption = ({ option, selectedValueId, handleValueChanged }: Props) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState<any>(option.values[0]);

  useEffect(() => {
    const val = option.values.find(x => x.id === selectedValueId);
    if (val) {
      setSelectedValue(val);
    }
  }, [selectedValueId]);

  const onSelected = (optionValue: any) => {
    setSelectedValue(optionValue);
    handleValueChanged(option.id, option.values.find(x => x.value === optionValue.value).id);
  };

  return (
    <>
      <Grid item container xs={12} key={option.id} className={classes.nested}>
        <AutoComplete
          list={sortAlphabeticallyByProperty(option.values, 'value')}
          label={option.description}
          required
          getOptionLabel={(optionValue: any) => {
            return optionValue.value;
          }}
          onChange={(event, optionValue) => {
            onSelected(optionValue);
          }}
          value={selectedValue}
          showCaret
          disablePortal
          data-cy={`${option.type}-Dropdown-Autocomplete`}
        />
      </Grid>
    </>
  );
};

export default GroundControlDropdownOption;
