import { Dayjs } from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { BorerShiftCrewMemberEmployee } from '../../models/models';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export const borerShiftPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        id
        isDeleted
        version
        updatedAt
        isOpen
        shiftId
        closedOn
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export interface BorerShift extends BaseEntity {
  readonly isOpen: boolean;
  readonly shiftId: string;
  readonly closedOn: string | null;
}

export interface BorerShiftCrewMemberInput {
  siteEmployeeId: string;
  employeeOrder: number;
}

export interface BorerShiftCrewType {
  id: string;
  borerShiftId: string;
  startDateTime: string | Dayjs;
  endDateTime: string | Dayjs;
  borerShiftCrewMemberInput: BorerShiftCrewMemberEmployee[];
  version: number;
  crewNumber: number;
  index?: number;
}

export interface UpdateBorerShiftCrewInput {
  id: string;
  borerShiftId: string;
  startDateTime: string;
  endDateTime: string;
  borerShiftCrewMemberInput: BorerShiftCrewMemberInput[];
  version: number;
}

export const borerShiftSchema: RxJsonSchema<BorerShift> = {
  type: 'object',
  version: 1,
  description: 'describes an borer shift object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    shiftId: { type: 'string' },
    isOpen: { type: 'boolean' },
    closedOn: { type: ['string', 'null'] },
  },
};

export const borerShiftMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftDocument) => {
    return {
      ...oldDoc,
      closedOn: null,
    };
  },
};

export type BorerShiftCollection = RxCollection<BorerShift> | null;
export type BorerShiftDocument = RxDocument<BorerShift>;

export const borerShiftPushQueryBuilder = (doc: BorerShiftDocument[]) => {
  doc = doc[0];

  const query = print(gql`
    mutation closeBorerShift($borerShiftInput: CloseBorerShiftInput) {
      closeBorerShift(input: $borerShiftInput)
    }
  `);

  const variables = {
    borerShiftInput: {
      id: doc.id,
      closedOn: doc.closedOn,
    },
  };

  return {
    query,
    variables,
  };
};

export default {};
