import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useBorerShiftInfo from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';
import { BORER_SHIFT_WRITE_PERMISSION } from '../../utilities/constants';
import { useNumberFormatter } from '../../utilities/useNumberFormatter';
import EditMeterHoursSidePanel from '../EditMeterHoursSidePanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    label: {
      color: theme.palette.text.secondary,
    },
    buttonDiv: {
      width: 'fit-content',
      minWidth: '125px',
    },
  }),
);

interface Props {
  borerShiftOpen: boolean;
}

const ShiftEndMeterHoursCard: React.FC<Props> = ({ borerShiftOpen }: Props) => {
  const classes = useStyles();
  const { shiftPicker, user } = useMst();
  const { isCory, isVanscoy } = useSiteFeatures();
  const { startMeterHours, endMeterHours, refreshStartAndEndMeters } = useBorerShiftInfo(
    shiftPicker.currentBorerShiftId,
  );

  const { format } = useNumberFormatter();

  const totalHours = useMemo(() => {
    if (startMeterHours !== undefined && endMeterHours !== undefined)
      return endMeterHours - startMeterHours;
  }, [startMeterHours, endMeterHours]);

  const [meterHoursEditOpen, setEditMeterHoursOpen] = useState(false);

  return (
    <>
      <Card className={classes.cardRoot} onClick={() => {}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">
              {isCory
                ? i18n.t('Head motor hours')
                : isVanscoy
                ? i18n.t('Rotor hours')
                : i18n.t('Meter hours')}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} container justify="flex-end">
            {user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && borerShiftOpen && (
              <div className={classes.buttonDiv}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setEditMeterHoursOpen(true);
                  }}
                  noMinHeight
                  startAdornment={<Icons.EditFeather color="primary" />}
                  id="edit-meter-hours-button"
                >
                  Edit end
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('Start')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {startMeterHours && format(startMeterHours)} hrs
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('End')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{endMeterHours && format(endMeterHours)} hrs</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" className={classes.label}>
              {i18n.t('Total')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {totalHours !== undefined && format(totalHours)} hrs
          </Grid>
        </Grid>
      </Card>
      <EditMeterHoursSidePanel
        isCory={isCory}
        isVanscoy={isVanscoy}
        open={meterHoursEditOpen}
        onClose={() => {
          setEditMeterHoursOpen(false);
          refreshStartAndEndMeters();
        }}
        onOpen={() => {
          setEditMeterHoursOpen(true);
        }}
        onCancel={() => {
          setEditMeterHoursOpen(false);
          refreshStartAndEndMeters();
        }}
      />
    </>
  );
};

export default observer(ShiftEndMeterHoursCard);
