import dayjs from 'dayjs';

import { BaseEntity } from '../../models/BaseEntity';

export const defaultDoc: BaseEntity = {
  id: '',
  updatedAt: dayjs().startOf('day').subtract(14, 'days').valueOf(),
  isDeleted: false,
  version: 1,
};

export default defaultDoc;
