import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { PassDocument, SequenceDocument } from '../../models/models';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { MiningPatternDocument } from '../MiningPattern/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface MiningCutSequencePassObj {
  readonly miningMethod: string;
  readonly miningCut: MiningCutDocument;
  readonly miningPattern?: MiningPatternDocument;
  readonly miningPatternName?: string;
  readonly sequence: SequenceDocument | null;
  readonly pass: PassDocument;
  readonly sequencePassString?: string;
}

export interface MiningCut extends BaseEntity {
  readonly miningPattern?: string;
  readonly density: number;
  readonly expectedLength: number;
  readonly order: number;
  readonly miningMethod: string;
  readonly miningStyle: string;
  readonly pass: string;
  readonly sequence?: string;
  readonly isActive: boolean;
}
export type MiningCutCollection = RxCollection<MiningCut> | null;
export type MiningCutDocument = RxDocument<MiningCut>;

export const miningCutSchema: RxJsonSchema<MiningCut> = {
  type: 'object',
  version: 1,
  description: 'describes a MiningCut object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    miningPattern: {
      ref: RxdbCollectionName.MINING_PATTERN,
      type: ['string', 'null'],
    },
    density: { type: 'number' },
    expectedLength: { type: 'number' },
    order: { type: 'number' },
    miningMethod: { type: 'string' },
    miningStyle: { type: 'string' },
    sequence: {
      ref: 'sequences',
      type: ['string', 'null'],
    },
    pass: {
      ref: 'passes',
      type: ['string', 'null'],
    },
    isActive: { type: 'boolean' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const miningCutMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: MiningCutDocument) => oldDoc,
};

export const miningCutPullQueryBuilder = (doc: MiningCut) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query miningCutFeed($siteId: ID!, $limit: Int!, $lastUpdateAt: Float!) {
      miningCutFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
        density
        sequence
        pass
        expectedLength
        id
        isActive
        isDeleted
        miningMethod
        miningPattern
        miningStyle
        order
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
      siteId: rootStore.user.siteId,
    },
  };
};

export default {};
