import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useEmployees from '../../rxdb/Employees/useEmployees';
import { EquipmentDeficiencyDocument } from '../../rxdb/EquipmentDeficiency/queryBuilder';
import {
  EquipmentDeficiencyWithChildren,
  useEquipmentDeficiency,
} from '../../rxdb/EquipmentDeficiency/useEquipmentDeficiency';
import useEquipmentDeficiencyLog from '../../rxdb/EquipmentDeficiencyLog/useEquipmentDeficiencyLog';
import { EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION, InspectionType } from '../../utilities/constants';
import AddDeficiencySidePanel from '../AddDeficiencySidePanel';
import AddPreOpCheck from '../AddPreOpCheck';
import DeficienciesMenu from '../DeficienciesMenu';
import DeficiencyAddCommentSidePanel from '../DeficiencyAddCommentSidePanel';
import DeficiencyPanelContent from '../DeficiencyPanelContent';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    cardContainer: {
      padding: '0px !important',
    },
    deficiencyCard: {
      padding: '16px',
      margin: '0px 8px 8px 8px !important',
    },
  }),
);

const ActiveDeficienciesCard: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker, user } = useMst();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [addDeficiencyOpen, setAddDeficiencyOpen] = useState(false);

  const { employeesList: employees } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: false,
    populatedPosition: false,
    onlyActiveCrew: false,
  });

  const {
    deficiencyCollectionsInitialized,
    listActiveEquipmentDeficienciesForThisShift,
    equipmentDeficiencyCollection,
  } = useEquipmentDeficiency();

  const { deficiencyLogInitialized, equipmentDeficiencyLogCollection } =
    useEquipmentDeficiencyLog();

  // Controls
  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const onCloseMenu = () => setAnchorEl(null);
  const onOpenAddDeficiency = useCallback(() => {
    setAddDeficiencyOpen(true);
    onCloseMenu();
  }, []);
  const onCloseAddDeficiency = useCallback(() => setAddDeficiencyOpen(false), []);

  // Active Deficiencies to Display
  const [isLoading, setIsLoading] = useState(true);
  const [activeDeficiencies, setActiveDeficiencies] = useState<EquipmentDeficiencyWithChildren[]>(
    [],
  );

  const getActiveDeficiencies = async (silentLoad = false) => {
    if (!silentLoad) setIsLoading(true);
    try {
      setActiveDeficiencies(await listActiveEquipmentDeficienciesForThisShift());
    } catch (error) {
      console.log(
        '🚀 ~ file: ActiveDeficienciesCard.tsx ~ line 46 ~ getActiveDeficiencyCards ~ error',
        error,
      );
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  const subscribeToDeficiencyChanges = async () => {
    if (equipmentDeficiencyCollection) {
      equipmentDeficiencyCollection.$.subscribe(() => {
        getActiveDeficiencies(true);
      });
    }
  };

  const subscribeToDeficiencyLogChanges = async () => {
    if (equipmentDeficiencyLogCollection) {
      equipmentDeficiencyLogCollection.$.subscribe(() => {
        getActiveDeficiencies(true);
      });
    }
  };

  useEffect(() => {
    if (deficiencyCollectionsInitialized) {
      getActiveDeficiencies();
    }

    subscribeToDeficiencyChanges();
    subscribeToDeficiencyLogChanges();
  }, [
    deficiencyCollectionsInitialized,
    equipmentDeficiencyCollection,
    deficiencyLogInitialized,
    shiftPicker.currentShiftId,
    shiftPicker.Date,
    shiftPicker.Type,
  ]);

  // Add comment side panel
  const [open, setOpen] = useState(false);
  const [selectedDeficiencyId, setSelectedDeficiencyId] = useState('');
  const [newInspectionType, setNewInspectionType] = useState<InspectionType | undefined>();
  const attachmentNamePrefixRef = useRef<string>('');

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const openAddCommentSidePanel = useCallback(
    (deficiency: EquipmentDeficiencyDocument, attachmentNamePrefix: string) => {
      setSelectedDeficiencyId(deficiency.id);
      attachmentNamePrefixRef.current = attachmentNamePrefix;
      onOpen();
    },
    [],
  );

  // Add Pre-Op Check
  const [preOpOpen, setPreOpOpen] = useState(false);

  const onOpenPreOp = (inspectionType = InspectionType.PRE_OP) => {
    setNewInspectionType(inspectionType);
    setPreOpOpen(true);
    onCloseMenu();
  };
  const onClosePreOp = () => {
    setNewInspectionType(undefined);
    setPreOpOpen(false);
  };

  const activeDeficienciesCards = useMemo(() => {
    return activeDeficiencies.map(doc => {
      const attachmentNamePrefix = `${doc.equipmentType.description}
      ${doc.equipment.longName}_${doc.deficiency.description}_`
        .split('/')
        .join('-');

      return (
        <Grid item xs={12} key={`grid-${doc.deficiency.id}`}>
          <Card className={classes.deficiencyCard}>
            <DeficiencyPanelContent
              deficiency={doc.deficiency}
              equipment={doc.equipment}
              equipmentType={doc.equipmentType}
              logs={doc.logs}
              employeeList={employees}
              openAddCommentSidePanel={deficiency =>
                openAddCommentSidePanel(deficiency, attachmentNamePrefix)
              }
              viewOnly={!user.hasPermissionTo(EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION)}
            />
          </Card>
        </Grid>
      );
    });
  }, [activeDeficiencies, user, employees]);

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={10}>
              <Typography variant="h3">{i18n.t('Active deficiencies')}</Typography>
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              {shiftPicker.isCurrentShiftSelected() &&
                user.hasPermissionTo(EQUIPMENT_DEFICIENCIES_WRITE_PERMISSION) && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      noMinHeight
                      startAdornment={<Icons.PlusFeather />}
                      onClick={onOpenMenu}
                      data-cy="add-deficiency-menu"
                      id="add-deficiency-menu"
                    >
                      {i18n.t('Add...')}
                    </Button>
                    <DeficienciesMenu
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={onCloseMenu}
                      openAddDeficiency={onOpenAddDeficiency}
                      onAddPreOpCheck={onOpenPreOp}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.cardContainer} justify="center">
            {isLoading && <CircularProgress />}
            {!isLoading && activeDeficienciesCards}
          </Grid>
        </Grid>
      </Card>
      <DeficiencyAddCommentSidePanel
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        deficiencyId={selectedDeficiencyId}
        attachmentNamePrefix={attachmentNamePrefixRef.current}
      />
      <AddDeficiencySidePanel
        open={addDeficiencyOpen}
        onClose={onCloseAddDeficiency}
        onOpen={onOpenAddDeficiency}
      />
      <AddPreOpCheck
        open={preOpOpen}
        onClose={onClosePreOp}
        onOpen={onOpenPreOp}
        inspectionType={newInspectionType}
      />
    </>
  );
};

export default observer(ActiveDeficienciesCard);
