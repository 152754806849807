import React from 'react';

import PreOpCheckCard from '../PreOpCheckCard';

const DeficienciesChecksTab: React.FC = () => {
  return (
    <>
      <PreOpCheckCard />
    </>
  );
};

export default DeficienciesChecksTab;
