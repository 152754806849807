import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Tab, Tabs, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { useConstructor } from '../../utilities';
import { AREA_CHECK_TAB_INDEX } from '../../utilities/constants';
import AreaCheckChecksTab from '../AreaCheckChecksTab';
import AreaCheckConditionsTab from '../AreaCheckConditionsTab';
import AreaCheckHistoricalTab from '../AreaCheckHistoricalTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      touchAction: 'none',
      width: '100%',
    },
    container: {
      overflowY: 'scroll',
      height: 'calc(100vh - 160px)',
      zIndex: 1500,
      width: '100%',
    },
    card: {
      padding: '16px',
    },
    tabCard: {
      backgroundColor: theme.palette.common.white,
      boxShadow:
        '0px 24px 38px rgba(0, 0, 0, 0.035), 0px 9px 46px rgba(0, 0, 0, 0.03), 0px 11px 15px rgba(0, 0, 0, 0.05);',
      '& button > span': {
        textTransform: 'none !important',
      },
    },
    progress: {
      display: 'flex',
      height: '100%',
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
    },
    backgroundCard: {
      backgroundColor: '#121212 !important',
    },
  }),
);

const tabProperties = [
  { label: i18n.t('Hazards'), key: 'conditionsTab' },
  { label: i18n.t('Checks this shift'), key: 'checksTab' },
  { label: i18n.t('Historical'), key: 'historicalTab' },
];

const AreaCheckPage: React.FC = () => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(-1);

  useConstructor(async () => {
    const lastTab: number = parseInt(localStorage.getItem(AREA_CHECK_TAB_INDEX) || '0');
    setTabIndex(lastTab);
  });

  const onTabChanged = (event: React.ChangeEvent<unknown>, newValue: number) => {
    localStorage.setItem(AREA_CHECK_TAB_INDEX, newValue.toString());
    setTabIndex(newValue);
  };

  const uiForTab = useMemo(
    (index: number = tabIndex) => {
      switch (index) {
        case 0:
          return <AreaCheckConditionsTab />;
        case 1:
          return <AreaCheckChecksTab />;
        case 2:
          return <AreaCheckHistoricalTab />;
        default:
          return (
            <div>
              <Typography>Unknown Tab</Typography>
            </div>
          );
      }
    },
    [tabIndex],
  );

  return (
    <div
      className={classes.root}
      onTouchStart={e => {
        e.preventDefault();
      }}
    >
      <Card className={classes.backgroundCard}>
        <Grid container className={classes.container} alignContent="flex-start">
          <Grid item xs={12}>
            {tabIndex !== -1 && (
              <>
                <Card className={classes.tabCard}>
                  <Tabs value={tabIndex} variant="standard" onChange={onTabChanged}>
                    {tabProperties.map(tab => (
                      <Tab label={tab.label} key={tab.key} />
                    ))}
                  </Tabs>
                </Card>
                <div>{uiForTab}</div>
              </>
            )}
            {tabIndex === -1 && (
              <Grid
                container
                item
                xs={12}
                justify="center"
                alignContent="center"
                className={classes.progress}
              >
                <CircularProgress className={classes.spinner} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default observer(AreaCheckPage);
