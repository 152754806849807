import { Grid } from '@material-ui/core';
import {
  Checkbox,
  i18n,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { BaseSyntheticEvent } from 'react';

import { WfaiInspectionDetails } from '../../rxdb/Inspections/useInspections';
import ActiveConditionsCard from '../ActiveConditionsCard';
import GCHAVerbage, { addNewHazardArray } from '../AddWorkFaceAreaInspection/GCHAVerbage';
import RemediatedConditionsCard from '../RemediatedConditionsCard';
import useStyles from './AddWorkFaceAreaInspectionCard.styles';

interface Props {
  onCommentClicked: (id: string) => void;
  inspectionDetails?: WfaiInspectionDetails;
  onAddAnotherHazardClicked: (forceRemediated: boolean) => void;
  onSelectedHazardButtonChange: (value: string) => void;
  onGchaChecked: (checked: boolean) => void;
  inspectionType: string;
  disableHazardButtons: boolean;
}

const AddWorkFaceAreaInspectionCard: React.FC<Props> = ({
  onCommentClicked,
  inspectionDetails,
  onAddAnotherHazardClicked,
  onSelectedHazardButtonChange,
  onGchaChecked,
  inspectionType,
  disableHazardButtons,
}: Props) => {
  const classes = useStyles();
  const { isRocanville } = useSiteFeatures();

  const onNewHazardClick = (value: string) => {
    const haz = addNewHazardArray.find(x => x.value === value);
    if (haz?.openNewHazard) onAddAnotherHazardClicked(haz.forceRemediation);
    onSelectedHazardButtonChange(value);
  };

  const handleGchaChange = (evt: BaseSyntheticEvent) => {
    onGchaChecked(evt.target.checked);
  };

  return (
    <Grid container className={classes.content}>
      <Grid container className={classes.panelWithoutLeftPadding}>
        <>
          <Grid item container xs={1} justify="center">
            <Grid item>
              <Checkbox
                checked={inspectionDetails?.wfaiDetails?.signoffCompleted}
                onChange={handleGchaChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h3">
              {(isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.header || ''}
            </Typography>
            <Typography variant="h4" style={{ marginTop: 20 }}>
              {(isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.subHeader || ''}
            </Typography>
            <Typography variant="body1">
              {(isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.bulletPoints.map(
                point => (
                  <li key={point}>{point}</li>
                ),
              )}
            </Typography>
            <Typography variant="h4" style={{ marginTop: 10 }}>
              {(isRocanville ? GCHAVerbage.general : GCHAVerbage[inspectionType])?.footer || ''}
            </Typography>
          </Grid>
        </>
      </Grid>
      <ActiveConditionsCard
        showAddButton={false}
        alternateHeaderText={i18n.t('Active hazards')}
        newGroundHazardIds={inspectionDetails?.wfaiDetails?.newGroundHazardIds}
        overrideAddComment={onCommentClicked}
      />
      <Grid container className={classes.panelWithoutTopMargin}>
        <Typography variant="h3" style={{ marginLeft: 6 }}>
          {i18n.t('Add new hazard')}
        </Typography>
        <Grid item xs={12} className={classes.buttonGroupContainer}>
          <ToggleButtonGroup
            value={inspectionDetails?.wfaiDetails?.newHazardStatus || ''}
            onChange={(evt: BaseSyntheticEvent, value: string) => onNewHazardClick(value)}
            exclusive
          >
            {addNewHazardArray.map(haz => (
              <ToggleButton
                key={`toggle-button${haz.value}`}
                value={haz.value}
                aria-label={haz.value}
                showColorOnDisabled
                disableRipple
                disabled={disableHazardButtons}
              >
                <Typography variant="button" className={classes.noTextTransform}>
                  {haz.value}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <RemediatedConditionsCard
        newGroundHazardIds={inspectionDetails?.wfaiDetails?.newRemediatedGroundHazardIds || []}
        conditionsAlwaysExpanded
        hideAddPhotoAction
        title={i18n.t('Remediated this check')}
      />
    </Grid>
  );
};

export default AddWorkFaceAreaInspectionCard;
