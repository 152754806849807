import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { AssignmentRoleDocument } from '../AssignmentRole/queryBuilder';
import { EmployeeDocument } from '../Employees/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface AssignmentEmployee extends BaseEntity {
  readonly assignmentId: string;
  readonly siteEmployeeId: string;
  readonly comment: string | null;
  readonly assignmentRoleId: string;
  readonly displayOrder: number;
}
export type AssignmentEmployeeCollection = RxCollection<AssignmentEmployee> | null;
export type AssignmentEmployeeDocument = RxDocument<AssignmentEmployee>;

export const assignmentEmployeeSchema: RxJsonSchema<AssignmentEmployee> = {
  type: 'object',
  version: 4,
  description: 'describes an assignment employee object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    assignmentId: { type: 'string' },
    assignmentRoleId: { type: ['string', 'null'], ref: RxdbCollectionName.ASSIGNMENT_ROLE },
    siteEmployeeId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.EMPLOYEES,
    },
    comment: { type: ['string', 'null'] },
    displayOrder: { type: 'number' },
  },
};

export const assignmentEmployeePullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query assignmentEmployeeFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      assignmentEmployeeFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        assignmentId
        assignmentRoleId
        siteEmployeeId
        comment
        id
        isDeleted
        updatedAt
        version
        displayOrder
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const assignmentEmployeeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: AssignmentEmployeeDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
  2: (oldDoc: AssignmentEmployeeDocument) => {
    const newDoc = { ...oldDoc, comment: '', assignmentRoleId: null };
    delete newDoc.employeeType;
    return newDoc;
  },
  3: (oldDoc: AssignmentEmployeeDocument) => {
    const newDoc = { ...oldDoc, displayOrder: 0 };
    return newDoc;
  },
  4: (oldDoc: AssignmentEmployeeDocument) => oldDoc,
};

export interface AugmentedAssignmentEmployeeDocument extends AssignmentEmployeeDocument {
  readonly employeeInformation: EmployeeDocument;
  readonly assignmentRole: AssignmentRoleDocument;
}

export default {};
