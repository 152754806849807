import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Supply extends BaseEntity {
  borerEquipmentId: string;
  quantity: string;
  otherDescription: string | null;
  supplyItemId: string;
}
export type SupplyDocument = RxDocument<Supply>;
export type SupplyCollection = RxCollection<Supply> | null;

export interface EnrichedSupply extends Supply {
  supplyItemDescription: string;
  supplyItemUnit: string | null;
  isNew: boolean;
}

export const supplySchema = {
  type: 'object',
  version: 2,
  description: 'describes a Supply object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerEquipmentId: { type: 'string' },
    quantity: { type: ['number', 'string'] },
    otherDescription: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    supplyItemId: {
      ref: 'supplyItems',
      type: 'string',
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const supplyPullQueryBuilder = (doc: Supply) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query borerSupplyFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerSupplyFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        borerEquipmentId
        id
        isDeleted
        quantity
        otherDescription
        supplyItemId
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const supplyMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SupplyDocument) => {
    return {
      ...oldDoc,
      otherDescription: null,
    };
  },
  2: (oldDoc: SupplyDocument) => oldDoc,
};

export const supplyPushQueryBuilder = (doc: Supply) => {
  doc = doc[0];

  const query = print(gql`
    mutation setBorerSupply($supply: SetBorerSupplyInput) {
      setBorerSupply(input: $supply) {
        id
        equipmentId
        quantity
        supplyItemId
        version
      }
    }
  `);

  const variables = {
    supply: doc,
  };

  return {
    query,
    variables,
  };
};

export const supplyPullModifier = (doc: any) => {
  const result = {
    ...doc,
  };
  return result;
};

export const supplyPushModifier = (doc: any) => {
  const result = {
    ...doc,
    equipmentId: doc.borerEquipmentId,
    createdOn: dayjs().toISOString(),
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc.isDeleted || false,
  };

  const propertyBlackList = [
    'updatedAt',
    '_deleted',
    '_attachments',
    '_rev',
    'borerEquipmentId',
    'updatedAt',
    '_meta',
  ];
  propertyBlackList.forEach(property => {
    result[property] = undefined;
  });

  return result;
};

// Subscriptions
export const supplySubscriptionQuery = gql`
  subscription OnBorerShiftSupplyChanged($equipmentId: ID!) {
    OnBorerShiftSupplyChanged(equipmentId: $equipmentId) {
      id
    }
  }
`;

export const supplySubscriptionVariables = {
  equipmentId: rootStore?.equipment?.selectedBorerId || '',
};

export default {};
