import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_50 } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Inspection extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly inspectionType: string;
  readonly miningMethod: string;
  readonly needsHazard: boolean;
}
export type InspectionCollection = RxCollection<Inspection> | null;
export type InspectionDocument = RxDocument<Inspection>;

export const inspectionSchema: RxJsonSchema<Inspection> = {
  type: 'object',
  version: 0,
  description: 'describes a Inspection object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    inspectionType: { type: 'string' },
    miningMethod: { type: 'string' },
    needsHazard: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

export const inspectionPullQueryBuilder = (doc: InspectionDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query inspectionFeed($siteId: ID!, $updatedAt: Float!, $limit: Int!) {
      inspectionFeed(lastUpdateAt: $updatedAt, limit: $limit, siteId: $siteId) {
        id
        description
        inspectionType
        isDeleted
        miningMethod
        needsHazard
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      updatedAt: doc.updatedAt,
      limit: SYNC_LIMIT_50,
    },
  };
};

export default {};
