import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { EnrichedSupply } from '../../rxdb/Supply/queryBuilder';
import useSupply from '../../rxdb/Supply/useSupply';
import { BORER_SHIFT_WRITE_PERMISSION, OTHER_SUPPLY_ITEM_DESC } from '../../utilities/constants';
import { sortSupplies } from '../../utilities/sortHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    label: {
      color: theme.palette.text.secondary,
      wordBreak: 'break-all',
    },
    value: {
      color: theme.palette.text.primary,
    },
    editBtnContainer: {
      alignItems: 'flex-end !important',
      '& > div': {
        minHeight: 'auto !important',
        alignItems: 'flex-end',
      },
      '& > div > span': {
        display: 'none',
      },
    },
    addOrEditButton: {
      maxWidth: '175px',
    },
  }),
);

interface Props {
  onEditClicked: () => void;
  borerShiftOpen: boolean;
}

const ShiftEndSuppliesCard: React.FC<Props> = ({ onEditClicked, borerShiftOpen }: Props) => {
  const classes = useStyles();
  const { user } = useMst();
  const { supplyInitialized, supplyCollection, supplyItemsCollection, listSupplies } = useSupply();

  const [supplySubscription, setSupplySubscription] = useState<Subscription>();
  const [supplyItemSubscription, setSupplyItemSubscription] = useState<Subscription>();
  const [supplies, setSupplies] = useState<EnrichedSupply[]>([]);

  const getSupplies = async () => {
    const supplyResult = await listSupplies();
    setSupplies(supplyResult);
  };

  const otherSupplyItemId = useMemo(
    () =>
      supplies.find(
        item => item.supplyItemDescription.toLowerCase() === OTHER_SUPPLY_ITEM_DESC.toLowerCase(),
      )?.supplyItemId,
    [supplies],
  );

  const subscribeToSupplies = async () => {
    if (supplyInitialized && supplyCollection && supplyItemsCollection) {
      const supplySub = supplyCollection.$.subscribe(async () => {
        await getSupplies();
      });
      const supplyItemSub = supplyItemsCollection.$.subscribe(async () => {
        await getSupplies();
      });
      setSupplySubscription(supplySub);
      setSupplyItemSubscription(supplyItemSub);
    }
  };

  const setup = async () => {
    await getSupplies();
    subscribeToSupplies();
  };

  const onEdit = () => {
    onEditClicked();
  };

  useEffect(() => {
    if (supplyInitialized && supplyCollection && supplyItemsCollection) {
      setup();
    }

    return function cleanup() {
      if (supplySubscription && supplyItemSubscription) {
        supplySubscription.unsubscribe();
        supplyItemSubscription.unsubscribe();
      }
    };
  }, [supplyInitialized, supplyCollection, supplyItemsCollection]);

  return (
    <Card className={classes.cardRoot}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">{i18n.t('Supplies')}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.editBtnContainer}>
          {user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && borerShiftOpen && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onEdit()}
              className={classes.addOrEditButton}
              id="edit-supplies-btn"
            >
              <Icons.EditFeather color="primary" />
              &nbsp;&nbsp;
              {i18n.t('Edit supplies')}
            </Button>
          )}
        </Grid>
      </Grid>
      {supplies && supplies.length > 0 && (
        <>
          {supplies.sort(sortSupplies).map(supply => {
            return (
              <Grid container spacing={2} key={supply.id}>
                <Grid item xs={6}>
                  <Typography className={classes.label} variant="body2">
                    {supply.supplyItemId === otherSupplyItemId
                      ? supply.otherDescription
                      : supply.supplyItemDescription}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label} variant="body2">
                    {supply.quantity} {supply.supplyItemUnit}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
      {!supplies ||
        (supplies?.length === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.label}>
                {i18n.t('None')}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Card>
  );
};

export default observer(ShiftEndSuppliesCard);
