import { ApolloQueryResult } from '@apollo/client';

import { client, gql } from './Client';

// Only being used for Inspection Options Migration 0 -> 1
const getBorerShiftInfoQuery = gql`
  query getBorerShiftInfo($borerShiftId: ID!) {
    getBorerShiftInfo(borerShiftId: $borerShiftId) {
      id
      faceSalt
      gprIsWorking
      gprIsWorkingChangedOn
      lastPreventativeMaintenanceOn
      lastServicedOn
      lastTrimChainChangedOn
      leachAnomaly
      oilLevel
      onMound
      oreGrade
      parkedForMaintenance
      phoneNumber
      shearPins
      lastServicedAtPanel {
        id
      }
    }
  }
`;

export interface BorerShiftInfo {
  id: string;
  faceSalt: number | null;
  gprIsWorking: boolean | null;
  gprIsWorkingChangedOn: string | null;
  lastPreventativeMaintenanceOn: string | null;
  lastServicedOn: string | null;
  lastTrimChainChangedOn: string | null;
  leachAnomaly: string | null;
  oilLevel: number | null;
  onMound: boolean | null;
  oreGrade: number | null;
  parkedForMaintenance: boolean | null;
  phoneNumber: string | null;
  shearPins: number | null;
  lastServicedAtPanel: {
    id: string;
  } | null;
}

export interface GetBorerShiftInfoResult {
  getBorerShiftInfo: BorerShiftInfo;
}

const getBorerShiftInfo = (
  borerShiftId: string,
): Promise<ApolloQueryResult<GetBorerShiftInfoResult>> =>
  client.query({
    query: getBorerShiftInfoQuery,
    variables: { borerShiftId },
    fetchPolicy: 'no-cache',
  });

export { getBorerShiftInfo };
