import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface AssignmentRole extends BaseEntity {
  readonly description: string;
}
export type AssignmentRoleCollection = RxCollection<AssignmentRole> | null;
export type AssignmentRoleDocument = RxDocument<AssignmentRole>;

export const assignmentRoleSchema: RxJsonSchema<AssignmentRole> = {
  type: 'object',
  version: 0,
  description: 'describes an assignment role object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    description: { type: 'string' },
  },
};

export const assignmentRolePullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query assignmentRoleFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      assignmentRoleFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
        description
        id
        isDeleted
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
