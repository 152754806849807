import { useRxData } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { BorerActivityType } from './queryBuilder';

export const useBorerActivityType = () => {
  const activityTypeQueryConstructor = collection =>
    collection.find({
      selector: {
        isActive: true,
      },
    });

  const { result: activityTypesForShift, isFetching: activityTypesFetching } =
    useRxData<BorerActivityType>(
      RxdbCollectionName.BORER_ACTIVITY_TYPE,
      activityTypeQueryConstructor,
    );

  return {
    activityTypesForShift,
    activityTypesFetching,
  };
};

export default useBorerActivityType;
