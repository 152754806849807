import { BottomNavigation, BottomNavigationAction, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icons } from '@nutrien/cxp-components';
import { useFlags } from '@nutrien/minesight-utility-module';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import TabBarConfig from './TabBarConfig.json';

const useStyles = makeStyles(() =>
  createStyles({
    hidden: {
      display: 'none',
    },
    root: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      justifyContent: 'space-between',
      height: '75px',
      boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 1)',
    },
  }),
);

const checkIfVisible = (pathname: string) => {
  return TabBarConfig.blacklistedPaths.indexOf(pathname) === -1;
};

const TabBar: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { flags } = useFlags();

  const showableTabs = useMemo(() => {
    return TabBarConfig.items.filter(item => {
      if (!item.featureFlag) return true;
      if ('featureFlag' in item && flags[item.featureFlag] === true) return true;
      return false;
    });
  }, [flags]);

  const getActiveTabNumber = (pathname: string) => {
    let result = -1;

    showableTabs.forEach((item, index) => {
      if (item.path.includes(pathname)) {
        result = index;
      }
    });

    return result;
  };

  const [visible, setVisible] = useState(checkIfVisible(location.pathname));
  const [value, setValue] = useState(getActiveTabNumber(location.pathname));

  useEffect(() => {
    const visibleValue = checkIfVisible(location.pathname);
    const activeTabNumber = getActiveTabNumber(location.pathname);
    setVisible(visibleValue);
    setValue(activeTabNumber);
  }, [location.pathname]);

  const getIconFromName = (name: string) => {
    switch (name) {
      case 'FileTextFeather':
        return <Icons.FileTextFeather color="white" />;
      case 'ShiftStartIcon':
        return <Icons.ShiftStartIcon color="white" />;
      case 'CheckCircleFeather':
        return <Icons.CheckCircleFeather color="white" />;
      case 'TruckFeather':
        return <Icons.TruckFeather color="white" />;
      case 'CalendarFeather':
        return <Icons.CalendarFeather color="white" />;
      case 'ArrowRightFeather':
        return <Icons.ArrowRightFeather color="white" />;
      case 'ShiftEndIcon':
        return <Icons.ShiftEndIcon color="white" />;
      default:
        return <Icons.HelpCircleFeather color="white" />;
    }
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={visible ? classes.root : classes.hidden}
      >
        {showableTabs.map(item => {
          return (
            <BottomNavigationAction
              key={item.key}
              label={item.label}
              icon={getIconFromName(item.iconName)}
              data-cy={`tabbar-${item.key}`}
              data-testid={`tabbar-${item.key}`}
              onClick={() => {
                history.push(item.path);
              }}
              id={`tabbar-${item.label}`}
            />
          );
        })}
      </BottomNavigation>
    </>
  );
};

export default TabBar;
