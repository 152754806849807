import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import React, { useMemo } from 'react';

import { InspectionResultDocument } from '../../rxdb/InspectionResults/queryBuilder';
import { PreOpResult } from '../../rxdb/InspectionResults/types';
import { InspectionDocument } from '../../rxdb/Inspections/queryBuilder';
import { useDateFormatters } from '../../utilities';
import { InspectionType } from '../../utilities/constants';
import { TIME_FORMAT } from '../../utilities/useDateFormatters';
import { jsonMapReviver } from '../../utilities/utilityFunctions';
import { getCheckTitle } from '../AddPreOpCheck/AddPreOpCheck';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px !important',
      padding: '16px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
    chevron: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface Props {
  inspectionDetails: InspectionDocument;
  inspectionResult: InspectionResultDocument;
  onSelected: (inspectionResult: InspectionResultDocument) => void;
}

const getHasDeficienciesText = (inspectionResult: InspectionResultDocument): string => {
  let inspectionDetail: PreOpResult | string | null;

  try {
    inspectionDetail = JSON.parse(inspectionResult.inspectionDetail, jsonMapReviver);
  } catch (error) {
    inspectionDetail = inspectionResult.inspectionDetail;
  }

  if (typeof inspectionDetail === 'string' || inspectionDetail === null)
    return i18n.t('No deficiencies');

  const { preOpDeficiencies } = inspectionDetail;
  if (!preOpDeficiencies) return i18n.t('No deficiencies');

  return preOpDeficiencies.size > 0 ? i18n.t('Deficiency reported') : i18n.t('No deficiencies');
};

const PreOpCard: React.FC<Props> = ({ inspectionDetails, inspectionResult, onSelected }: Props) => {
  const classes = useStyles();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const preOpText = useMemo(() => getHasDeficienciesText(inspectionResult), [inspectionResult]);

  return (
    <Card
      className={classes.cardRoot}
      onClick={() => {
        onSelected(inspectionResult);
      }}
      id={`open-preop-check-${inspectionResult.id}`}
    >
      <Grid container>
        <Grid item container xs={6}>
          <Grid item>
            <Typography variant="h5">
              {getCheckTitle(inspectionDetails.inspectionType as InspectionType)}
            </Typography>
          </Grid>
          <Grid item>
            <span className={classes.dot2}>&#183;</span>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textSecondary">
              {formatDateWithDisappearingDate(inspectionResult.createdOn, TIME_FORMAT)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} justify="flex-end" alignContent="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{preOpText}</Typography>
          </Grid>
          <Grid item className={classes.chevron}>
            <Icons.ChevronRightFeather color="primary" />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PreOpCard;
