import dayjs from 'dayjs';
import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface PanelDrawing extends BaseEntity {
  readonly borerShiftId: string;
  readonly drawingData: string;
  readonly miningMethod: string;
}
export type PanelDrawingCollection = RxCollection<PanelDrawing> | null;
export type PanelDrawingDocument = RxDocument<PanelDrawing>;

export const panelDrawingSchema: RxJsonSchema<PanelDrawing> = {
  type: 'object',
  version: 0,
  description: 'describes a PanelDrawing object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    borerShiftId: { type: 'string' },
    drawingData: { type: 'string' },
    miningMethod: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

export const panelDrawingPullQueryBuilder = (doc: PanelDrawingDocument) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query panelDrawingFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
      panelDrawingFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        isDeleted
        updatedAt
        version
        borerShiftId
        drawingData
        miningMethod
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const panelDrawingPushQueryBuilder = (doc: PanelDrawingDocument) => {
  doc = doc[0];

  const query = print(gql`
    mutation setPanelDrawing(
      $id: ID!
      $borerShiftId: ID!
      $drawingData: String!
      $isDeleted: Boolean!
      $miningMethod: String!
      $version: Int!
      $modifiedOn: AWSDateTime
    ) {
      setPanelDrawing(
        input: {
          id: $id
          version: $version
          isDeleted: $isDeleted
          miningMethod: $miningMethod
          drawingData: $drawingData
          borerShiftId: $borerShiftId
          modifiedOn: $modifiedOn
        }
      )
    }
  `);

  const variables = {
    id: doc.id,
    version: doc.version,
    isDeleted: doc.isDeleted,
    miningMethod: doc.miningMethod,
    drawingData: doc.drawingData,
    borerShiftId: doc.borerShiftId,
    modifiedOn: dayjs().toISOString(),
  };

  return {
    query,
    variables,
  };
};

export default {};
