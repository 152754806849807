import { DialogActions, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button, Card, i18n, Icons, TextField } from '@nutrien/cxp-components';
import { useFlags, useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import useBorerShift from '../../rxdb/BorerShift/useBorerShift';
import {
  BorerShiftSignature,
  BorerShiftSignatureCollection,
} from '../../rxdb/BorerShiftSignature/queryBuilder';
import useBorerShiftSignatures from '../../rxdb/BorerShiftSignature/useBorerShiftSignatures';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import {
  InspectionResult,
  InspectionResultCollection,
} from '../../rxdb/InspectionResults/queryBuilder';
import { CuttingPermitResult } from '../../rxdb/InspectionResults/types';
import useInspections, {
  InspectionDetails,
  InspectionOptionsWithDetails,
} from '../../rxdb/Inspections/useInspections';
import useLocations, { PopulatedLocation } from '../../rxdb/Locations/useLocations';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../../rxdb/rxdbUtilityFunctions';
import { Signature, SignatureCollection } from '../../rxdb/Signature/rxdbSignatureDefinition';
import { useDateFormatters } from '../../utilities';
import { InspectionType, MiningMethod } from '../../utilities/constants';
import { HazardSeverity, SignatureType } from '../../utilities/enums';
import useModalStyles from '../../utilities/modalStyles';
import { useNotification } from '../../utilities/useNotification';
import { locationDifferences } from '../../utilities/utilityFunctions';
import AddSignatureModal from '../AddSignatureModal';
import ChecksThisShiftSignatureCard from '../ChecksThisShiftSignatureCard';
import CustomBGSnackbar from '../CustomBGSnackbar';
import CuttingPermitCategory from '../CuttingPermitCategory';
import DiscardDraftModal from '../DiscardDraftModal';
import GenericLocationComponent from '../GenericLocationComponent';
import useStyles from './CuttingPermitModal.styles';

const UNDO_SNACKBAR_KEY = 'CuttingPermitUndo';
const SNACKBAR_BACKGROUND = {
  ADDRESS_AND_PROCEED: '#FFD966',
  IMMEDIATE_WORK_STOPPAGE: '#CD4E5F',
  SAFE_TO_CUT: '#2AAB3F',
  STOP_AND_ADDRESS: '#EB8E30',
};
interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  viewOnly?: boolean;
  inspectionDetailsSource?: InspectionDetails;
  selectedOptionsSource?: Record<string, InspectionOptionsWithDetails>;
  severityLevel?: HazardSeverity;
  signatureId: string | undefined | null;
  createdOn: string;
  inspectionComment?: string | null;
  inspectionLocation?: PopulatedLocation | null;
}

export const blankLocation: PopulatedLocation = {
  room: null,
  block: null,
  surveyPoint: null,
  sequence: null,
  pass: null,
  panel: null,
  miningMethod: null,
};

interface CuttingPermitSnackbar {
  backgroundColor?: SNACKBAR_BACKGROUND;
  text?: HazardSeverity;
}

const CuttingPermitModal: React.FC<Props> = ({
  open,
  onClose,
  onOpen,
  viewOnly = false,
  inspectionDetailsSource,
  selectedOptionsSource = {},
  severityLevel,
  signatureId,
  createdOn,
  inspectionComment,
  inspectionLocation,
}: Props) => {
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const { getInspectionFor } = useInspections();
  const { createLocation } = useLocations();
  const { listEligibleSignees } = useBorerShiftSignatures();

  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const {
    errorNotification,
    successNotification,
    closeSnackbar,
    enqueueSnackbar,
    defaultNotification,
  } = useNotification();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const { borerDisplayCuttingPermitSeverityValue, coryZoneBCuttingPermit } = useFlags().flags;
  type SnackbarOptions = Parameters<typeof enqueueSnackbar>[1];
  const { getCurrentBorerShift } = useBorerShift();
  const { shiftPicker } = useMst();
  const { miningMethod } = useCurrentBorer();

  // Controls
  const [canSave, setCanSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);
  const [comment, setComment] = useState(inspectionComment || '');
  const [hasLocationValidationError, setHasLocationValidationError] = useState(false);
  const { recentBorerLocation } = useCurrentBorer();

  const [location, setLocation] = useState<PopulatedLocation>(inspectionLocation || blankLocation);
  const [initialLocation, setInitialLocation] = useState<PopulatedLocation>(
    inspectionLocation || blankLocation,
  );

  // Inspection Details
  const [inspectionDetails, setInspectionDetails] = useState<InspectionDetails | undefined>(
    inspectionDetailsSource,
  );
  const [selectedOptions, setSelectedOptions] =
    useState<Record<string, InspectionOptionsWithDetails>>(selectedOptionsSource);
  const currentSnackbarId = useRef<HazardSeverity | undefined>(severityLevel);
  // Signature
  const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [nameNotInList, setNameNotInList] = useState<boolean>(false);
  const [selectedCustomName, setSelectedCustomName] = useState<string>('');
  const [reviewedCuttingPermits, setReviewedCuttinPermits] = useState<boolean>(false);
  const [eligibleEmployees, setEligibleEmployees] = useState<Employee[]>([]);

  // collections
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const borerShiftSignatureCollection: BorerShiftSignatureCollection | null = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );
  const inspectionResultCollection: InspectionResultCollection = useRxCollection(
    RxdbCollectionName.INSPECTION_RESULTS,
  );

  // Configure
  const setup = async () => {
    let inspectionMiningMethod = miningMethod;
    if (!inspectionMiningMethod) {
      errorNotification('Unable to find cutting permit - please check borer assignment');
      onClose();
      return;
    }

    // If the cory zone B cutting permit override flag is set then the mining method is always long room
    if (coryZoneBCuttingPermit) {
      inspectionMiningMethod = MiningMethod.LONG_ROOM;
    }

    try {
      const result = await getInspectionFor(InspectionType.CUTTING_PERMIT, inspectionMiningMethod);
      setInspectionDetails(result);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 25 ~ setup ~ error', error);
    }

    try {
      const eligibleSignees = await listEligibleSignees(false);
      setEligibleEmployees(eligibleSignees);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 172 ~ setup ~ error', error);
    }

    if (recentBorerLocation) {
      setLocation(recentBorerLocation);
      setInitialLocation(recentBorerLocation);
    }
  };

  const handleToggleChange = (event: any, value: InspectionOptionsWithDetails) => {
    if (value?.option)
      setSelectedOptions(prev => {
        const { inspectionCategoryId } = value.option;
        return { ...prev, [inspectionCategoryId]: value };
      });
  };

  // Validates the form is filled in
  const validateCanSave = useCallback(() => {
    let validSave = true;

    if ((isVanscoy || isCory || isRocanville) && hasLocationValidationError) validSave = false;
    if (inspectionDetails) {
      const numberOfCategories = inspectionDetails?.categories.length;
      const numberOfSelectedOptions = Object.keys(selectedOptions).length;

      if (numberOfCategories !== numberOfSelectedOptions) validSave = false;
    }

    setCanSave(validSave);
  }, [
    inspectionDetails,
    selectedOptions,
    isCory,
    isVanscoy,
    isRocanville,
    hasLocationValidationError,
  ]);

  const triggerNotification = (
    hazardSeverity: HazardSeverity,
    notificationFunction: (notificationText: string, options?: SnackbarOptions) => void,
  ) => {
    if (currentSnackbarId.current !== hazardSeverity) {
      closeSnackbar(currentSnackbarId.current);
    }

    if (!open || Object.keys(selectedOptions).length === 0) return;

    // Error Notification
    notificationFunction(hazardSeverity, {
      persist: true,
      autoHideDuration: null,
      key: hazardSeverity,
    });

    currentSnackbarId.current = hazardSeverity;
  };

  const severityModelOptions = useMemo(
    () =>
      inspectionDetails?.severityModel
        .map(doc => ({
          lowSeverity: doc.lowSeverity,
          highSeverity: doc.highSeverity,
          description: doc.description,
        }))
        .sort((a, b) => b.lowSeverity - a.lowSeverity),
    [inspectionDetails],
  );

  useEffect(() => {
    validateCanSave();
  }, [selectedOptions, validateCanSave]);

  // Generate the complicated set of options based on hazard details
  const lsitOfCategoriesAndOptions = useMemo(() => {
    if (!inspectionDetails) return [];

    return inspectionDetails.categories
      .sort((a, b) => a.category.displayOrder - b.category.displayOrder)
      .map(category => {
        return (
          <CuttingPermitCategory
            category={category}
            key={category.category.id}
            selectedOptions={selectedOptions}
            handleToggleChange={handleToggleChange}
            viewOnly={viewOnly}
            borerDisplayCuttingPermitSeverityValue={borerDisplayCuttingPermitSeverityValue}
            severityModelOptions={severityModelOptions}
          />
        );
      });
  }, [
    inspectionDetails,
    selectedOptions,
    severityModelOptions,
    borerDisplayCuttingPermitSeverityValue,
    viewOnly,
  ]);

  const clearSignatureData = () => {
    setSelectedEmployee(undefined);
    setSelectedCustomName('');
    setNameNotInList(false);
    setReviewedCuttinPermits(false);
  };

  const onSaveSignature = async (
    employee: Employee,
    customName: string,
    reviewedCuttingPermitsValue: boolean,
    signatureData: string,
  ) => {
    const currentBorerShift = await getCurrentBorerShift();

    if (currentBorerShift === null) {
      errorNotification("Can't save signature because no borer shift found was found");
      return;
    }

    // 1. Create Signature Document
    const signature: Signature = {
      ...generateBaseEntityWithCreatedOn(),
      signatureType: SignatureType.CUTTING_PERMIT,
      siteEmployeeId: customName ? undefined : employee?.id,
      personnelName: customName,
      signature: signatureData,
    };

    // 2. Create Borer Shift Signature Document
    const borerShiftSignature: BorerShiftSignature = {
      ...generateBaseEntityWithCreatedOn(),
      borerShiftId: currentBorerShift.id,
      signatureId: signature.id,
      reviewedCuttingPermits: reviewedCuttingPermitsValue,
      purposeOfVisit: null,
      reviewedSafeWorkPlan: null,
    };

    // 3. Insert Documents
    if (signatureCollection) {
      signatureCollection.insert(signature);
    }
    if (borerShiftSignatureCollection) {
      borerShiftSignatureCollection.insert(borerShiftSignature);
    }

    return {
      signatureId: signature.id,
      borerShiftSignatureId: borerShiftSignature.id,
    };
  };

  const onUpdateSignature = (
    employee: Employee,
    customName: string,
    employeeNotInList: boolean,
    reviewedCuttingPermitsValue: boolean,
  ) => {
    setSelectedEmployee(employee);
    setSelectedCustomName(customName);
    setNameNotInList(employeeNotInList);
    setReviewedCuttinPermits(reviewedCuttingPermitsValue);
  };

  const onOpenSignatureModal = () => {
    closeSnackbar(currentSnackbarId.current);
    setSignatureModalOpen(true);
  };

  const resetModalDetails = () => {
    setCanSave(false);
    setIsSaving(false);
    setInspectionDetails(undefined);
    setSelectedOptions({});
    setLocation({});
    setInitialLocation({});
    currentSnackbarId.current = undefined;
    setComment('');
  };

  const onCloseCuttingPermit = () => {
    closeSnackbar(currentSnackbarId.current);
    setSelectedOptions({});
    onClose();
  };

  const onCancelCuttingPermit = () => {
    if (
      (viewOnly || Object.keys(selectedOptions).length === 0) &&
      !locationDifferences(location, initialLocation)
    ) {
      closeSnackbar(currentSnackbarId.current);
      onClose();
    } else if (
      Object.keys(selectedOptions).length > 0 ||
      locationDifferences(location, initialLocation)
    ) {
      setDiscardOpen(true);
    }
  };

  // Handle status notification trigger
  const checkForStatusNotification = useCallback(() => {
    const options = Object.values(selectedOptions);
    let calculatedSeverityScore: number | null = null;
    if (options.length !== 0)
      calculatedSeverityScore = options.reduce((acc, curr) => acc + curr.option.severityValue, 0);

    if (calculatedSeverityScore === null && !!currentSnackbarId.current) {
      closeSnackbar(currentSnackbarId.current);
      return;
    }

    const matchingModel = severityModelOptions?.find(
      option =>
        calculatedSeverityScore >= option.lowSeverity &&
        calculatedSeverityScore <= option.highSeverity,
    ); // inclusive calculation on ranges

    const modelDescription = matchingModel?.description;
    const modelDescriptionUpperCase = matchingModel?.description?.toUpperCase();

    const snackbarDetails: CuttingPermitSnackbar = {
      text: modelDescription as HazardSeverity,
    };

    if (
      modelDescriptionUpperCase === HazardSeverity.SAFE_TO_CUT.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.GOOD_TO_CUT.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.CONDITION_OKAY.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.CONDITIONS_OKAY.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.R_CATEGORY_1.toUpperCase()
    )
      snackbarDetails.backgroundColor = SNACKBAR_BACKGROUND.SAFE_TO_CUT;

    if (
      modelDescriptionUpperCase === HazardSeverity.ADDRESS_AND_PROCEED.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.CAUTION.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.A_CATEGORY_1.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.R_CATEGORY_2.toUpperCase()
    )
      snackbarDetails.backgroundColor = SNACKBAR_BACKGROUND.ADDRESS_AND_PROCEED;

    if (
      modelDescriptionUpperCase === HazardSeverity.STOP_AND_ADDRESS.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.A_CATEGORY_2.toUpperCase()
    )
      snackbarDetails.backgroundColor = SNACKBAR_BACKGROUND.STOP_AND_ADDRESS;

    if (
      modelDescriptionUpperCase === HazardSeverity.IMMEDIATE_WORK_STOPPAGE.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.CORRECTIVE_ACTION_REQUIRED.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.A_CATEGORY_3.toUpperCase() ||
      modelDescriptionUpperCase === HazardSeverity.R_CATEGORY_3.toUpperCase()
    )
      snackbarDetails.backgroundColor = SNACKBAR_BACKGROUND.IMMEDIATE_WORK_STOPPAGE;

    if (snackbarDetails.backgroundColor && snackbarDetails.text) {
      triggerNotification(snackbarDetails.text, (txt, opt) => {
        defaultNotification(txt, {
          ...opt,
          content: (key, message) => (
            <CustomBGSnackbar
              id={key as string}
              message={message as string}
              bgColor={snackbarDetails.backgroundColor}
            />
          ),
        });
      });
    } else if (currentSnackbarId.current) {
      closeSnackbar(currentSnackbarId.current);
    }
  }, [selectedOptions, severityModelOptions]);

  useEffect(() => {
    checkForStatusNotification();
  }, [checkForStatusNotification]);

  const errorSaving = () => {
    errorNotification(i18n.t('Cutting Permit could not save. Please try again.'));
    setSignatureModalOpen(false);
    checkForStatusNotification();
    setIsSaving(false);
  };

  const onCancelSignature = (showStatusNotification = true) => {
    setSignatureModalOpen(false);
    clearSignatureData();
    if (showStatusNotification) checkForStatusNotification();
  };

  const onSaveInspection = async (
    employee: Employee,
    customName: string,
    cuttingPermitsReviewed: boolean,
    signatureData: string,
  ) => {
    setIsSaving(true);
    closeSnackbar(currentSnackbarId.current);

    // Save the Signature
    let signature;
    try {
      signature = await onSaveSignature(
        employee,
        customName,
        cuttingPermitsReviewed,
        signatureData,
      );
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 430 ~ onSaveInspection ~ error', error);
      return errorSaving();
    }

    if (!signature || !inspectionDetails || !shiftPicker.currentBorerShiftId) {
      return errorSaving();
    }

    try {
      let locationId = null;
      if (isCory || isVanscoy || isRocanville) {
        if (!location?.panel) throw new Error('Missing critical location elements');
        const newLocation = await createLocation(
          location.panel?.id,
          location.room?.id,
          location.surveyPoint?.id,
          location?.sequence?.id,
          location?.pass?.id,
        );
        locationId = newLocation?.id || null;
      }

      // Generate Inspection Object
      const inspectionResult: CuttingPermitResult = {
        cuttingPermitConfig: inspectionDetails,
        cuttingPermitSelections: selectedOptions,
        cuttingPermitSeverity: currentSnackbarId.current || null,
        comment: comment || null,
      };

      const doc: InspectionResult = {
        ...generateBaseEntityWithCreatedOn(),
        inspectionDetail: JSON.stringify(inspectionResult),
        signatureId: signature.signatureId,
        inspectionId: inspectionDetails.inspection.id,
        borerShiftId: shiftPicker.currentBorerShiftId,
        locationId,
      };

      // Save Inspection
      await inspectionResultCollection?.insert(doc);
    } catch (error) {
      console.log('🚀 ~ file: CuttingPermitModal.tsx ~ line 415 ~ error', error);
      return errorSaving();
    }

    // Close Modal
    onCloseCuttingPermit();
    successNotification('Cutting permit added');
  };

  const onCancelDiscardDraft = () => {
    setDiscardOpen(false);
    setUndo(false);
  };

  const onDiscardDraft = () => {
    onClose();
    closeSnackbar(currentSnackbarId.current);
    setDiscardOpen(false);
    errorNotification(i18n.t('Cutting permit draft discarded'), {
      key: UNDO_SNACKBAR_KEY,
      action: key => (
        <Button
          noMinHeight
          color="primary"
          variant="text"
          onClick={() => {
            setUndo(true);
            closeSnackbar(key);
            onOpen();
          }}
          id="undo-cutting-permit-draft-discard-button"
        >
          {i18n.t('Undo')}
        </Button>
      ),
    });
  };

  useEffect(() => {
    if (!viewOnly) {
      if (open === true && undo === false) {
        resetModalDetails();
        setup();
      }
      if (open === true) {
        if (undo) checkForStatusNotification();
        setUndo(false);
        closeSnackbar(UNDO_SNACKBAR_KEY);
      }
    }
  }, [open, miningMethod]);

  const onLocationChange = (key: keyof PopulatedLocation, value: any) => {
    setLocation(prevState => ({ ...prevState, [key]: value }));
  };

  if (!open) return null;
  return (
    <>
      {open && (
        <div className={modalClasses.root}>
          <MuiDialogTitle disableTypography className={modalClasses.dialogTitleContainer}>
            <div className={modalClasses.headerContainer}>
              {viewOnly && (
                <div className={modalClasses.leftHeaderButton}>
                  <Typography variant="h5" color="primary">
                    {formatDateWithDisappearingDate(createdOn)}
                  </Typography>
                </div>
              )}
              <div className={modalClasses.headerTitleContainer}>
                <Typography variant="h6">Cutting permit</Typography>
              </div>
              <div className={`${modalClasses.rightButtonContainer} `}>
                <IconButton
                  aria-label="close"
                  className={modalClasses.closeButton}
                  onClick={onCancelCuttingPermit}
                  data-cy="closeDialogButton"
                  id="close-dialog-button"
                >
                  <Icons.XFeather strokeWidth={1} color="primary" />
                </IconButton>
              </div>
            </div>
          </MuiDialogTitle>
          <DialogContent className={modalClasses.dialogContent}>
            {(isCory || isVanscoy || isRocanville) && (
              <Card className={modalClasses.card}>
                <Typography variant="h3">Location</Typography>
                <GenericLocationComponent
                  direction={'row'}
                  isLanigan={isLanigan}
                  isCory={isCory}
                  isVanscoy={isVanscoy}
                  isRocanville={isRocanville}
                  isAllan={isAllan}
                  location={location}
                  isChevron={miningMethod === MiningMethod.CHEVRON}
                  setHasValidationErrors={setHasLocationValidationError}
                  onLocationChange={onLocationChange}
                  viewOnly={viewOnly}
                />
              </Card>
            )}
            <Grid container>{lsitOfCategoriesAndOptions}</Grid>
            {(isCory || isVanscoy || isRocanville || isAllan) && (
              <Card className={modalClasses.card}>
                <Typography variant="h3">Comment (optional)</Typography>
                <TextField
                  multiline
                  rows={viewOnly ? undefined : 3}
                  onChange={event => {
                    setComment(event.target.value?.replace(/ /g, '\u00a0') || '');
                  }}
                  value={comment}
                  disabled={viewOnly}
                />
              </Card>
            )}
            {viewOnly && (
              <Grid container>
                <ChecksThisShiftSignatureCard signatureId={signatureId} />
              </Grid>
            )}
          </DialogContent>
          <DialogActions className={modalClasses.dialogActionsContainer}>
            <>
              {!viewOnly && (
                <Grid container justify="space-between">
                  <Grid item>
                    <Button
                      onClick={onCancelCuttingPermit}
                      variant="outlined"
                      color="primary"
                      noMinHeight
                      id="cancel-cutting-permit-button"
                    >
                      {i18n.t('Cancel')}
                    </Button>
                  </Grid>
                  <Grid item className={classes.marginRight}>
                    <Button
                      onClick={onOpenSignatureModal}
                      variant="contained"
                      color="primary"
                      noMinHeight
                      disabled={!canSave || isSaving}
                      isLoading={isSaving}
                      id="save-cutting-permit-button"
                    >
                      {i18n.t('Save')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {viewOnly && (
                <Grid container spacing={2} justify="space-between">
                  <Grid item xs={6} />
                  <Grid item className={classes.marginRight}>
                    <Button
                      onClick={onCancelCuttingPermit}
                      variant="contained"
                      color="primary"
                      noMinHeight
                      id="close-cutting-permit-button"
                    >
                      {i18n.t('Close')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          </DialogActions>
        </div>
      )}
      {signatureModalOpen && (
        <AddSignatureModal
          open={signatureModalOpen}
          type={SignatureType.CUTTING_PERMIT}
          onCancel={onCancelSignature}
          onSave={onSaveInspection}
          onUpdate={onUpdateSignature}
          employees={eligibleEmployees} // Only show employees that have signed in this shift
          selectedEmployee={selectedEmployee}
          selectedCustomName={selectedCustomName}
          nameNotInList={nameNotInList}
          reviewedCuttingPermits={reviewedCuttingPermits}
          disableEnforceFocus
        />
      )}
      {discardOpen && (
        <DiscardDraftModal
          open={discardOpen}
          onCancel={onCancelDiscardDraft}
          onDiscard={onDiscardDraft}
          disableEnforceFocus
        />
      )}
    </>
  );
};

export default CuttingPermitModal;
