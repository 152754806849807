import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';

import { useMst } from '../../mobx-models/Root';
import { LocationDocument } from '../../models/models';
import { useBorerShift } from '../../rxdb/BorerShift/useBorerShift';
import useLocations from '../../rxdb/Locations/useLocations';
import { PredictionDocument } from '../../rxdb/Prediction/queryBuilder';
import { usePrediction } from '../../rxdb/Prediction/usePrediction';
import { ProductionDocument } from '../../rxdb/Productions/queryBuilder';
import { AugmentedProduction, useProduction } from '../../rxdb/Productions/useProduction';
import { ProductionTargetDocument } from '../../rxdb/ProductionTarget/queryBuilder';
import { useProductionTarget } from '../../rxdb/ProductionTarget/useProductionTarget';
import { useShifts } from '../../rxdb/Shifts/useShifts';
import { BORER_SHIFT_WRITE_PERMISSION } from '../../utilities/constants';
import useViewingCurrentShift from '../../utilities/hooks/useViewingCurrentShift';
import EditPredictionSidePanel from '../EditPredictionSidePanel/EditPredictionSidePanel';
import ModifyAdvanceSidePanel from '../ModifyAdvanceSidePanel';
import PredictionCard from '../PredictionCard';
import ProductionCard from '../ProductionCard/ProductionCard';
import ProductionTargetCard from '../ProductionTargetCard';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    subCard: {
      margin: '0 !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.text.disabled,
    },
    addAdvanceButton: {
      '& svg': {
        stroke: '#000',
      },
    },
    rightColumn: {
      alignContent: 'flex-start',
    },
  }),
);

const AdvancesCard: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker, user } = useMst();
  const userViewingCurrentShift = useViewingCurrentShift();

  const [productionTargetSubscription, setProductionTargetSubscription] = useState<Subscription>();

  const [modifyAdvanceOpen, setModifyAdvanceOpen] = useState<boolean>(false);
  const [editPredictionOpen, setEditPredictionOpen] = useState<boolean>(false);
  const [productionToEdit, setProductionToEdit] = useState<ProductionDocument>();
  const [predictionToEdit, setPredictionToEdit] = useState<PredictionDocument>();

  const [productionTargetForShift, setProductionTargetForShift] = useState<
    ProductionTargetDocument[]
  >([]);
  const [previousLocation, setPreviousLocation] = useState<LocationDocument>();

  const { productionForShift, productionInitialized, getEndLocationForShift } = useProduction(
    shiftPicker.currentBorerShiftId,
  );
  const { predictionForShift, isPredictionFetching } = usePrediction();
  const {
    listProductionTargetForShiftId,
    productionTargetInitialized,
    productionTargetCollection,
  } = useProductionTarget();
  const { isRehabBorer } = useCurrentBorer();

  const { getPreviousShift, shiftsInitialized } = useShifts();
  const { getCurrentBorerShift } = useBorerShift();
  const { locationsInitialized } = useLocations();

  const queryProductionForPreviousShift = async () => {
    if (shiftsInitialized && productionInitialized) {
      const previousShift = await getPreviousShift();
      if (previousShift) {
        const borerShift = await getCurrentBorerShift(previousShift?.id);
        if (borerShift) {
          const prevShiftEndLocation = await getEndLocationForShift(borerShift.id);

          setPreviousLocation(prevShiftEndLocation?.location);
        }
      } else {
        console.log('🚀 ~ file: AdvancesCard.tsx ~ line 213 ~ No previous shift found');
      }
    }
  };

  const queryProductionTargetForShift = async () => {
    if (productionTargetInitialized) {
      const productionTarget = await listProductionTargetForShiftId(
        shiftPicker?.currentBorerShiftId,
      );
      setProductionTargetForShift(productionTarget);
    }
  };

  const subscribeToProductionTargets = async () => {
    if (productionTargetInitialized && productionTargetCollection) {
      const productionTargetSub = productionTargetCollection.$.subscribe(() => {
        queryProductionTargetForShift();
      });
      setProductionTargetSubscription(productionTargetSub);
    }
  };

  useEffect(() => {
    queryProductionForPreviousShift();
    queryProductionTargetForShift();
    subscribeToProductionTargets();

    return function cleanup() {
      if (productionTargetSubscription) productionTargetSubscription.unsubscribe();
    };
  }, [
    locationsInitialized,
    productionInitialized,
    productionTargetInitialized,
    shiftsInitialized,
    shiftPicker?.currentBorerShiftId,
    shiftPicker?.Date,
    shiftPicker?.Type,
  ]);

  const onEditProduction = (prodDoc: AugmentedProduction) => {
    setModifyAdvanceOpen(true);
    setProductionToEdit(prodDoc.production);
  };

  const onEditPrediction = (prediction: PredictionDocument) => {
    setEditPredictionOpen(true);
    setPredictionToEdit(prediction);
  };

  const editPredictionSidePanel = useMemo(() => {
    return (
      <EditPredictionSidePanel
        open={editPredictionOpen}
        onClose={() => {
          setEditPredictionOpen(false);
        }}
        onOpen={() => {
          setEditPredictionOpen(true);
        }}
        predictionToEdit={predictionToEdit}
      />
    );
  }, [editPredictionOpen, predictionToEdit]);

  const onOpenModifyAdvanceSidePanel = useCallback(() => {
    setModifyAdvanceOpen(true);
  }, []);
  const onCloseModifyAdvanceSidePanel = useCallback(() => {
    setModifyAdvanceOpen(false);
  }, []);

  return (
    <>
      <Card elevation={1} className={classes.card} data-cy="add-advances-card">
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography variant="h3">{`${i18n.t('Advance')}`}</Typography>
            </Grid>
            <Grid item container xs={4} justify="flex-end">
              <Grid item>
                {userViewingCurrentShift && user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
                  <Button
                    className={classes.addAdvanceButton}
                    variant="contained"
                    color="primary"
                    noMinHeight
                    startAdornment={<Icons.PlusFeather />}
                    onClick={() => {
                      setProductionToEdit(undefined);
                      setModifyAdvanceOpen(true);
                    }}
                    data-cy="add-advance-button"
                    id="add-advance-button"
                  >
                    {i18n.t('Add advance')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* ========== LEFT COLUMN : PRODUCTION CARDS ========== */}
          <Grid item container xs={6}>
            <Grid item container xs={12}>
              {productionForShift.map(production => {
                return (
                  <ProductionCard
                    key={production.production.id}
                    productionDocument={production}
                    onEdit={onEditProduction}
                  />
                );
              })}
              {productionForShift.length === 0 && (
                <Typography variant="body2" className={classes.type}>
                  {i18n.t('No advances exist for this shift.')}
                </Typography>
              )}
            </Grid>
          </Grid>

          {/* ========== RIGHT COLUMN - TARGET + PREDICTION CARDS ========== */}
          <Grid container item xs={6} alignContent="flex-start">
            {!isRehabBorer && (
              <Grid item container xs={12} className={classes.rightColumn}>
                {productionTargetForShift.map(productionTarget => {
                  return (
                    <ProductionTargetCard
                      key={productionTarget.id}
                      productionTargetDocument={productionTarget}
                      productionForShift={productionForShift}
                    />
                  );
                })}
                {productionTargetForShift.length === 0 && (
                  <Card className={classes.card}>
                    <Typography variant="body2" className={classes.type}>
                      {i18n.t('No production targets exist for this shift.')}
                    </Typography>
                  </Card>
                )}
              </Grid>
            )}
            <Grid item container xs={12}>
              {isPredictionFetching ? (
                <Spinner />
              ) : (
                <PredictionCard
                  predictionDocument={predictionForShift}
                  previousLocation={previousLocation}
                  onEdit={onEditPrediction}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <ModifyAdvanceSidePanel
        open={modifyAdvanceOpen}
        onClose={onCloseModifyAdvanceSidePanel}
        onOpen={onOpenModifyAdvanceSidePanel}
        productionToEdit={productionToEdit}
      />
      {editPredictionSidePanel}
    </>
  );
};

export default observer(AdvancesCard);
