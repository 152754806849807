import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface CuttingMethod extends BaseEntity {
  readonly description: string;
  readonly displayOrder: number | null;
}
export type CuttingMethodCollection = RxCollection<CuttingMethod> | null;
export type CuttingMethodDocument = RxDocument<CuttingMethod>;

export const cuttingMethodSchema: RxJsonSchema<CuttingMethod> = {
  type: 'object',
  version: 0,
  description: 'describes a CuttingMethod object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    description: { type: 'string' },
    displayOrder: { type: ['number', 'null'] },
  },
};

export const cuttingMethodMigrationStrategy: MigrationStrategies = {};

export const cuttingMethodPullQueryBuilder = (doc: CuttingMethod) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query cuttingMethodFeed($limit: Int!, $lastUpdateAt: Float!, $siteId: ID!) {
      cuttingMethodFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
        description
        displayOrder
        isDeleted
        id
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      siteId: rootStore.user.siteId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
