import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { RxdbCollectionName } from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface BorerActivityType extends BaseEntity {
  readonly delayActivityTypeCategory: string;
  readonly description: string;
  readonly name: string;
  readonly isActive: boolean;
  readonly createdOn: string;
}
export type BorerActivityTypeCollection = RxCollection<BorerActivityType> | null;
export type BorerActivityTypeDocument = RxDocument<BorerActivityType>;

export const borerActivityTypeSchema: RxJsonSchema<BorerActivityType> = {
  type: 'object',
  version: 0,
  description: 'describes a BorerActivityType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    delayActivityTypeCategory: {
      ref: RxdbCollectionName.DELAY_ACTIVITY_TYPE_CATEGORY,
      type: ['string', 'null'],
    },
    description: { type: 'string' },
    name: { type: 'string' },
    createdOn: { type: 'string' },
    isActive: { type: 'boolean' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const borerActivityTypePullQueryBuilder = (doc: BorerActivityType) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query activityTypeFeed($lastUpdateAt: Float!, $siteId: ID!, $limit: Int!) {
      activityTypeFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
        createdOn
        delayActivityTypeCategory
        description
        id
        isActive
        name
        updatedAt
        isDeleted
        version
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      siteId: rootStore.user.siteId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerActivityTypePushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const query = print(gql`
    mutation CreateBorerActivityType($borerActivityType: CreateBorerActivityTypeInput) {
      createBorerActivityType(input: $borerActivityType)
    }
  `);

  const variables = {
    borerActivityType: doc,
  };

  return {
    query,
    variables,
  };
};

export const borerActivityTypePushModifier = (doc: BorerActivityTypeDocument) => {
  const obj = {
    ...doc,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
