import type { Theme } from '@material-ui/core';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { AutoComplete, Button, i18n, Icons, TextField } from '@nutrien/cxp-components';
import React, { useCallback } from 'react';

import { EnrichedSupply } from '../../rxdb/Supply/queryBuilder';
import { SupplyItemDocument } from '../../rxdb/SupplyItem/queryBuilder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    divider: {
      borderBottom: `solid 1px ${theme.palette.text.disabled}`,
      margin: '10px 0',
    },
    deleteButtonContainer: {
      marginTop: '25px',
    },
  }),
);

interface Props {
  supply: EnrichedSupply;
  onItemChanged: (supply: EnrichedSupply, supplyItem: SupplyItemDocument) => void;
  onOtherDescriptionChanged: (supply: EnrichedSupply, newOtherDescription: string) => void;
  supplyItems: SupplyItemDocument[];
  onDeleteSupply: (supplyToDelete: EnrichedSupply) => void;
  otherSupplyItemId: string | undefined;
  onQuantityChanged: (supply: EnrichedSupply, newItemValue: string) => void;
  quantityErrorMessage: string | undefined;
  otherDescriptionErrorMessage: string | undefined;
  divider: boolean;
}

const SupplyItemSection = ({
  supply,
  onItemChanged,
  onOtherDescriptionChanged,
  onDeleteSupply,
  supplyItems,
  onQuantityChanged,
  otherSupplyItemId,
  quantityErrorMessage,
  otherDescriptionErrorMessage,
  divider,
}: Props) => {
  const classes = useStyles();

  const findSupplyItem = useCallback(() => {
    return supplyItems.find(item => item.id === supply.supplyItemId);
  }, [supplyItems, supply]);

  const handleClickDelete = useCallback(() => {
    onDeleteSupply(supply);
  }, [onDeleteSupply, supply]);

  const handleItemChanged = useCallback(
    (event, value) => {
      onItemChanged(supply, value);
    },
    [onItemChanged, supply, supplyItems],
  );

  const handleOtherDescriptionChange = useCallback(
    event => onOtherDescriptionChanged(supply, event.target.value),
    [supply],
  );

  const handleQuantityChange = useCallback(
    event => onQuantityChanged(supply, event.target.value),
    [supply],
  );

  return (
    <Grid container alignItems="center" key={`${supply.id}`}>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={10}>
            <AutoComplete
              autoSelect={false}
              list={supplyItems}
              label={i18n.t('Item')}
              required
              getOptionLabel={(item: SupplyItemDocument) => {
                return item.description;
              }}
              onChange={handleItemChanged}
              value={findSupplyItem()}
              showCaret
              data-cy={`${supply.id}-SupplyItem-chevron-step`}
            />
          </Grid>
          <Grid item xs={2} className={classes.deleteButtonContainer}>
            <Button
              id="deleteSupplyButton"
              noMinHeight
              startAdornment={<Icons.MinusCircleFeather color="error" />}
              onClick={handleClickDelete}
              variant="text"
              color="primary"
            />
          </Grid>
          {supply.supplyItemId === otherSupplyItemId && (
            <Grid item xs={12}>
              <TextField
                label={i18n.t('Please specify item')}
                required
                onChange={handleOtherDescriptionChange}
                value={supply.otherDescription}
                data-cy={`${supply.id}-SupplyItem-other-description`}
                error={otherDescriptionErrorMessage !== undefined}
                errorText={otherDescriptionErrorMessage ?? ''}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label={i18n.t('Quantity')}
              required
              unitText={supply.supplyItemUnit || ''}
              onChange={handleQuantityChange}
              value={supply.quantity}
              data-cy={`${supply.id}-SupplyItem-quantity`}
              inputProps={{ inputMode: 'numeric' }}
              error={quantityErrorMessage !== undefined}
              errorText={quantityErrorMessage ?? ''}
            />
          </Grid>
        </Grid>
      </Grid>
      {divider && <Grid item xs={12} className={classes.divider} />}
    </Grid>
  );
};

function propsAreEqual(prev: Props, next: Props) {
  if (prev.divider !== next.divider) return false;
  if (prev.supplyItems !== next.supplyItems) return false;

  for (const key of Object.keys(prev.supply)) {
    if (prev.supply[key] !== next.supply[key]) return false;
  }

  return true;
}

export default React.memo(SupplyItemSection, propsAreEqual);
