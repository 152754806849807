/* eslint-disable no-nested-ternary */
import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import {
  LocationDocument,
  PanelDocument,
  Pass,
  Room,
  Sequence,
  SurveyPoint,
} from '../../models/models';
import { Block, BlockCollection } from '../../rxdb/Blocks/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import useSite from '../../rxdb/Site/useSite';
import { MiningMethodAllCap } from '../../utilities';

export const NOT_AVAILABLE = '';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    titleLine: {
      marginBottom: '2px',
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    secondaryText: {
      color: theme.palette.text.primary,
    },
    secondaryTextRightPad: {
      color: theme.palette.text.primary,
      paddingRight: '8px',
    },
    sequencePassText: {
      color: theme.palette.primary.main,
    },
  }),
);

interface Props {
  location: LocationDocument;
  distance?: number;
  ignoreStepAndPassColors?: boolean;
}

const PredictionLocationDetailsComponent: React.FC<Props> = ({
  location,
  distance,
  ignoreStepAndPassColors = false,
}: Props) => {
  const classes = useStyles();
  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();

  const [block, setBlock] = useState<Block | undefined>(undefined);
  const [surveyPoint, setSurveyPoint] = useState<SurveyPoint | undefined>(undefined);
  const [sequence, setSequence] = useState<Sequence | undefined>(undefined);
  const [pass, setPass] = useState<Pass | undefined>(undefined);
  const [panel, setPanel] = useState<PanelDocument | undefined>(undefined);
  const [room, setRoom] = useState<Room | undefined>(undefined);
  const [miningMethod, setMiningMethod] = useState<MiningMethodAllCap>(MiningMethodAllCap.CHEVRON);

  const blocksCollection: BlockCollection = useRxCollection(RxdbCollectionName.BLOCKS);
  const panelCollection: BlockCollection = useRxCollection(RxdbCollectionName.PANELS);

  useEffect(() => {
    const populatePanelReferences = async () => {
      setPanel(undefined);
      try {
        const panelData = await location.populate('panel');
        setPanel(panelData);
        const blockData = await blocksCollection
          ?.findOne({
            selector: {
              id: panelData.block,
            },
          })
          .exec();
        if (blockData) {
          setBlock(blockData);
        }

        setRoom((await location.populate('room')) as Room);
        setPass((await location.populate('pass')) as Pass);
        setSurveyPoint((await location.populate('surveyPoint')) as SurveyPoint);
        setSequence((await location.populate('sequence')) as Sequence);
        setMiningMethod(
          panelData.miningMethod?.toLowerCase().includes('chevron')
            ? MiningMethodAllCap.CHEVRON
            : MiningMethodAllCap.LONG_ROOM,
        );
      } catch (error) {
        console.log(
          '🚀 ~ file: LocationDetailsComponent.tsx ~ line 114 ~ populatePanelReferences ~ error',
          error,
        );
      }
    };

    if (panelCollection) {
      populatePanelReferences();
    }
  }, [panelCollection, location, distance, blocksCollection]);

  const locationStartDetails = useMemo(() => {
    const roomName = room?.description.toLocaleUpperCase();

    if (isLanigan) {
      if (roomName === 'PE') {
        return (
          <>
            <Typography className={classes.secondaryText} variant="body2">
              {room?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="body2">
              {block?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="body2">
              {panel?.description || NOT_AVAILABLE}
            </Typography>
          </>
        );
      }

      //
      if (roomName === 'MN' || roomName === 'BR' || roomName === 'DM' || roomName === 'DB') {
        return (
          <>
            <Typography className={classes.secondaryText} variant="body2">
              {room?.description || NOT_AVAILABLE}
            </Typography>
            <Typography className={classes.secondaryText} variant="body2">
              {panel?.description || NOT_AVAILABLE}
            </Typography>
          </>
        );
      }
      return (
        <>
          <Typography className={classes.secondaryText} variant="body2">
            {block?.description || NOT_AVAILABLE}
          </Typography>
          <Typography className={classes.secondaryText} variant="body2">
            {panel?.description || NOT_AVAILABLE}
          </Typography>
          <Typography className={classes.secondaryText} variant="body2">
            {room?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }

    if ((isVanscoy || isRocanville) && miningMethod === MiningMethodAllCap.LONG_ROOM) {
      return (
        <>
          <Typography className={classes.secondaryText} variant="body2">
            {panel?.description || NOT_AVAILABLE}-{room?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }

    if (isCory || miningMethod === MiningMethodAllCap.CHEVRON) {
      return (
        <>
          <Typography className={classes.secondaryText} variant="body2">
            {panel?.description || NOT_AVAILABLE}
          </Typography>
        </>
      );
    }
  }, [isLanigan, isVanscoy, miningMethod, room, panel, block, isCory]);

  return (
    <>
      <Grid item container xs={12} spacing={1}>
        {locationStartDetails}
        <Typography className={classes.secondaryText} variant="body2">
          {(isCory || (isVanscoy && miningMethod === MiningMethodAllCap.CHEVRON) || isAllan) && (
            <span className={classes.dot2}>&#183;</span>
          )}
          {surveyPoint?.description || NOT_AVAILABLE}
        </Typography>
        {(sequence || pass) && ( // chevron type ONLY
          <>
            <span className={classes.dot2}>&#183;</span>
            <Typography
              className={`${classes.secondaryText} ${
                ignoreStepAndPassColors ? '' : classes.sequencePassText
              }`}
              variant="body2"
              style={{ paddingRight: '4px' }}
            >
              {sequence?.description || NOT_AVAILABLE}{' '}
            </Typography>
            <Typography
              className={`${classes.secondaryText} ${
                ignoreStepAndPassColors ? '' : classes.sequencePassText
              }`}
              variant="body2"
            >
              {pass?.description || NOT_AVAILABLE}
            </Typography>
          </>
        )}
        {location && distance !== undefined && distance !== null && (
          <>
            <span className={classes.dot2}>&#183;</span>
            <Typography className={classes.secondaryText} variant="body2">
              {distance} {distanceUnitAbbreviation}
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default observer(PredictionLocationDetailsComponent);
