import { isNumber } from 'radash';

import { DocumentUploadDocument } from '../../rxdb/Documents/queryBuilder';
import { cacheDocumentInS3 } from './cacheDocumentInS3';
import { attachmentInStorage, retryCountExceeded } from './documentUtilities';
import { getDocumentFromDocumentUploadCollection } from './getDocumentFromDocumentUploadCollection';

const DEBUG = sessionStorage.getItem('DEBUG') === 'true';
export const defaultProtectionLevel = 'public';

export const DOCUMENT_RETRY_COUNT = 3;

// Used for deficiency and hazard attachments
const retryCount: { [key: string]: number } = {};

/**
 * Using a filename the attachment will be attempted ot pull down, has a 5 second retry in case of race condition on upload
 *
 * @param {string} filename s3 filepath
 * @param {number} [retryTimeoutms=5000]
 */

export const getAttachmentWithRetry = async (
  document: DocumentUploadDocument,
  retryTimeoutms = 5000,
  numberOfRetries = DOCUMENT_RETRY_COUNT,
) => {
  if (!document.fileName) return;
  // Required to do atomic update on rxdbDoc
  const rxdbDoc = await getDocumentFromDocumentUploadCollection(document);

  if (!retryCount[document.id]) {
    retryCount[document.id] = 0;
  }

  if (rxdbDoc?.isCached || rxdbDoc?.isLoading) return;

  // Doc is already cached
  const alreadyInAttachmentStore = await attachmentInStorage(document.fileName);
  if (alreadyInAttachmentStore) {
    await rxdbDoc?.atomicUpdate(doc => {
      return { ...doc, isCached: true };
    });
    return true;
  }

  let timeout: NodeJS.Timeout;
  if (
    retryCount[document.id] < numberOfRetries ||
    (rxdbDoc && !retryCountExceeded(rxdbDoc, numberOfRetries))
  ) {
    if (DEBUG)
      console.info(
        'Attempting to download with retry',
        document.fileName,
        'retry count:',
        retryCount[document.id],
        retryCount,
      );
    try {
      await rxdbDoc?.atomicUpdate(doc => ({ ...doc, isLoading: true }));
      const successfulCache = await cacheDocumentInS3(document);
      if (successfulCache) {
        retryCount[document.id] = DOCUMENT_RETRY_COUNT;
        rxdbDoc?.atomicUpdate(doc => {
          return { ...doc, retryCount: DOCUMENT_RETRY_COUNT, isCached: true, isLoading: false };
        });
      }
    } catch (err) {
      await rxdbDoc?.atomicUpdate(doc => {
        return {
          ...doc,
          retryCount: isNumber(doc.retryCount) ? (doc.retryCount += 1) : 1,
          isLoading: false,
        };
      });
      retryCount[document.id] = retryCount[document.id] + 1;

      if (retryTimeoutms) {
        timeout = setTimeout(async () => {
          await getAttachmentWithRetry(document, retryTimeoutms, numberOfRetries);
        }, retryTimeoutms);
      } else {
        await getAttachmentWithRetry(document, retryTimeoutms, numberOfRetries);
      }
    }
  } else {
    if (DEBUG) console.info('Retry count exceeded on ', document.fileName);
    if (rxdbDoc?.isLoading)
      await rxdbDoc?.atomicUpdate(doc => {
        return {
          ...doc,
          isLoading: false,
        };
      });
    if (timeout) clearTimeout(timeout);
  }
};
