import { Grid } from '@material-ui/core';
import { Button, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { EmployeeSummary } from '@/rxdb/Employees/useEmployees';

import { useMst } from '../../mobx-models/Root';
import { EquipmentDocument } from '../../rxdb/Equipment/queryBuilder';
import { EquipmentDeficiencyDocument } from '../../rxdb/EquipmentDeficiency/queryBuilder';
import { EquipmentDeficiencyLogDocument } from '../../rxdb/EquipmentDeficiencyLog/queryBuilder';
import { EquipmentTypeDocument } from '../../rxdb/EquipmentType/queryBuilder';
import { useDateFormatters } from '../../utilities/useDateFormatters';
import DeficiencyPhotos from '../DeficiencyPhotos';
import useStyles from './DeficiencyPanelContent.styles';

interface Props {
  deficiency: EquipmentDeficiencyDocument;
  equipment: EquipmentDocument;
  equipmentType: EquipmentTypeDocument;
  logs: EquipmentDeficiencyLogDocument[];
  employeeList: EmployeeSummary[];
  openAddCommentSidePanel?: (deficiency: EquipmentDeficiencyDocument) => void;
  showType?: boolean;
  viewOnly?: boolean;
  photoSource?: string;
  hideStatus?: boolean;
}

const DeficiencyPanelContent: React.FC<Props> = ({
  deficiency,
  equipment,
  equipmentType,
  logs,
  employeeList,
  openAddCommentSidePanel,
  showType = true,
  viewOnly = false,
  photoSource = 'page',
  hideStatus = false,
}: Props) => {
  const classes = useStyles();

  const { formatDateWithDisappearingDate } = useDateFormatters();
  const { shiftPicker } = useMst();
  const imageNamePrefix = `${equipmentType.description}
  ${equipment.longName}_${deficiency.description}_`
    .split('/')
    .join('-');

  const getLogComponents = (employees: EmployeeSummary[]) => {
    if (!logs) return [];

    return logs.map(log => {
      const employee = employees.filter(emp => emp.id === log.siteEmployeeId)[0];

      return (
        <Grid container item xs={12} key={`log-${log.id}`} className={classes.commentItemContainer}>
          <Grid item xs={3}>
            {employee && (
              <Typography variant="caption" color="textSecondary">
                {employee.firstName} {employee.lastName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="textSecondary">
              {formatDateWithDisappearingDate(log.createdOn)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{log.comment}</Typography>
          </Grid>
        </Grid>
      );
    });
  };

  const onAddComment = () => {
    if (openAddCommentSidePanel) openAddCommentSidePanel(deficiency);
  };

  return (
    <>
      <Grid container>
        <Grid item container xs={8}>
          <Grid item container xs={12} spacing={1} className={classes.titleLine}>
            <Typography variant="h5">{deficiency.description}</Typography>
            {showType && (
              <>
                <span className={classes.dot}>&#183;</span>
                <Typography className={classes.secondaryText} variant="h5">
                  {equipmentType?.description} {equipment?.longName}
                </Typography>
              </>
            )}
          </Grid>
          {!hideStatus && (
            <Grid item container xs={12} spacing={1}>
              {deficiency.isWorkOrderRequired ? (
                <>
                  <Typography variant="caption" className={classes.secondaryText}>
                    WO pending review
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="caption" className={classes.secondaryText}>
                    No WO required
                  </Typography>
                  <span className={classes.dot2}>&#183;</span>
                  <Typography className={classes.secondaryText} variant="caption">
                    Active
                  </Typography>
                </>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item container xs={4} justify="flex-end">
          {shiftPicker.isCurrentShiftSelected() && openAddCommentSidePanel && !viewOnly && (
            <Button
              noMinHeight
              color="primary"
              startAdornment={<Icons.EditFeather color="primary" />}
              onClick={onAddComment}
              style={{ maxWidth: '205px' }}
              id="add-deficiency-comment-btn"
            >
              {i18n.t('Update deficiency')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.commentsContainer}>
        <Grid item container xs={8}>
          {getLogComponents(employeeList)}
        </Grid>
        <Grid item container xs={4} justify="flex-end">
          <DeficiencyPhotos
            hideAddPhotoAction={!shiftPicker.isCurrentShiftSelected() || viewOnly}
            deficiencyId={deficiency.id}
            source={photoSource}
            photoPrefix={imageNamePrefix}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default observer(DeficiencyPanelContent);
