import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_HIGH } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface SurveyPoint extends BaseEntity {
  readonly id: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly miningPattern: string;
  readonly room: string;
}
export type SurveyPointsCollection = RxCollection<SurveyPoint> | null;
export type SurveyPointDocument = RxDocument<SurveyPoint>;

export const surveyPointsSchema: RxJsonSchema<SurveyPoint> = {
  type: 'object',
  version: 1,
  description: 'describes a SurveyPoint object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    miningPattern: {
      ref: RxdbCollectionName.MINING_PATTERN,
      type: ['string', 'null'],
    },
    room: { ref: 'rooms', type: 'string' },
    version: { type: 'number' },
  },
};

export const surveyPointMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SurveyPointDocument) => ({ ...oldDoc }),
};

export const surveyPointsPullQueryBuilder = (doc: SurveyPoint) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query surveyPointFeed($siteId: ID!, $lastUpdatedAt: Float!, $limit: Int!) {
      surveyPointFeed(siteId: $siteId, lastUpdateAt: $lastUpdatedAt, limit: $limit) {
        id
        description
        isActive
        isDeleted
        miningPattern
        room
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      lastUpdatedAt: doc.updatedAt,
      limit: SYNC_LIMIT_HIGH,
    },
  };
};

export default {};
