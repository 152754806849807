import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { i18n, MaterialPalette, Typography } from '@nutrien/cxp-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { GroundControlType } from '../../rxdb/GroundControlTypes/queryBuilder';
import { GroundHazardCollection, GroundHazardDocument } from '../../rxdb/GroundHazard/queryBuilder';
import { HazardLog } from '../../rxdb/GroundHazardLog/queryBuilder';
import useHazardLog from '../../rxdb/GroundHazardLog/useHazardLog';
import { LocationCollection } from '../../rxdb/Locations/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import { useDateFormatters } from '../../utilities';
import { GroundControlMapper } from '../../utilities/groundControlMapper';
import { sortDescendingByUpdatedAt } from '../../utilities/sortHelper';
import HazardPanelPhotos from '../HazardPanelPhotos';
import LocationDetailsComponent from '../LocationDetailsComponent/LocationDetailsComponent';

const useStyles = makeStyles(() =>
  createStyles({
    comment: {
      marginTop: '30px',
    },
    groundControlView: {
      paddingBottom: '10px',
    },
    bullet: {
      color: MaterialPalette.primary.main,
      padding: '0 10px',
    },
    bold: {
      fontWeight: 'bold',
    },
    circularProgress: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '15px',
      color: `${MaterialPalette.primary.main}`,
      '& h6': {
        marginLeft: '20px',
      },
    },
    groundControlSet: {
      marginTop: '15px',
    },
    hazardPanelContent: {
      display: 'flex',
      flex: 0.8,
    },
    flexSmall: {
      flex: 0.2,
    },
    flexMid: {
      flex: 0.23,
    },
    flexLarge: {
      flex: 0.56,
    },
  }),
);

interface Props {
  groundHazard: GroundHazardDocument;
  locations?: LocationCollection;
  hideAddPhotoAction?: boolean;
  hideCommentsBeforeDate?: number;
  conditionType?: string;
}

const HazardPanelContent: React.FC<Props> = ({
  groundHazard,
  locations,
  hideAddPhotoAction,
  hideCommentsBeforeDate,
  conditionType,
}: Props) => {
  const classes = useStyles();
  const { formatDateFromUnixMilliInLocalTz, formatDateWithDisappearingDate } = useDateFormatters();
  const { hazardLogsInitialized, listHazardLogsFor } = useHazardLog();

  const [hazardLogs, setHazardLogs] = useState<HazardLog[]>();
  const [employeeDictionary, setUserDictionary] = useState<Record<string, unknown>>({});
  const [groundControlSetDictionary, setGroundControlSetDictionary] = useState({});
  const [groundControlSets, setGroundControlSets] = useState<any[]>([]);
  const groundControlMapper = new GroundControlMapper();
  const [addingNewHazardLog, setAddingNewHazardLog] = useState(false);
  const [render, setRender] = useState(false);

  // TEMP Fix for rendering in Area Check Read Only
  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 100);
  }, []);

  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );

  const groundControlTypesQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: groundControlTypes }: { result: GroundControlType[] } = useRxData(
    RxdbCollectionName.GROUND_CONTROL_TYPES,
    groundControlTypesQueryConstructor,
  );

  useEffect(() => {
    if (groundHazardCollection && hazardLogsInitialized) {
      groundHazardCollection.$.subscribe(() => {
        populateData().then(() => {
          setAddingNewHazardLog(false);
        });
      });
      populateData();
    }
  }, [groundHazard, groundHazardCollection, hazardLogsInitialized]);

  const populateData = async () => {
    if (groundHazardCollection && hazardLogsInitialized && groundHazard) {
      let logs = await listHazardLogsFor(groundHazard.id);
      logs = logs.sort(sortDescendingByUpdatedAt);
      if (hideCommentsBeforeDate) logs = logs.filter(log => log.updatedAt < hideCommentsBeforeDate);

      const employees = employeeDictionary;
      const gcSets = {};

      if (logs && logs.length > 0) {
        await Promise.all(
          logs.map(async log => {
            if (log) {
              const user = await log.populate('reportedBy');
              const gcsForLog = await log.populate('groundControlSets');
              gcSets[log.id] = gcsForLog;
              employees[log.id] = user;
            }
          }),
        );
      }

      setUserDictionary(employees);
      setGroundControlSets(gcSets);
      setHazardLogs(logs); // todo: sort logs
      setGroundControlSetDictionary(gcSets);
    }
  };

  const getLocation = (locationId: string) => {
    if (locations) {
      return locations.find(l => l.id === locationId);
    }
  };

  const renderGroundControlSets = (hazardLogId: string) => {
    const gcs = groundControlSetDictionary[hazardLogId];
    const gcViews = groundControlMapper.MapCardViewFromValues(groundControlTypes, gcs);

    if (gcViews.length === 0) {
      return <></>;
    }
    return (
      <Grid container>
        {gcViews.map((gc, index) => {
          return (
            <Grid item xs={12} key={`${gc.id}-${index}`} className={classes.groundControlView}>
              <Typography variant="body2">
                <span className={classes.bold}>{gc.type}</span>
                {gc.requiresQuantity && (
                  <>
                    <span className={classes.bullet}>&#8226;</span>
                    Qty: {gc.quantity}
                  </>
                )}
                {gc.values.map((val, indexValue) => {
                  return (
                    <span key={`${val}-${indexValue}`}>
                      <span className={classes.bullet}>&#8226;</span>
                      {val.description}: {val.value}
                    </span>
                  );
                })}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      {render && (
        <Grid container data-cy="HazardPanelContent" key={groundHazard.id}>
          <Grid
            item
            container
            className={classes.hazardPanelContent}
            alignItems="flex-start"
            alignContent="flex-start"
          >
            {addingNewHazardLog && (
              <div className={classes.circularProgress}>
                <CircularProgress />
                <Typography variant="subtitle2">{i18n.t('Updating...')}</Typography>
              </div>
            )}
            {hazardLogs &&
              hazardLogs.map((hazardLog, index) => {
                return (
                  <Grid
                    item
                    container
                    key={hazardLog.id}
                    spacing={2}
                    alignItems="flex-start"
                    className={index > 0 ? classes.comment : ''}
                  >
                    <Grid item className={classes.flexSmall}>
                      <Typography variant="caption">
                        {employeeDictionary[hazardLog.id] && (
                          <>
                            {`${employeeDictionary[hazardLog.id]?.firstName} ${
                              employeeDictionary[hazardLog.id]?.lastName
                            }`}
                          </>
                        )}
                        {!employeeDictionary[hazardLog.id] && <>Unknown</>}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.flexMid}>
                      <Typography variant="subtitle2">
                        {hazardLog.createdOn
                          ? formatDateWithDisappearingDate(hazardLog.createdOn)
                          : formatDateFromUnixMilliInLocalTz(hazardLog.updatedAt)}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.flexLarge}>
                      <Typography variant="body1">{hazardLog.comment}</Typography>
                      {groundControlSets[hazardLog.id] && (
                        <Grid className={hazardLog.comment ? classes.groundControlSet : ''}>
                          {renderGroundControlSets(hazardLog.id)}
                        </Grid>
                      )}
                    </Grid>

                    {groundHazard.remediatedOn && groundHazard.endLocation && index === 0 && (
                      <Grid item xs={12}>
                        <LocationDetailsComponent
                          groundHazard={groundHazard}
                          location={getLocation(groundHazard.endLocation)}
                          hazardLandmark={groundHazard.endHazardLandmark}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <Grid item className={classes.flexSmall}>
            <HazardPanelPhotos
              groundHazardId={groundHazard.id}
              hideAddPhotoAction={hideAddPhotoAction}
              hidePhotosBeforeDate={hideCommentsBeforeDate}
              attachmentPrefix={`Borer_${conditionType}_`}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HazardPanelContent;
