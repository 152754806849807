/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import postal from 'postal';
import type { RxDatabase, SyncOptionsGraphQL } from 'rxdb';
import { RxDatabaseBase } from 'rxdb/dist/types/rx-database';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';

import { apiConfig } from '../config/api.config';
import { BorerDatabaseCollections } from '../models/BorerDatabaseCollections';
import { SYNC_LIMIT_50, SYNC_LIMIT_HIGH, SYNC_LIMIT_LOW } from '../utilities/constants';
import { getBorerShortName } from '../utilities/utilityFunctions';
import {
  advancesPullQueryBuilder,
  advancesPushModifier,
  advancesPushQueryBuilder,
} from './Advance/queryBuilder';
import { assignmentPullQueryBuilder } from './Assignment/queryBuilder';
import { assignmentEmployeePullQueryBuilder } from './AssignmentEmployee/queryBuilder';
import { assignmentRolePullQueryBuilder } from './AssignmentRole/queryBuilder';
import { blocksPullQueryBuilder } from './Blocks/queryBuilder';
import {
  borerActivityPullQueryBuilder,
  borerActivityPushQueryBuilder,
  createBorerShiftActivityPushModifier,
} from './BorerActivity/queryBuilder';
import {
  borerActivityTypePullQueryBuilder,
  borerActivityTypePushModifier,
  borerActivityTypePushQueryBuilder,
} from './BorerActivityType/queryBuilder';
import {
  borerOperatorChangePushModifier,
  borerOperatorChangePushQueryBuilder,
} from './BorerOperatorChangeFeed/queryBuilder';
import { handleOperatorStateFeedEvents } from './BorerOperatorStateFeed/handleOperatorStateFeedEvents';
import {
  borerOperatorStatePullModifier,
  borerOperatorStatePullQueryBuilder,
} from './BorerOperatorStateFeed/queryBuilder';
import { borerShiftPullQueryBuilder, borerShiftPushQueryBuilder } from './BorerShift/queryBuilder';
import {
  borerShiftActivityEmployeePullQueryBuilder,
  borerShiftActivityEmployeePushModifier,
  borerShiftActivityEmployeePushQueryBuilder,
} from './BorerShiftActivityEmployees/queryBuilder';
import {
  borerShiftCommentPullQueryBuilder,
  borerShiftCommentPushQueryBuilder,
} from './BorerShiftComment/queryBuilder';
import { borerShiftCommentTypePullQueryBuilder } from './BorerShiftCommentType/queryBuilder';
import {
  borerShiftCrewPullModifier,
  borerShiftCrewPullQueryBuilder,
  borerShiftCrewPushModifier,
  borerShiftCrewPushQueryBuilder,
} from './BorerShiftCrew/queryBuilder';
import {
  borerShiftCrewMemberPullQueryBuilder,
  borerShiftCrewMemberPushModifier,
  borerShiftCrewMemberPushQueryBuilder,
} from './BorerShiftCrewMember/queryBuilder';
import {
  borerShiftInfoPullQueryBuilder,
  borerShiftInfoPushQueryBuilder,
} from './BorerShiftInfo/queryBuilder';
import {
  borerShiftSignaturePullQueryBuilder,
  borerShiftSignaturePushModifier,
  borerShiftSignaturePushQueryBuilder,
} from './BorerShiftSignature/queryBuilder';
import { borerStateTypeCategoryPullQueryBuilder } from './BorerStateTypeCategory/queryBuilder';
import { borerStateTypePullQueryBuilder } from './BorerStateTypeFeed/queryBuilder';
import { crewPullQueryBuilder } from './Crew/queryBuilder';
import { cuttingMethodPullQueryBuilder } from './CuttingMethod/queryBuilder';
import { cuttingTypePullQueryBuilder } from './CuttingType/queryBuilder';
import { dailySafetyTopicsPullQueryBuilder } from './DailySafetyTopic/queryBuilder';
import {
  delayActivityTypeCategoryPullQueryBuilder,
  delayActivityTypeCategoryPushModifier,
  delayActivityTypeCategoryPushQueryBuilder,
} from './DelayActivityTypeCategory/queryBuilder';
import { departmentPullQueryBuilder } from './Department/queryBuilder';
import {
  documentUploadPullModifier,
  documentUploadPullQueryBuilder,
} from './Documents/queryBuilder';
import { documentTypePullQueryBuilder } from './DocumentTypes/queryBuilder';
import { employeesPullQueryBuilder } from './Employees/queryBuilder';
import { equipmentPullQueryBuilder } from './Equipment/queryBuilder';
import {
  equipmentDeficiencyPullQueryBuilder,
  equipmentDeficiencyPushModifier,
  equipmentDeficiencyPushQueryBuilder,
} from './EquipmentDeficiency/queryBuilder';
import {
  equipmentDeficiencyAttachmentPullQueryBuilder,
  equipmentDeficiencyAttachmentPushModifier,
  equipmentDeficiencyAttachmentPushQueryBuilder,
} from './EquipmentDeficiencyAttachments/queryBuilder';
import {
  equipmentDeficiencyLogPullQueryBuilder,
  equipmentDeficiencyLogPushModifier,
  equipmentDeficiencyLogPushQueryBuilder,
} from './EquipmentDeficiencyLog/queryBuilder';
import { equipmentStatusPullQueryBuilder } from './EquipmentStatus/queryBuilder';
import { equipmentTypePullQueryBuilder } from './EquipmentType/queryBuilder';
import {
  groundControlSetPushModifier,
  groundControlSetPushQueryBuilder,
  groundControlSetsPullModifier,
  groundControlSetsPullQueryBuilder,
} from './GroundControlSet/queryBuilder';
import { groundControlTypesPullQueryBuilder } from './GroundControlTypes/queryBuilder';
import {
  groundHazardPullModifier,
  groundHazardPushModifier,
  groundHazardPushQueryBuilder,
  groundHazardsPullQueryBuilder,
} from './GroundHazard/queryBuilder';
import {
  groundHazardAttachmentPushModifier,
  groundHazardAttachmentPushQueryBuilder,
  groundHazardAttachmentsPullQueryBuilder,
} from './GroundHazardAttachment/queryBuilder';
import {
  createHazardLogPushModifier,
  hazardLogPushQueryBuilder,
  hazardLogsPullQueryBuilder,
} from './GroundHazardLog/queryBuilder';
import { handleReplicationErrors } from './handleReplicationErrors';
import { groundHazardConditionTypePullQueryBuilder } from './HazardConditionType/queryBuilder';
import { hazardSeverityPullQueryBuilder } from './HazardSeverities/queryBuilder';
import { inspectionCategoriesPullQueryBuilder } from './InspectionCategories/queryBuilder';
import { inspectionOptionsPullQueryBuilder } from './InspectionOptions/queryBuilder';
import {
  inspectionResultPullQueryBuilder,
  inspectionResultPushModifier,
  inspectionResultPushQueryBuilder,
} from './InspectionResults/queryBuilder';
import { inspectionPullQueryBuilder } from './Inspections/queryBuilder';
import { inspectionSeverityPullQueryBuilder } from './InspectionSeverityFeed/queryBuilder';
import { LiveInterval } from './LiveInterval';
import {
  createLocationPushModifier,
  locationPushQueryBuilder,
  locationsPullQueryBuilder,
} from './Locations/queryBuilder';
import { miningCutPullQueryBuilder } from './MiningCut/queryBuilder';
import { miningPatternPullQueryBuilder } from './MiningPattern/queryBuilder';
import {
  panelDrawingPullQueryBuilder,
  panelDrawingPushQueryBuilder,
} from './PanelDrawing/queryBuilder';
import {
  panelDrawingCommentPullQueryBuilder,
  panelDrawingCommentPushModifier,
  panelDrawingCommentPushQueryBuilder,
} from './PanelDrawingComment/queryBuilder';
import {
  panelLogPushModifier,
  panelLogPushQueryBuilder,
  panelLogsPullQueryBuilder,
} from './PanelLog/queryBuilder';
import { panelsPullQueryBuilder } from './Panels/queryBuilder';
import { passesPullQueryBuilder } from './Passes/queryBuilder';
import { positionPullQueryBuilder } from './Position/queryBuilder';
import {
  predictionPullModifier,
  predictionPullQueryBuilder,
  predictionPushModifier,
  predictionPushQueryBuilder,
} from './Prediction/queryBuilder';
import {
  productionPullModifier,
  productionPullQueryBuilder,
  productionPushModifier,
  productionPushQueryBuilder,
} from './Productions/queryBuilder';
import { productionTargetPullQueryBuilder } from './ProductionTarget/queryBuilder';
import { roomsPullQueryBuilder } from './Rooms/queryBuilder';
import RxdbCollectionName from './rxdbCollectionName';
import { sequencesPullQueryBuilder } from './Sequences/queryBuilder';
import { serviceStatusPullQueryBuilder } from './ServiceStatus/queryBuilder';
import { shiftPullQueryBuilder } from './Shifts/queryBuilder';
import {
  createSignaturePushModifier,
  signaturePullQueryBuilder,
  signaturePushQueryBuilder,
} from './Signature/rxdbSignatureDefinition';
import { sitePullQueryBuilder } from './Site/queryBuilder';
import {
  supplyPullModifier,
  supplyPullQueryBuilder,
  supplyPushModifier,
  supplyPushQueryBuilder,
  supplySubscriptionQuery,
  supplySubscriptionVariables,
} from './Supply/queryBuilder';
import { supplyItemPullQueryBuilder } from './SupplyItem/queryBuilder';
import { surveyPointsPullQueryBuilder } from './SurveyPoints/queryBuilder';
import { syncGraphQLWithSubscriptionFor } from './SyncHandler/SyncGraphQLWithSubscriptionFor';
import { targetBorerRunningTimesPullQueryBuilder } from './TargetBorerRunningTimes/queryBuilder';
import { workOrdersPullQueryBuilder } from './WorkOrders/queryBuilder';

export const DEBUG = localStorage.getItem('DEBUG') === 'true';
if (DEBUG) console.log('RxDB Debugging Enabled...');

export const refreshTokenOnSyncState = async (
  replicationState: RxGraphQLReplicationState<any>,
  maxTimeToExpiry = 0, // seconds
) => {
  // Get the borers name from local storage
  const shortName = getBorerShortName();

  let tokenExpired = false;
  let tokenExpiringSoon = false;
  let currentIdTokenExpiration = -1;
  const currentTimestamp = dayjs().unix();
  const collectionName = replicationState.collection.name;

  try {
    // 1. Get the id token expiration on the token in storage
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const currentIdToken = currentSession.getIdToken().getJwtToken();
    currentIdTokenExpiration = currentSession.getIdToken().getExpiration(); // seconds

    const diff = currentIdTokenExpiration - currentTimestamp; // if this is positive its still a valid token
    if (DEBUG) console.log(`Token will expire in ${diff} seconds`);

    tokenExpired = diff <= 0;
    tokenExpiringSoon = diff <= maxTimeToExpiry;

    if (!tokenExpiringSoon && DEBUG)
      console.log(
        `Token newer than max time to expire... no need to refresh (${diff}s > ${maxTimeToExpiry}s)`,
      );

    // 2. If the id token is expired, refresh the token
    if (tokenExpired || tokenExpiringSoon) {
      const refreshToken = currentSession.getRefreshToken();
      if (DEBUG) console.log(`Refresh token: ${refreshToken}`);

      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        if (err) {
          if (DEBUG) console.log(`Token refresh failed:`, err);

          if (
            err.message === 'Request timed out.' ||
            err.message?.toLowerCase().includes('network') ||
            err.message?.toLowerCase().includes('offline')
          ) {
            return;
          }

          Sentry.captureException(err, {
            tags: {
              replicationError: true,
              rxDBError: true,
              shortName,
              collectionName,
              tokenExpired,
              tokenExpiringSoon,
              refreshTokenError: true,
              currentIdTokenExpiration,
              currentTimestamp,
            },
          });
          postal.publish({
            channel: 'token',
            topic: 'token.invalid',
          });
          return;
        }

        const newIdToken = session.idToken.getJwtToken();
        if (DEBUG) console.log(`New ID token: ${newIdToken}`);

        replicationState.setHeaders({ Authorization: newIdToken });
      });
    } else {
      // 3. Token not expired but set the token on the collection headers regardless just in case the collection has a stale token
      replicationState.setHeaders({ Authorization: currentIdToken });
    }
  } catch (error) {
    console.error('Error refreshing token on collection:', replicationState.collection.name, error);

    if (
      error.message === 'Request timed out.' ||
      error.message?.toLowerCase().includes('network') ||
      err.message?.toLowerCase().includes('offline')
    ) {
      return;
    }
    Sentry.captureException(error, {
      tags: {
        replicationError: true,
        rxDBError: true,
        shortName,
        collectionName,
        tokenExpired,
        tokenExpiringSoon,
        refreshTokenError: true,
        currentIdTokenExpiration,
        currentTimestamp,
      },
    });
    postal.publish({
      channel: 'token',
      topic: 'token.invalid',
    });
  }
};

export const defaultModifier = (doc: any) => doc;

const syncGraphQLFor = (
  idToken: string,
  rxdb: RxDatabase<BorerDatabaseCollections>,
  collectionName: RxdbCollectionName,
  pullQueryBuilder?: any,
  pullModifier?: any,
  pushQueryBuilder?: any,
  pushModifier?: any,
  // Used when we absolutely need to update a document that was already deleted
  // See comments on https://github.com/Nutrien/minesight-borer-app/pull/514/
  bypassDeletedFlag = false,
  live = true,
  syncInterval = LiveInterval.SECONDS_60,
  pullBatchSize = SYNC_LIMIT_LOW,
  overrideGraphQLURL?: string,
) => {
  const config: SyncOptionsGraphQL<any> = {
    url: `${overrideGraphQLURL || apiConfig.graphUrl}?collection=${collectionName}`,
    headers: { Authorization: idToken },
    deletedFlag: bypassDeletedFlag ? 'notUsed' : 'isDeleted',
    live,
    liveInterval: syncInterval,
    retryTime: 5000, // on failed mutation retry after 5 seconds, this reduces the number of foreign key constraint errors
  };

  if (pullQueryBuilder) {
    config.pull = {
      queryBuilder: pullQueryBuilder,
      modifier: pullModifier || defaultModifier,
      batchSize: pullBatchSize,
    };
  }

  if (pushQueryBuilder) {
    config.push = {
      queryBuilder: pushQueryBuilder,
      batchSize: 1,
      modifier: pushModifier || defaultModifier,
    };
  }

  const state: RxGraphQLReplicationState<any> =
    rxdb?.collections[collectionName]?.syncGraphQL(config);

  return state;
};

export default async (
  idToken: string,
  db: RxDatabaseBase<BorerDatabaseCollections>,
  live = true,
  borerdelaysV2enabled = false,
) => {
  const syncStates: any[] = [];

  const shiftState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SHIFT,
    shiftPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (shiftState) {
    syncStates.push(shiftState);
  }

  const borerShiftState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT,
    borerShiftPullQueryBuilder,
    undefined,
    borerShiftPushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftState) {
    syncStates.push(borerShiftState);
  }

  const employeesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EMPLOYEES,
    employeesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (employeesState) {
    syncStates.push(employeesState);
  }

  const siteState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SITES,
    sitePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (siteState) {
    syncStates.push(siteState);
  }

  const blocksState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BLOCKS,
    blocksPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (blocksState) {
    syncStates.push(blocksState);
  }

  const panelsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANELS,
    panelsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (panelsState) {
    syncStates.push(panelsState);
  }

  const roomsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ROOMS,
    roomsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (roomsState) {
    syncStates.push(roomsState);
  }

  const surveyPointsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SURVEY_POINTS,
    surveyPointsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_HIGH,
  );
  if (surveyPointsState) {
    syncStates.push(surveyPointsState);
  }

  const sequencesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SEQUENCES,
    sequencesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (sequencesState) {
    syncStates.push(sequencesState);
  }

  const passState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PASSES,
    passesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (passState) {
    syncStates.push(passState);
  }

  const locationsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.LOCATIONS,
    locationsPullQueryBuilder,
    undefined,
    locationPushQueryBuilder,
    createLocationPushModifier,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_HIGH,
  );
  if (locationsState) {
    syncStates.push(locationsState);
  }

  const hazardLogsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.HAZARD_LOGS,
    hazardLogsPullQueryBuilder,
    undefined,
    hazardLogPushQueryBuilder,
    createHazardLogPushModifier,
    false,
    live,
  );
  if (hazardLogsState) {
    syncStates.push(hazardLogsState);
  }

  const groundHazardsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_HAZARDS,
    groundHazardsPullQueryBuilder,
    groundHazardPullModifier,
    groundHazardPushQueryBuilder,
    groundHazardPushModifier,
    false,
    live,
  );
  if (groundHazardsState) {
    syncStates.push(groundHazardsState);
  }

  const groundHazardConditionTypesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
    groundHazardConditionTypePullQueryBuilder,
    undefined,
    undefined,
    undefined,
    false,
    live,
  );
  if (groundHazardConditionTypesState) {
    syncStates.push(groundHazardConditionTypesState);
  }

  const groundHazardAttachmentsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
    groundHazardAttachmentsPullQueryBuilder,
    undefined,
    groundHazardAttachmentPushQueryBuilder,
    groundHazardAttachmentPushModifier,
    false,
    live,
  );
  if (groundHazardAttachmentsState) {
    syncStates.push(groundHazardAttachmentsState);
  }

  const groundControlTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_CONTROL_TYPES,
    groundControlTypesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (groundControlTypeState) {
    syncStates.push(groundControlTypeState);
  }

  const groundControlSetsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_CONTROL_SETS,
    groundControlSetsPullQueryBuilder,
    groundControlSetsPullModifier,
    groundControlSetPushQueryBuilder,
    groundControlSetPushModifier,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_HIGH,
  );
  if (groundControlSetsState) {
    syncStates.push(groundControlSetsState);
  }

  const signatureState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SIGNATURES,
    signaturePullQueryBuilder,
    undefined,
    signaturePushQueryBuilder,
    createSignaturePushModifier,
    false,
    live,
  );
  if (signatureState) {
    syncStates.push(signatureState);
  }

  const borerShiftSignatureState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
    borerShiftSignaturePullQueryBuilder,
    undefined,
    borerShiftSignaturePushQueryBuilder,
    borerShiftSignaturePushModifier,
    false,
    live,
  );

  if (borerShiftSignatureState) {
    syncStates.push(borerShiftSignatureState);
  }

  const inspectionsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTIONS,
    inspectionPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (inspectionsState) {
    syncStates.push(inspectionsState);
  }

  const inspectionCategoriesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_CATEGORIES,
    inspectionCategoriesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (inspectionCategoriesState) {
    syncStates.push(inspectionCategoriesState);
  }

  const inspectionOptionsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_OPTIONS,
    inspectionOptionsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (inspectionOptionsState) {
    syncStates.push(inspectionOptionsState);
  }

  const inspectionSeverityState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_SEVERITY,
    inspectionSeverityPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (inspectionSeverityState) {
    syncStates.push(inspectionSeverityState);
  }

  const hazardSeveritiesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.HAZARD_SEVERITIES,
    hazardSeverityPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (hazardSeveritiesState) {
    syncStates.push(hazardSeveritiesState);
  }

  const inspectionResultsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_RESULTS,
    inspectionResultPullQueryBuilder,
    undefined,
    inspectionResultPushQueryBuilder,
    inspectionResultPushModifier,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_50,
  );
  if (inspectionResultsState) {
    syncStates.push(inspectionResultsState);
  }

  const equipmentTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_TYPE,
    equipmentTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (equipmentTypeState) {
    syncStates.push(equipmentTypeState);
  }

  const equipmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT,
    equipmentPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (equipmentState) {
    syncStates.push(equipmentState);
  }

  const equipmentStatusState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_STATUS,
    equipmentStatusPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (equipmentStatusState) {
    syncStates.push(equipmentStatusState);
  }

  const equipmentDeficiencyState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_DEFICIENCY,
    equipmentDeficiencyPullQueryBuilder,
    undefined,
    equipmentDeficiencyPushQueryBuilder,
    equipmentDeficiencyPushModifier,
    false,
    live,
  );
  if (equipmentDeficiencyState) {
    syncStates.push(equipmentDeficiencyState);
  }

  const equipmentDeficiencyLogState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_LOG,
    equipmentDeficiencyLogPullQueryBuilder,
    undefined,
    equipmentDeficiencyLogPushQueryBuilder,
    equipmentDeficiencyLogPushModifier,
    false,
    live,
  );
  if (equipmentDeficiencyLogState) {
    syncStates.push(equipmentDeficiencyLogState);
  }

  const equipmentDeficiencyAttachmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT,
    equipmentDeficiencyAttachmentPullQueryBuilder,
    undefined,
    equipmentDeficiencyAttachmentPushQueryBuilder,
    equipmentDeficiencyAttachmentPushModifier,
    false,
    live,
  );
  if (equipmentDeficiencyAttachmentState) {
    syncStates.push(equipmentDeficiencyAttachmentState);
  }

  const borerActivityState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_ACTIVITY,
    borerActivityPullQueryBuilder,
    undefined,
    borerActivityPushQueryBuilder,
    createBorerShiftActivityPushModifier,
    false,
    live,
  );
  if (borerActivityState) {
    syncStates.push(borerActivityState);
  }

  const borerActivityTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_ACTIVITY_TYPE,
    borerActivityTypePullQueryBuilder,
    undefined,
    borerActivityTypePushQueryBuilder,
    borerActivityTypePushModifier,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (borerActivityTypeState) {
    syncStates.push(borerActivityTypeState);
  }

  const delayActivityTypeCategoryState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DELAY_ACTIVITY_TYPE_CATEGORY,
    delayActivityTypeCategoryPullQueryBuilder,
    undefined,
    delayActivityTypeCategoryPushQueryBuilder,
    delayActivityTypeCategoryPushModifier,
    false,
    live,
  );
  if (delayActivityTypeCategoryState) {
    syncStates.push(delayActivityTypeCategoryState);
  }

  const workOrdersState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.WORK_ORDERS,
    workOrdersPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (workOrdersState) {
    syncStates.push(workOrdersState);
  }

  const borerShiftActivityEmployeeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_ACTIVITY_EMPLOYEES,
    borerShiftActivityEmployeePullQueryBuilder,
    undefined,
    borerShiftActivityEmployeePushQueryBuilder,
    borerShiftActivityEmployeePushModifier,
    false,
    live,
  );
  if (borerShiftActivityEmployeeState) {
    syncStates.push(borerShiftActivityEmployeeState);
  }

  const borerShiftCrewState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_CREW,
    borerShiftCrewPullQueryBuilder,
    borerShiftCrewPullModifier,
    borerShiftCrewPushQueryBuilder,
    borerShiftCrewPushModifier,
    true,
    live,
  );
  if (borerShiftCrewState) {
    syncStates.push(borerShiftCrewState);
  }

  const borerShiftCrewMemberState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_CREW_MEMBER,
    borerShiftCrewMemberPullQueryBuilder,
    undefined,
    borerShiftCrewMemberPushQueryBuilder,
    borerShiftCrewMemberPushModifier,
    true,
    live,
  );
  if (borerShiftCrewMemberState) {
    syncStates.push(borerShiftCrewMemberState);
  }

  const borerShiftAdvanceState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_ADVANCE,
    advancesPullQueryBuilder,
    undefined,
    advancesPushQueryBuilder,
    advancesPushModifier,
    false,
    live,
  );
  if (borerShiftAdvanceState) {
    syncStates.push(borerShiftAdvanceState);
  }

  const borerShiftProductionState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_PRODUCTION,
    productionPullQueryBuilder,
    productionPullModifier,
    productionPushQueryBuilder,
    productionPushModifier,
    false,
    live,
  );
  if (borerShiftProductionState) {
    syncStates.push(borerShiftProductionState);
  }

  const borerShiftPredictionState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_PREDICTION,
    predictionPullQueryBuilder,
    predictionPullModifier,
    predictionPushQueryBuilder,
    predictionPushModifier,
    false,
    live,
  );
  if (borerShiftPredictionState) {
    syncStates.push(borerShiftPredictionState);
  }

  const borerShiftProductionTargetState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PRODUCTION_TARGET,
    productionTargetPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (borerShiftProductionTargetState) {
    syncStates.push(borerShiftProductionTargetState);
  }

  const miningCutState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.MINING_CUTS,
    miningCutPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (miningCutState) {
    syncStates.push(miningCutState);
  }

  const assignmentRoleState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ASSIGNMENT_ROLE,
    assignmentRolePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );

  if (assignmentRoleState) {
    syncStates.push(assignmentRoleState);
  }

  const assignmentEmployeeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ASSIGNMENT_EMPLOYEE,
    assignmentEmployeePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );

  if (assignmentEmployeeState) {
    syncStates.push(assignmentEmployeeState);
  }

  const assignmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ASSIGNMENT,
    assignmentPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );

  if (assignmentState) {
    syncStates.push(assignmentState);
  }

  const panelDrawingState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANEL_DRAWINGS,
    panelDrawingPullQueryBuilder,
    undefined,
    panelDrawingPushQueryBuilder,
    null,
    false,
    live,
  );
  if (panelDrawingState) {
    syncStates.push(panelDrawingState);
  }

  const panelDrawingCommentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANEL_DRAWING_COMMENTS,
    panelDrawingCommentPullQueryBuilder,
    undefined,
    panelDrawingCommentPushQueryBuilder,
    panelDrawingCommentPushModifier,
    false,
    live,
  );
  if (panelDrawingCommentState) {
    syncStates.push(panelDrawingCommentState);
  }

  const dailySafetyTopicsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DAILY_SAFETY_TOPIC,
    dailySafetyTopicsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (dailySafetyTopicsState) {
    syncStates.push(dailySafetyTopicsState);
  }

  const borerShiftInfoState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_INFO,
    borerShiftInfoPullQueryBuilder,
    undefined,
    borerShiftInfoPushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftInfoState) {
    syncStates.push(borerShiftInfoState);
  }

  const documentUploadState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DOCUMENT_UPLOAD,
    documentUploadPullQueryBuilder,
    documentUploadPullModifier,
    null,
    null,
    false,
    live,
  );
  if (documentUploadState) {
    syncStates.push(documentUploadState);
  }

  const documentTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DOCUMENT_TYPES,
    documentTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (documentTypeState) {
    syncStates.push(documentTypeState);
  }

  // Supply Subscription Spike
  const supplyState = syncGraphQLWithSubscriptionFor({
    idToken,
    rxdb: db,
    collectionName: RxdbCollectionName.SUPPLIES,
    pullQueryBuilder: supplyPullQueryBuilder,
    pullModifier: supplyPullModifier,
    pushQueryBuilder: supplyPushQueryBuilder,
    pushModifier: supplyPushModifier,
    subscriptionQuery: supplySubscriptionQuery,
    subscriptionVariables: supplySubscriptionVariables,
    syncInterval: LiveInterval.SECONDS_60,
  });
  if (supplyState) {
    syncStates.push(supplyState);
  }

  const supplyItemState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SUPPLY_ITEMS,
    supplyItemPullQueryBuilder,
    supplyPullModifier,
    supplyPushQueryBuilder,
    supplyPushModifier,
    false,
    live,
  );
  if (supplyItemState) {
    syncStates.push(supplyItemState);
  }

  const serviceStatusState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SERVICE_STATUS,
    serviceStatusPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (serviceStatusState) {
    syncStates.push(serviceStatusState);
  }

  const targetBorerRunningTimeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.TARGET_BORER_RUNNING_TIMES,
    targetBorerRunningTimesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (targetBorerRunningTimeState) {
    syncStates.push(targetBorerRunningTimeState);
  }

  const crewState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.CREWS,
    crewPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (crewState) {
    syncStates.push(crewState);
  }

  const positionState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.POSITIONS,
    positionPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (positionState) {
    syncStates.push(positionState);
  }

  const miningPatternState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.MINING_PATTERN,
    miningPatternPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (miningPatternState) {
    syncStates.push(miningPatternState);
  }

  const departmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DEPARTMENT,
    departmentPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (departmentState) {
    syncStates.push(departmentState);
  }

  const borerShiftCommentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_COMMENT,
    borerShiftCommentPullQueryBuilder,
    null,
    borerShiftCommentPushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftCommentState) {
    syncStates.push(borerShiftCommentState);
  }

  const borerShiftCommentTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_COMMENT_TYPE,
    borerShiftCommentTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (borerShiftCommentTypeState) {
    syncStates.push(borerShiftCommentTypeState);
  }

  const panelLogState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANEL_LOGS,
    panelLogsPullQueryBuilder,
    null,
    panelLogPushQueryBuilder,
    panelLogPushModifier,
    false,
    live,
    LiveInterval.SECONDS_15,
  );
  if (panelLogState) {
    syncStates.push(panelLogState);
  }

  if (borerdelaysV2enabled) {
    const borerOperatorStateFeedState = syncGraphQLFor(
      idToken,
      db,
      RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
      borerOperatorStatePullQueryBuilder,
      borerOperatorStatePullModifier,
      null,
      null,
      false,
      live,
      LiveInterval.SECONDS_15,
      SYNC_LIMIT_LOW,
    );
    if (borerOperatorStateFeedState) {
      syncStates.push(borerOperatorStateFeedState);
    }

    const borerStateTypeState = syncGraphQLFor(
      idToken,
      db,
      RxdbCollectionName.BORER_STATE_TYPE,
      borerStateTypePullQueryBuilder,
      null,
      null,
      null,
      false,
      live,
      LiveInterval.MINUTES_15,
      SYNC_LIMIT_LOW,
    );
    if (borerStateTypeState) {
      syncStates.push(borerStateTypeState);
    }

    const borerStateTypeCategoryState = syncGraphQLFor(
      idToken,
      db,
      RxdbCollectionName.BORER_STATE_TYPE_CATEGORY,
      borerStateTypeCategoryPullQueryBuilder,
      null,
      null,
      null,
      false,
      live,
      LiveInterval.MINUTES_30,
      SYNC_LIMIT_LOW,
    );
    if (borerStateTypeCategoryState) {
      syncStates.push(borerStateTypeCategoryState);
    }

    const cuttingTypeState = syncGraphQLFor(
      idToken,
      db,
      RxdbCollectionName.CUTTING_TYPE,
      cuttingTypePullQueryBuilder,
      null,
      null,
      null,
      false,
      live,
      LiveInterval.MINUTES_30,
      SYNC_LIMIT_LOW,
    );

    if (cuttingTypeState) {
      syncStates.push(cuttingTypeState);
    }

    const cuttingMethodState = syncGraphQLFor(
      idToken,
      db,
      RxdbCollectionName.CUTTING_METHOD,
      cuttingMethodPullQueryBuilder,
      null,
      null,
      null,
      false,
      live,
      LiveInterval.MINUTES_30,
      SYNC_LIMIT_LOW,
    );

    if (cuttingMethodState) {
      syncStates.push(cuttingMethodState);
    }

    const borerOperatorChangeState = syncGraphQLFor(
      idToken,
      db,
      RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED,
      null,
      null,
      borerOperatorChangePushQueryBuilder,
      borerOperatorChangePushModifier,
      false,
      live,
      LiveInterval.SECONDS_60,
      SYNC_LIMIT_LOW,
    );
    if (borerOperatorChangeState) {
      syncStates.push(borerOperatorChangeState);
    }

    // Special case for handling new items on the borerOperatorStateFeed
    // We toggle showInSchedulerView as false for matching changeState items
    handleOperatorStateFeedEvents(borerOperatorStateFeedState, borerOperatorChangeState, db);
  }

  syncStates.forEach(state => handleReplicationErrors(state));

  return { db, syncStates };
};
