import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface GroundHazardAttachment extends BaseEntity {
  readonly fileName: string;
  readonly groundHazardId: string;
  readonly createdBy?: string;
  readonly createdOn?: string;
}

export type GroundHazardAttachmentDocument = RxDocument<GroundHazardAttachment>;
export type GroundHazardAttachmentCollection = RxCollection<GroundHazardAttachment> | null;

const groundHazardAttachmentSchema: RxJsonSchema<GroundHazardAttachment> = {
  type: 'object',
  version: 1,
  description: 'describes a GroundHazardAttachment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    groundHazardId: { type: 'string' },
    fileName: { type: 'string' },
    createdBy: { type: 'string' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
  indexes: ['updatedAt'],
};

const groundHazardAttachmentsPullQueryBuilder = (doc: GroundHazardAttachmentDocument) => {
  if (!doc) {
    doc = {
      ...defaultDoc,
      updatedAt: dayjs().startOf('day').subtract(90, 'days').valueOf(),
    };
  }

  const query = print(gql`
    query groundHazardAttachmentFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      groundHazardAttachmentFeed(
        borerEquipmentId: $borerEquipmentId
        limit: $limit
        lastUpdateAt: $lastUpdateAt
      ) {
        id
        fileName
        updatedAt
        isDeleted
        version
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

const groundHazardAttachmentPushQueryBuilder = (doc: GroundHazardAttachmentDocument) => {
  doc = doc[0];

  const query = print(gql`
    mutation CreateGroundHazardAttachment(
      $groundHazardAttachment: CreateGroundHazardAttachmentInput
    ) {
      createGroundHazardAttachment(input: $groundHazardAttachment)
    }
  `);

  const variables = {
    groundHazardAttachment: doc,
  };

  return {
    query,
    variables,
  };
};

const groundHazardAttachmentPushModifier = (doc: GroundHazardAttachmentDocument) => {
  const obj = {
    id: doc.id,
    fileName: doc.fileName,
    groundHazardId: doc.groundHazardId,
    createdOn: dayjs(doc.updatedAt).toISOString(),
    uploadedById: doc.createdBy,
    updateLastViewed: false,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

const groundHazardAttachmentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: GroundHazardAttachmentDocument) => ({
    ...oldDoc,
  }),
};
export {
  groundHazardAttachmentSchema,
  groundHazardAttachmentsPullQueryBuilder,
  groundHazardAttachmentPushQueryBuilder,
  groundHazardAttachmentPushModifier,
  groundHazardAttachmentMigrationStrategies,
};
