import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { sortDescriptionWithNumberStrings } from '../../utilities/sortHelper';
import { useRooms } from '../Rooms/useRooms';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { SurveyPointDocument, SurveyPointsCollection } from './queryBuilder';

export const useSurveyPoints = (panelId?: string, roomId?: string) => {
  const surveyPointsCollection: SurveyPointsCollection = useRxCollection(
    RxdbCollectionName.SURVEY_POINTS,
  );

  // Specifically for Vanscoy Chevron, where each panel has 1 room and room contains all survey points
  const [surveyPointsForPanel, setSurveyPointsForPanel] = useState<SurveyPointDocument[]>([]);
  const [surveyPointsForRoom, setSurveyPointsForRoom] = useState<SurveyPointDocument[]>([]);

  const [surveyPointsForPanelLoaded, setSurveyPointsForPanelLoaded] = useState(false);
  const [surveyPointsForRoomLoaded, setSurveyPointsForRoomLoaded] = useState(false);
  const { getRoomForPanel } = useRooms();

  const getSurveyPointsForRoom = async (roomIdentifier: string) => {
    if (!surveyPointsCollection) return [];

    let surveyPoints: SurveyPointDocument[] = [];

    try {
      surveyPoints = await surveyPointsCollection.find().where('room').eq(roomIdentifier).exec();
    } catch (error) {
      console.error('🚀 ~ file: usePanels.ts ~ line 24 ~ getRoomOptions ~ error', error);
    }

    return surveyPoints.sort((a, b) =>
      a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
    );
  };

  useEffect(() => {
    const getSurveyPointsForPanel = async () => {
      setSurveyPointsForPanelLoaded(false);
      if (!panelId) {
        setSurveyPointsForPanel([]);
        setSurveyPointsForPanelLoaded(true);
        return;
      }

      const room = await getRoomForPanel(panelId);

      if (room?.id && surveyPointsCollection) {
        const surveyPoints = await getSurveyPointsForRoom(room?.id);
        setSurveyPointsForPanel(surveyPoints.sort(sortDescriptionWithNumberStrings));
      } else {
        setSurveyPointsForPanel([]);
      }
      setSurveyPointsForPanelLoaded(true);
    };

    getSurveyPointsForPanel();
  }, [panelId, surveyPointsCollection]);

  useEffect(() => {
    const getSurveyPointsForRoomId = async () => {
      setSurveyPointsForRoomLoaded(false);
      if (!roomId) {
        setSurveyPointsForRoom([]);
        setSurveyPointsForRoomLoaded(true);
        return;
      }

      if (surveyPointsCollection) {
        const surveyPoints = await getSurveyPointsForRoom(roomId);
        setSurveyPointsForRoom(surveyPoints.sort(sortDescriptionWithNumberStrings));
      } else {
        setSurveyPointsForRoom([]);
      }
      setSurveyPointsForRoomLoaded(true);
    };

    getSurveyPointsForRoomId();
  }, [roomId, surveyPointsCollection]);

  return {
    getSurveyPointsForRoom,
    surveyPointsForPanel,
    surveyPointsForPanelLoaded,
    surveyPointsForRoom,
    surveyPointsForRoomLoaded,
  };
};

export default useSurveyPoints;
