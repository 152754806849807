import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';

import { useMst } from '../../mobx-models/Root';
import { useOnlineStatus } from '../../utilities';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';

interface BorerHeartbeatEvent {
  email: string;
  appVersion: string;
  ipadOSVersion: string;
  serviceWorkerStatus: string;
  userAgent: string;
  isOnline: boolean;
  lastUserInteractionTime: string;
  loginTime: string;
  logoutTime: string;
}

const useBorerHeartbeat = () => {
  const { trackEvent } = useEventTracking();
  const { appVersion } = useMst();
  const isOnline = useOnlineStatus();

  // Create event tracking for borer heartbeat
  const getiOSVersion = () => {
    const splits = navigator.userAgent.split('Version/');

    if (splits.length > 1) return splits[1].split(' ')[0];
    else return 'NA';
  };

  const getServerWorkerStatus = async () => {
    // determine service worker state
    try {
      const registrations = await navigator.serviceWorker?.getRegistrations();

      if (registrations?.length > 0) {
        const registration = registrations[0];

        if (registration.waiting) {
          return 'waiting';
        } else if (registration.installing) {
          return 'installing';
        } else if (registration.active) {
          return 'active';
        }
      }
    } catch (error) {
      console.log('🚀 ~ file: BorerHeartbeat.tsx:35 ~ sendBorerHeartbeat ~ error', error);
    }

    return 'NA';
  };

  const sendBorerHeartbeat = async ({
    lastUserInteractionTime = dayjs(),
    loginTime,
    logoutTime,
  }: {
    lastUserInteractionTime?: dayjs.Dayjs;
    loginTime?: dayjs.Dayjs;
    logoutTime?: dayjs.Dayjs;
  }) => {
    const userDetails = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const serviceWorkerStatus = await getServerWorkerStatus();

    const heartBeatEvent: BorerHeartbeatEvent = {
      email: userDetails.attributes?.email || 'NA',
      appVersion: appVersion.currentAppVersion,
      ipadOSVersion: getiOSVersion(),
      serviceWorkerStatus,
      userAgent: navigator.userAgent,
      isOnline,
      lastUserInteractionTime: lastUserInteractionTime.toISOString(),
      loginTime: loginTime?.format() || '',
      logoutTime: logoutTime?.format() || '',
    };

    await trackEvent(TrackingEventType.BORER_HEART_BEAT, heartBeatEvent);
  };

  return { sendBorerHeartbeat };
};

export { useBorerHeartbeat };
