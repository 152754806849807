import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface EquipmentType extends BaseEntity {
  readonly description: string;
}
export type EquipmentTypeCollection = RxCollection<EquipmentType> | null;
export type EquipmentTypeDocument = RxDocument<EquipmentType>;

export const equipmentTypeSchema: RxJsonSchema<EquipmentType> = {
  type: 'object',
  version: 0,
  description: 'describes a EquipmentType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const equipmentTypePullQueryBuilder = (doc: EquipmentTypeDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query equipmentTypeFeed($lastUpdateAt: Float!, $limit: Int!) {
      equipmentTypeFeed(lastUpdateAt: $lastUpdateAt, limit: $limit) {
        id
        description
        isDeleted
        updatedAt
        version
      }
    }
  `);
  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
