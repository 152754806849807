import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { AutoComplete, Card, i18n, TextField, Typography } from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import { TWO_DECIMALS_OR_LESS_REGEX } from '@/utilities/constants';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftInfo } from '../../rxdb/BorerShiftInfo/queryBuilder';
import useBorerShiftInfo from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import { ServiceStatus } from '../../rxdb/ServiceStatus/queryBuilder';
import useSite from '../../rxdb/Site/useSite';
import { useNotification } from '../../utilities';
import { isNumeric } from '../../utilities/mathHelper';
import GenericSidePanel from '../GenericSidePanel';

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
}

const EditShiftInfoSidePanel = ({ open, onClose, onOpen, onCancel }: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const {
    borerShiftInfoInitialized,
    rotorBits,
    trimBits,
    cornerBits,
    belt,
    cable,
    shearPins,
    setBorerShiftInfo,
    getBorerShiftInfo,
    serviceStatusList,
    serviceStatusId,
    bottomChainShearPinsReplaced,
    topChainShearPinsReplaced,
    cableSlackEnd,
    oreLineHeight,
    breakthroughFaceFootage,
    oreGrade,
  } = useBorerShiftInfo(shiftPicker.currentBorerShiftId);
  const { miningMethod, isSandvikBorer } = useCurrentBorer();
  const { isVanscoy, isAllan, isRocanville } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();
  const showBits = !isRocanville;

  const { successNotification } = useNotification();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    rotorBits: '',
    trimBits: '',
    cornerBits: '',
    belt: '',
    cable: '',
    shearPins: '',
    bottomChainShearPins: '',
    topChainShearPins: '',
    oreLineHeight: '',
    cableSlackEnd: '',
    breakthroughFaceFootage: '',
    oreGrade: '',
  });

  const [rotorBitsValue, setRotorBitsValue] = useState<string>('');
  const [trimBitsValue, setTrimBitsValue] = useState<string>('');
  const [cornerBitsValue, setCornerBitsValue] = useState<string>('');
  const [beltValue, setBeltValue] = useState<string>('');
  const [cableValue, setCableValue] = useState<string>('');
  const [shearPinsValue, setShearPinsValue] = useState<string>('');
  const [serviceStatus, setServiceStatus] = useState<ServiceStatus | null>(null);
  const [bottomChainShearPinsValue, setBottomChainShearPinsValue] = useState<string>('');
  const [topChainShearPinsValue, setTopChainShearPinsValue] = useState<string>('');
  const [oreLineHeightValue, setOreLineHeightValue] = useState<string>('');
  const [cableSlackEndValue, setCableSlackEndValue] = useState<string>('');
  const [breakthroughFaceFootageValue, setBreakthroughFaceFootageValue] = useState<string>('');
  const [oreGradeValue, setOreGradeValue] = useState<string>('');

  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  const prefillValues = () => {
    if (rotorBits || rotorBits === 0) setRotorBitsValue(rotorBits.toString());
    if (!rotorBits) setRotorBitsValue('0');
    if (trimBits || trimBits === 0) setTrimBitsValue(trimBits.toString());
    if (!trimBits) setTrimBitsValue('0');
    if (cornerBits || cornerBits === 0) setCornerBitsValue(cornerBits.toString());
    if (!cornerBits) setCornerBitsValue('0');
    if (belt || belt === 0) setBeltValue(belt.toString());
    if (cable || cable === 0) setCableValue(cable.toString());
    if (shearPins || shearPins === 0) setShearPinsValue(shearPins.toString());
    const selectedServiceList = serviceStatusList.filter(status => {
      return status.id === serviceStatusId;
    });
    if (selectedServiceList.length) {
      setServiceStatus(selectedServiceList[0]);
    } else {
      setServiceStatus(null);
    }
    if (topChainShearPinsReplaced || topChainShearPinsReplaced === 0)
      setTopChainShearPinsValue(topChainShearPinsReplaced.toString());
    if (bottomChainShearPinsReplaced || bottomChainShearPinsReplaced === 0)
      setBottomChainShearPinsValue(bottomChainShearPinsReplaced.toString());
    if (oreLineHeight || oreLineHeight === 0) setOreLineHeightValue(oreLineHeight.toString());
    if (cableSlackEnd) setCableSlackEndValue(cableSlackEnd.toString());
    if (breakthroughFaceFootage)
      setBreakthroughFaceFootageValue(breakthroughFaceFootage.toString());
    if (oreGrade || oreGrade === 0) setOreGradeValue(oreGrade.toString());
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setErrors({
        rotorBits: '',
        trimBits: '',
        cornerBits: '',
        belt: '',
        cable: '',
        shearPins: '',
        bottomChainShearPins: '',
        topChainShearPins: '',
        breakthroughFaceFootage: '',
        oreLineHeight: '',
        cableSlackEnd: '',
        oreGrade: '',
      });
      setHasEdits(false);
      setCanSave(false);
      setIsSaving(false);
      setHasEdits(false);

      prefillValues();
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open, borerShiftInfoInitialized]);

  // Input Validation
  const validateSave = useCallback(() => {
    let validSave = true;

    if (Object.values(errors).find(error => error !== '')) {
      validSave = false;
    }

    setCanSave(validSave && hasEdits);
  }, [errors]);

  useEffect(() => {
    validateSave();
  }, [validateSave]);

  const onValueChanged = (
    value: string,
    setFunction: React.Dispatch<React.SetStateAction<string>>,
    errorKey: keyof BorerShiftInfo,
  ) => {
    setFunction(value);
    setHasEdits(true);
    if (
      !RegExp('^[0-9]+$').test(value) &&
      errorKey !== 'serviceStatusId' &&
      errorKey !== 'breakthroughFaceFootage' &&
      errorKey !== 'cableSlackEnd' &&
      errorKey !== 'oreLineHeight' &&
      errorKey !== 'oreGrade'
    ) {
      setErrors(prev => ({
        ...prev,
        [errorKey]: i18n.t('Invalid value.'),
      }));
    } else if (errorKey === 'shearPins' && Number(value) > 1000) {
      setErrors(prev => ({ ...prev, shearPins: 'Must be between 0 and 1000' }));
    } else if (
      (errorKey === 'oreLineHeight' || errorKey === 'oreGrade') &&
      !Number(value) &&
      Number(value) !== 0
    ) {
      setErrors(prev => ({ ...prev, [errorKey]: 'Invalid value.' }));
    } else if (
      (errorKey === 'oreLineHeight' || errorKey === 'oreGrade') &&
      !TWO_DECIMALS_OR_LESS_REGEX.test(value)
    ) {
      setErrors(prev => ({ ...prev, [errorKey]: 'Must be 2 or less decimals' }));
    } else if ((errorKey === 'oreGrade' && Number(value) > 100) || Number(value) < 0) {
      setErrors(prev => ({ ...prev, [errorKey]: 'Must be within 0 - 100%' }));
    } else if (
      (errorKey === 'cableSlackEnd' || errorKey === 'breakthroughFaceFootage') &&
      value.length > 25
    ) {
      setErrors(prev => ({ ...prev, [errorKey]: 'Must be 25 characters or less' }));
    } else {
      setErrors(prev => ({ ...prev, [errorKey]: '' }));
    }
  };

  // Save
  const onSave = async () => {
    if (!shiftPicker.currentBorerShiftId) return;

    setIsSaving(true);

    const shearPinsNumber = isNumeric(shearPinsValue) ? parseInt(shearPinsValue, 10) : undefined;

    // update the Borer Shift Info
    try {
      await setBorerShiftInfo(
        parseInt(rotorBitsValue, 10),
        parseInt(trimBitsValue, 10),
        parseInt(cornerBitsValue, 10),
        Number(oreLineHeightValue),
        cableSlackEndValue,
        breakthroughFaceFootageValue,
        Number(oreGradeValue),
        parseInt(beltValue, 10),
        parseInt(cableValue, 10),
        shearPinsNumber,
        serviceStatus?.id,
        parseInt(bottomChainShearPinsValue, 10),
        parseInt(topChainShearPinsValue, 10),
      );

      getBorerShiftInfo(shiftPicker.currentBorerShiftId);

      successNotification('Info saved');
      onClose();
    } catch (error) {
      console.log('🚀 ~ file: EditShiftInfoSidePanel.tsx ~ line 143 ~ onSave ~ error', error);

      setIsSaving(false);
    }
  };

  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={i18n.t('Edit info')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Info draft discarded"
      >
        <Card className={classes.cardRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">{i18n.t('Info')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                label={`${translate('Borer status')}`}
                autoSelect={false}
                autoHighlight={false}
                list={serviceStatusList}
                onChange={(event, value) =>
                  onValueChanged(value, setServiceStatus, 'serviceStatusId')
                }
                value={serviceStatus}
                getOptionLabel={status => {
                  if (status?.description) {
                    return `${status?.description}`;
                  }
                  if (!status) {
                    return '';
                  }
                  return status;
                }}
                id="serviceStatus"
                css={undefined}
                required
              />
            </Grid>
            {showBits && (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Rotor bits')}
                    onChange={event =>
                      onValueChanged(event.target.value, setRotorBitsValue, 'rotorBits')
                    }
                    value={rotorBitsValue}
                    data-cy="RotorBitsInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.rotorBits !== ''}
                    errorText={errors.rotorBits}
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={isSandvikBorer ? i18n.t('Drum bits') : i18n.t('Trim bits')}
                    onChange={event =>
                      onValueChanged(event.target.value, setTrimBitsValue, 'trimBits')
                    }
                    value={trimBitsValue}
                    data-cy="TrimBitsInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.trimBits !== ''}
                    errorText={errors.trimBits}
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Corner bits')}
                    onChange={event =>
                      onValueChanged(event.target.value, setCornerBitsValue, 'cornerBits')
                    }
                    value={cornerBitsValue}
                    data-cy="CornerBitsInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.cornerBits !== ''}
                    errorText={errors.cornerBits}
                    css={undefined}
                  />
                </Grid>
              </>
            )}
            {isVanscoy && (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Shear Pins')}
                    onChange={event =>
                      onValueChanged(event.target.value, setShearPinsValue, 'shearPins')
                    }
                    value={shearPinsValue}
                    data-cy="ShearPinInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.shearPins !== ''}
                    errorText={errors.shearPins}
                    css={undefined}
                  />
                </Grid>
              </>
            )}
            {isAllan && (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Bottom chain shear pins replaced')}
                    onChange={event =>
                      onValueChanged(
                        event.target.value,
                        setBottomChainShearPinsValue,
                        'bottomChainShearPins',
                      )
                    }
                    value={bottomChainShearPinsValue}
                    data-cy="BottomChainShearPinsInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.bottomChainShearPins !== ''}
                    errorText={errors.bottomChainShearPins}
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Top chain shear pins replaced')}
                    onChange={event =>
                      onValueChanged(
                        event.target.value,
                        setTopChainShearPinsValue,
                        'topChainShearPins',
                      )
                    }
                    value={topChainShearPinsValue}
                    data-cy="TopChainShearPinsInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.topChainShearPins !== ''}
                    errorText={errors.topChainShearPins}
                    css={undefined}
                  />
                </Grid>
              </>
            )}
            {(isRocanville || miningMethod === 'Long Room') && (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Belt')}
                    onChange={event => onValueChanged(event.target.value, setBeltValue, 'belt')}
                    value={beltValue}
                    data-cy="BeltInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.belt !== ''}
                    errorText={errors.belt}
                    unitText={isVanscoy ? undefined : distanceUnitAbbreviation || ''}
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Cable')}
                    onChange={event => onValueChanged(event.target.value, setCableValue, 'cable')}
                    value={cableValue}
                    data-cy="CableInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.cable !== ''}
                    errorText={errors.cable}
                    unitText={isVanscoy ? undefined : distanceUnitAbbreviation || ''}
                    css={undefined}
                  />
                </Grid>
              </>
            )}
            {isRocanville && (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Ore line height')}
                    onChange={event =>
                      onValueChanged(event.target.value, setOreLineHeightValue, 'oreLineHeight')
                    }
                    value={oreLineHeightValue}
                    data-cy="OreLineHeightInput"
                    inputProps={{ inputMode: 'decimal' }}
                    error={errors.oreLineHeight !== ''}
                    errorText={errors.oreLineHeight}
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Grade')}
                    onChange={event =>
                      onValueChanged(event.target.value, setOreGradeValue, 'oreGrade')
                    }
                    value={oreGradeValue}
                    data-cy="OreGradeInput"
                    inputProps={{ inputMode: 'numeric' }}
                    error={errors.oreGrade !== ''}
                    errorText={errors.oreGrade}
                    unitText="%"
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('BT footage (face) (optional)')}
                    onChange={event =>
                      onValueChanged(
                        event.target.value,
                        setBreakthroughFaceFootageValue,
                        'breakthroughFaceFootage',
                      )
                    }
                    value={breakthroughFaceFootageValue}
                    data-cy="BreakthroughFaceFootageInput"
                    error={errors.breakthroughFaceFootage !== ''}
                    errorText={errors.breakthroughFaceFootage}
                    css={undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label={i18n.t('Cable slack end (optional)')}
                    onChange={event =>
                      onValueChanged(event.target.value, setCableSlackEndValue, 'cableSlackEnd')
                    }
                    value={cableSlackEndValue}
                    data-cy="CableSlackEndInput"
                    error={errors.cableSlackEnd !== ''}
                    errorText={errors.cableSlackEnd}
                    css={undefined}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </GenericSidePanel>
    </>
  );
};

export default observer(EditShiftInfoSidePanel);
