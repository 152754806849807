import { useMemo } from 'react';
import { useRxData } from 'rxdb-hooks';

import RxdbCollectionName from '../rxdbCollectionName';
import { AdvanceDocument } from './queryBuilder';

export interface AugmentedAdvance {
  borerShiftAdvanceId: string;
  locationString: string;
  distanceString: string;
}

export const useAdvanceIdArray = (
  advanceIdArray: string[],
): {
  advances: AdvanceDocument[];
  calculatedFootage: number;
  calculatedNumberOfBuckets: number;
} => {
  const { result: advanceDocuments } = useRxData<AdvanceDocument>(
    RxdbCollectionName.BORER_SHIFT_ADVANCE,
    collection =>
      collection.find({
        selector: {
          id: {
            $in: advanceIdArray,
          },
        },
      }),
  );

  const calculatedFootage = useMemo(() => {
    return advanceDocuments?.reduce((total, advance) => {
      return total + (advance.distance ? advance.distance : 0);
    }, 0);
  }, [advanceDocuments]);

  const calculatedNumberOfBuckets = useMemo(() => {
    return advanceDocuments?.reduce((total, advance) => {
      return total + (advance.numberOfBuckets ? advance.numberOfBuckets : 0);
    }, 0);
  }, [advanceDocuments]);
  return {
    advances: advanceDocuments,
    calculatedFootage,
    calculatedNumberOfBuckets,
  };
};
