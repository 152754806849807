import { CircularProgress, Grid } from '@material-ui/core';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { usePanelLog } from '@/rxdb/PanelLog/usePanelLog';

import RoomConditionCommentEditDrawer from '../RoomConditionCommentEditDrawer';
import RoomConditionCommentTable from '../RoomConditionCommentsTable/RoomConditionCommentsTable';
import { useStyles } from './RoomConditionCommentsCard.styles';

const RoomConditionCommentsCard: FC = () => {
  const classes = useStyles();

  const { currentBorer, recentBorerLocation } = useCurrentBorer();
  const { panelLogsForBorerEquipmentId, panelLogsFetching, createPanelLog } = usePanelLog(
    currentBorer?.id,
  );

  const panelLogsFilteredByCurrentPanel = useMemo(() => {
    if (recentBorerLocation?.panel) {
      return panelLogsForBorerEquipmentId.filter(
        panelLog => panelLog.panelId === recentBorerLocation?.panel?.id,
      );
    }
    return [...panelLogsForBorerEquipmentId];
  }, [recentBorerLocation, panelLogsForBorerEquipmentId]);

  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Card elevation={2} className={classes.subCard}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography
              variant="h5"
              className={classes.type}
              data-testid="room-condition-comments-card-header"
            >
              {i18n.t('Room Condition Comments')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
              noMinHeight
              id="cancel-button"
              startAdornment={<Icons.PlusFeather color="primary" />}
              data-testid="room-condition-comments-card-add-button"
            >
              {i18n.t('Add room condition comment')}
            </Button>
          </Grid>
        </Grid>
        {!panelLogsFetching ? (
          <RoomConditionCommentTable data={panelLogsFilteredByCurrentPanel} />
        ) : (
          <Grid container justify="space-around">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Card>
      <RoomConditionCommentEditDrawer
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        onCancel={onCancel}
        createPanelLog={createPanelLog}
      />
    </>
  );
};

export default RoomConditionCommentsCard;
