import type { Theme } from '@material-ui/core';
import { createStyles, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CustomPalette, i18n, SidePanel } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import { v4 as uuidv4 } from 'uuid';

import useSite from '@/rxdb/Site/useSite';

import { useMst } from '../../mobx-models/Root';
import { BlockDocument } from '../../rxdb/Blocks/queryBuilder';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import { useEmployees } from '../../rxdb/Employees/useEmployees';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import {
  GroundControlSet,
  GroundControlSetCollection,
} from '../../rxdb/GroundControlSet/queryBuilder';
import { GroundHazardCollection, GroundHazardDocument } from '../../rxdb/GroundHazard/queryBuilder';
import {
  GroundHazardAttachment,
  GroundHazardAttachmentCollection,
} from '../../rxdb/GroundHazardAttachment/queryBuilder';
import { HazardLog, HazardLogCollection } from '../../rxdb/GroundHazardLog/queryBuilder';
import {
  GroundHazardConditionType,
  GroundHazardConditionTypeCollection,
} from '../../rxdb/HazardConditionType/queryBuilder';
import { LocationCollection, LocationDocument } from '../../rxdb/Locations/queryBuilder';
import useLocations, { PopulatedLocation } from '../../rxdb/Locations/useLocations';
import { PanelDocument } from '../../rxdb/Panels/queryBuilder';
import usePanels from '../../rxdb/Panels/usePanels';
import { PassDocument } from '../../rxdb/Passes/queryBuilder';
import usePasses from '../../rxdb/Passes/usePasses';
import useProduction from '../../rxdb/Productions/useProduction';
import { RoomDocument } from '../../rxdb/Rooms/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import {
  generateBaseEntity,
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../../rxdb/rxdbUtilityFunctions';
import { SequenceDocument } from '../../rxdb/Sequences/queryBuilder';
import useSequences from '../../rxdb/Sequences/useSequences';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import useSurveyPoints from '../../rxdb/SurveyPoints/useSurveyPoints';
import { HazardPanelTypes, useDateFormatters, useNotification } from '../../utilities';
import { FEET_PER_METERS, MiningMethod } from '../../utilities/constants';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';
import useFileStorage from '../../utilities/useFileStorage';
import {
  convertListOfFilesAndFileListsToArray,
  formatFileNamesToBeUnique,
  getFormattedImages,
} from '../../utilities/utilityFunctions';
import AddCommentDetailsPanel from '../AddCommentDetailsPanel';
import DiscardDraftModal from '../DiscardDraftModal';
import GenericSidePanelHeader from '../GenericSidePanelHeader';
import GroundControlExpansionPanel from '../GroundControl/GroundControlExpansionPanel';
import HazardPhotosPanel from '../HazardPhotosPanel/HazardPhotosPanel';
import HazardStatusPanel from '../HazardStatusPanel';
import LocationDetailsPanel from '../LocationDetailsPanel';

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '16px',
      width: '400px',
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
    dialogActions: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      backgroundColor: CustomPalette.elevation.dp4Solid,
    },
  }),
);

interface Props {
  open: boolean;
  hazardConditionType?: GroundHazardConditionType;
  onClose: () => void;
  onOpen: () => void;
  onNewHazardCreated?: (hazard: GroundHazardDocument) => void;
  onCancel?: () => void;
  inspectionId?: string | null;
  forceRemediatedHazard?: boolean;
}

const AddHazardsSidePanel = ({
  open,
  hazardConditionType,
  onClose,
  onOpen,
  onNewHazardCreated,
  onCancel,
  inspectionId,
  forceRemediatedHazard,
}: Props) => {
  const { uploadImage } = useFileStorage();
  const classes = useStyles();
  const { successNotification, errorNotification, closeSnackbar } = useNotification();
  const { equipment, shiftPicker } = useMst();
  const { formatDateToUnix } = useDateFormatters();
  const { getRoomOptions } = usePanels();
  const { getSurveyPointsForRoom } = useSurveyPoints();
  const { getSequencesForMiningMethod } = useSequences();
  const { getPassesForMiningMethod } = usePasses();
  const { currentBlock, currentPanel, currentRoom, currentSurveyPoint } = useCurrentBorer();
  const { getMostRecentProductionForShiftId } = useProduction();
  const { getLocationById } = useLocations();
  const { trackEvent } = useEventTracking();
  const { isLanigan, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const { useMeters } = useSite();

  // RXDB - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // collections
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );
  const locationCollection: LocationCollection = useRxCollection(RxdbCollectionName.LOCATIONS);
  const hazardLogCollection: HazardLogCollection = useRxCollection(RxdbCollectionName.HAZARD_LOGS);
  const attachmentCollection: GroundHazardAttachmentCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
  );
  const groundControlSetCollection: GroundControlSetCollection = useRxCollection(
    RxdbCollectionName.GROUND_CONTROL_SETS,
  );

  const { employeesList } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: false,
  });

  const conditionTypesQueryConstructor = useCallback(
    (collection: GroundHazardConditionTypeCollection) => {
      return collection.find({
        selector: {
          $and: [{ isSingleEntry: { $eq: true } }, { isActive: { $eq: !hazardConditionType } }],
        },
      });
    },
    [],
  );

  const { result: conditionTypes }: { result: GroundHazardConditionType[] } = useRxData(
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
    conditionTypesQueryConstructor,
  );

  const [expandedPanelId, setExpandedPanel] = useState<HazardPanelTypes | undefined>(
    HazardPanelTypes.DETAILS,
  );
  const [validationIndex, setValidationIndex] = useState(0);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);

  // Hazard Details
  const [selectedConditionType, setSelectedConditionType] = useState<GroundHazardConditionType>({});
  const [selectedUser, setSelectedUser] = useState<Employee>({});
  const [comment, setComment] = useState<string>('');

  // Ground Control
  const [selectedGroundControls, setSelectedGroundControls] = useState<any[]>([]);
  const [groundControlErrors, setGroundControlErrors] = useState<boolean>(false);

  // Photo Details
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  // Start Location data
  const [block, setBlock] = useState<BlockDocument>();
  const [panel, setPanel] = useState<PanelDocument>();
  const [roomOptions, setRoomOptions] = useState<PopulatedLocation[]>([]);
  const [room, setRoom] = useState<RoomDocument>();

  const [surveyPoints, setSurveyPoints] = useState<SurveyPointDocument[]>([]);
  const [surveyPoint, setSurveyPoint] = useState<SurveyPointDocument>();
  const [sequences, setSequences] = useState<SequenceDocument[]>();
  const [sequence, setSequence] = useState<SequenceDocument>();
  const [passes, setPasses] = useState<PassDocument[]>();
  const [pass, setPass] = useState<PassDocument>();
  const [startDistance, setStartDistance] = useState<string>('');
  const [startDistanceErrorText, setStartDistanceErrorText] = useState<string>('');
  const [startHazardLandmark, setStartHazardLandmark] = useState<string>('');

  // End Location data
  const [endBlock, setEndBlock] = useState<BlockDocument>();
  const [endPanel, setEndPanel] = useState<PanelDocument>();
  const [endRoom, setEndRoom] = useState<RoomDocument>();
  const [endSurveyPoints, setEndSurveyPoints] = useState<SurveyPointDocument[]>([]);
  const [endSurveyPoint, setEndSurveyPoint] = useState<SurveyPointDocument | null>();
  const [endSequences, setEndSequences] = useState<SequenceDocument[]>();
  const [endSequence, setEndSequence] = useState<SequenceDocument | null>();
  const [endPasses, setEndPasses] = useState<PassDocument[]>();
  const [endPass, setEndPass] = useState<PassDocument | null>();
  const [endDistance, setEndDistance] = useState<string>('');
  const [endDistanceErrorText, setEndDistanceErrorText] = useState<string>('');
  const [isFullyRemediated, setIsFullyRemediated] = useState<boolean>(false);
  const [reportEndLocation, setReportEndLocation] = useState<boolean>(false);
  const [endHazardLandmark, setEndHazardLandmark] = useState<string>('');

  const prefillLocationFromAdvance = async () => {
    if (!shiftPicker || !shiftPicker.currentBorerShiftId) return;

    const production = await getMostRecentProductionForShiftId(shiftPicker.currentBorerShiftId);

    if (!production) {
      prePopulateFromBorerLocation();
      return;
    }

    const location: LocationDocument | null | undefined = await getLocationById(
      production.locationId,
    );

    if (!location) return;
    const advancePanel = await location.populate('panel');
    const advanceBlock = await advancePanel.populate('block');
    const advanceRoom = await location.populate('room');
    const advanceSurveyPoint = await location.populate('surveyPoint');
    const advanceSequence = await location.populate('sequence');
    const advancePass = await location.populate('pass');

    if (advancePanel && advanceRoom && advanceBlock)
      onRoomChanged({
        block: advanceBlock,
        panel: advancePanel,
        room: advanceRoom,
      });
    if (advanceSequence) onSurveyPointChanged(advanceSurveyPoint);
    if (advanceSequence) onSequenceChanged(advanceSequence);
    if (advancePass) onPassChanged(advancePass);
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setSelectedConditionType(hazardConditionType || '');
      setSelectedUser('');
      setComment('');
      setRoom(undefined);
      setPass(undefined);
      setSurveyPoint(undefined);
      setSurveyPoints([]);
      setSequence(undefined);
      setStartDistance('');
      setStartDistanceErrorText('');
      setEndRoom(undefined);
      setEndPass(undefined);
      setEndSurveyPoint(undefined);
      setEndSequence(undefined);
      setEndDistance('');
      setEndDistanceErrorText('');
      setExpandedPanel(HazardPanelTypes.DETAILS);
      setIsFullyRemediated(forceRemediatedHazard || false);
      setReportEndLocation(false);
      setSelectedImages([]);
      setHasEdits(false);
      setSelectedGroundControls([]);
      prefillLocationFromAdvance();
      setValidationIndex(0);
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open]);

  const saveGroundControl = async (groundControlSet: any, groundHazardId: string) => {
    const quantity =
      groundControlSet.quantity && groundControlSet.quantity !== ''
        ? parseInt(groundControlSet.quantity)
        : 1;

    const newGroundControlSet: GroundControlSet = {
      id: uuidv4(),
      type: groundControlSet.typeId,
      quantity,
      optionValues: groundControlSet.optionValues,
      updatedAt: getUnixMillisecondTimestamp(),
      groundHazardId,
    };

    if (groundControlSetCollection !== null) {
      await groundControlSetCollection.insert(newGroundControlSet);
    }

    return newGroundControlSet.id;
  };

  const saveComment = async (hazard: GroundHazardDocument, groundControlSets: string[]): string => {
    try {
      const hazardLog: HazardLog = {
        ...generateBaseEntityWithCreatedOn(),
        groundHazard: hazard.id,
        reportedBy: selectedUser.id,
        comment,
        groundControlSets,
      };
      if (hazardLogCollection !== null) {
        await hazardLogCollection.insert(hazardLog);
      }
      return hazardLog.id;
    } catch (error) {
      console.log('🚀 ~ file: AddHazardsSidePanel.tsx ~ line 327 ~ saveComment ~ error', error);
    }
  };

  const createAttachment = async (hazardId: string, fileName: string) => {
    const attachment: GroundHazardAttachment = {
      id: uuidv4(),
      fileName,
      groundHazardId: hazardId,
      createdBy: selectedUser.id,
      isDeleted: false,
      updatedAt: getUnixMillisecondTimestamp(),
      version: 1,
    };
    if (attachmentCollection) {
      await attachmentCollection.insert(attachment);
    }
    return attachment.id;
  };

  const uploadImages = async (hazardId: string): string[] => {
    try {
      let attachmentIds: string[] = [];
      const formattedImages = getFormattedImages(
        `Borer_${selectedConditionType.displayName}_`,
        selectedImages,
      );
      const imageResults = await Promise.all(
        formattedImages.map(file => uploadImage(hazardId, file)),
      );

      attachmentIds = await Promise.all(
        imageResults.map(imgResult => createAttachment(hazardId, imgResult.key)),
      );

      return attachmentIds;
    } catch (error) {
      console.log('🚀 ~ file: AddHazardsSidePanel.tsx ~ line 361 ~ uploadImages ~ error', error);
      // TODO: Image upload error
    }
  };

  const saveEndLocation = async () => {
    let endDistanceInMeters;

    if (endDistance === '') {
      endDistanceInMeters = null;
    } else if (useMeters) {
      endDistanceInMeters = parseInt(endDistance, 10);
    } else {
      endDistanceInMeters = parseInt(endDistance, 10) / FEET_PER_METERS;
    }

    const endLocation: LocationDocument = {
      ...generateBaseEntity(),
      startMeters: endDistanceInMeters,
      endMeters: undefined,
      panel: endPanel?.id,
      room: endRoom?.id || null,
      surveyPoint: endSurveyPoint?.id || null,
      sequence: endSequence?.id || null,
      pass: endPass?.id || null,
    };
    if (locationCollection !== null) {
      await locationCollection.insert(endLocation);
    }
    return endLocation;
  };

  const saveStartLocation = async () => {
    let distanceInMeters;

    if (startDistance === '') {
      distanceInMeters = null;
    } else if (useMeters) {
      distanceInMeters = parseInt(startDistance, 10);
    } else {
      distanceInMeters = parseInt(startDistance, 10) / FEET_PER_METERS;
    }

    const startLocation: LocationDocument = {
      id: uuidv4(),
      startMeters: distanceInMeters,
      endMeters: undefined,
      panel: panel?.id,
      room: room?.id,
      surveyPoint: surveyPoint?.id,
      sequence: sequence?.id,
      pass: pass?.id,
      isDeleted: false,
      updatedAt: getUnixMillisecondTimestamp(),
      version: 1,
    };
    if (locationCollection !== null) {
      locationCollection.insert(startLocation);
    }
    return startLocation;
  };

  const saveHazard = async () => {
    const hazardId = uuidv4();
    trackEvent(TrackingEventType.HAZARD_SAVE_CLICKED, {
      hazardId,
      conditionTypeName: selectedConditionType.description,
      selectedUser: `${selectedUser.firstName} ${selectedUser.lastName}`,
    });

    let endLocation: LocationDocument | undefined;
    setIsSaving(true);

    try {
      if (isFullyRemediated && reportEndLocation) {
        endLocation = await saveEndLocation();
      }
      const startLocation = await saveStartLocation();
      let newHazard: GroundHazardDocument;

      const newDateAsString = dayjs().toISOString();
      const hazard: GroundHazardDocument = {
        ...generateBaseEntityWithCreatedOn(),
        conditionType: selectedConditionType.id,
        startLocation: startLocation.id,
        endLocation: endLocation?.id || undefined,
        hazardLogs: [],
        remediatedOn: isFullyRemediated ? newDateAsString : null,
        remediatedOnUnix: isFullyRemediated ? formatDateToUnix(newDateAsString) : null,
        createdOnUnix: formatDateToUnix(newDateAsString),
        createdBy: selectedUser?.id,
        borerEquipmentId: equipment.selectedBorerId || null,
        inspectionId,
        id: hazardId,
        updateLastViewed: false,
        startHazardLandmark: startHazardLandmark !== '' ? startHazardLandmark : null,
        endHazardLandmark: endHazardLandmark !== '' ? endHazardLandmark : null,
      };

      if (groundHazardCollection !== null) {
        newHazard = await groundHazardCollection.insert(hazard);

        const groundControlSets: string[] = await Promise.all(
          selectedGroundControls.map(gc => saveGroundControl(gc, newHazard.id)),
        );

        const attachmentIds = await uploadImages(newHazard.id);
        const hazardLogId = await saveComment(newHazard, groundControlSets);
        const updatedHazard = await updateGroundHazard(newHazard.id, hazardLogId, attachmentIds);
        if (updatedHazard && onNewHazardCreated) {
          onNewHazardCreated(updatedHazard);
        }
        trackEvent(TrackingEventType.HAZARD_SAVE_SUCCESS, {
          hazardId,
          conditionTypeName: selectedConditionType.description,
          selectedUser: `${selectedUser.firstName} ${selectedUser.lastName}`,
        });
      }
      setIsFullyRemediated(false);
      successNotification(i18n.t('Hazard added'));
      onClose();
    } catch (error) {
      console.log(`error = ${error}`);
      errorNotification(i18n.t('Hazard could not save. Please try again.'));
      trackEvent(TrackingEventType.HAZARD_SAVE_FAILURE, {
        hazardId,
        conditionTypeName: selectedConditionType.description,
        selectedUser: `${selectedUser.firstName} ${selectedUser.lastName}`,
      });
    }
    setIsSaving(false);
  };

  const updateGroundHazard = async (
    groundHazardId: string,
    hazardLogId: string,
    attachments: string[],
  ): Promise<GroundHazardDocument | undefined> => {
    try {
      if (groundHazardCollection) {
        const updated = await groundHazardCollection
          .findOne()
          .where('id')
          .eq(groundHazardId)
          .update({
            $set: {
              updatedAt: getUnixMillisecondTimestamp(),
              attachments,
              hazardLogs: [hazardLogId],
            },
          });
        return updated !== null ? updated : undefined;
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: AddCommentSidePanel.tsx ~ line 425 ~ updateConditionType ~ error',
        error,
      );
      return undefined;
    }
  };

  const openDiscardEditsModel = () => {
    if (hasEdits || selectedGroundControls.length > 0) {
      setDiscardOpen(true);
      trackEvent(TrackingEventType.HAZARD_DISCARD_OPENED, {});
    } else {
      onClose();
      trackEvent(TrackingEventType.HAZARD_ADD_HAZARD_CLOSED, {});
      if (onCancel) onCancel();
    }
  };

  const onConditionSelected = (condition: GroundHazardConditionType) => {
    setSelectedConditionType(condition);
    setHasEdits(true);
  };

  const onEmployeeSelected = (employee: Employee) => {
    setSelectedUser(employee);
    setHasEdits(true);
  };

  const onCommentTextChange = (commentText: string) => {
    setComment(commentText);
    setHasEdits(true);
  };

  const handleGroundControlError = (val: boolean) => {
    setGroundControlErrors(val);
  };

  const handleGroundControlSelected = (
    groundControlId: string,
    quantityValue: string,
    optionValuePairs: any[],
  ) => {
    const currentGroundControls = [...selectedGroundControls];
    const index = currentGroundControls.findIndex(x => x.typeId === groundControlId);
    if (index !== -1) {
      currentGroundControls.splice(index, 1);
    } else {
      currentGroundControls.push({
        typeId: groundControlId,
        quantity: quantityValue,
        optionValues: optionValuePairs,
      });
    }
    setSelectedGroundControls(currentGroundControls);
  };

  const handleGroundControlUpdated = (
    groundControlId: string,
    quantityValue?: string,
    optionId?: string,
    optionValueId?: string,
  ) => {
    const currentGroundControls = selectedGroundControls;
    const index = currentGroundControls.findIndex(x => x.typeId === groundControlId);
    const gcToUpdate = currentGroundControls[index];

    gcToUpdate.quantity = quantityValue;

    if (optionId && optionValueId) {
      const optionIndex = gcToUpdate.optionValues.findIndex(x => x.optionId === optionId);
      if (optionIndex !== -1) {
        gcToUpdate.optionValues[optionIndex].valueId = optionValueId;
      } else {
        gcToUpdate.optionValues.push({
          optionId,
          valueId: optionValueId,
        });
      }
    }

    currentGroundControls[index] = gcToUpdate;
    setSelectedGroundControls(currentGroundControls);
  };

  const onExpanded = (expanded: boolean, expansionPanelId: HazardPanelTypes) => {
    if (expanded) setExpandedPanel(expansionPanelId);
    else setExpandedPanel(undefined);
  };

  // Save Validation
  const canSave = useMemo(() => {
    if (!selectedConditionType) return false;
    if (!selectedUser) return false;
    if (!panel) return false;
    if (!room) return false;
    if (!surveyPoint && surveyPoints.length > 0 && !isRocanville) return false;
    if (isLanigan || isAllan || (isVanscoy && panel?.miningMethod === MiningMethod.CHEVRON)) {
      if (!sequence) return false;
    }
    if (startDistance === '' || startDistanceErrorText !== '') return false;

    if (groundControlErrors) return false;

    if (reportEndLocation) {
      if (!endPanel) return false; // This covers block, panel, and room selection
      if (endSurveyPoints.length > 0 && !endSurveyPoint) return false;
      if (isLanigan || (isVanscoy && panel?.miningMethod === MiningMethod.CHEVRON)) {
        if (!endSequence) return false;
      }
      if (endDistanceErrorText !== '') return false;
    }

    if (isFullyRemediated && !selectedGroundControls?.length) return false;

    return true;
  }, [
    selectedConditionType,
    selectedUser,
    panel,
    room,
    surveyPoint,
    surveyPoints,
    sequence,
    startDistance,
    startDistanceErrorText,
    reportEndLocation,
    endPanel,
    endSurveyPoints,
    endSurveyPoint,
    endSequence,
    groundControlErrors,
    endDistanceErrorText,
    isLanigan,
    isVanscoy,
    isFullyRemediated,
    selectedGroundControls.length,
    selectedGroundControls,
    isRocanville,
    isAllan,
  ]);

  // Block Panel Room
  const getPanelRoomOptions = async () => {
    const rooms = await getRoomOptions();
    const activeRooms = rooms.filter(
      roomValue => !roomValue.panel.completedOn && roomValue.room.isActive,
    );
    setRoomOptions(activeRooms);
  };

  useEffect(() => {
    getPanelRoomOptions();
  }, [open]);

  const clearSubPanelFields = () => {
    setSequence(undefined);
    setSurveyPoint(undefined);
    setPass(undefined);
  };

  // Survey Points (Sequences In UI)
  const getSurveyPoints = async (roomDoc: RoomDocument) => {
    const options = await getSurveyPointsForRoom(roomDoc.id);
    const activeOptions = options.filter(opt => opt.isActive);
    setSurveyPoints(activeOptions);
  };

  const onSurveyPointChanged = (newSurveyPoint: SurveyPointDocument) => {
    setSurveyPoint(newSurveyPoint);
    if (!isFullyRemediated) onEndSurveyPointChanged(newSurveyPoint);
  };

  // Sequences (Step In UI)
  const getSequences = async (panelDoc: PanelDocument) => {
    const { miningMethod } = panelDoc;

    const sequenceOptions = await getSequencesForMiningMethod(miningMethod);
    setSequences(sequenceOptions);
  };

  const onSequenceChanged = (newSequence: SequenceDocument) => {
    setSequence(newSequence);
    if (!isFullyRemediated) onEndSequenceChanged(newSequence);
  };

  // Pass
  const getPasses = async (panelDoc: PanelDocument) => {
    const { miningMethod } = panelDoc;

    const passOptions = await getPassesForMiningMethod(miningMethod);
    setPasses(passOptions);
  };

  const onPassChanged = (newPass: PassDocument) => {
    setPass(newPass);
    if (!isFullyRemediated) onEndPassChanged(newPass);
  };

  const onStartDistanceChanged = (value: string) => {
    setStartDistance(value);
    setHasEdits(true);

    if (RegExp('^[0-9]+$').test(value) || (isRocanville && value == '')) {
      setStartDistanceErrorText('');

      if (!isFullyRemediated) onEndDistanceChanged(value);
    } else {
      setStartDistanceErrorText(i18n.t('Invalid Start Distance.'));
    }
  };

  // End Location

  // Survey Points (Sequences In UI)
  const getEndSurveyPoints = async (roomDoc: RoomDocument) => {
    const options = await getSurveyPointsForRoom(roomDoc.id);
    setEndSurveyPoints(options);
  };

  const onEndSurveyPointChanged = (newSurveyPoint: SurveyPointDocument) => {
    setEndSurveyPoint(newSurveyPoint);
  };

  // Sequences (Step In UI)
  const getEndSequences = async (panelDoc: PanelDocument) => {
    const { miningMethod } = panelDoc;

    const endSequenceOptions = await getSequencesForMiningMethod(miningMethod);
    setEndSequences(endSequenceOptions);
  };

  const onEndSequenceChanged = (newSeqeunce: SequenceDocument) => {
    setEndSequence(newSeqeunce);
  };

  // Pass
  const getEndPasses = async (panelDoc: PanelDocument) => {
    const { miningMethod } = panelDoc;

    const endPassOptions = await getPassesForMiningMethod(miningMethod);
    setEndPasses(endPassOptions);
  };

  const onEndPassChanged = (newPass: PassDocument) => {
    setEndPass(newPass);
  };

  const onEndDistanceChanged = (value: string) => {
    setEndDistance(value);
    setHasEdits(true);

    if (value === '' || RegExp('^[0-9]+$').test(value)) {
      setEndDistanceErrorText('');
    } else {
      setEndDistanceErrorText(i18n.t('Invalid End Distance.'));
    }
  };

  const onEndRoomChanged = (newRoom: PopulatedLocation) => {
    setEndBlock(newRoom.block || undefined);
    setEndPanel(newRoom.panel || undefined);
    setEndRoom(newRoom.room || undefined);

    getEndSequences(newRoom.panel);
    getEndSurveyPoints(newRoom.room);
    getEndPasses(newRoom.panel);
  };

  const onRoomChanged = (newRoom: PopulatedLocation) => {
    setBlock(newRoom.block || undefined);
    setPanel(newRoom.panel || undefined);
    setRoom(newRoom.room || undefined);

    clearSubPanelFields();

    getSequences(newRoom.panel);
    getSurveyPoints(newRoom.room);
    getPasses(newRoom.panel);

    if (!isFullyRemediated) onEndRoomChanged(newRoom);
  };

  const onFilesSelected = useCallback((filesInput: File[] | FileList[]) => {
    const files = convertListOfFilesAndFileListsToArray(filesInput);
    const formattedFiles = formatFileNamesToBeUnique(files, 'hazard', true);

    setSelectedImages(formattedFiles);
    setHasEdits(true);
    onExpanded(true, HazardPanelTypes.PHOTOS);
  }, []);

  // Pre Populate Block, Panel, Room and Survey Point
  const prePopulateFromBorerLocation = async () => {
    if (currentBlock && currentPanel && currentRoom) {
      onRoomChanged({
        block: currentBlock,
        panel: currentPanel,
        room: currentRoom,
      });
      if (currentSurveyPoint) onSurveyPointChanged(currentSurveyPoint);
    }
  };

  if (!open) return null;
  return (
    <>
      <SidePanel
        open={open}
        onClose={openDiscardEditsModel}
        transitionDuration={200}
        disableBackdropClick
        ModalProps={{
          BackdropProps: {
            onClick: event => {
              event.preventDefault();
            },
            onTouchStart: event => {
              event.preventDefault();
            },
            style: { touchAction: 'none' },
          },
        }}
      >
        <GenericSidePanelHeader title={i18n.t('Add A Hazard')} onClose={openDiscardEditsModel} />
        <DialogContent className={classes.dialogContent}>
          <AddCommentDetailsPanel
            names={employeesList}
            onNameSelected={onEmployeeSelected}
            selectedName={selectedUser}
            conditions={conditionTypes}
            onConditionSelected={onConditionSelected}
            selectedCondition={selectedConditionType}
            conditionDisabled={hazardConditionType !== undefined}
            onCommentTextChanged={onCommentTextChange}
            commentText={comment}
            validationIndex={validationIndex}
            setValidationIndex={setValidationIndex}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.DETAILS}
            currentExpandedPanelId={expandedPanelId}
          />
          <LocationDetailsPanel
            roomOptions={roomOptions}
            onRoomChanged={onRoomChanged}
            selectedRoom={block && panel && room ? { block, panel, room } : undefined}
            surveyPointOptions={surveyPoints}
            onSurveyPointChanged={onSurveyPointChanged}
            selectedSurveyPoint={surveyPoint}
            sequences={sequences}
            selectedSequence={sequence}
            onSequenceChanged={onSequenceChanged}
            passes={passes}
            selectedPass={pass}
            onPassChanged={onPassChanged}
            startDistance={startDistance}
            onStartDistanceChanged={onStartDistanceChanged}
            startDistanceErrorText={startDistanceErrorText}
            validationIndex={validationIndex}
            setValidationIndex={setValidationIndex}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.LOCATION}
            currentExpandedPanelId={expandedPanelId}
            startHazardLandmark={startHazardLandmark}
            setStartHazardLandmark={setStartHazardLandmark}
          />
          <GroundControlExpansionPanel
            title={i18n.t('Ground control')}
            updateQuantityErrors={handleGroundControlError}
            onGroundControlSelected={handleGroundControlSelected}
            onGroundControlUpdated={handleGroundControlUpdated}
            currentSelectedGroundControls={selectedGroundControls}
            setValidationIndex={setValidationIndex}
            validationIndex={validationIndex}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.GROUND_CONTROLS}
          />
          <HazardPhotosPanel
            expansionPanelId={HazardPanelTypes.PHOTOS}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.PHOTOS}
            selectedImages={selectedImages}
            onFilesSelected={onFilesSelected}
            setValidationIndex={setValidationIndex}
          />
          <HazardStatusPanel
            hasGroundControls={selectedGroundControls.length > 0}
            isFullyRemediated={isFullyRemediated}
            onFullyRemediatedChanged={(val: boolean) => {
              setIsFullyRemediated(val);
              setHasEdits(true);
            }}
            reportEndLocation={reportEndLocation}
            onReportEndLocationChanged={val => setReportEndLocation(val)}
            roomOptions={roomOptions}
            onRoomChanged={onEndRoomChanged}
            selectedRoom={
              endBlock && endPanel && endRoom
                ? { block: endBlock, panel: endPanel, room: endRoom }
                : undefined
            }
            surveyPointOptions={endSurveyPoints}
            onSurveyPointChanged={onEndSurveyPointChanged}
            selectedSurveyPoint={endSurveyPoint}
            sequences={endSequences}
            selectedSequence={endSequence}
            onSequenceChanged={onEndSequenceChanged}
            passes={endPasses}
            selectedPass={endPass}
            onPassChanged={onEndPassChanged}
            endDistance={endDistance}
            onEndDistanceChanged={onEndDistanceChanged}
            endDistanceErrorText={endDistanceErrorText}
            validationIndex={validationIndex}
            setValidationIndex={setValidationIndex}
            forceRemediatedHazard={forceRemediatedHazard}
            onExpanded={onExpanded}
            expanded={expandedPanelId === HazardPanelTypes.REMEDIATION}
            endHazardLandmark={endHazardLandmark}
            setEndHazardLandmark={setEndHazardLandmark}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={openDiscardEditsModel}
                data-cy="cancelAddHazard"
                noMinHeight
                id="cancel-add-hazard"
              >
                {i18n.t('Cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                data-cy="saveAddHazard"
                data-testid="saveAddHazard"
                noMinHeight
                onClick={saveHazard}
                disabled={!canSave || isSaving}
                isLoading={isSaving}
                id="save-add-hazard"
              >
                {i18n.t('Save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </SidePanel>
      <DiscardDraftModal
        open={discardOpen}
        onCancel={() => {
          setDiscardOpen(false);
          setUndo(false);
          trackEvent(TrackingEventType.HAZARD_DISCARD_CANCELLED, {});
        }}
        onDiscard={() => {
          onClose();
          setDiscardOpen(false);
          if (onCancel) onCancel();
          errorNotification(i18n.t('Draft hazard discarded'), {
            action: key => (
              <Button
                noMinHeight
                color="primary"
                variant="text"
                onClick={() => {
                  setUndo(true);
                  closeSnackbar(key);
                  onOpen();
                }}
                id="undo-discard-hazard"
              >
                {i18n.t('Undo')}
              </Button>
            ),
          });
          trackEvent(TrackingEventType.HAZARD_DISCARD_DISCARDED, {});
        }}
      />
    </>
  );
};

export default observer(AddHazardsSidePanel);
