import { Card, i18n } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import GenericRichTextInput from '@/components/GenericRichTextInput/GenericRichTextInput';
import GenericSidePanel from '@/components/GenericSidePanel';
import { useMst } from '@/mobx-models/Root';
import { ExtendedBorerShiftComment } from '@/rxdb/BorerShiftComment/queryBuilder';
import useBorerShiftComment from '@/rxdb/BorerShiftComment/useBorerShiftComment';
import { getUnixMillisecondTimestamp } from '@/rxdb/rxdbUtilityFunctions';
import { useNotification } from '@/utilities';
import { EMPTY_GUID, EMPTY_HTML_COMMENT, OPERATOR_COMMENT_TYPE } from '@/utilities/constants';

import useStyles from './ModifyAutomationCommentSidePanel.styles';

interface Props {
  open: boolean;
  setSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  commentToEdit: Partial<ExtendedBorerShiftComment> | null;
  loading: boolean;
  commentTypeId: string | undefined;
}

const generateDefaultComment = () => ({
  borerShiftCommentTypeId: EMPTY_GUID,
  borerShiftId: '',
  comment: '',
  id: uuid(),
  isDeleted: false,
  updatedAt: getUnixMillisecondTimestamp(),
  version: 1,
});

const ModifyCommentSidePanel: React.FC<Props> = ({
  open,
  setSidePanelOpen,
  commentToEdit,
  commentTypeId,
  loading,
}: Props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const undo = useRef(false);
  const [comment, setComment] = useState(commentToEdit || generateDefaultComment());
  const { upsertBorerShiftComment } = useBorerShiftComment();
  const { successNotification, errorNotification } = useNotification();
  const { shiftPicker } = useMst();
  const [initialHTML, setInitialHTML] = useState('');
  const [commentHTML, setCommentHTML] = useState('');

  const hasEdits = useMemo(
    () => initialHTML !== commentHTML.replace('&nbsp;', ' '),

    [initialHTML, commentHTML],
  );

  const onClose = useCallback(() => setSidePanelOpen(false), [setSidePanelOpen]);

  const onOpen = useCallback(() => setSidePanelOpen(true), [setSidePanelOpen]);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    try {
      if (!commentTypeId) {
        errorNotification(
          `Could not save comment, missing "${OPERATOR_COMMENT_TYPE}" comment type.`,
        );
      } else if (shiftPicker?.currentBorerShiftId) {
        await upsertBorerShiftComment({
          borerShiftId: shiftPicker?.currentBorerShiftId,
          borerShiftCommentTypeId: commentTypeId,
          id: comment.id || uuid(),
          comment: commentHTML,
          version: comment.version || 1,
          updatedAt: getUnixMillisecondTimestamp(),
          isDeleted: false,
        });

        successNotification(i18n.t('Comment added'));
        onClose();
      }
    } catch (error) {
      console.error(`ERROR saving comment: ${error}`);
      errorNotification(`Could not save comment`);
    }
    setIsSaving(false);
  }, [
    comment,
    commentHTML,
    commentTypeId,
    errorNotification,
    onClose,
    shiftPicker,
    upsertBorerShiftComment,
    successNotification,
  ]);

  const onSetUndo = useCallback(() => (undo.current = true), []);

  useEffect(() => {
    if (open && !undo.current) {
      setCommentHTML(commentToEdit?.comment || '');
      setComment(commentToEdit || generateDefaultComment());
      setInitialHTML(commentToEdit?.comment || '');
    } else if (open) {
      undo.current = false;
    }
  }, [open, commentToEdit]);

  if (!open) return null;
  return (
    <GenericSidePanel
      open={open}
      onClose={onClose}
      title={
        !commentToEdit?.comment || commentToEdit?.comment === EMPTY_HTML_COMMENT
          ? i18n.t('Add comment')
          : i18n.t('Edit comment')
      }
      onOpen={onOpen}
      hasEdits={hasEdits}
      canSave={hasEdits}
      isSaving={isSaving}
      setUndo={onSetUndo}
      loading={loading}
      onSave={onSave}
      discardNotificationText={i18n.t('Comment draft discarded')}
    >
      <Card className={classes.cardRoot}>
        <GenericRichTextInput
          title={i18n.t('Automation')}
          subtitle={i18n.t('Automation notes (optional)')}
          commentHTML={commentHTML}
          setCommentHTML={setCommentHTML}
        />
      </Card>
    </GenericSidePanel>
  );
};

export default observer(ModifyCommentSidePanel);
