import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { DelayActivitiesBlockType } from '../../utilities/enums';
import { formatDateFromUnixInReginaTzForScheduler } from '../../utilities/useDateFormatters';
import useDebug from '../../utilities/useDebug';
import { getTimeUntilNextMinute } from '../../utilities/utilityFunctions';
import useBorerStateType from '../BorerStateTypeFeed/useBorerStateType';
import { PopulatedBorerOperatorState } from './useBorerOperatorState';

//  A 'generated block' only occurs when the borer is offline and the last event is a running block
//  It uses the most recent borerStateId of the running block to create a mock item, which will extend to
//  the current time. After re-coding, it will exist as a borerOperatorChangeState.

export const useGeneratedOfflineBlock = (
  isOnline: boolean,
  isCurrentShiftSelected: boolean,
  operatorStates: PopulatedBorerOperatorState[],
  lastSyncTime?: number,
) => {
  const { defaultNonRunningStateType } = useBorerStateType();

  const DEBUG = useDebug();
  const [generatedBlock, setGeneratedBlock] = useState<PopulatedBorerOperatorState[]>([]);

  const latestBlock = useMemo(() => {
    // get appointment with most recent endTime
    if (!Array.isArray(operatorStates)) return undefined;

    return operatorStates?.reduce((prev, current) => {
      if (current?.endDate && dayjs(current?.endDate).unix()) {
        if (prev?.endDate && dayjs(prev?.endDate).unix() > dayjs(current?.endDate).unix()) {
          return prev;
        }
        return current;
      }
      return prev;
    }, undefined);
  }, [operatorStates]);

  useEffect(() => {
    const generateBlock = async () => {
      if (isOnline || !lastSyncTime || !isCurrentShiftSelected) {
        setGeneratedBlock([]);
        return;
      }
      if (latestBlock?.borerStateType?.isRunning === false) {
        setGeneratedBlock([]);
        return;
      }

      if (!latestBlock?.borerOperatorStateId) {
        return console.error('Missing BorerOperatorStateId for generated block');
      }

      let startTime: number | string = lastSyncTime;
      if (latestBlock?.borerStateType?.isRunning === true && Boolean(latestBlock?.endDate)) {
        // Edge case, where we code previous generated block as running
        startTime = latestBlock.endDate.toString();
      }

      setGeneratedBlock([
        {
          borerOperatorStateId: latestBlock?.borerOperatorStateId,
          borerStateTypeId: defaultNonRunningStateType?.id,
          startDate: formatDateFromUnixInReginaTzForScheduler(dayjs(startTime).unix()),
          endDate: formatDateFromUnixInReginaTzForScheduler(dayjs().unix()),
          borerShiftAdvanceId: null,
          advanceString: '',
          startTimeUnix: dayjs(startTime).unix(),
          endTimeUnix: null,
          title: defaultNonRunningStateType?.description,
          comment: '',
          typeId: 1, // Delays are 1, activities are 2
          blockType: DelayActivitiesBlockType.NOT_RUNNING,
          id: v4(),
          isTempState: false,
          existingTempStateId: latestBlock?.existingTempStateId || '',
          isGeneratedState: true,
          updatedAt: dayjs().unix(),
          failedSync: false,
          isRunning: false,
          cuttingMethodId: null,
          cuttingTypeId: null,
        },
      ]);
    };
    generateBlock();

    const interval = setInterval(() => {
      // Re-run on on the minute to make sure last appointment is accurate
      generateBlock();
    }, getTimeUntilNextMinute());

    return () => clearInterval(interval);
  }, [lastSyncTime, latestBlock, isOnline, defaultNonRunningStateType, isCurrentShiftSelected]);

  useEffect(() => {
    if (DEBUG) console.info('latestBlock', latestBlock, 'Generated state block', generatedBlock);
  }, [DEBUG, generatedBlock, latestBlock, lastSyncTime]);

  return {
    generatedBlock,
  };
};

export default useGeneratedOfflineBlock;
