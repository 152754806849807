import postal from 'postal';
import { useCallback, useEffect, useState } from 'react';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';
import { Subscription } from 'rxjs';

import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import RxdbManager from '../../rxdb/RxdbManager';
import { COLLECTION_SYNC_TIMES } from '../constants';
import useOnlineStatus from '../useOnlineStatus';

export const saveCollectionSyncTimeToLocalStorage = (collectionName: RxdbCollectionName) => {
  let collectionSyncTimes = { [collectionName]: Date.now() };
  const existingSyncTimes = localStorage.getItem(COLLECTION_SYNC_TIMES);

  if (existingSyncTimes && JSON.parse(existingSyncTimes))
    collectionSyncTimes = {
      ...JSON.parse(existingSyncTimes),
      ...collectionSyncTimes,
    };

  localStorage.setItem(COLLECTION_SYNC_TIMES, JSON.stringify(collectionSyncTimes));
};

const getCollectionSyncTime = (collectionName: RxdbCollectionName) => {
  const existingSyncTimes = localStorage.getItem(COLLECTION_SYNC_TIMES);

  if (existingSyncTimes && JSON.parse(existingSyncTimes)) {
    const collectionSyncTimes = JSON.parse(existingSyncTimes);
    return collectionSyncTimes[collectionName] || null;
  }
  return null;
};

export const useLastSyncTime = (collectionName: RxdbCollectionName): number => {
  const online = useOnlineStatus();
  const [lastSyncTime, setLastSyncTime] = useState(getCollectionSyncTime(collectionName));
  const [syncState, setSyncState] = useState<RxGraphQLReplicationState<any> | undefined>();
  const assignSyncState = useCallback(() => {
    const syncStateInArray = RxdbManager.instance.syncStates.find(
      sS => sS?.collection?.name === collectionName,
    );
    if (syncStateInArray) setSyncState(syncStateInArray);
  }, [collectionName]);

  useEffect(() => {
    assignSyncState();
    const postalSub = postal.subscribe({
      channel: 'sync',
      topic: 'sync.online',
      callback: assignSyncState,
    });

    return () => postalSub.unsubscribe();
  }, [assignSyncState]);

  useEffect(() => {
    let sub: Subscription | undefined;
    if (online && syncState) {
      sub = syncState?.active$?.subscribe(active => {
        // Switches to 'non-active' after replication complete
        if (!active) {
          setLastSyncTime(Date.now());
          saveCollectionSyncTimeToLocalStorage(collectionName);
        }
      });
    }
    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
    };
  }, [syncState, online]);

  return lastSyncTime;
};

export default useLastSyncTime;
