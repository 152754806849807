import type { Theme } from '@material-ui/core';
import { createStyles, Grid, Popover } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Calendar } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  Button,
  CustomPalette,
  darkTheme,
  i18n,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@nutrien/cxp-components';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';

import { ShiftType } from '../../utilities/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { maxWidth: '340px' },
    header: {
      padding: '16px 24px',
      backgroundColor: CustomPalette.elevation.dp8Solid,
    },
    caption: {
      color: theme.palette.text.secondary,
    },
    actionContainer: {},
    paper: {
      minHeight: '480px',
    },
  }),
);

const customTheme = createMuiTheme({
  ...darkTheme,
  overrides: {
    MuiPickersDay: {
      current: {
        border: `1px solid ${darkTheme.palette.primary.main}`,
        color: 'white',
      },
      daySelected: {
        color: 'white',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: darkTheme.palette.primary.main,
      },
    },
  },
});

interface Props {
  id: string;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  currentDateText: string;
  selectedDate: Dayjs;
  selectedShiftType: ShiftType;
  onShiftAndDateSelected: (date: Dayjs, type: ShiftType) => void;
  currentDateNightShiftStart: Dayjs;
  currentShift: { date: string; type: ShiftType; datejs: Dayjs };
}

const CustomDatePicker: React.FC<Props> = ({
  id,
  open,
  anchorEl,
  onClose,
  currentDateText,
  selectedDate,
  selectedShiftType,
  onShiftAndDateSelected,
  currentDateNightShiftStart,
  currentShift,
}: Props) => {
  const classes = useStyles();

  const [localDate, setLocalDate] = useState(selectedDate);
  const [localShiftType, setLocalShiftType] = useState(selectedShiftType);
  const [NSToggleDisabled, setNSToggleDisabled] = useState(false);

  useEffect(() => {
    // Disbale the NS toggle as needed
    if (localDate.startOf('day').isSame(dayjs().startOf('day'))) {
      // Same date need to check if current time after night shift start time
      if (dayjs().isBefore(currentDateNightShiftStart)) {
        setNSToggleDisabled(true);
        setLocalShiftType(ShiftType.DAY_SHIFT);
      }
    } else {
      setNSToggleDisabled(false);
    }
  }, [localDate, currentDateNightShiftStart]);

  const onDateChange = (date: Dayjs) => {
    setLocalDate(date);
  };

  const onApply = () => {
    onShiftAndDateSelected(localDate, localShiftType);
    onClose();
  };

  useEffect(() => {
    if (open) {
      // Reset to currently selected ones when modal re opens
      setLocalDate(selectedDate);
      setLocalShiftType(selectedShiftType);
    }
  }, [open, selectedDate, selectedShiftType]);

  const onShiftTypeChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    if (value === ShiftType.DAY_SHIFT || value === ShiftType.NIGHT_SHIFT) {
      setLocalShiftType(value);
    }
  };

  const onThisShift = () => {
    setLocalDate(currentShift.datejs);
    setLocalShiftType(currentShift.type);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: classes.paper }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12} container className={classes.header} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.caption}>
              {i18n.t('Select date')}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h5">{currentDateText}</Typography>
          </Grid>
          <Grid item xs={5} container justify="flex-end">
            <Grid item>
              <ToggleButtonGroup
                value={[localShiftType]}
                onChange={onShiftTypeChange}
                aria-label="Shift Selector"
                exclusive
              >
                <ToggleButton value="DS" aria-label="Day Shift" disableRipple>
                  <p>DS</p>
                </ToggleButton>
                <ToggleButton
                  value="NS"
                  aria-label="Night Shift"
                  disabled={NSToggleDisabled}
                  disableRipple
                >
                  <p>NS</p>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            <div>
              <Button
                color="primary"
                variant="outlined"
                noMinHeight
                size="small"
                style={{ height: '32px' }}
                onClick={onThisShift}
                id="this-shift"
              >
                {i18n.t('This shift')}
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center">
          <div>
            <ThemeProvider theme={customTheme}>
              <Calendar
                date={localDate}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) onDateChange(date);
                }}
                disableFuture
                minDate={dayjs().subtract(14, 'days')}
              />
            </ThemeProvider>
          </div>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="flex-end"
          className={classes.actionContainer}
          spacing={2}
        >
          <Grid item>
            <Button
              color="primary"
              variant="text"
              size="small"
              noMinHeight
              style={{ height: '32px' }}
              onClick={onClose}
              id="cancel"
            >
              {i18n.t('Cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              noMinHeight
              style={{ height: '32px' }}
              onClick={onApply}
              id="apply"
            >
              {i18n.t('Apply')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default CustomDatePicker;
