import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  GroupedAutoComplete,
  i18n,
  IconButton,
  Icons,
  MaterialPalette,
} from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import { BorerShiftCrewMemberEmployee } from '../../models/models';
import { ValidationOperation } from '../EditCrewDetailsPanel/EditCrewDetailsPanel';

export enum CrewEmployeePanelOperation {
  AddEmployee,
  DeleteEmployee,
}

interface Props {
  crewMemberDetails: BorerShiftCrewMemberEmployee;
  crewMemberList: BorerShiftCrewMemberEmployee[];
  crewMemberIndex: number;
  setValidationError: (index: number, error: boolean | ValidationOperation) => void;
  onCrewEmployeeChange: (
    index: number,
    value: BorerShiftCrewMemberEmployee | CrewEmployeePanelOperation,
  ) => void;
  employeeList: BorerShiftCrewMemberEmployeeDocument[];
  validationIndex: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    listbox: {
      '& li': {
        padding: '6px 6px',
      },
    },
    divider: {
      color: MaterialPalette.text.secondary,
      opacity: 0.9,
      fontSize: '14px',
      margin: '0px',
      padding: '0px',
    },
    trashContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      paddingTop: 10,
    },
    errorIcon: {
      '& svg': {
        stroke: `${MaterialPalette.error.main} !important`,
      },
    },
    spacer: {
      marginTop: 10,
    },
  }),
);

const CrewMember: React.FC<Props> = ({
  crewMemberDetails,
  setValidationError,
  crewMemberIndex,
  onCrewEmployeeChange,
  employeeList,
  crewMemberList,
  validationIndex,
}: Props) => {
  const classes = useStyles();

  const [errors, setErrors] = useState({
    name: '',
  });

  useEffect(() => {
    const copiedArray = crewMemberList.slice();
    copiedArray.splice(crewMemberIndex, 1);

    const hasDuplicates = copiedArray.some(
      crewMember => !!crewMemberDetails?.id && crewMemberDetails?.id === crewMember?.id,
    );

    if (validationIndex >= crewMemberIndex + 1) {
      if (crewMemberIndex === 0 && !crewMemberDetails?.id) {
        setErrors({ name: 'Required' });
      } else if (hasDuplicates) {
        setErrors({ name: 'Must be unique.' });
      } else {
        setErrors({ name: '' });
      }
    }
  }, [crewMemberList, crewMemberDetails, crewMemberIndex, validationIndex]);

  useEffect(() => {
    setValidationError(crewMemberIndex, !!errors.name);
  }, [errors.name, crewMemberIndex]);

  const onUpdateSelectedOperator = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: BorerShiftCrewMemberEmployee,
  ) => {
    onCrewEmployeeChange(crewMemberIndex, value);
  };

  const onRemoveCrew = () => {
    setValidationError(crewMemberIndex, ValidationOperation.Remove);
    onCrewEmployeeChange(crewMemberIndex, CrewEmployeePanelOperation.DeleteEmployee);
  };

  const getEmployeeLabel = employee => employee.fullName;

  return (
    <>
      <Grid container className={classes.spacer}>
        <Grid item xs={crewMemberIndex > 0 ? 10 : 12}>
          <GroupedAutoComplete
            autoSelect={false}
            list={employeeList}
            label={
              crewMemberIndex > 0
                ? i18n.t('Operator {{index}}', { index: crewMemberIndex + 1 })
                : i18n.t('Borer Operator')
            }
            getOptionLabel={getEmployeeLabel}
            onChange={onUpdateSelectedOperator}
            value={crewMemberDetails}
            showCaret
            error={!!errors.name}
            errorText={errors.name}
            groupByProperty="crewName"
            primaryProperty="fullName"
            secondaryProperty="crewName"
            tertiaryProperty="positionName"
          />
        </Grid>
        {crewMemberIndex > 0 && (
          <Grid item xs={2} className={classes.trashContainer}>
            <IconButton
              onClick={onRemoveCrew}
              className={classes.errorIcon}
              id="crew-member-remove"
            >
              <Icons.MinusCircleFeather color="error" className={classes.errorIcon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CrewMember;
