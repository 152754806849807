import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { v4 as uuidv4, v4 } from 'uuid';

import { useMst } from '../../mobx-models/Root';
import {
  AWS_DATE_TIME_FORMAT,
  useDateFormatters,
  USER_TIMEZONE,
} from '../../utilities/useDateFormatters';
import { BorerShiftCrewType } from '../BorerShift/queryBuilder';
import {
  BorerShiftCrewMemberCollection,
  SetBorerShiftCrewMemberInput,
} from '../BorerShiftCrewMember/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { getUnixMillisecondTimestamp } from '../rxdbUtilityFunctions';
import { BorerShiftCrewCollection, ExtendedBorerShiftCrewDocument } from './queryBuilder';

export interface BorerShiftCrewMemberInput {
  siteEmployeeId: string;
  employeeOrder: number;
}

export interface SetBorerShiftCrewInput {
  id: string;
  borerShiftId: string;
  crewNumber: number;
  startDateTime: string;
  endDateTime: string;
  createdOn?: string;
  borerShiftCrewMemberInput?: BorerShiftCrewMemberInput[];
  modifiedOn?: string;
  isDeleted: boolean;
  version: number;
}

export const useBorerShiftCrew = () => {
  const { formatAsAWSDateTime } = useDateFormatters();
  const { shiftPicker } = useMst();

  const borerShiftCrewCollection: BorerShiftCrewCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_CREW,
  );
  const borerShiftCrewMemberCollection: BorerShiftCrewMemberCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_CREW_MEMBER,
  );

  const [borerShiftCrewInitialized, setBorerShiftCrewInitialized] = useState(false);
  const [borerShiftCrewMemberInitialized, setBorerShiftCrewMemberInitialized] = useState(false);

  useEffect(() => {
    if (borerShiftCrewCollection) setBorerShiftCrewInitialized(true);
  }, [borerShiftCrewCollection]);

  useEffect(() => {
    if (borerShiftCrewMemberCollection) setBorerShiftCrewMemberInitialized(true);
  }, [borerShiftCrewMemberCollection]);

  const getBorerShiftCrews = async (
    borerShiftId?: string | undefined,
  ): Promise<{
    borerShiftCrews: ExtendedBorerShiftCrewDocument[] | null;
  }> => {
    const borerShiftCrews: ExtendedBorerShiftCrewDocument[] | null = [];
    if (!borerShiftCrewCollection) return { borerShiftCrews };

    try {
      if (borerShiftCrewCollection && borerShiftCrewMemberCollection) {
        const firstCrew = await borerShiftCrewCollection
          ?.findOne({
            selector: {
              crewNumber: 1,
              borerShiftId,
              isDeleted: false,
            },
            sort: [{ updatedAt: 'desc' }],
          })
          .exec();

        const secondCrew = await borerShiftCrewCollection
          ?.findOne({
            selector: {
              crewNumber: 2,
              borerShiftId,
              isDeleted: false,
            },
            sort: [{ updatedAt: 'desc' }],
          })
          .exec();

        if (firstCrew) {
          borerShiftCrews.push(firstCrew);
        }
        if (secondCrew) {
          borerShiftCrews.push(secondCrew);
        }

        await Promise.all(
          borerShiftCrews?.map(async (borerShiftCrew, index) => {
            const borerShiftCrewMembers = await borerShiftCrewMemberCollection
              .find({
                selector: {
                  borerShiftCrewId: borerShiftCrew.id,
                  isDeleted: false,
                },
              })
              .exec();

            const employees = await Promise.all(
              borerShiftCrewMembers
                .sort((a, b) => a.employeeOrder - b.employeeOrder)
                .map(async crewMember => {
                  const employeeInformation = await crewMember.populate('siteEmployeeId');

                  if (employeeInformation?.isActive) {
                    return {
                      firstName: employeeInformation.firstName,
                      lastName: employeeInformation.lastName,
                      id: employeeInformation.id,
                      borerShiftCrewMemberId: crewMember.id,
                      borerShiftCrewMemberVersion: crewMember.version,
                      reactKey: uuidv4(),
                      employeeOrder: crewMember.employeeOrder,
                      fullName: `${employeeInformation.firstName} ${employeeInformation.lastName}`,
                    };
                  }
                }),
            );

            if (employees) {
              borerShiftCrews[index] = {
                ...borerShiftCrews[index],
                id: borerShiftCrew.id,
                crewNumber: borerShiftCrew.crewNumber,
                borerShiftCrewMemberInput: employees.filter(employee => !!employee?.id),
                start: dayjs(borerShiftCrew.start).tz(USER_TIMEZONE).format(AWS_DATE_TIME_FORMAT),
                end: dayjs(borerShiftCrew.end).tz(USER_TIMEZONE).format(AWS_DATE_TIME_FORMAT),
                version: borerShiftCrew.version,
                hidden: borerShiftCrew.hidden,
              };
            }
          }),
        );
      }

      return { borerShiftCrews };
    } catch (error) {
      console.log('🚀 ~ file: useBorerShiftCrew.ts ~ line 71 ~ error', error);
      throw error;
    }
  };
  const updateBorerShiftCrew = async (
    borerShiftCrewDetails: BorerShiftCrewType,
    originalBorerShiftCrewDetails: BorerShiftCrewType,
  ) => {
    if (!shiftPicker.currentBorerShiftId) throw new Error('Missing current borer shift id');

    try {
      // Remove 'blank' employees
      const filteredCrewDetails = {
        ...borerShiftCrewDetails,
        borerShiftCrewMemberInput: borerShiftCrewDetails.borerShiftCrewMemberInput.filter(
          crewMember => !!crewMember.id,
        ),
      };

      const deletedEmployees = originalBorerShiftCrewDetails.borerShiftCrewMemberInput
        .filter(originalCrewMember => !!originalCrewMember?.id)
        // Find see if original crew members are present in our new member array
        // Need to have same ID AND same position, otherwise add to delete array
        .filter(
          originalCrewMember =>
            !filteredCrewDetails.borerShiftCrewMemberInput.find(crewMember => {
              return crewMember?.id === originalCrewMember?.id;
            }),
        );

      const employeesToUpsert = filteredCrewDetails.borerShiftCrewMemberInput.filter(
        crewMember =>
          !deletedEmployees.find(deletedCrewMember => deletedCrewMember.id === crewMember.id),
      );

      const uniqueUpsert = employeesToUpsert.filter(
        (employee, index, self) =>
          self.findIndex(employeeToIndex => employeeToIndex.id === employee.id) === index,
      );

      const existingDoc = await borerShiftCrewCollection
        ?.findOne({
          selector: {
            crewNumber: borerShiftCrewDetails.crewNumber,
            borerShiftId: shiftPicker.currentBorerShiftId,
          },
          sort: [{ updatedAt: 'desc' }],
        })
        .exec();

      let borerShiftCrewId = filteredCrewDetails.id;
      if (existingDoc) {
        borerShiftCrewId = existingDoc.id;
        await existingDoc.update({
          $set: {
            updatedAt: getUnixMillisecondTimestamp(),
            isDeleted: false,
            version: existingDoc.version,
            hidden: false,
            end: formatAsAWSDateTime(dayjs(filteredCrewDetails.endDateTime), true),
            start: formatAsAWSDateTime(dayjs(filteredCrewDetails.startDateTime), true),
          },
        });
      } else {
        const doc: SetBorerShiftCrewInput = {
          id: filteredCrewDetails.id,
          isDeleted: false,
          version: filteredCrewDetails.version ? filteredCrewDetails.version : 1,
          end: formatAsAWSDateTime(dayjs(filteredCrewDetails.endDateTime), true),
          start: formatAsAWSDateTime(dayjs(filteredCrewDetails.startDateTime), true),
          borerShiftId: shiftPicker.currentBorerShiftId,
          crewNumber: filteredCrewDetails.crewNumber || 1,
          updatedAt: getUnixMillisecondTimestamp(),
          hidden: false,
        };

        await borerShiftCrewCollection?.upsert(doc);
      }

      await Promise.all([
        ...uniqueUpsert.map(async (employee, index) => {
          const existingCrewMemberDoc = await borerShiftCrewMemberCollection
            ?.findOne({
              selector: {
                borerShiftCrewId,
                siteEmployeeId: employee.id,
              },
            })
            .exec();

          if (existingCrewMemberDoc) {
            await existingCrewMemberDoc.update({
              $set: {
                updatedAt: getUnixMillisecondTimestamp(),
                employeeOrder: index,
                isDeleted: false,
              },
            });
          } else {
            const borerShiftCrewMemberDoc: SetBorerShiftCrewMemberInput = {
              id: employee.borerShiftCrewMemberId || v4(),
              borerShiftCrewId,
              siteEmployeeId: employee.id,
              employeeOrder: index,
              isDeleted: false,
              version: employee.borerShiftCrewMemberVersion || 1,
              updatedAt: getUnixMillisecondTimestamp(),
            };
            await borerShiftCrewMemberCollection?.upsert(borerShiftCrewMemberDoc);
          }
        }),
        ...deletedEmployees.map(async employee => {
          const employeeToDeleteDoc = await borerShiftCrewMemberCollection
            ?.findOne()
            .where('id')
            .eq(employee.borerShiftCrewMemberId)
            .exec();

          await employeeToDeleteDoc?.update({
            $set: {
              isDeleted: true,
            },
          });
        }),
      ]);
    } catch (err) {
      console.log('🚀 ~ file: useBorerShiftCrew.ts ~ line 109 ~ err', err);
      throw new Error(err);
    }
  };

  const softDeleteBorerShiftCrew = async (originalBorerShiftCrewDetails: BorerShiftCrewType) => {
    const doc = await borerShiftCrewCollection
      ?.findOne({
        selector: {
          id: originalBorerShiftCrewDetails.id,
        },
      })
      .exec();

    if (doc) {
      await doc?.update({
        $set: {
          hidden: true,
        },
      });
    }
  };

  const unSoftDeleteBorerShiftCrew = async (originalBorerShiftCrewDetails: BorerShiftCrewType) => {
    const doc = await borerShiftCrewCollection
      ?.findOne({
        selector: {
          id: originalBorerShiftCrewDetails.id,
        },
      })
      .exec();

    if (doc) {
      await doc?.update({
        $set: {
          hidden: false,
        },
      });
    }
  };

  const deleteBorerShiftCrew = async (originalBorerShiftCrewDetails: BorerShiftCrewType) => {
    const doc = await borerShiftCrewCollection
      ?.findOne({
        selector: {
          id: originalBorerShiftCrewDetails.id,
        },
      })
      .exec();

    await doc?.remove();
  };

  return {
    borerShiftCrewCollection,
    getBorerShiftCrews,
    borerShiftCrewInitialized,
    updateBorerShiftCrew,
    borerShiftCrewMemberCollection,
    borerShiftCrewMemberInitialized,
    deleteBorerShiftCrew,
    softDeleteBorerShiftCrew,
    unSoftDeleteBorerShiftCrew,
  };
};

export default useBorerShiftCrew;
