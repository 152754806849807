import { sleep } from 'radash';
import { Workbox } from 'workbox-window';

import { versionCompare } from '../../utilities/semanticVersionCompare';

const debug = localStorage.getItem('DEBUG_PWA') === 'true';

const appVersionFromEnv = process.env.APP_VERSION;

/*
 * Currently there is no difference between a MAJOR & MINOR update in the code below. This is being left in for now if it is needed in the future.
 */
export enum UpdateTypes {
  MAJOR = 'MAJOR',
  MINOR = 'MINOR',
}

/**
 * Get the current PWA version based on the version.json file deployed
 *
 * @return {*}
 */
export const getPWAVersion = async (): Promise<{
  major: string;
  minor: string;
  patch: string;
  version: string;
}> => {
  try {
    const response = await fetch('/version.json', {
      headers: { 'Cache-Control': 'no-cache', pragma: 'no-cache' },
    });
    const json = await response.json();

    if ('version' in json) {
      const { version } = json;
      if (debug) console.log(`<PWAUpdate> Version from fetch: ${version}`);
      const [major, minor, patch] = version.split('.');
      return { major, minor, patch, version };
    }
    throw new Error('Unable to get version number');
  } catch (error) {
    console.log('Unable to get PWA version....', error);
    throw error;
  }
};

/**
 * Remove the PWA update keys from local storage
 *
 */
export const removePWAUpdateKeysFromLocalStorage = () => {
  if (debug) console.log('<PWAUpdate> Removing PWAUpdate keys from local storage');
  localStorage.removeItem('updateDeferralTime');
};

/**
 * Tell the service worker to skip waiting and install the update
 *
 * @param {Workbox} wb
 */
export const installPendingUpdate = (wb: Workbox) => {
  if (debug) console.log('<PWAUpdate> Installing pending update');

  wb.messageSkipWaiting();

  removePWAUpdateKeysFromLocalStorage();

  // Wait for the new service worker to take over, if it doesn't happen in 15 seconds, reload the page
  // This is to prevent the user from being stuck on the loading screen
  // We also wait 1 second after the 'controlling' event fires to make sure the new service worker has taken over, if this is too fast safari can crash the page
  return Promise.race([
    new Promise(resolve => wb.addEventListener('controlling', () => setTimeout(resolve, 1000))),
    sleep(15000),
  ]).then(() => window.location.reload());
};

/**
 * Check if the flags allow the update to be installed
 *
 * @param {string} serverVersion
 * @param {boolean} borerBlockUpdate
 * @param {string[]} versionBlacklist
 * @param {string} maxUpdateVersion
 * @param {boolean} isOnline
 * @return {boolean}
 */
export const checkIfFlagsAllowUpdate = (
  serverVersion: string,
  borerBlockUpdate: boolean,
  versionBlacklist: string[],
  maxUpdateVersion: string,
  isOnline: boolean,
) => {
  if (debug) {
    console.log('<PWAUpdate> new serverVersion: v', serverVersion);
    console.log('<PWAUpdate> current version: v', appVersionFromEnv);
    console.log('<PWAUpdate> max version: v', maxUpdateVersion);
    console.log('<PWAUpdate> blacklisted versions: ', versionBlacklist);
    console.log('<PWAUpdate> appVersionFromEnv: ', appVersionFromEnv);
  }

  let shouldInstallPWAUpdate = true;
  let hasNewVersion = false;

  // Check all the reasons not to install the update and set shouldInstallPWAUpdate to false if any of them are true
  // 0. Check if update is disabled
  if (!borerBlockUpdate) {
    if (debug) console.log(`<PWAUpdate> Update is disabled... do not update`);
    shouldInstallPWAUpdate = false;
  }

  // 1. Check if newer serverVersion is available
  if (appVersionFromEnv === serverVersion) {
    if (debug)
      console.log(
        `<PWAUpdate> App versions are the same... do not update (v${serverVersion} = v${appVersionFromEnv})`,
      );
    shouldInstallPWAUpdate = false;
  } else hasNewVersion = true;

  //2. Check if version is blacklisted
  if (versionBlacklist && versionBlacklist.includes(serverVersion)) {
    if (debug)
      console.log(`<PWAUpdate> New version is blacklisted... do not update to (v${serverVersion})`);
    shouldInstallPWAUpdate = false;
  }

  // 3. Check if version to be installed is greater than max version
  if (maxUpdateVersion && versionCompare(serverVersion, maxUpdateVersion) === 1) {
    if (debug)
      console.log(
        `<PWAUpdate> New version is greater than max version... do not update (v${serverVersion} > v${maxUpdateVersion})`,
      );
    shouldInstallPWAUpdate = false;
  }

  // 4. Check if app is offline
  if (!isOnline) {
    if (debug) console.log(`<PWAUpdate> App is offline... do not update`);
    shouldInstallPWAUpdate = false;
  }

  return { shouldInstallPWAUpdate, hasNewVersion };
};

/**
 * Check if there is a new service worker that needs to be installed
 * If there is, install it and place it into the waiting state
 *
 * @param {ServiceWorkerRegistration} [registration]
 * @return {*}
 */
export const installNewServiceWorkerIfFound = (workbox?: Workbox) => {
  if (!workbox) return Promise.reject(new Error('No service worker registration found'));

  return workbox.update();
};
