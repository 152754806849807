import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import useBorerOperatorChange from '../../rxdb/BorerOperatorChangeFeed/useBorerOperatorChange';
import useBorerOperatorState from '../../rxdb/BorerOperatorStateFeed/useBorerOperatorState';
import { formatDateFromUnixInReginaTzForScheduler, TIME_FORMAT } from '../useDateFormatters';

export const useActualCuttingTimes = () => {
  // Can be removed after PiSight implemented

  const { currentShiftBorerOperatorStates } = useBorerOperatorState();
  const { tempAugmentedStates } = useBorerOperatorChange();

  const [actualCuttingStartTime, setActualCuttingStartTime] = useState<string>('');
  const [actualCuttingEndTime, setActualCuttingEndTime] = useState<string>('');

  useEffect(() => {
    let minStartTime: string | number = '';
    let maxEndTime: string | number = '';

    const combinedStateData = [...tempAugmentedStates, ...currentShiftBorerOperatorStates].filter(
      block => block.borerStateType?.isRunning === true,
    );

    if (combinedStateData?.length) {
      minStartTime = formatDateFromUnixInReginaTzForScheduler(
        Math.min(...combinedStateData.map(state => state.startTimeUnix)),
        TIME_FORMAT,
      );
      maxEndTime = formatDateFromUnixInReginaTzForScheduler(
        Math.max(...combinedStateData.map(state => state.endTimeUnix || dayjs().unix())),
        TIME_FORMAT,
      );
    }

    setActualCuttingStartTime(minStartTime);
    setActualCuttingEndTime(maxEndTime);
  }, [tempAugmentedStates, currentShiftBorerOperatorStates]);

  return {
    actualCuttingStartTime,
    actualCuttingEndTime,
  };
};

export default useActualCuttingTimes;
