import { useMemo, useState } from 'react';

import useInterval from './useInterval';
import useTimeout from './useTimeout';

function useFifteenMinuteInterval(callback: () => void, active = true) {
  const [isRunning, setIsRunning] = useState(false);
  const [delayStart] = useState(60 * 15 * 1000);

  const firstCall = useMemo(() => {
    const now = new Date();

    return (
      delayStart - ((now.getMinutes() % 15) * 60 + now.getSeconds()) * 1000 - now.getMilliseconds()
    );
  }, [delayStart]);

  useTimeout(
    () => {
      callback();
      setIsRunning(true);
    },
    active ? firstCall + 1000 : null,
  );

  useInterval(
    () => {
      callback();
    },
    isRunning && active ? delayStart + 1000 : null,
  );
}

export default useFifteenMinuteInterval;
