import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Dialog, i18n } from '@nutrien/cxp-components';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
    },
    message: {
      marginTop: '20px !important',
    },
  }),
);

interface Props {
  closeInvalidTokenDialog: () => void;
}

const InvalidTokenDialog: React.FC<Props> = ({ closeInvalidTokenDialog }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      titleText="Token expired"
      backButton={false}
      onClose={closeInvalidTokenDialog}
      disableEnforceFocus
      maxWidth="xs"
      maxHeight="200px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },

        style: { touchAction: 'none' },
      }}
    >
      <div className={classes.dialog}>
        <Typography variant="body1" className={classes.message}>
          {i18n.t('Your token has expired.')}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {i18n.t(
            'App token has expired, please contact your control room supervisor to re-log into the borer application.',
          )}
        </Typography>
      </div>
    </Dialog>
  );
};

export default InvalidTokenDialog;
