import dayjs, { Dayjs } from 'dayjs';

import { ShiftType } from './enums';
import { USER_TIMEZONE } from './useDateFormatters';

const getShiftCorrectedTime = (
  usersTime: Dayjs, // only use the time from this
  shiftDate: Dayjs | string, // YYYY-MM-DD
  shiftType: ShiftType,
  inputTimezone = USER_TIMEZONE,
): Dayjs => {
  // if shiftDate is a dayjs object, convert to string
  let formattedShiftDate = '';
  if (dayjs.isDayjs(shiftDate)) {
    formattedShiftDate = shiftDate.format('YYYY-MM-DD');
  } else {
    formattedShiftDate = shiftDate;
  }

  // if shift type is day, return the time
  if (shiftType === ShiftType.DAY_SHIFT) {
    return dayjs.tz(`${formattedShiftDate} ${usersTime.format('HH:mm')}`, inputTimezone);
  }

  // if shift type is night, return the time + 1 day if time is before 8am and after midnight
  let dateTimeInTZ = dayjs.tz(`${formattedShiftDate} ${usersTime.format('HH:mm')}`, inputTimezone);

  // if night shift, and time is after midnight, add a day
  if (dateTimeInTZ.hour() >= 0 && dateTimeInTZ.hour() < 8) {
    dateTimeInTZ = dateTimeInTZ.add(1, 'day');
  }

  return dateTimeInTZ;
};

export { getShiftCorrectedTime };
