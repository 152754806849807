import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface SupplyItem extends BaseEntity {
  readonly description: string;
  readonly unit: string;
  readonly isActive: boolean;
  readonly siteId: string;
}
export type SupplyItemCollection = RxCollection<SupplyItem> | null;
export type SupplyItemDocument = RxDocument<SupplyItem>;

export const supplyItemSchema: RxJsonSchema<SupplyItem> = {
  type: 'object',
  version: 0,
  description: 'describes a SupplyItem object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    siteId: { type: 'string' },
    unit: { type: ['string', 'null'] },
    description: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    isActive: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const supplyItemPullQueryBuilder = (doc: SupplyItem) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = `{
    supplyItemsFeed (siteId: "${rootStore.user.siteId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
        description
        id
        isActive
        isDeleted
        siteId
        unit
        updatedAt
        version
    }
  }`;

  return {
    query,
    variables: {},
  };
};

export const supplyItemPullModifier = (doc: any) => {
  const result = {
    ...doc,
  };
  return result;
};

export default {};
