import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface BorerActivity extends BaseEntity {
  readonly activityTypeId: string;
  readonly borerShiftId: string;
  readonly borerShiftCrewId?: string;
  readonly comment: string;
  readonly start: string;
  readonly end: string;
  readonly isFaceChangeCrew: boolean;
  readonly typeName?: string;
  readonly createdOn?: string;
}
export type BorerActivityCollection = RxCollection<BorerActivity> | null;
export type BorerActivityDocument = RxDocument<BorerActivity>;

export const borerActivitySchema: RxJsonSchema<BorerActivity> = {
  type: 'object',
  version: 0,
  description: 'describes a BorerActivity object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    activityTypeId: { type: 'string' },
    borerShiftId: { type: 'string' },
    borerShiftCrewId: { type: ['string', 'null'] },
    comment: { type: ['string', 'null'] },
    start: { type: ['string', 'null'] },
    end: { type: ['string', 'null'] },
    isFaceChangeCrew: { type: 'boolean' },
    createdOn: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const borerActivityPullQueryBuilder = (doc: BorerActivity) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftActivityFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
      borerShiftActivityFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        borerShiftId
        activityTypeId
        comment
        end
        isDeleted
        start
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerActivityPushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const query = print(gql`
    mutation SetBorerShiftActivity($borerActivity: SetBorerShiftActivityInput) {
      setBorerShiftActivity(input: $borerActivity)
    }
  `);

  const variables = {
    borerActivity: doc,
  };
  return {
    query,
    variables,
  };
};

export const createBorerShiftActivityPushModifier = (doc: any) => {
  const obj = {
    ...doc,
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc.isDeleted,
  };

  const propertyBlackList = [
    'updatedAt',
    '_deleted',
    '_attachments',
    '_rev',
    'isFaceChangeCrew',
    '_meta',
  ];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
