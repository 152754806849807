import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { translate } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import useBorerShiftInfo from '../../rxdb/BorerShiftInfo/useBorerShiftInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },

    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
  }),
);

const BorerShiftInfoCard: FC = () => {
  const classes = useStyles();

  const [initialShiftInfoLoad, setInitialShiftInfoLoad] = useState(true);
  const [shiftInfo, setShiftInfo] = useState<{ serviceStatus: string }>();
  const { getBorerShiftInfo, borerShiftInfoInitialized, borerShiftInfoCollection } =
    useBorerShiftInfo();

  const { shiftPicker } = useMst();

  const setup = async (shiftId: string, silentLoad = false) => {
    if (!silentLoad) setInitialShiftInfoLoad(true);
    try {
      const result = await getBorerShiftInfo(shiftId);
      setShiftInfo(result);
    } catch (error) {
      console.log('🚀 ~BorerShiftInfoCard.tsx ~ line 96 ~ error', error);
    }
    if (!silentLoad) setInitialShiftInfoLoad(false);
  };

  useEffect(() => {
    let subscription: Subscription;
    if (borerShiftInfoInitialized && !!shiftPicker?.currentBorerShiftId) {
      setup(shiftPicker.currentBorerShiftId);
      if (borerShiftInfoCollection) {
        subscription = borerShiftInfoCollection.$.subscribe(() => {
          if (shiftPicker.currentBorerShiftId) setup(shiftPicker.currentBorerShiftId, true);
        });
      }
    }

    return () => {
      if (subscription?.unsubscribe()) {
        subscription.unsubscribe();
      }
    };
  }, [borerShiftInfoCollection, borerShiftInfoInitialized, shiftPicker.currentBorerShiftId]);

  return (
    <>
      <Card elevation={2} className={classes.subCard}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.type}>
              {i18n.t('Info')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {!initialShiftInfoLoad && (
            <>
              <Grid container className={classes.verticalSpacer}>
                <Grid item xs={4}>
                  <Typography variant="body1" color="textSecondary">
                    {translate('Borer status')}
                  </Typography>
                </Grid>
                {shiftInfo?.serviceStatus && (
                  <Grid item xs={8}>
                    <Grid item xs={6}>
                      <Typography variant="body1">{shiftInfo?.serviceStatus}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {initialShiftInfoLoad && <CircularProgress className={classes.spinner} />}
        </Grid>
      </Card>
    </>
  );
};

export default observer(BorerShiftInfoCard);
