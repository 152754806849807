import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { BorerShiftCrewMemberEmployee } from '../../models/models';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';
import { SetBorerShiftCrewInput } from './useBorerShiftCrew';

export interface BorerShiftCrew extends BaseEntity {
  readonly borerShiftId: string;
  readonly crewNumber: number;
  readonly start: string;
  readonly end: string;
  readonly notUsed: boolean;
  readonly hidden: boolean;
}
export type BorerShiftCrewCollection = RxCollection<BorerShiftCrew> | null;
export type BorerShiftCrewDocument = RxDocument<BorerShiftCrew>;

export const borerShiftCrewSchema: RxJsonSchema<BorerShiftCrew> = {
  type: 'object',
  version: 4,
  description: 'describes an borer shift crew object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    borerShiftId: { type: 'string' },
    crewNumber: { type: 'number' },
    start: { type: 'string' },
    end: { type: 'string' },
    hidden: { type: 'boolean' },
    notUsed: { type: 'boolean' },
  },
  indexes: ['updatedAt'],
};

export const borerShiftCrewMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc, borerShiftCrewMemberInput: null };
  },
  2: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc };
  },
  3: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc };
  },
  4: (oldDoc: BorerShiftCrewDocument) => {
    return { ...oldDoc };
  },
};

export const borerShiftCrewPullModifier = (doc: any) => {
  const result = {
    ...doc,
    hidden: false,
  };
  return result;
};

export const borerShiftCrewPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftCrewFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftCrewFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        borerShiftId
        crewNumber
        end
        id
        isDeleted
        start
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerShiftCrewPushQueryBuilder = (doc: SetBorerShiftCrewInput) => {
  doc = doc[0];

  const query = print(gql`
    mutation setBorerShiftCrew($input: SetBorerShiftCrewInput) {
      setBorerShiftCrew(input: $input) {
        id
        siteId
        shiftId
        borerEquipmentId
        borerShiftId
      }
    }
  `);

  const variables = {
    input: doc,
  };

  return {
    query,
    variables,
  };
};

export const borerShiftCrewPushModifier = (doc: BorerShiftCrewDocument) => {
  const obj = {
    ...doc,
    modifiedOn: dayjs().toISOString(),
    startDateTime: doc.start,
    endDateTime: doc.end,
    isDeleted: doc.notUsed,
  };

  const propertyBlackList = [
    'updatedAt',
    '_deleted',
    '_attachments',
    '_rev',
    'borerShiftCrewMemberInput',
    'start',
    'end',
    '_meta',
    'notUsed',
    'hidden',
  ];

  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export interface ExtendedBorerShiftCrewDocument extends BorerShiftCrewDocument {
  borerShiftCrewMemberInput: BorerShiftCrewMemberEmployee[];
}

export default {};
