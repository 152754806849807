import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 144px)',
      overflow: 'scroll',
    },
    card: {
      padding: '16px',
    },
    topMargin: {
      marginTop: 20,
    },
    nightShiftScheduleContainer: {
      '& > div': {
        height: 'auto !important',
      },
      '& > div:first-child > div': {
        marginBottom: -8,
        overflowY: 'hidden',
      },
      marginTop: 20,
    },
    fab: {
      position: 'absolute',
      top: '135px',
      right: '15px',
      zIndex: '500',
    },
  }),
);

export default useStyles;
