import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { InspectionType } from '../../utilities/constants';
import { InspectionDocument } from '../Inspections/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import { InspectionResultDocument } from './queryBuilder';

export interface InspectionResultItem {
  inspectionDetails: InspectionDocument;
  inspectionResult: InspectionResultDocument;
}

const useInspectionResults = (inspectionId?: string) => {
  const inspectionsCollection = useRxCollection(RxdbCollectionName.INSPECTIONS);
  const inspectionResultsCollection = useRxCollection(RxdbCollectionName.INSPECTION_RESULTS);
  const { shiftPicker } = useMst();

  const [inspectionsInitialized, setInspectionsInitialized] = useState(false);
  const [inspectionResultForId, setInspectionResultForId] = useState<InspectionResultDocument>();

  useEffect(() => {
    if (inspectionsCollection && inspectionResultsCollection) setInspectionsInitialized(true);
  }, [inspectionsCollection, inspectionResultsCollection]);

  const listInspections = async (borerShiftId: string): InspectionResultItem[] => {
    if (!inspectionsCollection) return;
    if (!inspectionResultsCollection) return;

    let inspectionResults: InspectionResultDocument[];
    try {
      inspectionResults = await inspectionResultsCollection
        .find({
          selector: {
            borerShiftId,
          },
        })
        .exec();
      if (!inspectionResults) return [];
    } catch (error) {
      console.log('🚀 ~ file: useInspectionResults.tsx ~ line 58 ~ listInspections ~ error', error);
      return;
    }

    let inspections: Map<string, InspectionDocument>;
    try {
      inspections = await inspectionsCollection.findByIds(
        inspectionResults.map(result => result.inspectionId),
      );
    } catch (error) {
      console.log('🚀 ~ file: useInspectionResults.tsx ~ line 58 ~ listInspections ~ error', error);
    }

    return inspectionResults.map(result => {
      return {
        inspectionResult: result,
        inspectionDetails: inspections.get(result.inspectionId),
      };
    });
  };

  /**
   * List the PreOp Checks in this shift based on the borerShiftId
   * Also includes Borer / Post-cutting / Borer Flexiveyor checks
   *
   * @return {InspectionResultDocument[]} List of inspection results that are pre op checks
   */
  const listPreOpChecksThisShift = async (): Promise<InspectionResultItem[]> => {
    if (!inspectionResultsCollection || !shiftPicker.currentBorerShiftId || !inspectionsCollection)
      return [];

    let inspectionResults: InspectionResultDocument[];
    try {
      inspectionResults = (await inspectionResultsCollection
        .find({
          selector: {
            borerShiftId: {
              $eq: shiftPicker.currentBorerShiftId,
            },
          },
          sort: [
            {
              updatedAt: 'desc',
            },
          ],
        })
        .exec()) as InspectionResultDocument[];
    } catch (error) {
      console.log(
        '🚀 ~ file: useInspectionResults.tsx ~ line 82 ~ listPreOpChecksThisShift ~ error',
        error,
      );
      throw error;
    }

    let inspections: Map<string, InspectionDocument>;
    try {
      inspections = (await inspectionsCollection.findByIds(
        inspectionResults.map(result => result.inspectionId),
      )) as Map<string, InspectionDocument>;
    } catch (error) {
      console.log('🚀 ~ file: useInspectionResults.tsx ~ line 58 ~ listInspections ~ error', error);
    }

    const inspectionResultsWithInspection = inspectionResults
      .map(result => {
        return {
          inspectionResult: result,
          inspectionDetails: inspections.get(result.inspectionId),
        };
      })
      .filter(
        inspection =>
          inspection.inspectionDetails?.inspectionType === InspectionType.PRE_OP ||
          inspection.inspectionDetails?.inspectionType ===
            InspectionType.BORER_FLEXIVEYOR_PREOP_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BORER_CUTTING_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.POST_CUTTING_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.MINER_PRE_OP ||
          inspection.inspectionDetails?.inspectionType === InspectionType.MINER_RUNNING ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BRIDGES_PRE_OP ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BRIDGES_RUNNING ||
          inspection.inspectionDetails?.inspectionType === InspectionType.EQUIPMENT_CHECK ||
          inspection.inspectionDetails?.inspectionType === InspectionType.BORER_INSPECTION ||
          inspection.inspectionDetails?.inspectionType === InspectionType.MINEVEYOR_INSPECTION,
      );

    return inspectionResultsWithInspection;
  };

  useEffect(() => {
    let subscription: Subscription | undefined;

    if (!!inspectionResultsCollection && inspectionId) {
      subscription = inspectionResultsCollection
        .findOne({ selector: { id: { $eq: inspectionId } } })
        .$.subscribe(item => {
          setInspectionResultForId(item);
        });
    } else {
      setInspectionResultForId(undefined);
    }
    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [inspectionId, inspectionResultsCollection]);

  return {
    listInspections,
    inspectionsInitialized,
    inspectionResultsCollection,
    listPreOpChecksThisShift,
    inspectionResultForId,
  };
};

export default useInspectionResults;
