import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import useBorerShift from '../BorerShift/useBorerShift';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntity } from '../rxdbUtilityFunctions';
import { ServiceStatus, ServiceStatusCollection } from '../ServiceStatus/queryBuilder';
import useShifts from '../Shifts/useShifts';
import { BorerShiftInfo, BorerShiftInfoCollection, BorerShiftInfoDocument } from './queryBuilder';

export const useBorerShiftInfo = (borerShiftId?: string | null) => {
  const { getPreviousShift } = useShifts();
  const { getCurrentBorerShiftId } = useBorerShift();

  const borerShiftInfoCollection: BorerShiftInfoCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_INFO,
  );
  const borerStatusCollection: ServiceStatusCollection = useRxCollection(
    RxdbCollectionName.SERVICE_STATUS,
  );

  const [borerShiftInfoInitialized, setBorerShiftInfoInitialized] = useState(false);
  const [borerStatusInitialized, setBorerShiftStatusInitialized] = useState(false);

  useEffect(() => {
    if (borerShiftInfoCollection) setBorerShiftInfoInitialized(true);
    if (borerStatusInitialized) setBorerShiftStatusInitialized(true);
  }, [borerShiftInfoCollection, borerStatusCollection]);

  // Key values for cards
  const [serviceStatus, setServiceStatus] = useState<string | null>(null);

  const [rotorBits, setRotorBits] = useState<number | null>(null);
  const [trimBits, setTrimBits] = useState<number | null>(null);
  const [cornerBits, setCornerBits] = useState<number | null>(null);
  const [shearPins, setShearPins] = useState<number | null>(null);

  const [belt, setBelt] = useState<number | null>(null);
  const [breaks, setBreaks] = useState<string | null>(null);
  const [cable, setCable] = useState<number | null>(null);
  const [startMeterHours, setStartMeterHours] = useState<number | null>(null);
  const [endMeterHours, setEndMeterHours] = useState<number | null>(null);
  const [serviceStatusId, setServiceStatusId] = useState<string | null>(null);

  const [existingShiftInfoDoc, setShiftInfoDoc] = useState<BorerShiftInfoDocument>();
  const [initialLoadComplete, setLoadComplete] = useState(false);
  const [serviceStatusList, setServiceStatusList] = useState<ServiceStatus[]>([]);

  const [bottomChainShearPinsReplaced, setBottomChainShearPinsReplaced] = useState<number | null>(
    null,
  );
  const [topChainShearPinsReplaced, setTopChainShearPinsReplaced] = useState<number | null>(null);
  const [cableSlackEnd, setCableSlackEnd] = useState<string | null>(null);
  const [oreLineHeight, setOreLineHeight] = useState<number | null>(null);
  const [breakthroughFaceFootage, setBreakthroughFaceFootage] = useState<string | null>(null);
  const [oreGrade, setOreGrade] = useState<number | null>(null);

  const getBorerShiftInfoByBorerShiftId = async (id: string) => {
    if (!borerShiftInfoCollection) throw new Error('BorerShiftInfoCollection not initialized');

    try {
      const borerShiftInfo = await borerShiftInfoCollection
        .findOne()
        .where('borerShiftId')
        .eq(id)
        .exec();
      return borerShiftInfo;
    } catch (error) {
      console.log(
        '🚀 ~ file: useBorerShiftInfo.ts ~ line 82 ~ getBorerShiftInfoByBorerShiftId ~ error',
        error,
      );
      throw error;
    }
  };

  /**
   * Returns a DailySafetyTopicDocument matching the current shift
   *
   * @param {string} id Is the borer shift id
   * @return {*}
   */
  const getBorerShiftInfo = async (id: string) => {
    if (!borerShiftInfoCollection) throw new Error('BorerShiftInfoCollection not initialized');
    if (!borerStatusCollection) throw new Error('BorerStatusCollection not initialized');

    try {
      const [allData, borerServiceStatus] = await Promise.all([
        await borerShiftInfoCollection.findOne().where('borerShiftId').eq(id).exec(),
        await borerStatusCollection.find().sort('updatedAt').exec(),
      ]);

      const serviceStatusInfo = await allData?.populate('serviceStatusId');
      if (serviceStatusInfo) {
        setServiceStatus(serviceStatusInfo.description);
      } else {
        setServiceStatus(null);
      }

      if (borerServiceStatus) {
        setServiceStatusList(borerServiceStatus);
      }

      if (allData) {
        setServiceStatusId(allData.serviceStatusId);
        setRotorBits(allData.rotorBits);
        setTrimBits(allData.trimBits);
        setCornerBits(allData.cornerBits);
        setBelt(allData.belt);
        setBreaks(allData.breaks);
        setCable(allData.cable);
        setEndMeterHours(allData.meterHours);
        setBottomChainShearPinsReplaced(allData.bottomChainShearPinsReplaced);
        setTopChainShearPinsReplaced(allData.topChainShearPinsReplaced);

        if (!existingShiftInfoDoc || existingShiftInfoDoc.borerShiftId !== id)
          setShiftInfoDoc(allData);

        setShearPins(allData.shearPins);
        setCableSlackEnd(allData.cableSlackEnd);
        setOreLineHeight(allData.oreLineHeight);
        setBreakthroughFaceFootage(allData.breakthroughFaceFootage);
        setOreGrade(allData.oreGrade);
      } else {
        setRotorBits(null);
        setTrimBits(null);
        setCornerBits(null);
        setBelt(null);
        setBreaks(null);
        setCable(null);
        setShiftInfoDoc(undefined);
        setEndMeterHours(null);
        setShearPins(null);
        setBottomChainShearPinsReplaced(null);
        setTopChainShearPinsReplaced(null);
        setCableSlackEnd(null);
        setOreLineHeight(null);
        setBreakthroughFaceFootage(null);
        setOreGrade(null);
      }

      setLoadComplete(true);

      return { serviceStatus: serviceStatusInfo?.description || '' };
    } catch (error) {
      console.log('🚀 ~ file: useBorerShiftInfo.ts ~ line 33 ~ getBorerShiftInfo ~ error', error);
      throw error;
    }
  };

  const getStartMeterHours = async () => {
    const previousShift = await getPreviousShift();

    if (!previousShift) {
      setStartMeterHours(null);
      return;
    }

    const previousBorerShiftId = await getCurrentBorerShiftId(previousShift.id);

    if (!previousBorerShiftId) {
      setStartMeterHours(null);
      return;
    }

    const previousBorerShiftInfo = await getBorerShiftInfoByBorerShiftId(previousBorerShiftId);
    if (previousBorerShiftInfo) {
      setStartMeterHours(previousBorerShiftInfo.meterHours);
    } else {
      setStartMeterHours(null);
    }
  };

  const getEndMeterHours = async () => {
    const currentBorerShiftId = await getBorerShiftInfoByBorerShiftId(borerShiftId);

    if (currentBorerShiftId) {
      setEndMeterHours(currentBorerShiftId.meterHours);
    } else {
      setEndMeterHours(null);
    }
  };

  const setMeterHours = (meterHours: number) => {
    if (!borerShiftId) throw new Error('Missing borerShiftId');
    if (!borerShiftInfoCollection) throw new Error('Missing borerShiftInfoCollection');

    if (existingShiftInfoDoc) {
      return existingShiftInfoDoc.update({
        $set: {
          meterHours: meterHours || null,
        },
      });
    }
  };

  const setBorerShiftInfo = (
    rotorBitsValue: number,
    trimBitsValue: number,
    cornerBitsValue: number,
    oreLineHeightValue: number | null,
    cableSlackEndValue: string | null,
    breakthroughFaceFootageValue: string | null,
    oreGradeValue: number | null,
    beltValue?: number,
    cableValue?: number,
    shearPinsValue?: number,
    serviceBorerStatusId?: string,
    bottomChainShearPinsReplacedValue?: number,
    topChainShearPinsReplacedValue?: number,
  ) => {
    if (existingShiftInfoDoc) {
      return existingShiftInfoDoc.update({
        $set: {
          rotorBits: rotorBitsValue,
          trimBits: trimBitsValue,
          cornerBits: cornerBitsValue,
          belt: beltValue || null,
          cable: cableValue || null,
          meterHours: endMeterHours || null,
          shearPins: shearPinsValue !== undefined ? shearPinsValue : shearPins,
          serviceStatusId: serviceBorerStatusId || null,
          bottomChainShearPinsReplaced: bottomChainShearPinsReplacedValue || 0,
          topChainShearPinsReplaced: topChainShearPinsReplacedValue || 0,
          oreLineHeight: oreLineHeightValue || 0,
          cableSlackEnd: cableSlackEndValue || null,
          breakthroughFaceFootage: breakthroughFaceFootageValue || null,
          oreGrade: oreGradeValue || 0,
        },
      });
    }

    if (!borerShiftId) throw new Error('Missing borerShiftId');
    if (!borerShiftInfoCollection) throw new Error('Missing borerShiftInfoCollection');

    const newDoc: BorerShiftInfo = {
      ...generateBaseEntity(),
      borerShiftId,
      rotorBits: rotorBitsValue,
      trimBits: trimBitsValue,
      cornerBits: cornerBitsValue,
      belt: beltValue || null,
      cable: cableValue || null,
      breaks: 'Early',
      serviceStatusId: serviceStatusId || null,
      faceSalt: null,
      gprIsWorking: null,
      gprIsWorkingChangedOn: null,
      lastPreventativeMaintenanceOn: null,
      lastServicedAtPanelId: null,
      lastServicedOn: null,
      lastTrimChainChangedOn: null,
      leachAnomaly: null,
      oilLevel: null,
      onMound: null,
      parkedForMaintenance: null,
      phoneNumber: null,
      shearPins: shearPinsValue || null,
      bottomChainShearPinsReplaced: bottomChainShearPinsReplacedValue || null,
      topChainShearPinsReplaced: topChainShearPinsReplacedValue || null,
      oreLineHeight: oreLineHeightValue || 0,
      cableSlackEnd: cableSlackEndValue || null,
      breakthroughFaceFootage: breakthroughFaceFootageValue || null,
      oreGrade: oreGradeValue || 0,
    };

    return borerShiftInfoCollection.insert(newDoc);
  };

  const refreshStartAndEndMeters = async () => {
    if (borerShiftInfoCollection) {
      await Promise.all([getEndMeterHours(), getStartMeterHours()]);
    }
  };

  useEffect(() => {
    if (borerShiftInfoCollection && borerShiftId) {
      getBorerShiftInfo(borerShiftId);
      refreshStartAndEndMeters();
    }
  }, [borerShiftId, borerShiftInfoCollection]);

  // Subscribe to document values
  useEffect(() => {
    if (existingShiftInfoDoc) {
      existingShiftInfoDoc.$.subscribe(() => {
        getBorerShiftInfo(borerShiftId);
      });
    }
  }, [existingShiftInfoDoc]);

  return {
    borerShiftInfoCollection,
    borerShiftInfoInitialized,
    getBorerShiftInfo,
    rotorBits,
    trimBits,
    cornerBits,
    shearPins,
    belt,
    cable,
    breaks,
    serviceStatus,
    setBorerShiftInfo,
    initialLoadComplete,
    startMeterHours,
    endMeterHours,
    refreshStartAndEndMeters,
    setMeterHours,
    serviceStatusList,
    serviceStatusId,
    bottomChainShearPinsReplaced,
    topChainShearPinsReplaced,
    cableSlackEnd,
    oreLineHeight,
    breakthroughFaceFootage,
    oreGrade,
  };
};

export default useBorerShiftInfo;
