import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { formatDateToUnix } from '../../utilities/useDateFormatters';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface BorerOperatorState extends BaseEntity {
  readonly borerStateId: string;
  readonly borerStateTypeId: string;
  readonly comment: string | null;
  readonly startTime: string;
  readonly endTime: string | null;
  readonly borerOperatorStateRequestId: string | null;
  readonly startTimeUnix: number;
  readonly endTimeUnix?: number;
  readonly borerShiftAdvanceId: string | null;
  readonly showInSchedulerView: boolean | null;
  readonly cuttingTypeId: string | null;
  readonly cuttingMethodId: string | null;
}
export type BorerOperatorStateCollection = RxCollection<BorerOperatorState> | null;
export type BorerOperatorStateDocument = RxDocument<BorerOperatorState>;

export const borerOperatorStateSchema: RxJsonSchema<BorerOperatorState> = {
  type: 'object',
  version: 3,
  description: 'describes a BorerOperatorState object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerStateId: { type: 'string' },
    borerStateTypeId: {
      type: 'string',
      ref: RxdbCollectionName.BORER_STATE_TYPE,
    },
    comment: { type: ['string', 'null'] },
    startTime: { type: 'string' },
    endTime: { type: ['string', 'null'] },
    borerOperatorStateRequestId: { type: ['string', 'null'] },
    startTimeUnix: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    endTimeUnix: {
      type: ['number', 'null'],
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    borerShiftAdvanceId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.BORER_SHIFT_ADVANCE,
    },
    showInSchedulerView: { type: 'boolean' },
    cuttingTypeId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.CUTTING_TYPE,
    },
    cuttingMethodId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.CUTTING_METHOD,
    },
  },
  indexes: ['startTimeUnix'],
};

export const borerOperatorStateMigrationStrategy: MigrationStrategies = {
  1: (oldDoc: BorerOperatorStateDocument) => ({ ...oldDoc, borerShiftAdvanceId: null }),
  2: (oldDoc: BorerOperatorStateDocument) => ({ ...oldDoc, showInSchedulerView: true }),
  3: (oldDoc: BorerOperatorStateDocument) => ({
    ...oldDoc,
    cuttingTypeId: null,
    cuttingMethodId: null,
  }),
};

export const borerOperatorStatePullQueryBuilder = (doc: BorerOperatorState) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerOperatorStateFeed($limit: Int!, $lastUpdateAt: Float!, $borerEquipmentId: ID!) {
      borerOperatorStateFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        borerStateId
        borerStateTypeId
        comment
        endTime
        id
        isDeleted
        startTime
        updatedAt
        version
        borerOperatorStateRequestId
        borerShiftAdvanceId
        cuttingTypeId
        cuttingMethodId
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerOperatorStatePullModifier = (doc: BorerOperatorState) => {
  const result = {
    ...doc,
    startTimeUnix: formatDateToUnix(doc.startTime),
    endTimeUnix: doc.endTime ? formatDateToUnix(doc.endTime) : null,
    showInSchedulerView: true,
  };
  return result;
};

export default {};
