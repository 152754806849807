import type { DocumentNode } from 'graphql';
import type { RxDatabase } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';

import { apiConfig } from '../../config/api.config';
import { createAppSyncApolloClient } from '../../graphql/WSClient/WSClient';
import { BorerDatabaseCollections } from '../../models/BorerDatabaseCollections';
import { LiveInterval } from '../LiveInterval';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultModifier } from '../syncReplicationStatesWithGraphQL';

interface SyncGraphQLWithSubscriptionForOptions {
  idToken: string;
  rxdb: RxDatabase<BorerDatabaseCollections>;
  collectionName: RxdbCollectionName;
  pullQueryBuilder: any;
  pullModifier?: any;
  pushQueryBuilder?: any;
  pushModifier?: any;
  // Used when we absolutely need to update a document that was already deleted
  // See comments on https://github.com/Nutrien/minesight-borer-app/pull/514/
  bypassDeletedFlag?: boolean;
  live?: boolean;
  syncInterval?: LiveInterval;
  pullBatchSize?: number;
  // Used for GraphQL subscriptions
  subscriptionQuery?: DocumentNode;
  subscriptionVariables?: Record<string, any>;
}

export const DEBUG = sessionStorage.getItem('DEBUG') === 'true';

const initializeSyncSubscription = async (
  idToken: string,
  state: RxGraphQLReplicationState<any>,
  subscriptionQuery: DocumentNode,
  variables?: Record<string, any>,
) => {
  if (!state) return;
  if (DEBUG) console.log(`Initializing subscription...`);

  const wsClient = await createAppSyncApolloClient(idToken);

  wsClient.subscribe({ query: subscriptionQuery, variables }).subscribe({
    next(data) {
      if (DEBUG) console.log(`Subscription notification received: ${data}`);
      state.run();
    },
    error(err) {
      if (DEBUG) console.log('🚀 ~  useGraphSubscription.ts ~ line 18 ~ err', err);
    },
  });
};

export const syncGraphQLWithSubscriptionFor = ({
  idToken,
  rxdb,
  collectionName,
  pullQueryBuilder,
  pullModifier,
  pushQueryBuilder,
  pushModifier,
  pullBatchSize,
  bypassDeletedFlag = false,
  live = true,
  syncInterval = LiveInterval.HOURS_1,
  subscriptionQuery,
  subscriptionVariables,
}: SyncGraphQLWithSubscriptionForOptions) => {
  const config: any = {
    url: `${apiConfig.graphUrl}?collection=${collectionName}`,
    headers: { Authorization: idToken },
    pull: {
      queryBuilder: pullQueryBuilder,
      modifier: pullModifier || defaultModifier,
      batchSize: pullBatchSize,
    },
    deletedFlag: bypassDeletedFlag ? 'notUsed' : 'isDeleted',
    live,
    liveInterval: syncInterval,
  };

  if (pushQueryBuilder) {
    config.push = {
      queryBuilder: pushQueryBuilder,
      batchSize: 1,
      modifier: pushModifier || defaultModifier,
    };
  }

  const state: RxGraphQLReplicationState<any> =
    rxdb?.collections[collectionName]?.syncGraphQL(config);

  // If a subscription query is provided, subscribe to it
  if (subscriptionQuery) {
    initializeSyncSubscription(idToken, state, subscriptionQuery, subscriptionVariables);
  }

  return state;
};

export default {};
