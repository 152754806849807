import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface InspectionSeverity extends BaseEntity {
  readonly inspectionId: string;
  readonly description: string;
  readonly lowSeverity: number;
  readonly highSeverity: number;
}
export type InspectionSeverityCollection = RxCollection<InspectionSeverity> | null;
export type InspectionSeverityDocument = RxDocument<InspectionSeverity>;

export const inspectionSeveritySchema: RxJsonSchema<InspectionSeverity> = {
  type: 'object',
  version: 0,
  description: 'describes a InspectionSeverity object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    inspectionId: {
      ref: RxdbCollectionName.INSPECTIONS,
      type: 'string',
    },
    description: { type: 'string' },
    lowSeverity: { type: 'number' },
    highSeverity: { type: 'number' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

export const inspectionSeverityPullQueryBuilder = (doc: InspectionSeverityDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query inspectionSeverityFeed($siteId: ID!, $updatedAt: Float!, $limit: Int!) {
      inspectionSeverityFeed(lastUpdateAt: $updatedAt, limit: $limit, siteId: $siteId) {
        id
        inspectionId
        description
        lowSeverity
        highSeverity
        isDeleted
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      updatedAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
