import type { GridSize } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, MaterialPalette, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useMiningCuts from '../../rxdb/MiningCut/useMiningCuts';
import { ProductionDocument } from '../../rxdb/Productions/queryBuilder';
import useProduction from '../../rxdb/Productions/useProduction';
import { ProductionTargetDocument } from '../../rxdb/ProductionTarget/queryBuilder';
import useSite from '../../rxdb/Site/useSite';
import useTargetBorerRunningTimes from '../../rxdb/TargetBorerRunningTimes/useTargetBorerRunningTimes';
import useActualCuttingTimes from '../../utilities/hooks/useActualCuttingTimes';
import { useNumberFormatter } from '../../utilities/useNumberFormatter';

const useStyles = makeStyles(() =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    subTitleText: {
      color: MaterialPalette.text.disabled,
    },
  }),
);

interface Props {
  productionTargetDocument: ProductionTargetDocument;
  productionForShift: ProductionDocument[];
}

const colSpacing: Array<boolean | GridSize | undefined> = [4, 3, 5];

const ProductionTargetCard: React.FC<Props> = ({
  productionTargetDocument,
  productionForShift,
}: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const { getProductionTotalsForShift } = useProduction();
  const {
    targetBorerRunningTimeCollectionInitialized,
    dayShiftStart,
    nightShiftStart,
    dayShiftEnd,
    nightShiftEnd,
  } = useTargetBorerRunningTimes();

  const { miningCutsInitialized } = useMiningCuts();
  const { format } = useNumberFormatter();
  const { distanceUnitAbbreviation } = useSite();

  const [actualTonnes, setActualTonnes] = useState<string>();
  const [totalAdvance, setTotalAdvance] = useState<string>();
  const [targetStart, setTargetStart] = useState<string>();
  const [targetEnd, setTargetEnd] = useState<string>();
  const { actualCuttingStartTime, actualCuttingEndTime } = useActualCuttingTimes();

  const calculateActualTonnes = async () => {
    const actual = await getProductionTotalsForShift();
    setActualTonnes(format(actual.actualTotalTonnes));
    setTotalAdvance(format(actual.actualTotalAdvance));
  };

  const calculateTargetRunningTimes = async () => {
    const startTime = shiftPicker.isDayShift() ? dayShiftStart : nightShiftStart;
    const endTime = shiftPicker.isDayShift() ? dayShiftEnd : nightShiftEnd;
    setTargetStart(startTime);
    setTargetEnd(endTime);
  };

  const setup = async () => {
    await calculateActualTonnes();
    await calculateTargetRunningTimes();
  };

  useEffect(() => {
    if (
      productionTargetDocument &&
      productionForShift &&
      miningCutsInitialized &&
      shiftPicker?.currentBorerShiftId
    ) {
      setup();
    }
  }, [
    productionTargetDocument,
    productionForShift,
    miningCutsInitialized,
    dayShiftStart,
    dayShiftEnd,
    nightShiftStart,
    nightShiftEnd,
    targetBorerRunningTimeCollectionInitialized,
    shiftPicker?.currentBorerShiftId,
  ]);

  return (
    <Card className={classes.cardRoot} onClick={() => {}}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h5">{i18n.t('Target vs actual')}</Typography>
        </Grid>
        <Grid item xs={colSpacing[2]} />
        <Grid item xs={colSpacing[0]} />
        <Grid item xs={colSpacing[1]}>
          <Typography variant="body1" className={classes.subTitleText}>
            {i18n.t('Target')}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[2]}>
          <Typography variant="body1" className={classes.subTitleText}>
            {i18n.t('Actual')}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[0]}>
          <Typography variant="body1" className={classes.subTitleText}>
            {i18n.t('Total tonnes')}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[1]}>
          <Typography variant="body1">{productionTargetDocument.tonnes} t</Typography>
        </Grid>
        <Grid item xs={colSpacing[2]}>
          <Typography variant="body1">{actualTonnes} t</Typography>
        </Grid>
        <Grid item xs={colSpacing[0]}>
          <Typography variant="body1" className={classes.subTitleText}>
            {i18n.t('Total advance')}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[1]}>
          <Typography variant="body1">
            {productionTargetDocument.distance !== null &&
            productionTargetDocument.distance !== undefined ? (
              <>
                {productionTargetDocument.distance} {distanceUnitAbbreviation}
              </>
            ) : (
              <>{i18n.t('Unknown')}</>
            )}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[2]}>
          <Typography variant="body1">
            {totalAdvance} {distanceUnitAbbreviation}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[0]}>
          <Typography variant="body1" className={classes.subTitleText}>
            {i18n.t('Start time')}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[1]}>
          <Typography variant="body1">{targetStart}</Typography>
        </Grid>
        <Grid item xs={colSpacing[2]}>
          <Typography variant="body1">{actualCuttingStartTime}</Typography>
        </Grid>
        <Grid item xs={colSpacing[0]}>
          <Typography variant="body1" className={classes.subTitleText}>
            {i18n.t('End time')}
          </Typography>
        </Grid>
        <Grid item xs={colSpacing[1]}>
          <Typography variant="body1">{targetEnd}</Typography>
        </Grid>
        <Grid item xs={colSpacing[2]}>
          <Typography variant="body1">{actualCuttingEndTime}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductionTargetCard;
