import { Borer } from '@/mobx-models/Equipment/Equipment';

import { BORER_PAGINATION_LIMIT, EMPTY_GUID } from '../utilities/constants';
import { client, gql } from './Client';

const listEquipmentForTypeQuery = gql`
  query listEquipmentForType($siteId: ID!, $limit: Int!, $lastId: ID!) {
    listEquipmentForType(
      siteId: $siteId
      equipmentType: BorerAndRehab
      limit: $limit
      lastId: $lastId
    ) {
      id
      longName
      shortName
      isActive
      equipmentType {
        description
        id
      }
      assignedPanel {
        id
        description
        block {
          description
        }
        miningMethod {
          layer
          name
          zone
        }
      }
    }
  }
`;

export interface ListBorersResult {
  data: {
    listEquipmentForType: Borer[];
  };
}

const listBorers = (siteId: string, limit = BORER_PAGINATION_LIMIT, lastId = EMPTY_GUID) =>
  client.query({
    query: listEquipmentForTypeQuery,
    variables: { siteId, limit, lastId },
    fetchPolicy: 'no-cache',
  });

const listEquipmentQuery = gql`
  query listEquipment($siteId: ID!, $limit: Int, $lastId: ID, $includeInactive: Boolean) {
    listEquipment(
      siteId: $siteId
      limit: $limit
      lastId: $lastId
      includeInactive: $includeInactive
    ) {
      id
      comment
      status {
        id
      }
      tonnesPerScoop
    }
  }
`;

const listEquipment = (siteId: string, limit: number, lastId: string, includeInactive: boolean) => {
  if (!client) throw new Error('client was never created properly');
  return client.query({
    query: listEquipmentQuery,
    variables: { siteId, limit, lastId, includeInactive },
    fetchPolicy: 'no-cache',
  });
};

export { listBorers, listEquipment };
