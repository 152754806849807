import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface ProductionTarget extends BaseEntity {
  readonly borerShiftId: string;
  readonly tonnes: number;
  readonly distance: number | null | undefined;
}
export type ProductionTargetCollection = RxCollection<ProductionTarget> | null;
export type ProductionTargetDocument = RxDocument<ProductionTarget>;

export const productionTargetSchema: RxJsonSchema<ProductionTarget> = {
  type: 'object',
  version: 3,
  description: 'describes a ProductionTarget object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    tonnes: { type: 'number' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    distance: { type: ['number', 'null'] },
  },
};

export const productionTargetPullQueryBuilder = (doc: ProductionTarget) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }
  const query = print(gql`
    query borerShiftProductionTargetFeed(
      $borerEquipmentId: ID!
      $lastUpdateAt: Float!
      $limit: Int!
    ) {
      borerShiftProductionTargetFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        borerShiftId
        tonnes
        id
        isDeleted
        updatedAt
        version
        distance
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const productionTargetMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: ProductionTargetDocument) => {
    return { ...oldDoc, footage: null };
  },
  2: (oldDoc: ProductionTargetDocument) => {
    return { ...oldDoc };
  },
  3: (oldDoc: ProductionTargetDocument) => {
    return { ...oldDoc, distance: oldDoc.footage };
  },
};

export default {};
