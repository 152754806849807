import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { getInspectionOption } from '../../graphql/InspectionOptions';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface InspectionOptions extends BaseEntity {
  readonly description: string;
  readonly displayOrder: number;
  readonly equipmentTypeId: string | null;
  readonly hazardConditionTypeId: string | null;
  readonly inspectionCategoryId: string;
  readonly severityValue: number;
}
export type InspectionOptionsCollection = RxCollection<InspectionOptions> | null;
export type InspectionOptionsDocument = RxDocument<InspectionOptions>;

export const inspectionOptionsSchema: RxJsonSchema<InspectionOptions> = {
  type: 'object',
  version: 1,
  description: 'describes a InspectionOptions object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    displayOrder: { type: 'number' },
    equipmentTypeId: { type: ['string', 'null'] },
    hazardConditionTypeId: {
      ref: RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
      type: ['string', 'null'],
    },
    inspectionCategoryId: {
      ref: RxdbCollectionName.INSPECTION_CATEGORIES,
      type: 'string',
    },
    severityValue: { type: 'number' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

export const inspectionOptionsMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: InspectionOptionsDocument) => {
    const { id } = oldDoc;

    // For each document we call the get and migrate with the new severity value
    return getInspectionOption(id).then(response => {
      const { severityValue } = response.data.getInspectionOption;
      return { ...oldDoc, severityValue };
    });
  },
};

export const inspectionOptionsPullQueryBuilder = (doc: InspectionOptionsDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query inspectionOptionFeed($siteId: ID!, $updatedAt: Float!, $limit: Int!) {
      inspectionOptionFeed(lastUpdateAt: $updatedAt, limit: $limit, siteId: $siteId) {
        id
        description
        displayOrder
        equipmentTypeId
        hazardConditionTypeId
        inspectionCategoryId
        severityValue
        isDeleted
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      updatedAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
