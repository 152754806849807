import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';

import CommentCard from '@/pages/ShiftEnd/CommentCard';

import { useMst } from '../../mobx-models/Root';
import useBorerShift from '../../rxdb/BorerShift/useBorerShift';
import useShifts from '../../rxdb/Shifts/useShifts';
import { useDateFormatters } from '../../utilities';
import { BORER_SHIFT_WRITE_PERMISSION } from '../../utilities/constants';
import useDebug from '../../utilities/useDebug';
import CloseShiftModal from '../CloseShiftModal';
import AutomationCommentCard from '../pages/ShiftEnd/AutomationCommentCard/AutomationCommentCard';
import UnableToCloseShiftModal from '../UnableToCloseShiftModal';
import EditSuppliesSidePanel from './EditSuppliesSidePanel';
import ShiftEndInfoCard from './ShiftEndInfoCard';
import ShiftEndLocationCard from './ShiftEndLocationCard';
import ShiftEndMeterHoursCard from './ShiftEndMeterHoursCard';
import ShiftEndProductionCard from './ShiftEndProductionCard';
import ShiftEndRunningTimesCard from './ShiftEndRunningTimesCard';
import ShiftEndSuppliesCard from './ShiftEndSuppliesCard';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    subCard: {
      margin: '0 !important',
      padding: '16px',
    },
    buttonContainer: {
      '& > div': {
        alignItems: 'flex-end',
      },
    },
    closeShiftButton: {
      maxWidth: '160px',
    },
  }),
);

const ShiftEndCard: FC = () => {
  const classes = useStyles();
  const { user, shiftPicker } = useMst();
  const { formatAsDayOfWeekPlusDate, isNightShift } = useDateFormatters();
  const { isRocanville } = useSiteFeatures();
  const DEBUG = useDebug();
  const [editSuppliesOpen, setEditSuppliesOpen] = useState<boolean>(false);
  const [closeShiftModalOpen, setCloseShiftModalOpen] = useState(false);
  const [unableToCloseShiftModalOpen, setUnableToCloseShiftModalOpen] = useState(false);
  const [borerShiftOpen, setBorerShiftOpen] = useState(false);
  const [shiftCanBeClosed, setShiftCanBeClosed] = useState(false);
  const [shiftStillOpenDate, setShiftStillOpenDate] = useState<string>('');
  const [borerShiftSubscription, setBorerShiftSubscription] = useState<Subscription>();

  const { shiftsInitialized } = useShifts();

  const { getCurrentBorerShiftById, borerShiftCollection, getLastKnownOpenShift } = useBorerShift();

  const checkShiftStatusStatus = async () => {
    const remainingMinutes = shiftPicker.getRemainingShiftMinutes();

    if (remainingMinutes <= 720 && shiftPicker.currentBorerShiftId) {
      const borerShift = await getCurrentBorerShiftById(shiftPicker.currentBorerShiftId);
      setBorerShiftOpen(!borerShift?.closedOn);
    } else {
      setBorerShiftOpen(false);
    }

    if (DEBUG) {
      console.table({
        borerShiftOpen,
        remainingMinutes,
      });
    }
  };

  const subscribeToBorerShifts = () => {
    if (!borerShiftSubscription && borerShiftCollection) {
      const subscription = borerShiftCollection.$.subscribe(() => {
        checkShiftStatusStatus();
      });
      setBorerShiftSubscription(subscription);
    }
  };

  const init = async () => {
    const lastOpenShift = await getLastKnownOpenShift();
    const nightShift: boolean = isNightShift(
      lastOpenShift?.shift?.start,
      lastOpenShift?.shift?.end,
    );
    if (!lastOpenShift) {
      setShiftCanBeClosed(true);
    } else {
      setShiftCanBeClosed(false);
      setShiftStillOpenDate(formatAsDayOfWeekPlusDate(lastOpenShift?.shift.start, nightShift));
    }
  };

  useEffect(() => {
    if (shiftsInitialized && borerShiftCollection) {
      init();
    }
  }, [shiftsInitialized, borerShiftCollection, shiftPicker.Date, shiftPicker.currentBorerShiftId]);

  useEffect(() => {
    subscribeToBorerShifts();
    return () => {
      if (borerShiftSubscription) borerShiftSubscription.unsubscribe();
    };
  }, [borerShiftCollection]);

  useEffect(() => {
    if (borerShiftCollection && shiftPicker.currentBorerShiftId) {
      checkShiftStatusStatus();
    }
  }, [borerShiftCollection, shiftPicker.currentBorerShiftId]);

  const closeShiftClicked = () => {
    if (!shiftCanBeClosed) {
      setUnableToCloseShiftModalOpen(true);
    } else {
      setCloseShiftModalOpen(true);
    }
  };

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography variant="h3">{i18n.t('Shift end')}</Typography>
            </Grid>
            <Grid item xs={4} className={classes.buttonContainer}>
              {user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
                <Button
                  className={classes.closeShiftButton}
                  variant={borerShiftOpen ? 'contained' : 'outlined'}
                  color="primary"
                  noMinHeight
                  startAdornment={
                    borerShiftOpen ? (
                      <Icons.UnlockFeather />
                    ) : (
                      <Icons.LockFeather color="disabled" />
                    )
                  }
                  onClick={closeShiftClicked}
                  data-cy="close-shift"
                  disabled={!borerShiftOpen}
                  id="close-shift"
                >
                  {borerShiftOpen ? i18n.t('Close shift') : i18n.t('Shift closed')}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <ShiftEndProductionCard />
              <ShiftEndMeterHoursCard borerShiftOpen={borerShiftOpen} />
              <ShiftEndRunningTimesCard />
            </Grid>
            <Grid item xs={6}>
              <ShiftEndLocationCard />
              <ShiftEndSuppliesCard
                onEditClicked={() => setEditSuppliesOpen(true)}
                borerShiftOpen={borerShiftOpen}
              />
              {isRocanville && <AutomationCommentCard />}
              <CommentCard />
              <ShiftEndInfoCard borerShiftOpen={borerShiftOpen} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <EditSuppliesSidePanel
        open={editSuppliesOpen}
        onClose={() => {
          setEditSuppliesOpen(false);
        }}
        onOpen={() => {
          setEditSuppliesOpen(true);
        }}
      />
      <UnableToCloseShiftModal
        open={unableToCloseShiftModalOpen}
        onClose={() => setUnableToCloseShiftModalOpen(false)}
        shiftDate={shiftStillOpenDate}
      />
      <CloseShiftModal open={closeShiftModalOpen} onClose={() => setCloseShiftModalOpen(false)} />
    </>
  );
};

export default observer(ShiftEndCard);
