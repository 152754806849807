import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface EquipmentDeficiencyLog extends BaseEntity {
  readonly comment: string;
  readonly siteEmployeeId: string;
  readonly equipmentDeficiencyId: string;
  readonly createdOn: string;
}
export type EquipmentDeficiencyLogCollection = RxCollection<EquipmentDeficiencyLog> | null;
export type EquipmentDeficiencyLogDocument = RxDocument<EquipmentDeficiencyLog>;

export const equipmentDeficiencyLogSchema: RxJsonSchema<EquipmentDeficiencyLog> = {
  type: 'object',
  version: 1,
  description: 'describes a EquipmentDeficiencyLog object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    comment: { type: 'string' },
    siteEmployeeId: { type: 'string' },
    equipmentDeficiencyId: {
      ref: RxdbCollectionName.EQUIPMENT_DEFICIENCY,
      type: 'string',
    },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    createdOn: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

export const equipmentDeficiencyLogPullQueryBuilder = (doc: EquipmentDeficiencyLogDocument) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query equipmentDeficiencyLogFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
      equipmentDeficiencyLogFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        comment
        siteEmployeeId
        equipmentDeficiencyId
        isDeleted
        updatedAt
        version
        createdOn
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const equipmentDeficiencyLogPushQueryBuilder = (doc: EquipmentDeficiencyLogDocument) => {
  doc = doc[0];

  const query = print(gql`
    mutation createEquipmentDeficiencyLog(
      $id: ID!
      $comment: String!
      $siteEmployeeId: ID!
      $equipmentDeficiencyId: ID!
      $createdOn: AWSDateTime
    ) {
      createEquipmentDeficiencyLog(
        input: {
          id: $id
          comment: $comment
          siteEmployeeId: $siteEmployeeId
          equipmentDeficiencyId: $equipmentDeficiencyId
          createdOn: $createdOn
        }
      ) {
        id
        isWorkOrderRequired
        siteId
      }
    }
  `);

  const variables = {
    ...doc,
    equipmentDeficiencyId: doc.equipmentDeficiencyId,
  };

  return {
    query,
    variables,
  };
};

export const equipmentDeficiencyLogPushModifier = (doc: EquipmentDeficiencyLogDocument) => {
  const obj = {
    ...doc,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export const equipmentDeficiencyLogStrategies: MigrationStrategies = {
  1: (oldDoc: EquipmentDeficiencyLogDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
};

export default {};
