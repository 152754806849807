import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import postal from 'postal';
import type { RxDatabase } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';

import { BorerDatabaseCollections } from '@/models/BorerDatabaseCollections';

import { isJestOrStorybook } from '../test-helpers/isJestOrStorybook';
import { setupMockRxdbInstance } from '../test-helpers/setupMockRxdbInstance';
import initializeDB from './initializeDB';
import RxdbCollectionName from './rxdbCollectionName';
import syncReplicationStatesWithGraphQL, {
  refreshTokenOnSyncState,
} from './syncReplicationStatesWithGraphQL';

const getIdToken = async (): Promise<string> => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();
  return idToken;
};

export default class RxdbManager {
  private static _instance: RxdbManager;

  db: RxDatabase<BorerDatabaseCollections> | undefined;

  isInitialized: boolean;

  syncStates: RxGraphQLReplicationState<any>[];

  syncActive: boolean;

  DEBUG: boolean;

  constructor(DEBUG = true, providedDb?: RxDatabase) {
    this.db = undefined;
    this.syncStates = [];
    this.isInitialized = false;
    this.syncActive = false;
    this.DEBUG = DEBUG;
    RxdbManager._instance = this;
    if (providedDb) this.db = providedDb;
    this.initialize();
  }

  public static get instance(): RxdbManager {
    return RxdbManager._instance;
  }

  public static get syncStates(): RxGraphQLReplicationState<any>[] {
    return this.syncStates;
  }

  public initialize = async () => {
    // No need to run during tests which have import trail back to RxdbManager;
    if (isJestOrStorybook()) {
      if (!this.db) {
        this.db = await setupMockRxdbInstance();
      }
      this.isInitialized = true;
      return;
    }

    const rxdbInfo = await initializeDB();
    this.db = rxdbInfo.db;
    this.isInitialized = true;
    postal.publish({
      channel: 'db',
      topic: 'db.initialized',
    });
  };

  startReplication = async (borerdelaysV2enabled = false) => {
    if (isJestOrStorybook()) return;
    if (this.DEBUG) console.log('startReplication called...');

    // if the db is not initialized, we can't start replication
    if (!this.isInitialized) return;

    const borerOperatorStatesSyncing =
      this.syncStates.find(
        state => state.collection.name === RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
      ) !== undefined;

    const allSyncStatesRunning =
      this.syncStates.length > 0 &&
      this.syncStates.every(state => {
        return state.isStopped() === false;
      });

    if (this.DEBUG)
      console.table({
        borerdelaysV2enabled,
        borerOperatorStatesSyncing,
        allSyncStatesRunning,
      });

    if (
      (borerdelaysV2enabled && !borerOperatorStatesSyncing) || // if borerdelaysV2enabled and borerOperatorStatesSyncing is false (not syncing but should be)
      (!borerdelaysV2enabled && borerOperatorStatesSyncing) || // if borerdelaysV2enabled is false and borerOperatorStatesSyncing is true (syncing but shouldn't be)
      !allSyncStatesRunning // if all sync states are not running
    ) {
      await this.stopReplication();
    } else return;

    try {
      const idToken = await getIdToken();

      const { syncStates } = await syncReplicationStatesWithGraphQL(
        idToken,
        this.db,
        true,
        borerdelaysV2enabled,
      );

      this.syncStates = syncStates;

      if (this.DEBUG) {
        const allRunning = this.syncStates.every(state => !state.isStopped());
        console.log(
          `Start replication complete... ${this.syncStates.length} sync states. All running?: ${allRunning}`,
        );
      }
      this.syncActive = true;

      postal.publish({
        channel: 'sync',
        topic: 'sync.online',
      });
    } catch (err) {
      console.log('🚀 ~ file: RxdbManager.ts:130 ~ RxdbManager ~ startReplication= ~ err:', err);
      Sentry.captureException(err, {
        tags: {
          rxDBError: true,
          startReplicationFailure: true,
        },
      });
    }
  };

  stopReplication = async () => {
    if (!this.isInitialized || isJestOrStorybook()) return;
    if (this.DEBUG) console.log('stopReplication called...');

    if (this.syncStates) {
      await Promise.all(
        this.syncStates.map(async state => {
          return state.cancel();
        }),
      );
      if (this.DEBUG) {
        const allStopped = this.syncStates.every(state => state.isStopped());
        console.log('stopReplication completed...', allStopped);
      }
    }
    this.syncActive = false;
  };

  runSingleReplication = async () => {
    if (this.DEBUG) console.log('Running single replication');
    try {
      if (this.syncStates) {
        await Promise.all(
          this.syncStates.map(async state => {
            return state.run();
          }),
        );
      }
    } catch (error) {
      console.error('🚀 ~ file: usetsx ~ line 136 ~ runSingleReplication ~ error', error);
      throw error;
    }
  };

  refreshTokensOnAllCollections = async () => {
    if (this.syncStates) {
      console.log('Calling refresh token on all collections');

      for (const state of this.syncStates) {
        await refreshTokenOnSyncState(state, 6 * 60 * 60);
      }
    }
  };
}
