import { useCallback, useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { customStepSort } from '../../utilities/sortHelper';
import { PassDocument } from '../Passes/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { SequenceDocument } from '../Sequences/queryBuilder';
import { MiningCutDocument, MiningCutSequencePassObj } from './queryBuilder';

const useMiningCuts = (miningPattern?: string, miningMethod?: string) => {
  const miningCutsCollection = useRxCollection<MiningCutDocument>(RxdbCollectionName.MINING_CUTS);
  const sequencesCollection = useRxCollection<SequenceDocument>(RxdbCollectionName.SEQUENCES);
  const passesCollection = useRxCollection<PassDocument>(RxdbCollectionName.PASSES);
  const [miningCutsInitialized, setMiningCutsInitialized] = useState(false);
  const [miningCutListForMiningPattern, setMiningCutListForMiningPattern] = useState<
    MiningCutDocument[]
  >([]);
  const [miningCutsForMiningPatternLoaded, setMiningCutsForMiningPatternLoaded] = useState(false);
  const [miningCutListForMiningMethod, setMiningCutListForMiningMethod] = useState<
    MiningCutDocument[]
  >([]);
  const [miningCutsForMiningMethodLoaded, setMiningCutsForMiningMethodLoaded] = useState(false);

  useEffect(() => {
    if (miningCutsCollection && sequencesCollection && passesCollection)
      setMiningCutsInitialized(true);
  }, [miningCutsCollection, sequencesCollection, passesCollection]);

  const getMiningCutById = useCallback(
    async (miningCutId?: string): Promise<MiningCutSequencePassObj | undefined> => {
      if (!miningCutsCollection || !miningCutId) return undefined;

      let miningCutDocument;

      try {
        miningCutDocument = await miningCutsCollection
          .findOne({
            selector: {
              id: miningCutId,
            },
          })
          .exec();
      } catch (error) {
        console.log('🚀 ~ file: useMiningCuts.tsx ~ line 44 ~ getMiningCutById ~ error', error);
        return undefined;
      }

      const [sequence, pass] = await Promise.all([
        miningCutDocument?.populate('sequence'),
        miningCutDocument?.populate('pass'),
      ]);

      if (miningCutDocument) {
        return {
          miningMethod: miningCutDocument.miningMethod,
          miningCut: miningCutDocument,
          sequence,
          pass,
          sequencePassString: `${sequence?.description || ''}${
            sequence?.description && pass?.description ? ' ' : ''
          }${pass?.description || ''}`,
        };
      }
      return undefined;
    },
    [miningCutsCollection],
  );

  const getMiningCutForSequence = async (
    sequenceId: string,
  ): Promise<MiningCutDocument | undefined> => {
    if (!miningCutsCollection) return undefined;

    let miningCutDocument;

    try {
      miningCutDocument = await miningCutsCollection
        .findOne({
          selector: {
            sequence: sequenceId,
          },
        })
        .exec();
    } catch (error) {
      console.log(
        '🚀 ~ file: useMiningCuts.tsx ~ line 68 ~ getMiningCutForSequence ~ error',
        error,
      );
    }

    return miningCutDocument || undefined;
  };

  const getMiningCutWithDetails = async (miningCut: MiningCutDocument) => {
    const [sequence, pass, miningPatternValue] = await Promise.all([
      miningCut.populate('sequence'),
      miningCut.populate('pass'),
      miningCut.populate('miningPattern'),
    ]);

    return {
      miningMethod: miningCut.miningMethod,
      miningCut,
      sequence,
      pass,
      miningPattern,
      miningPatternName: miningPatternValue?.description || 'Universal',
      sequencePassString: `${sequence?.description || ''}${
        sequence?.description && pass?.description ? ' ' : ''
      }${pass?.description || ''}`,
    };
  };

  const getMiningCutsForMiningMethod = async (
    miningMethodToQuery: string,
  ): Promise<MiningCutSequencePassObj[] | undefined> => {
    if (!miningCutsCollection || !sequencesCollection || !passesCollection) return undefined;

    let result: MiningCutSequencePassObj[] = [];
    let miningCutDocuments: MiningCutDocument[] = [];

    try {
      miningCutDocuments = await miningCutsCollection
        .find({
          selector: {
            $or: [
              { miningMethod: { $eq: miningMethodToQuery } },
              { miningMethod: { $eq: 'Universal' } },
            ],
          },
        })
        .exec();
    } catch (error) {
      console.log(
        '🚀 ~ file: useMiningCuts.tsx ~ line 95 ~ getMiningCutsForMiningMethod ~ error',
        error,
      );
    }

    // Filter out mining cuts that are the same because their sequence and pass are the same
    const nonDuplicateMiningCuts = miningCutDocuments.filter(
      (miningCut, index, self) =>
        index ===
        self.findIndex(t => t.sequence === miningCut.sequence && t.pass === miningCut.pass),
    );

    // hydrate sequence and pass
    result = await Promise.all(
      nonDuplicateMiningCuts.map(async miningCut => getMiningCutWithDetails(miningCut)),
    );

    return result.sort(customStepSort);
  };

  useEffect(() => {
    const getMiningCuts = async () => {
      setMiningCutsForMiningMethodLoaded(false);
      if (miningMethod && miningCutsCollection && sequencesCollection && passesCollection) {
        const mcList = await getMiningCutsForMiningMethod(miningMethod);

        if (mcList) {
          setMiningCutListForMiningMethod(mcList);
        } else {
          setMiningCutListForMiningMethod([]);
        }
      } else {
        setMiningCutListForMiningMethod([]);
      }
      setMiningCutsForMiningMethodLoaded(true);
    };
    getMiningCuts();
  }, [miningMethod, miningCutsCollection, sequencesCollection, passesCollection]);

  const getMiningCutsForMiningPattern = async (
    miningPatternValue: string,
  ): Promise<MiningCutSequencePassObj[] | undefined> => {
    if (!miningCutsCollection || !sequencesCollection || !passesCollection) return undefined;

    let result: MiningCutSequencePassObj[] = [];
    let miningCutDocuments: MiningCutDocument[] = [];

    try {
      miningCutDocuments = await miningCutsCollection
        .find({
          selector: {
            $or: [
              { miningPattern: { $eq: miningPatternValue } },
              { miningMethod: { $eq: 'Universal' } },
            ],
          },
        })
        .exec();
    } catch (error) {
      console.log(
        '🚀 ~ file: useMiningCuts.tsx ~ line 95 ~ getMiningCutsForMiningMethod ~ error',
        error,
      );
    }

    // Filter out mining cuts that are the same because their sequence and pass are the same
    const nonDuplicateMiningCuts = miningCutDocuments.filter(
      (miningCut, index, self) =>
        index ===
        self.findIndex(t => t.sequence === miningCut.sequence && t.pass === miningCut.pass),
    );

    // hydrate sequence and pass
    result = await Promise.all(
      nonDuplicateMiningCuts.map(async miningCut => getMiningCutWithDetails(miningCut)),
    );

    return result.sort(customStepSort);
  };

  useEffect(() => {
    const getMiningCuts = async () => {
      setMiningCutsForMiningPatternLoaded(false);
      if (miningPattern && miningCutsCollection && sequencesCollection && passesCollection) {
        const mcList = await getMiningCutsForMiningPattern(miningPattern);

        if (mcList) {
          setMiningCutListForMiningPattern(mcList);
        } else {
          setMiningCutListForMiningPattern([]);
        }
      } else {
        setMiningCutListForMiningPattern([]);
      }
      setMiningCutsForMiningPatternLoaded(true);
    };
    getMiningCuts();
  }, [miningPattern, miningCutsCollection, sequencesCollection, passesCollection]);

  const getMiningCutLabel = (miningCutSequencePassObj?: MiningCutSequencePassObj) => {
    if (!miningCutSequencePassObj || miningCutSequencePassObj === '') return '';

    return miningCutSequencePassObj.sequence?.description
      ? `${miningCutSequencePassObj.sequence.description} ${
          miningCutSequencePassObj.pass?.description || ''
        }`
      : `${miningCutSequencePassObj.pass?.description || ''}`;
  };

  return {
    getMiningCutLabel,
    getMiningCutById,
    getMiningCutForSequence,
    getMiningCutsForMiningMethod,
    getMiningCutsForMiningPattern,
    miningCutsCollection,
    miningCutsInitialized,
    miningCutListForMiningPattern,
    miningCutListForMiningMethod,
    miningCutsForMiningPatternLoaded,
    miningCutsForMiningMethodLoaded,
  };
};

export default useMiningCuts;
