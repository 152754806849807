import { Grid } from '@material-ui/core';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useMemo, useState } from 'react';

import Spinner from '@/components/Spinner';
import { useMst } from '@/mobx-models/Root';
import { ExtendedBorerShiftComment } from '@/rxdb/BorerShiftComment/queryBuilder';
import { useBorerShiftComment } from '@/rxdb/BorerShiftComment/useBorerShiftComment';
import { BORER_SHIFT_WRITE_PERMISSION, EMPTY_HTML_COMMENT } from '@/utilities/constants';
import { ABBREVIATED_DATE_FORMAT } from '@/utilities/useDateFormatters';
import { capitalizeFirstLetterOnly } from '@/utilities/utilityFunctions';

import useStyles from '../CommentCard/CommentCard.styles';
import ModifyAutomationCommentSidePanel from '../ModifyAutomationCommentSidePanel';

const AutomationCommentCard: FC = () => {
  const { user } = useMst();

  const classes = useStyles();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [labels, setLabels] = useState<string[]>([]);
  const [comments, setComments] = useState<Partial<ExtendedBorerShiftComment>[]>([]);

  const toggleSidePanel = () => {
    setSidePanelOpen(prev => !prev);
  };

  const { borerShiftCommentsLoaded, automationComments } = useBorerShiftComment();

  const existingComment = useMemo(
    () => comments[1]?.comment !== EMPTY_HTML_COMMENT && comments[1]?.comment !== undefined,
    [comments],
  );

  useEffect(() => {
    if (automationComments) {
      const labelsArray = Array.from(automationComments.keys());
      const commentsArray = Array.from(automationComments.values());
      setLabels(labelsArray);
      setComments(commentsArray);
    }
  }, [automationComments]);

  return (
    <>
      <Card className={classes.cardRoot}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">{i18n.t('Automation')}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.editBtnContainer}>
            {user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
              <Button
                color="primary"
                variant="outlined"
                onClick={toggleSidePanel}
                className={classes.addOrEditButton}
                disabled={!borerShiftCommentsLoaded}
                id="edit-comment-button"
              >
                <Icons.EditFeather color="primary" />
                {existingComment ? i18n.t('Edit comment') : i18n.t('Add comment')}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!borerShiftCommentsLoaded ? (
              <Spinner />
            ) : (
              automationComments?.size > 0 && (
                <>
                  <Grid container spacing={2} className={classes.commentRow}>
                    <Grid item xs={12}>
                      <Typography className={classes.label} variant="h5">
                        {capitalizeFirstLetterOnly(labels[0])}
                      </Typography>
                      <Typography className={classes.label} variant="caption">
                        {`Automation News ${
                          comments[0].updatedAt
                            ? `- Updated ${dayjs(comments[0].updatedAt).format(
                                ABBREVIATED_DATE_FORMAT,
                              )}`
                            : ''
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <span className={classes.commentText}>{comments[0].parsedComment || ''}</span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.commentRow}>
                    <Grid item xs={12}>
                      <Typography className={classes.label} variant="h5">
                        {capitalizeFirstLetterOnly(labels[1].split('(')[0])}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <span className={classes.commentText}>{comments[1].parsedComment || ''}</span>
                    </Grid>
                  </Grid>
                </>
              )
            )}
          </Grid>
        </Grid>
      </Card>
      <ModifyAutomationCommentSidePanel
        open={sidePanelOpen}
        setSidePanelOpen={setSidePanelOpen}
        commentToEdit={comments[1]?.comment !== undefined ? comments[1] : null}
        loading={!borerShiftCommentsLoaded}
        commentTypeId={comments[1]?.borerShiftCommentTypeId}
      />
    </>
  );
};

export default observer(AutomationCommentCard);
