import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface TargetBorerRunningTime extends BaseEntity {
  readonly borerEquipmentId: string;
  readonly dayShiftStart: string;
  readonly dayShiftEnd: string | null;
  readonly nightShiftStart: string | null;
  readonly nightShiftEnd: string | null;
  readonly dayShiftEndOffsetMinutes: number;
  readonly dayShiftStartOffsetMinutes: number;
  readonly nightShiftEndOffsetMinutes: number;
  readonly nightShiftStartOffsetMinutes: number;
}
export type TargetBorerRunningTimeCollection = RxCollection<TargetBorerRunningTime> | null;
export type TargetBorerRunningTimeDocument = RxDocument<TargetBorerRunningTime>;

export const targetBorerRunningTimeSchema: RxJsonSchema<TargetBorerRunningTime> = {
  type: 'object',
  version: 1,
  description: 'describes a TargetBorerRunningTime object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerEquipmentId: { type: 'string' },
    dayShiftStart: { type: 'string' },
    dayShiftEnd: { type: ['string', 'null'] },
    nightShiftStart: { type: ['string', 'null'] },
    nightShiftEnd: { type: ['string', 'null'] },
    dayShiftEndOffsetMinutes: { type: 'number' },
    dayShiftStartOffsetMinutes: { type: 'number' },
    nightShiftEndOffsetMinutes: { type: 'number' },
    nightShiftStartOffsetMinutes: { type: 'number' },
  },
};

export const targetBorerRunningTimesPullQueryBuilder = (doc: TargetBorerRunningTimeDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query targetBorerRunningTimesFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      targetBorerRunningTimesFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        isDeleted
        updatedAt
        version
        borerEquipmentId
        dayShiftEndOffsetMinutes
        dayShiftStartOffsetMinutes
        nightShiftEndOffsetMinutes
        nightShiftStartOffsetMinutes
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const targetRunningTimesMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: TargetBorerRunningTimeDocument) => {
    return {
      ...oldDoc,
      dayShiftEndOffsetMinutes: 0,
      dayShiftStartOffsetMinutes: 0,
      nightShiftEndOffsetMinutes: 0,
      nightShiftStartOffsetMinutes: 0,
    };
  },
};

export default {};
