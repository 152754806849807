import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  i18n,
  IconButton,
  Icons,
  MaterialPalette,
  TextField,
  TimePicker2,
  Typography,
} from '@nutrien/cxp-components';
import { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BorerActivityType } from '../../rxdb/BorerActivityType/queryBuilder';
import { DelayActivityTypeCategory } from '../../rxdb/DelayActivityTypeCategory/queryBuilder';
import { FormField } from '../../rxdb/Employees/formHelper';
import { EmployeeDocument } from '../../rxdb/Employees/queryBuilder';
import { useDateFormatters } from '../../utilities';
import { translate } from '../../utilities/siteLanguageTranslation/siteLanguageTranslation';
import { sortByFirstName } from '../../utilities/sortHelper';
import { USER_TIMEZONE } from '../../utilities/useDateFormatters';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
      margin: '0 !important',
    },
    comment: {
      marginBottom: '10px',
    },
  }),
);

interface Props {
  startTime: Dayjs;
  onStartTimeChanged: (time: Dayjs) => void;
  startErrorText: string;
  endTime: Dayjs;
  onEndTimeChanged: (time: Dayjs) => void;
  category: DelayActivityTypeCategory;
  onCategorySelected: (category: DelayActivityTypeCategory) => void;
  activityType: BorerActivityType;
  onActivityTypeSelected: (activityType?: BorerActivityType) => void;
  comment: string;
  onCommentChanged: (comment: string) => void;
  completedBy: EmployeeDocument[];
  completedByFields: FormField<EmployeeDocument | undefined>[];
  handleAddCompletedByField: () => void;
  handleUpdateSelectedEmployeeField: (index: number, operator: EmployeeDocument) => void;
  onRemoveCompletedByField: (index: number) => void;
  faceChangeCrew: boolean;
  faceChangeCrewAllowed: boolean;
  onFaceChangeCrewChanged: (val: boolean) => void;
  categories: DelayActivityTypeCategory[];
  activityTypes: BorerActivityType[];
  allEmployees: EmployeeDocument[];
}

interface SelectedCompletedById {
  [key: string]: string | undefined;
}

const AddBorerActivityCard: React.FC<Props> = ({
  startTime,
  onStartTimeChanged,
  startErrorText,
  endTime,
  onEndTimeChanged,
  category,
  onCategorySelected,
  activityType,
  onActivityTypeSelected,
  comment,
  onCommentChanged,
  completedByFields,
  handleAddCompletedByField,
  handleUpdateSelectedEmployeeField,
  onRemoveCompletedByField,
  faceChangeCrew,
  faceChangeCrewAllowed,
  onFaceChangeCrewChanged,
  categories,
  activityTypes,
  allEmployees,
}: Props) => {
  const classes = useStyles();
  const { convertMinutesToShortenedTimeString } = useDateFormatters();

  const [duration, setDuration] = useState<string>('0 min');
  const [selectedCompletedByIndex, setSelectedCompletedByIndex] = useState<SelectedCompletedById>(
    {},
  );

  // note: business has decided to remove this from initial release until a fast follow
  const showCheckbox = false;

  const activityTypesListForCategory = useMemo(() => {
    if (category) {
      return activityTypes.filter(
        activityTypeValue => activityTypeValue.delayActivityTypeCategory === category.id,
      );
    }
    return activityTypes;
  }, [category]);

  useEffect(() => {
    const newSelectedCompletedByIndex = { ...selectedCompletedByIndex };
    completedByFields.forEach((operator, idx) => {
      if (operator) {
        newSelectedCompletedByIndex[idx] = operator.value?.id;
      } else {
        delete newSelectedCompletedByIndex[idx];
      }
    });
    setSelectedCompletedByIndex(newSelectedCompletedByIndex);
  }, [completedByFields]);

  const onCategoryChanged = (event: any, value: any) => {
    onActivityTypeSelected(undefined);
    onCategorySelected(value);
  };

  const onTypeChanged = (event: any, value: any) => {
    const delayCategory = categories.find(
      cat => cat.id !== undefined && cat.id === value?.delayActivityTypeCategory,
    );
    onCategorySelected(delayCategory);
    onActivityTypeSelected(value);
  };

  const calculateDuration = useCallback(() => {
    const diff = endTime.diff(startTime, 'minute');
    const dur = convertMinutesToShortenedTimeString(diff);
    setDuration(dur);
  }, [startTime, endTime, convertMinutesToShortenedTimeString]);

  useEffect(() => {
    calculateDuration();
  }, [startTime, endTime, calculateDuration]);

  const onUpdateSelectedEmployee = (index: number, employee: EmployeeDocument) => {
    handleUpdateSelectedEmployeeField(index, employee);
  };

  const handleRemoveCompletedByField = (index: number) => {
    onRemoveCompletedByField(index);
  };

  const handleFaceChangeCrew = (evt: any) => {
    onFaceChangeCrewChanged(evt.target.checked);
  };

  const getCompletedByList = () => {
    const result = allEmployees.filter(x => {
      return !completedByFields.map(y => y.value?.id).includes(x.id);
    });
    return result.sort(sortByFirstName);
  };

  const renderCompletedByFields = () => {
    return completedByFields.map((formField: FormField<EmployeeDocument>, idx) => {
      return (
        <Grid container item key={`completed-by-${idx}`}>
          <Grid item xs={idx > 0 ? 10 : 12}>
            <AutoComplete
              autoSelect={false}
              autoHighlight={false}
              list={getCompletedByList(formField)}
              label={idx === 0 ? i18n.t('Completed by') : undefined}
              getOptionLabel={emp => {
                if (emp) {
                  return `${emp.firstName} ${emp.lastName}`;
                }
                return '';
              }}
              onChange={(event, emp: EmployeeDocument) => {
                onUpdateSelectedEmployee(idx, emp || undefined);
              }}
              value={formField.value}
              showCaret
            />
          </Grid>
          {idx > 0 && (
            <Grid container item xs={2} justify="center" alignContent="center">
              <IconButton
                style={{ color: MaterialPalette.error.main }}
                onClick={() => handleRemoveCompletedByField(idx)}
                id={`remove-completed-by-${idx}`}
              >
                <Icons.Trash2Feather color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      );
    });
  };

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">{translate('Shift activity')}</Typography>
        </Grid>
        <br />
        <br />
        <Grid item xs={12}>
          <TimePicker2
            value={startTime.tz(USER_TIMEZONE)}
            label={i18n.t('Start time')}
            onTimeChanged={(date: Dayjs) => {
              onStartTimeChanged(date);
            }}
            error={startErrorText !== ''}
            errorText={startErrorText}
            useBrowserTimePicker
          />
        </Grid>
        <Grid item xs={12}>
          <TimePicker2
            value={endTime.tz(USER_TIMEZONE)}
            label={i18n.t('End time')}
            onTimeChanged={(date: Dayjs) => {
              onEndTimeChanged(date);
            }}
            error={startErrorText !== ''}
            errorText={startErrorText}
            useBrowserTimePicker
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            {i18n.t('Calculated duration')}: {duration}
          </Typography>
          <br />
          <br />
        </Grid>
        <Grid item xs={12}>
          <AutoComplete
            list={categories}
            label={i18n.t('Category')}
            getOptionLabel={(item: any) => {
              return item.description;
            }}
            onChange={onCategoryChanged}
            value={category}
            showCaret
          />
        </Grid>
        <Grid item xs={12}>
          <AutoComplete
            list={activityTypesListForCategory}
            label={translate('Activity')}
            getOptionLabel={(item: any) => {
              return item.name;
            }}
            onChange={onTypeChanged}
            value={activityType}
            showCaret
          />
        </Grid>
        <Grid item xs={12} className={classes.comment}>
          <TextField
            label={i18n.t('Comment (optional)')}
            multiline
            rows={2}
            onChange={event => {
              onCommentChanged(event.target.value);
            }}
            value={comment}
          />
        </Grid>
        <Grid item xs={12}>
          {renderCompletedByFields()}
        </Grid>
        <Grid item container xs={12}>
          <Button
            color="primary"
            style={{ width: '90%' }}
            variant="outlined"
            startAdornment={<Icons.PlusFeather color="primary" />}
            onClick={() => handleAddCompletedByField()}
            id="add-completed-by"
          >
            {i18n.t('Add another person')}
          </Button>
        </Grid>
        {showCheckbox && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFaceChangeCrew}
                  name="face-change-crew-checkbox"
                  checked={faceChangeCrew}
                  disabled={!faceChangeCrewAllowed}
                />
              }
              label={i18n.t('Face change crew')}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default AddBorerActivityCard;
