import { GroundControlType } from '../rxdb/GroundControlTypes/queryBuilder';

export const BOLTS = 'BOLTS';
export const CRIBS = 'CRIBS';
export const OTHER = 'OTHER';

export const LENGTH = 'Length';
export const GENERATION = 'Generation';

export class GroundControlMapper {
  public MapCardViewFromValues(groundControlTypes: GroundControlType[], values: any[] = []): any[] {
    const result: any[] = [];
    values.forEach(groundControlValue => {
      const renderType = this.GetRenderType(groundControlTypes, groundControlValue);
      const groundControl = groundControlTypes.find(gct => gct.id === groundControlValue.type);

      if (groundControl) {
        result.push(this.MapView(groundControl, groundControlValue, renderType, groundControl));
      }
    });
    return result;
  }

  private MapView(
    groundControlType: GroundControlType,
    groundControlValue: any,
    renderType: string,
    groundControl: any,
  ): any {
    // map all
    const descriptionValuePairs: any[] = [];
    const { optionValues } = groundControlValue;
    optionValues.forEach(x => {
      const descValuePair = this.getOptionValueById(groundControlType, x.optionId, x.valueId);
      descriptionValuePairs.push(descValuePair);
    });

    return {
      renderType,
      type: groundControl?.description || 'N/A',
      requiresQuantity: groundControl?.requiresQuantity || false,
      quantity: groundControl?.requiresQuantity
        ? `${groundControlValue.quantity} ${this.GetQuantityType(renderType, groundControlValue)}`
        : '',
      values: descriptionValuePairs,
    };
  }

  private getOptionValueById(
    groundControl: GroundControlType,
    optionId: string,
    valueId: string,
  ): any {
    const options = JSON.parse(JSON.parse(groundControl.options));
    const option = options.find(x => x.id === optionId);
    return {
      description: option?.description,
      value: option?.values.find(x => x.id === valueId).value,
    };
  }

  private GetRenderType(groundControlTypes: GroundControlType[], gc: any): string {
    const groundControl = groundControlTypes.find(gct => gct.id === gc.type);
    if (groundControl && groundControl.description.toLowerCase().indexOf('bolt') !== -1) {
      return BOLTS;
    }
    if (groundControl && groundControl.description.toLowerCase().indexOf('crib') !== -1) {
      return CRIBS;
    }
    return OTHER;
  }

  private GetQuantityType(renderType: string, groundControlValue: any): string {
    switch (renderType) {
      case BOLTS:
        return groundControlValue.quantity > 1 ? 'bolts' : 'bolt';
      case CRIBS:
        return groundControlValue.quantity > 1 ? 'cribs' : 'crib';
      default:
        return '';
    }
  }
}
