import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface Employee extends BaseEntity {
  readonly siteId: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly crew: string;
  readonly crewName: string;
  readonly department: string;
  readonly position: string;
  readonly positionName: string;
  readonly isActive: boolean;
  readonly borerShiftCrewId?: string;
  readonly borerShiftCrewVersion?: number;
}

export type EmployeeCollection = RxCollection<Employee> | null;
export type EmployeeDocument = RxDocument<Employee>;

export const employeesSchema: RxJsonSchema<Employee> = {
  type: 'object',
  version: 8,
  description: 'describes an employee object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    siteId: { type: 'string', ref: RxdbCollectionName.SITES },
    email: { type: 'string' },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    phoneNumber: { type: ['string', 'null'] },
    crew: { type: ['string', 'null'], ref: RxdbCollectionName.CREWS },
    department: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.DEPARTMENT,
    },
    position: { type: ['string', 'null'], ref: RxdbCollectionName.POSITIONS },
    isActive: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

export const employeeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: EmployeeDocument) => {
    return {
      ...oldDoc,
    };
  },
  2: (oldDoc: EmployeeDocument) => oldDoc,
  3: (oldDoc: EmployeeDocument) => oldDoc,
  4: (oldDoc: EmployeeDocument) => oldDoc,
  5: (oldDoc: EmployeeDocument) => oldDoc,
  6: (oldDoc: EmployeeDocument) => ({ ...oldDoc, middleName: null }),
  7: (oldDoc: EmployeeDocument) => {
    delete oldDoc.middleName;

    return {
      ...oldDoc,
    };
  },
  8: (oldDoc: EmployeeDocument) => {
    const newDoc = { ...oldDoc, siteId: oldDoc.site };
    delete newDoc.site;
    return newDoc;
  },
};

export const employeesPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`{
      siteEmployeeFeed (siteId: "${rootStore.user.siteId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
            crew
            department
            firstName
            email
            id
            isActive
            isDeleted
            lastName
            phoneNumber
            position
            siteId
            updatedAt
            version
          }
        }`);
  return {
    query,
    variables: {},
  };
};

export default {};
