import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface Prediction extends BaseEntity {
  readonly borerShiftId: string;
  readonly footage: number;
  readonly location: string;
  readonly endLocation: string;
  readonly endDistance: string | number;
}
export type PredictionCollection = RxCollection<Prediction> | null;
export type PredictionDocument = RxDocument<Prediction>;

export const predictionSchema: RxJsonSchema<Prediction> = {
  type: 'object',
  version: 3,
  description: 'describes a Prediction object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    footage: { type: 'number' },
    updatedAt: { type: 'number' },
    location: {
      ref: RxdbCollectionName.LOCATIONS,
      type: 'string',
    },
    endDistance: {
      type: ['number', 'null'],
    },
    endLocation: {
      ref: RxdbCollectionName.LOCATIONS,
      type: 'string',
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const predictionPullQueryBuilder = (doc: Prediction) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }
  const query = `
    query borerShiftPredictionFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftPredictionFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
          borerShiftId
          id
          isDeleted
          updatedAt
          version
          endLocationId
          endDistance
      }
    }
  `;

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const predictionPushQueryBuilder = (doc: Prediction) => {
  doc = doc[0];

  const query = print(gql`
    mutation setBorerShiftPrediction($prediction: SetBorerShiftPredictionInput) {
      setBorerShiftPrediction(input: $prediction) {
        id
        borerEquipmentId
        borerShiftId
        siteId
      }
    }
  `);

  const variables = {
    prediction: doc,
  };

  return {
    query,
    variables,
  };
};

export const predictionPullModifier = (doc: any) => {
  const result = {
    ...doc,
    endLocation: doc.endLocationId,
    location: '',
  };

  delete result.startLocationId;
  delete result.endLocationId;
  return result;
};

export const predictionPushModifier = (doc: any) => {
  const result = {
    ...doc,
    endLocationId: doc.endLocation,
  };

  delete result.startLocation;
  delete result.endLocation;

  const propertyBlackList = [
    'updatedAt',
    '_deleted',
    '_attachments',
    '_rev',
    'location',
    'startLocation',
    'endLocation',
    'updatedAt',
    'footage',
    'meta',
    '_meta',
  ];
  propertyBlackList.forEach(property => {
    result[property] = undefined;
  });

  return result;
};

export const borerShiftPredictionMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: PredictionDocument) => {
    return {
      ...oldDoc,
      startLocation: null,
      startFootage: null,
      endLocation: null,
      endFootage: null,
    };
  },
  2: (oldDoc: PredictionDocument) => {
    return {
      ...oldDoc,
      startLocation: null,
      startDistance: oldDoc.startFootage,
      endDistance: oldDoc.endFootage,
    };
  },
  3: (oldDoc: PredictionDocument) => {
    const newDoc = { ...oldDoc };
    delete newDoc.startDistance;
    delete newDoc.startLocation;
    return newDoc;
  },
};

export default {};
