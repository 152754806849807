import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { RootModel } from '../mobx-models/Root';
import { getCurrentShift } from '../mobx-models/ShiftPicker';
import { DATE_STORAGE_FORMAT } from '../mobx-models/ShiftPicker/ShiftPicker';
import { ApiStatus } from '../utilities/apiStatus';
import { LANIGAN_FIXED_SITE_ID, OVERRIDE_SITE_ID } from '../utilities/constants';
import { ShiftType } from '../utilities/enums';
import { MockRxdbInstanceOptions } from './setupMockRxdbInstance';
const currentAppVersion = process.env.APP_VERSION || '';

const currentShift = getCurrentShift();
export const mockBorerShiftId = uuidv4();
export const mockBorerId = uuidv4();

export const generateRootStoreMock = (config?: MockRxdbInstanceOptions) => {
  // Ensures proper date is set for tests
  const shiftDate = config?.overrideDayShift
    ? dayjs().format(DATE_STORAGE_FORMAT)
    : currentShift.Date;

  return RootModel.create({
    hazards: {
      HazardTypesStatus: ApiStatus.LOADING,
      HazardConditionTypes: [],
      HazardConditionTypesMap: {},
    },
    employees: {
      EmployeesStatus: ApiStatus.LOADING,
      EmployeesMap: {},
      Employees: [],
    },
    panels: {
      panelsStatus: ApiStatus.LOADING,
      panelsMap: {},
      panels: [],
    },
    user: {
      ProfileStatus: ApiStatus.LOADING,
      siteId: localStorage.getItem(OVERRIDE_SITE_ID) || LANIGAN_FIXED_SITE_ID,
    },
    groundControls: {
      GroundControlOptionsFetchStatus: ApiStatus.NOT_CALLED,
    },
    shiftPicker: {
      Date: shiftDate,
      Type: config?.overrideNightShift
        ? ShiftType.NIGHT_SHIFT
        : config?.overrideDayShift
        ? ShiftType.DAY_SHIFT
        : currentShift.Type,
      currentBorerShiftId: mockBorerShiftId,
      currentShiftId: '99a73b37-2df2-4adc-ae1c-709b2413aed4',
      shiftStartDateUnix: dayjs().startOf('day').add(8, 'hours').unix(),
      shiftEndDateUnix: dayjs()
        .startOf('day')
        .add(8 + 12, 'hours')
        .unix(),
      dayShiftStartHour: 8,
      nightShiftStartHour: 20,
    },
    appVersion: {
      currentAppVersion,
      hasNewUpdate: false,
      showUpdateModal: false,
      deferredAt: 0,
    },
    equipment: {
      BorersFetchStatus: ApiStatus.NOT_CALLED,
      selectedBorerId: mockBorerId,
      selectedBorerName: 'Tester',
    },
  });
};
