import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import { defaultDoc } from '../Shared/defaultDoc';

export interface BorerShiftActivityEmployee extends BaseEntity {
  readonly borerActivityId: string;
  readonly siteEmployeeId: string;
}
export type BorerShiftActivityEmployeeCollection = RxCollection<BorerShiftActivityEmployee> | null;
export type BorerShiftActivityEmployeeDocument = RxDocument<BorerShiftActivityEmployee>;

export const borerShiftActivityEmployeeSchema: RxJsonSchema<BorerShiftActivityEmployee> = {
  type: 'object',
  version: 1,
  description: 'describes a BorerShiftActivityEmployee object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerActivityId: { type: 'string' },
    siteEmployeeId: { type: 'string', ref: RxdbCollectionName.EMPLOYEES },
  },
};

export const borerShiftActivityEmployeePullQueryBuilder = (doc: BorerShiftActivityEmployee) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftActivitySiteEmployeeFeed(
      $lastUpdateAt: Float!
      $borerEquipmentId: ID!
      $limit: Int!
    ) {
      borerShiftActivitySiteEmployeeFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        siteEmployeeId
        borerActivityId
        updatedAt
        isDeleted
        version
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerShiftActivityEmployeePushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const query = print(gql`
    mutation setBorerShiftActivityEmployee($borerActivity: SetBorerShiftActivityEmployeeInput) {
      setBorerShiftActivityEmployee(input: $borerActivity)
    }
  `);

  const variables = {
    borerActivity: {
      id: doc.id,
      borerShiftActivityId: doc.borerActivityId,
      siteEmployeeId: doc.siteEmployeeId,
      isDeleted: doc.isDeleted,
      version: doc.version,
    },
  };

  return {
    query,
    variables,
  };
};

export const borerShiftActivityEmployeePushModifier = (doc: any) => {
  const obj = {
    ...doc,
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc._deleted === true ? doc._deleted : doc.isDeleted,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev', 'isFaceChangeCrew'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export const borerShiftActivityEmployeeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftActivityEmployeeDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
};

export default {};
