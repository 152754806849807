import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Department extends BaseEntity {
  readonly description: string;
}

export type DepartmentCollection = RxCollection<Department> | null;
export type DepartmentDocument = RxDocument<Department>;

export const departmentSchema: RxJsonSchema<Department> = {
  type: 'object',
  version: 0,
  description: 'describes a deparment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
  },
};

export const departmentPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`{
      departmentFeed (siteId: "${rootStore.user.siteId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
            description
            id
            isDeleted
            version
            updatedAt
          }
        }`);
  return {
    query,
    variables: {},
  };
};

export default {};
