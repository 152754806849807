import { Card, Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup, Typography } from '@nutrien/cxp-components';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';

import { EmployeeSummary } from '@/rxdb/Employees/useEmployees';

import { EquipmentDeficiencyWithChildren } from '../../rxdb/EquipmentDeficiency/useEquipmentDeficiency';
import {
  InspectionCategoryWithOptions,
  InspectionOptionsWithDetails,
} from '../../rxdb/Inspections/useInspections';
import useModalStyles from '../../utilities/modalStyles';
import { useStyles } from '../AddPreOpCheck/AddPreOpCheck.styles';
import DeficiencyPanelContent from '../DeficiencyPanelContent';

interface Props {
  deficiencyDetails: EquipmentDeficiencyWithChildren | undefined;
  selectedOptionIsDeficiency: boolean;
  category: InspectionCategoryWithOptions;
  handleToggleChange: (event: any, value: InspectionOptionsWithDetails) => void;
  selectedOption: InspectionOptionsWithDetails;
  viewOnly?: boolean;
  employeeList: EmployeeSummary[];
}

const PreOpCheckCategory: React.FC<Props> = React.memo(
  ({
    deficiencyDetails,
    selectedOptionIsDeficiency,
    category,
    handleToggleChange,
    selectedOption,
    viewOnly,
    employeeList,
  }: Props) => {
    const classes = useModalStyles();
    const customClasses = useStyles();

    return (
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">{category.category.description}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.buttonGroupContainer}>
              <ToggleButtonGroup
                value={selectedOption || null}
                onChange={handleToggleChange}
                aria-label=""
                exclusive
              >
                {category.category.detail && (
                  <Typography variant="body2" classes={{ button: classes.toggleButtonLabel }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(category.category.detail),
                      }}
                    />
                  </Typography>
                )}
                {category.options
                  .sort((a, b) => a.option.displayOrder - b.option.displayOrder)
                  .map(val => {
                    const { option } = val;

                    return (
                      <ToggleButton
                        key={option.id}
                        value={val}
                        aria-label={`${option.id}-toggle`}
                        disabled={viewOnly || selectedOptionIsDeficiency}
                        showColorOnDisabled
                        disableRipple
                        color={
                          option.description === 'Deficiency' ||
                          option.description === 'Substandard' ||
                          option.description === 'WO Required'
                            ? 'error'
                            : 'success'
                        }
                        onTouchEnd={e => handleToggleChange(e, val)}
                      >
                        <Typography
                          variant="button"
                          classes={{ button: classes.toggleButtonLabel }}
                        >
                          {option.description}
                        </Typography>
                      </ToggleButton>
                    );
                  })}
              </ToggleButtonGroup>
            </Grid>
            {deficiencyDetails && (
              <Grid item xs={12} className={customClasses.deficiencyContentContainer}>
                <DeficiencyPanelContent
                  deficiency={deficiencyDetails.deficiency}
                  equipmentType={deficiencyDetails.equipmentType}
                  equipment={deficiencyDetails.equipment}
                  logs={deficiencyDetails.logs}
                  employeeList={employeeList}
                  showType
                  viewOnly={viewOnly}
                  photoSource="add-pre-op"
                  hideStatus
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    );
  },
  (prevProps, nextProps) =>
    prevProps.selectedOptionIsDeficiency === nextProps.selectedOptionIsDeficiency &&
    prevProps.deficiencyDetails?.deficiency?.id === nextProps.deficiencyDetails?.deficiency?.id &&
    prevProps.selectedOption?.option?.id === nextProps.selectedOption?.option?.id,
);

PreOpCheckCategory.displayName = 'PreOpCheckCategory';
export default PreOpCheckCategory;
