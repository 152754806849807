import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface MiningPattern extends BaseEntity {
  readonly description: string;
  readonly miningMethod: string;
}
export type MiningPatternCollection = RxCollection<MiningPattern> | null;
export type MiningPatternDocument = RxDocument<MiningPattern>;

export const miningPatternSchema: RxJsonSchema<MiningPattern> = {
  type: 'object',
  version: 1,
  description: 'describes a MiningPattern object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    miningMethod: { type: ['string', 'null'] },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const miningPatternMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: MiningPatternDocument) => {
    return {
      ...oldDoc,
      miningMethod: null,
    };
  },
};

export const miningPatternPullQueryBuilder = (doc: MiningPattern) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query miningPatternFeed($siteId: ID!, $limit: Int!, $lastUpdateAt: Float!) {
      miningPatternFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
        id
        description
        miningMethod
        isDeleted
        updatedAt
        version
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
      siteId: rootStore.user.siteId,
    },
  };
};

export default {};
