import { useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useWindowVisibility from '../useWindowVisibility';
import useFifteenMinuteInterval from './useFifteenMinuteInterval';

export const useViewingCurrentShift = (): boolean => {
  const { shiftPicker } = useMst();
  const windowVisible = useWindowVisibility();

  const [userViewingCurrentShift, setUserViewingCurrentShift] = useState(
    shiftPicker.isCurrentShiftSelected(),
  );

  // Trigger current shift check whenever shift changes.
  useEffect(() => {
    setUserViewingCurrentShift(shiftPicker.isCurrentShiftSelected());
  }, [shiftPicker.Date, shiftPicker.Type, shiftPicker, windowVisible]);

  // Check for current shift every hour.
  useFifteenMinuteInterval(() => {
    setUserViewingCurrentShift(shiftPicker.isCurrentShiftSelected());
  });

  return userViewingCurrentShift;
};

export default useViewingCurrentShift;
