import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { IAppointment } from '../../components/pages/DelaysAndActivitiesV2/DelaysSchedulerView/ActivitiesSchedulerHelpers';
import { useMst } from '../../mobx-models/Root';
import { SCHEDULER_DATE_FORMAT, USER_TIMEZONE } from '../../utilities/useDateFormatters';
import { BorerActivityTypeCollection } from '../BorerActivityType/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../rxdbUtilityFunctions';
import { BorerActivity, BorerActivityCollection, BorerActivityDocument } from './queryBuilder';

export const useBorerActivity = () => {
  const { shiftPicker } = useMst();

  const borerActivityCollection: BorerActivityCollection = useRxCollection(
    RxdbCollectionName.BORER_ACTIVITY,
  );

  const borerActivityTypeCollection: BorerActivityTypeCollection = useRxCollection(
    RxdbCollectionName.BORER_ACTIVITY_TYPE,
  );

  // Activities with types attached
  const [augmentedActivitiesForShift, setAugmentedActivitiesForShift] = useState<BorerActivity[]>(
    [],
  );

  const shiftActivityQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          borerShiftId: shiftPicker.currentBorerShiftId,
        },
      }),
    [shiftPicker.currentBorerShiftId],
  );

  const { result: activitiesForShift, isFetching: activitiesFetching } = useRxData<BorerActivity>(
    RxdbCollectionName.BORER_ACTIVITY,
    shiftActivityQueryConstructor,
  );

  useEffect(() => {
    const augmentActivitiesForShift = async () => {
      const activitiesWithTypes: BorerActivity[] = [];

      if (activitiesForShift.length) {
        for (let k = 0; k < activitiesForShift.length; k++) {
          activitiesWithTypes.push({
            typeName: await getActivityTypeName(activitiesForShift[k].activityTypeId),
            activityTypeId: activitiesForShift[k].activityTypeId,
            id: activitiesForShift[k].id,
            comment: activitiesForShift[k].comment,
            start: dayjs(activitiesForShift[k].start)
              .tz(USER_TIMEZONE)
              .format(SCHEDULER_DATE_FORMAT),
            end: dayjs(activitiesForShift[k].end).tz(USER_TIMEZONE).format(SCHEDULER_DATE_FORMAT),
            version: activitiesForShift[k].version,
            updatedAt: activitiesForShift[k].updatedAt,
            borerShiftId: activitiesForShift[k].borerShiftId,
            isDeleted: false,
            isFaceChangeCrew: activitiesForShift[k].isFaceChangeCrew,
          });
        }
      }
      setAugmentedActivitiesForShift(activitiesWithTypes);
    };

    augmentActivitiesForShift();
  }, [activitiesForShift]);

  const getActivityTypeName = async (typeId: string) => {
    const activityType = await borerActivityTypeCollection
      ?.findOne({
        selector: {
          id: typeId,
        },
      })
      .exec();

    return activityType?.name || 'Unknown';
  };

  const createBorerActivity = async (
    comment: string | undefined,
    start: Dayjs,
    end: Dayjs,
    activityTypeId: string,
    isFaceChangeCrew: boolean,
    borerShiftCrewId: string,
  ) => {
    const doc = {
      ...generateBaseEntityWithCreatedOn(),
      comment,
      start: start.toISOString(),
      end: end.toISOString(),
      activityTypeId,
      borerShiftId: shiftPicker.currentBorerShiftId,
      borerShiftCrewId,
      isFaceChangeCrew,
      isDeleted: false,
    };

    return borerActivityCollection?.insert(doc);
  };

  const updateBorerActivity = (
    comment: string | undefined,
    start: Dayjs,
    end: Dayjs,
    activityTypeId: string,
    isFaceChangeCrew: boolean,
    borerShiftCrewId: string,
    appointment: IAppointment,
  ) => {
    const doc: BorerActivityDocument = {
      id: appointment.id,
      comment,
      start: start.toISOString(),
      end: end.toISOString(),
      activityTypeId,
      borerShiftId: shiftPicker.currentBorerShiftId || '',
      isFaceChangeCrew,
      borerShiftCrewId,
      version: appointment.version,
      updatedAt: appointment.updatedAt,
      isDeleted: false,
    };
    return borerActivityCollection?.upsert(doc);
  };

  const deleteBorerActivity = async (appointment: IAppointment) => {
    const doc = await borerActivityCollection
      ?.findOne({
        selector: {
          id: appointment.id,
        },
      })
      .exec();

    await doc?.remove();
  };

  const setBorerActivity = async (
    comment: string | undefined,
    start: Dayjs,
    end: Dayjs,
    activityTypeId: string,
    siteEmployeeIds: string[],
    isFaceChangeCrew: boolean,
    appointment?: IAppointment,
  ) => {
    if (!shiftPicker.currentBorerShiftId) throw new Error('Missing current borer shift id');

    const crewIds = shiftPicker.currentBorerShiftCrewIds;
    if (!crewIds || crewIds.length === 0) throw new Error('No current borer shift crew is set');

    const borerShiftCrewId =
      isFaceChangeCrew && crewIds.length > 1
        ? crewIds.find(x => x.crewNumber === 2)?.id
        : crewIds.find(x => x.crewNumber === 1)?.id;

    if (appointment) {
      // update existing
      return updateBorerActivity(
        comment,
        start,
        end,
        activityTypeId,
        isFaceChangeCrew,
        borerShiftCrewId,
        appointment,
      );
    }

    return createBorerActivity(
      comment,
      start,
      end,
      activityTypeId,
      isFaceChangeCrew,
      borerShiftCrewId,
    );
  };

  return {
    setBorerActivity,
    deleteBorerActivity,
    augmentedActivitiesForShift,
    activitiesFetching,
  };
};

export default useBorerActivity;
