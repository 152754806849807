import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@nutrien/cxp-components';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import type { RxDatabase } from 'rxdb';

import { useMst } from '../../../mobx-models/Root';
import useEventTracking from '../../../utilities/hooks/useEventTracking';
import { useBorerHeartbeat } from '../../BorerHeartbeat/useBorerHeartbeat';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      height: '100vh',
    },
  }),
);

interface Props {
  rxdb?: RxDatabase;
}

const Logout: React.FC<Props> = ({ rxdb }: Props) => {
  const classes = useStyles();

  const { equipment } = useMst();
  const { sendCachedEvents } = useEventTracking();
  const { sendBorerHeartbeat } = useBorerHeartbeat();

  const signOutUser = async () => {
    try {
      await sendBorerHeartbeat({ lastUserInteractionTime: dayjs(), logoutTime: dayjs() });
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:39 ~ signOutUser ~ error', error);
    }

    try {
      await sendCachedEvents();
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx:35 ~ signOutUser ~ error', error);
    }

    // Revokes all existing refresh tokens
    const globalLogout = localStorage.getItem('globalLogout') === 'true';
    try {
      await Auth.signOut({
        global: globalLogout,
      });
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx ~ line 30 ~ signOutUser ~ error', error);
    }

    // Clear rxDB on logout
    try {
      const dbs = await window.indexedDB.databases();
      dbs.forEach(db => {
        window.indexedDB.deleteDatabase(db.name);
      });
    } catch (error) {
      console.log('🚀 ~ file: Logout.tsx ~ line 41 ~ signOutUser ~ error', error);
    }

    // Clear image cache on logout
    if ('serviceWorker' in navigator) {
      try {
        const cache = await caches.open(`s3-images`);
        const imageKeys = await cache.keys();
        await Promise.all(imageKeys.map(key => cache.delete(key)));
      } catch (error) {
        console.log('🚀 ~ file: Logout.tsx ~ line 56 ~ signOutUser ~ error', error);
      }
    }

    sessionStorage.clear();
    localStorage.clear();
    equipment.resetStore();
    localStorage.clear();
    window.location.replace('/login'); // dont use history in this case a trigger a page refresh
  };

  useEffect(() => {
    signOutUser();
  }, [rxdb]);

  return (
    <Grid container className={classes.root} justify="center" alignItems="center">
      <Grid item xs={3}>
        <Typography color="primary">Logging Out...</Typography>
      </Grid>
    </Grid>
  );
};

export default Logout;
