import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface BorerShiftCrewMember extends BaseEntity {
  readonly borerShiftCrewId: string;
  readonly siteEmployeeId: string;
  readonly employeeOrder: number;
}
export type BorerShiftCrewMemberCollection = RxCollection<BorerShiftCrewMember> | null;
export type BorerShiftCrewMemberDocument = RxDocument<BorerShiftCrewMember>;

export const borerShiftCrewMemberSchema: RxJsonSchema<BorerShiftCrewMember> = {
  type: 'object',
  version: 2,
  description: 'describes an borer shift crew member object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    borerShiftCrewId: {
      type: 'string',
      ref: RxdbCollectionName.BORER_SHIFT_CREW,
    },
    siteEmployeeId: { type: 'string', ref: RxdbCollectionName.EMPLOYEES },
    employeeOrder: { type: ['number', 'null'] },
  },
};

export const borerShiftCrewMemberMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCrewMemberDocument) => {
    return { ...oldDoc, employeeOrder: null };
  },
  2: (oldDoc: BorerShiftCrewMemberDocument) => {
    const newDoc = { ...oldDoc, siteEmployeeId: oldDoc.employeeId };
    delete newDoc.employeeId;
    return newDoc;
  },
};

export interface SetBorerShiftCrewMemberInput {
  id: string;
  borerShiftCrewId: string;
  siteEmployeeId: string;
  employeeOrder: number;
  createdOn?: string;
  modifiedOn?: string;
  isDeleted: boolean;
  version: number;
  updatedAt?: number;
}

export const borerShiftCrewMemberPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftCrewMemberFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftCrewMemberFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        borerShiftCrewId
        siteEmployeeId
        id
        isDeleted
        updatedAt
        version
        employeeOrder
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerShiftCrewMemberPushQueryBuilder = (doc: SetBorerShiftCrewMemberInput) => {
  doc = doc[0];

  const query = print(gql`
    mutation setBorerShiftCrewMember($input: SetBorerShiftCrewMemberInput) {
      setBorerShiftCrewMember(input: $input) {
        id
        siteId
        shiftId
        borerEquipmentId
      }
    }
  `);

  const variables = {
    input: doc,
    limit: SYNC_LIMIT_LOW,
  };

  return {
    query,
    variables,
  };
};

export const borerShiftCrewMemberPushModifier = (doc: any) => {
  const obj = {
    ...doc,
    modifiedOn: dayjs().toISOString(),
    employeeOrder: doc.employeeOrder,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev', '_meta', 'notUsed'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
