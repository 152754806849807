import { Auth } from 'aws-amplify';
import localforage from 'localforage';
import { v4 as uuid4 } from 'uuid';

import { isJestOrStorybook } from '@/src/test-helpers/isJestOrStorybook';

import { createTrackingEvent } from '../../graphql/TrackingEvent';
import { SELECTED_BORER_ID_KEY } from '../../mobx-models/Equipment';
import { rootStore } from '../../mobx-models/Root';
import { useOnlineStatus } from '..';
import useDebug from '../useDebug';

export const trackingEventStore = localforage.createInstance({
  name: 'tracking-event-store',
});

//
export enum TrackingEventType {
  HAZARD_ADD_OPEN = 'HAZARD_ADD_OPEN',
  HAZARD_ADD_HAZARD_CLOSED = 'HAZARD_ADD_HAZARD_CLOSED',
  HAZARD_SAVE_CLICKED = 'HAZARD_SAVE_CLICKED',
  HAZARD_SAVE_SUCCESS = 'HAZARD_SAVE_SUCCESS',
  HAZARD_SAVE_FAILURE = 'HAZARD_SAVE_FAILURE',
  HAZARD_DISCARD_OPENED = 'HAZARD_DISCARD_OPENED',
  HAZARD_DISCARD_CANCELLED = 'HAZARD_DISCARD_CANCELLED',
  HAZARD_DISCARD_DISCARDED = 'HAZARD_DISCARD_DISCARDED',
  LOGOUT_CLICKED = 'LOGOUT_CLICKED',
  BORER_HEART_BEAT = 'BORER_HEART_BEAT',
}

// get the borers name and id from storage
const getBorerDetails = () => {
  const data = localStorage.getItem(SELECTED_BORER_ID_KEY);

  const borerDetails = {
    id: '',
    shortName: '',
  };

  if (data) {
    const parsedData = JSON.parse(data);
    borerDetails.id = parsedData.id;
    borerDetails.shortName = parsedData.shortName;
  }

  return borerDetails;
};

// get user details
const getUserDetails = async () => {
  let userInfo = undefined;
  try {
    userInfo = await Auth.currentUserInfo();
  } catch (err) {
    console.error('🚀 ~ file: useEventTracking.ts ~ line 40 ~ err', err);
  }

  const userDetails = {
    email: '',
  };

  if (userInfo) {
    // Adding this because of errors thrown
    userDetails.email = userInfo?.attributes?.email || 'NO_EMAIL_FOUND';
  }

  return userDetails;
};

// Sends An Event to the server
const dispatchEvent = async (
  type: string,
  blob: string,
  eventTimeOnDevice: string,
  siteId = rootStore.user.siteId,
) =>
  createTrackingEvent({
    siteId,
    eventType: type,
    eventTimeOnDevice,
    eventData: blob,
  });

// Cache Event
const cacheEvent = async (
  id: string,
  type: string,
  blob: string,
  eventTimeOnDevice: string,
  siteId: string,
) =>
  trackingEventStore.setItem(id, {
    type,
    blob,
    eventTimeOnDevice,
    siteId,
  });

// Track Event
export const trackEventFn = async (
  type: string,
  blob: Record<string, any>,
  isOnline = true,
  DEBUG = false,
) => {
  // Create event data
  const eventTimeOnDevice = new Date().toISOString();
  const borerDetails = getBorerDetails();
  const userDetails = await getUserDetails();

  const extraBlobData = {
    ...blob,
    shortName: borerDetails.shortName,
    borerId: borerDetails.id,
    email: userDetails.email,
    id: uuid4(),
    unixTimestamp: Date.now(),
  };
  const blobString = JSON.stringify(extraBlobData);

  // If online dispatch to server
  if (isOnline) {
    if (DEBUG) {
      console.log('[useEventTracking] Dispatching event to server');
      console.dir([type, blobString, eventTimeOnDevice]);
    }
    try {
      const result = await dispatchEvent(type, blobString, eventTimeOnDevice);
      if (DEBUG) {
        console.log('[useEventTracking] Event dispatched to server');
        console.dir(result);
      }
    } catch (error) {
      if (DEBUG) {
        console.log('🚀 ~ file: useEventTracking.ts ~ line 27 ~ trackEvent ~ error', error);
        console.log('[useEventTracking] Error sending event storing event locally');
      }
      await cacheEvent(
        extraBlobData.id,
        type,
        blobString,
        eventTimeOnDevice,
        rootStore.user.siteId,
      );
    }
  }
  // else store in local storage
  else {
    if (DEBUG) console.log('[useEventTracking] Storing event locally');
    await cacheEvent(extraBlobData.id, type, blobString, eventTimeOnDevice, rootStore.user.siteId);
  }
};

export const useEventTracking = () => {
  const isOnline = useOnlineStatus();
  const DEBUG = useDebug();

  // Send cached events
  const sendCachedEvents = async () => {
    if (DEBUG) console.log('[useEventTracking] Sending cached events');
    const cachedEvents = await trackingEventStore.keys();
    if (DEBUG) {
      console.log('[useEventTracking] Cached events');
      console.dir(cachedEvents);
    }
    cachedEvents.forEach(async (key: string) => {
      try {
        const { type, blob, eventTimeOnDevice, siteId } = await trackingEventStore.getItem(key);
        if (DEBUG)
          console.log('[useEventTracking] Event data', type, blob, eventTimeOnDevice, siteId);
        await dispatchEvent(type, blob, eventTimeOnDevice, siteId);
        await trackingEventStore.removeItem(key);
      } catch (error) {
        if (DEBUG) {
          console.log('🚀 ~ file: useEventTracking.ts ~ line 67 ~ sendCachedEvents ~ error', error);
          console.log('[useEventTracking] Error sending cached event', key);
        }
      }
    });

    if (DEBUG) console.log('[useEventTracking] Cached events sent');
  };

  const trackEvent = (type: TrackingEventType, blob: Record<string, any>) => {
    if (isJestOrStorybook()) return;
    return trackEventFn(type, blob, isOnline, DEBUG);
  };

  return { trackEvent, sendCachedEvents };
};

export default useEventTracking;
