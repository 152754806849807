import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { CONSTRUCTION_PRODUCTION_DEPARTMENTS } from '../../utilities/constants';
import { CrewDocument } from '../Crew/queryBuilder';
import { DepartmentDocument } from '../Department/queryBuilder';
import { PositionDocument } from '../Position/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { Employee, EmployeeCollection, EmployeeDocument } from './queryBuilder';

export type EmployeeSummary = {
  id: string;
  firstName: string;
  lastName: string;
  crewName: string;
  positionName?: string;
  fullName: string;
};

export type ListEmployeesOptions = {
  isActive?: boolean;
  onlyConstructionAndProduction?: boolean;
  populateCrew?: boolean;
  populatedPosition?: boolean;
  onlyActiveCrew?: boolean;
};

const defaultListEmployeeOptions: ListEmployeesOptions = {
  isActive: true,
  onlyConstructionAndProduction: true,
  populateCrew: true,
  populatedPosition: true,
  onlyActiveCrew: false,
};

export const useEmployees = (initialListEmployeeOptions = defaultListEmployeeOptions) => {
  const employeeCollection: EmployeeCollection = useRxCollection(RxdbCollectionName.EMPLOYEES);

  const [employeesList, setEmployeesList] = useState<EmployeeSummary[]>([]);

  const queryOptions = useMemo(
    () => ({
      ...defaultListEmployeeOptions,
      ...initialListEmployeeOptions,
    }),
    [JSON.stringify(initialListEmployeeOptions)],
  );

  const sWPDocQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          isActive: queryOptions.isActive,
        },
      }),
    [queryOptions],
  );

  const { result: activeEmployees, isFetching: employeeListLoading } = useRxData<Employee>(
    RxdbCollectionName.EMPLOYEES,
    sWPDocQueryConstructor,
  );

  const augmentEmployees = useCallback(async (): Promise<EmployeeSummary[]> => {
    if (!activeEmployees) {
      return [];
    }
    try {
      const augmentedEmployees = await Promise.all(
        activeEmployees.map(async employee => {
          const crew: CrewDocument | null = queryOptions.populateCrew
            ? await employee.populate('crew')
            : null;
          const position: PositionDocument | null = queryOptions.populatedPosition
            ? await employee.populate('position')
            : null;
          const department: DepartmentDocument | null = queryOptions.populatedPosition
            ? await employee.populate('department')
            : null;

          const isInConstructionOrProductionDepartment =
            CONSTRUCTION_PRODUCTION_DEPARTMENTS.includes(
              department?.description.toLocaleLowerCase() || '',
            );
          const activeCrew = crew?.isActive === true;

          if (queryOptions.isActive && !employee.isActive) return null;
          if (queryOptions.onlyConstructionAndProduction && !isInConstructionOrProductionDepartment)
            return null;
          if (queryOptions.onlyActiveCrew && !activeCrew) return null;

          return {
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            crewName: crew?.description || 'No crew',
            positionName: position?.description,
            fullName: `${employee?.firstName} ${employee?.lastName}`,
          };
        }),
      );

      // filter any null values
      let filteredEmployees = augmentedEmployees.filter(
        employee => employee !== null,
      ) as EmployeeSummary[];

      // Sort by crew name
      filteredEmployees = filteredEmployees.sort((a: EmployeeSummary, b: EmployeeSummary) => {
        return (
          a.crewName.toLowerCase().localeCompare(b.crewName.toLowerCase()) ||
          a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        );
      });

      setEmployeesList(filteredEmployees);
      return filteredEmployees;
    } catch (error) {
      console.error('🚀 ~ file: useEmployees.ts ~ line 29 ~ augmentEmployees ~ error', error);
      throw error;
    }
  }, [queryOptions, activeEmployees]);

  const getEmployee = useCallback(
    (id: string): Promise<EmployeeDocument | null> | undefined => {
      return employeeCollection
        ?.findOne({
          selector: {
            id,
          },
        })
        .exec();
    },
    [employeeCollection],
  );

  useEffect(() => {
    augmentEmployees();
  }, [activeEmployees, queryOptions, augmentEmployees]);

  return {
    employeesList,
    getEmployee,
    employeeListLoading,
  };
};

export default useEmployees;
