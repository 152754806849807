import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import useWorkOrders, { WorkOrderWithChildren } from '../../rxdb/WorkOrders/useWorkOrders';
import WorkOrderExpansionPanel from '../WorkOrderExpansionPanel';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
    },
    cardContainer: {
      padding: '16px 0px 0px 0px !important',
    },
  }),
);

const ActiveWorkOrdersCard: React.FC = () => {
  const classes = useStyles();

  const { shiftPicker } = useMst();
  const { listActiveWorkOrders, workOrdersCollection, workOrdersCollectionsInitialized } =
    useWorkOrders();

  // Active Work Orders to Display
  const [activeWorkOrders, setActiveWorkOrders] = useState<WorkOrderWithChildren[]>([]);

  const getActiveWorkOrders = async () => {
    if (shiftPicker.currentShiftId === null) setActiveWorkOrders([]);

    try {
      setActiveWorkOrders(await listActiveWorkOrders());
    } catch (error) {
      console.log(
        '🚀 ~ file: OtherDeficienciesCard.tsx ~ line 88 ~ getActiveDeficiencies ~ error',
        error,
      );
    }
  };

  const subscribeToWorkOrderChanges = async () => {
    if (workOrdersCollection) {
      workOrdersCollection.$.subscribe(() => {
        getActiveWorkOrders();
      });
    }
  };

  useEffect(() => {
    if (workOrdersCollectionsInitialized) {
      getActiveWorkOrders();
    }

    subscribeToWorkOrderChanges();
  }, [workOrdersCollectionsInitialized, workOrdersCollection, shiftPicker.currentShiftId]);

  const [expandedId, setExpandedId] = useState<string>();

  const onExpandedPanel = (expanded: boolean, deficiencyId: string) => {
    if (expanded) setExpandedId(deficiencyId);
    else setExpandedId(undefined);
  };

  const getActiveWorkOrdersCards = () => {
    return activeWorkOrders.map((doc, index) => {
      return (
        <Grid item xs={12} key={`grid-${doc.workOrderDoc.id}`}>
          <WorkOrderExpansionPanel
            workOrder={doc.workOrderDoc}
            equipment={doc.equipmentDoc}
            equipmentType={doc.equipmentTypeDoc}
            index={index}
            expandedWorkOrderId={expandedId}
            onExpanded={onExpandedPanel}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={10}>
              <Typography variant="h3">{i18n.t('Active work orders')}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.cardContainer}>
            {getActiveWorkOrdersCards()}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default observer(ActiveWorkOrdersCard);
