import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import type { EmployeeCollection, EmployeeDocument } from '../../models/models';
import useBorerShift from '../BorerShift/useBorerShift';
import useBorerShiftCrew from '../BorerShiftCrew/useBorerShiftCrew';
import { Employee } from '../Employees/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { SignatureCollection, SignatureDocument } from '../Signature/rxdbSignatureDefinition';
import { BorerShiftSignatureCollection, BorerShiftSignatureDocument } from './queryBuilder';

export const useBorerShiftSignatures = () => {
  const { shiftPicker } = useMst();

  const borerShiftSignatureCollection: BorerShiftSignatureCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const employeeCollection: EmployeeCollection = useRxCollection(RxdbCollectionName.EMPLOYEES);

  const { getPreviousBorerShift } = useBorerShift();
  const { getBorerShiftCrews } = useBorerShiftCrew();

  const [borerShiftSignaturesInitialized, setBorerShiftSignaturesInitialized] = useState(false);

  useEffect(() => {
    if (borerShiftSignatureCollection && signatureCollection)
      setBorerShiftSignaturesInitialized(true);
  }, [borerShiftSignatureCollection, signatureCollection]);

  const listBorerShiftSignaturesForBorerShiftId = async (
    currentBorerShiftId: string,
  ): Promise<BorerShiftSignatureDocument[]> => {
    if (!borerShiftSignatureCollection) {
      return [];
    }

    return borerShiftSignatureCollection
      .find({
        selector: {
          borerShiftId: currentBorerShiftId,
        },
      })
      .exec();
  };

  const listEligibleSignees = async (
    filterOutVisitingEmployees = true,
  ): Promise<EmployeeDocument[]> => {
    // * Anyone who signed this shift
    // * Anyone who signed the previous shift
    // * All crew members for this shift.
    // * Visitors that are employees for this shift. if filterOutVisitingEmployees is false

    const { currentBorerShiftId } = shiftPicker;
    let employeeArray: EmployeeDocument[] = [];

    if (
      !currentBorerShiftId ||
      !borerShiftSignatureCollection ||
      !signatureCollection ||
      !employeeCollection
    ) {
      return [];
    }

    // Get the signatures for this borer shift
    const borerShiftSignatures: BorerShiftSignatureDocument[] =
      await listBorerShiftSignaturesForBorerShiftId(currentBorerShiftId);

    // Pull the signatureIds of the shifts signatures
    const signatureIdsToPull = borerShiftSignatures.map(signature => signature.signatureId);

    // Signatures from shift
    const signaturesForShift: Map<string, SignatureDocument> = await signatureCollection.findByIds(
      signatureIdsToPull,
    );

    let signaturesFromShift = Array.from(signaturesForShift.values());

    // Filter out visitor signatures
    if (filterOutVisitingEmployees)
      signaturesFromShift = signaturesFromShift.filter(
        signature => signature.signatureType !== 'visitor',
      );

    // Pull the employeesIds on the signature
    const siteEmployeeIdsToPull: string[] = [
      ...signaturesFromShift.map(signature => signature.siteEmployeeId),
    ].filter(signature => signature !== undefined && signature !== null);

    // Pull the employees by id
    const employees = await employeeCollection.findByIds(siteEmployeeIdsToPull);

    // Comment out bc need to augment
    employeeArray = Array.from(employees.values());

    const previousBorerShift = await getPreviousBorerShift();

    if (previousBorerShift?.id) {
      // Get the signatures for the previous borer shift
      const prevBorerShiftSignatures: BorerShiftSignatureDocument[] =
        await listBorerShiftSignaturesForBorerShiftId(previousBorerShift?.id);

      // Pull the signatureIds of the shifts signatures
      const prevSignatureIdsToPull = prevBorerShiftSignatures.map(
        signature => signature.signatureId,
      );

      // Signatures from shift
      const signaturesForPrevShift: Map<string, SignatureDocument> =
        await signatureCollection.findByIds(prevSignatureIdsToPull);

      let signaturesFromPrevShift = Array.from(signaturesForPrevShift.values());

      // Filter out visitor signatures
      if (filterOutVisitingEmployees)
        signaturesFromPrevShift = signaturesFromPrevShift.filter(
          signature => signature.signatureType !== 'visitor',
        );

      // Pull the employeesIds on the signature
      const siteEmployeeIdsToPullFromPrevShift: string[] = [
        ...new Set(signaturesFromPrevShift.map(signature => signature.siteEmployeeId)),
      ].filter(signature => signature !== undefined && signature !== null);

      const prevShiftEmployees = await employeeCollection.findByIds(
        siteEmployeeIdsToPullFromPrevShift,
      );
      employeeArray = [...employeeArray, ...Array.from(prevShiftEmployees.values())];
    }

    // Augment with all employees assigned to current shift
    const crewsForCurrentShift = await getBorerShiftCrews(currentBorerShiftId);

    if (crewsForCurrentShift?.borerShiftCrews) {
      const { borerShiftCrews } = crewsForCurrentShift;
      borerShiftCrews.forEach(crew => {
        crew.borerShiftCrewMemberInput.forEach(employee => employeeArray.push(employee));
      });
    }

    const uniqueActiveEmployees = employeeArray.filter((value, index, self) => {
      if (value.hasOwnProperty('isActive'))
        return self.map(e => e.id).indexOf(value.id) === index && value.isActive;
      return self.map(e => e.id).indexOf(value.id) === index;
    });

    return uniqueActiveEmployees.sort((a: Employee, b: Employee) =>
      a.firstName?.toLowerCase().localeCompare(b.firstName?.toLowerCase()),
    );
  };

  return {
    borerShiftSignaturesInitialized,
    listBorerShiftSignaturesForBorerShiftId,
    listEligibleSignees,
  };
};
export default useBorerShiftSignatures;
