import dayjs from 'dayjs';

import { SYNC_DURATION_KEY } from './constants';

export const getLastSyncDiff = () => {
  const lastSyncInStorage = localStorage.getItem(SYNC_DURATION_KEY);

  if (lastSyncInStorage) {
    const parsed = JSON.parse(lastSyncInStorage);
    const diff = dayjs().diff(dayjs(parsed), 'seconds');
    return diff;
  }
  return null;
};
export const saveSyncTimeToLocalStorage = () => {
  localStorage.setItem(SYNC_DURATION_KEY, JSON.stringify(Date.now()));
};

export const clearSyncTime = () => {
  localStorage.removeItem(SYNC_DURATION_KEY);
};
