import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import React, { useMemo } from 'react';

import { InspectionResultDocument } from '../../rxdb/InspectionResults/queryBuilder';
import { AreaCheckResult } from '../../rxdb/InspectionResults/types';
import { InspectionDocument } from '../../rxdb/Inspections/queryBuilder';
import { useDateFormatters } from '../../utilities';
import { TIME_FORMAT } from '../../utilities/useDateFormatters';
import { formatAsStartCase } from '../../utilities/utilityFunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px !important',
      padding: '16px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
  }),
);

interface Props {
  inspectionDetails: InspectionDocument;
  inspectionResult: InspectionResultDocument;
  onAreaCheckSelected: (inspectionResult: InspectionResultDocument) => void;
}

const getHazardsMessage = (inspectionResult: InspectionResultDocument): string => {
  let inspectionDetail: AreaCheckResult | string | null;

  try {
    inspectionDetail = JSON.parse(inspectionResult.inspectionDetail);
  } catch (error) {
    inspectionDetail = inspectionResult.inspectionDetail;
  }

  if (typeof inspectionDetail === 'string' || inspectionDetail === null)
    return i18n.t('No hazards');

  const { areaCheckHazards } = inspectionDetail;
  if (areaCheckHazards) {
    const hasHazards = areaCheckHazards.some((x: { hazards: string | any[] }) => {
      return x.hazards.length > 0;
    });
    return hasHazards ? i18n.t('Hazards added') : i18n.t('No hazards');
  }

  return i18n.t('No hazards');
};

const AreaCheckCard: React.FC<Props> = ({
  inspectionDetails,
  inspectionResult,
  onAreaCheckSelected,
}: Props) => {
  const classes = useStyles();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const hazardsMessage = getHazardsMessage(inspectionResult);

  const areaCheckName = useMemo(
    () => formatAsStartCase(inspectionDetails?.description),
    [inspectionDetails],
  );

  return (
    <Card
      className={classes.cardRoot}
      onClick={() => {
        onAreaCheckSelected(inspectionResult);
      }}
      id={`open-area-check-card-${inspectionResult.id}`}
    >
      <Grid container>
        <Grid item container xs={6}>
          <Grid item>
            <Typography variant="h5">{areaCheckName}</Typography>
          </Grid>
          <Grid item>
            <span className={classes.dot2}>&#183;</span>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textSecondary">
              {formatDateWithDisappearingDate(inspectionResult.createdOn, TIME_FORMAT)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} justify="flex-end" alignContent="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">{hazardsMessage}</Typography>
          </Grid>
          <Grid item>
            <Icons.ChevronRightFeather color="primary" />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AreaCheckCard;
