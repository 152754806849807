import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { formatInLocalTimezone, TIME_FORMAT } from '../../utilities/useDateFormatters';
import { RxdbCollectionName } from '../rxdbCollectionName';
import useShifts from '../Shifts/useShifts';
import { TargetBorerRunningTimeCollection, TargetBorerRunningTimeDocument } from './queryBuilder';

const useTargetBorerRunningTimes = () => {
  const { equipment, shiftPicker } = useMst();
  const { getShiftById } = useShifts();

  const targetBorerRunningTimeCollection: TargetBorerRunningTimeCollection = useRxCollection(
    RxdbCollectionName.TARGET_BORER_RUNNING_TIMES,
  );

  const [
    targetBorerRunningTimeCollectionInitialized,
    setTargetBorerRunningTimeCollectionInitialized,
  ] = useState(false);

  const [dayShiftStart, setDayShiftStart] = useState<string>();
  const [dayShiftEnd, setDayShiftEnd] = useState<string>();
  const [nightShiftStart, setNightShiftStart] = useState<string>();
  const [nightShiftEnd, setNightShiftEnd] = useState<string>();

  const [targetBorerRunningTimeDoc, setTargetBorerRunningTimeDoc] =
    useState<TargetBorerRunningTimeDocument>();

  useEffect(() => {
    if (targetBorerRunningTimeCollection) setTargetBorerRunningTimeCollectionInitialized(true);
  }, [targetBorerRunningTimeCollection]);

  const getTargetBorerRunningTime = async (borerEquipmentId: string | undefined) => {
    if (!targetBorerRunningTimeCollection || !borerEquipmentId) {
      return undefined;
    }

    try {
      const result = await targetBorerRunningTimeCollection
        .findOne({
          selector: {
            borerEquipmentId,
          },
        })
        .exec();

      const selectedShift = await getShiftById(shiftPicker.currentShiftId);

      if (result && selectedShift) {
        setTargetBorerRunningTimeDoc(result);

        const dayStart = formatInLocalTimezone(
          dayjs(selectedShift.start)
            .add(result.dayShiftStartOffsetMinutes, 'minutes')
            .toISOString(),
          TIME_FORMAT,
        );
        const nightStart = formatInLocalTimezone(
          dayjs(selectedShift.start)
            .add(result.nightShiftStartOffsetMinutes, 'minutes')
            .toISOString(),
          TIME_FORMAT,
        );
        const dayEnd = formatInLocalTimezone(
          dayjs(selectedShift.end)
            .subtract(result.dayShiftEndOffsetMinutes, 'minutes')
            .toISOString(),
          TIME_FORMAT,
        );
        const nightEnd = formatInLocalTimezone(
          dayjs(selectedShift.end)
            .subtract(result.nightShiftEndOffsetMinutes, 'minutes')
            .toISOString(),
          TIME_FORMAT,
        );

        setDayShiftStart(dayStart || undefined);
        setNightShiftStart(nightStart || undefined);
        setDayShiftEnd(dayEnd || undefined);
        setNightShiftEnd(nightEnd || undefined);
      } else {
        setTargetBorerRunningTimeDoc(undefined);
        setDayShiftStart(undefined);
        setDayShiftEnd(undefined);
        setNightShiftStart(undefined);
        setNightShiftEnd(undefined);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: useTargetBorerRunningTimes.ts ~ line 70 ~ useTargetBorerRunningTimes ~ error',
        error,
      );
      throw error;
    }
  };

  useEffect(() => {
    if (targetBorerRunningTimeCollectionInitialized && equipment.selectedBorerId) {
      getTargetBorerRunningTime(equipment.selectedBorerId);
    }
  }, [
    equipment.selectedBorerId,
    targetBorerRunningTimeCollectionInitialized,
    shiftPicker.currentShiftId,
  ]);

  useEffect(() => {
    if (targetBorerRunningTimeDoc) {
      targetBorerRunningTimeDoc.$.subscribe(() => {
        getTargetBorerRunningTime(equipment.selectedBorerId);
      });
    }
  }, [targetBorerRunningTimeDoc]);

  return {
    dayShiftStart,
    dayShiftEnd,
    nightShiftStart,
    nightShiftEnd,
    targetBorerRunningTimeCollectionInitialized,
  };
};

export { useTargetBorerRunningTimes };
export default useTargetBorerRunningTimes;
