import { ApolloClient, gql, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createContext, useContext } from 'react';

import { apiConfig } from '../../config/api.config';
import { createAppSyncHybridLink } from './appSyncHybridLink';

// Create Apollo Client
const createAppSyncApolloClient = async (
  idToken: string,
  appSyncApiUrl = apiConfig.webSocketGraphUrl,
  connectToDevTools = false,
) => {
  try {
    const link = await createAppSyncHybridLink({
      appSyncApiUrl,
      getJwtToken: () => idToken,
    });
    return new ApolloClient({
      link,
      cache: new InMemoryCache(),
      connectToDevTools,
    });
  } catch (error) {
    console.log('🚀 ~ file: WSClient.tsx ~ line 31 ~ error', error);
    throw error;
  }
};

export { createAppSyncApolloClient, gql };

export default { createAppSyncApolloClient, gql };

// Creating a context with hook for these
const subscriptionClientContext = createContext<null | ApolloClient<NormalizedCacheObject>>(null);
export const { Provider: GraphQLSubscriptionProvider } = subscriptionClientContext;

export const useSubscriptionClient = () => {
  const client = useContext(subscriptionClientContext);
  return client;
};
