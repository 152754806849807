import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutoComplete,
  darkTheme,
  GroupedAutoComplete,
  i18n,
  TextField,
  Typography,
} from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';

import {
  EquipmentTypeList,
  EquipmentWithType,
  useEquipmentListByTypeName,
} from '@/rxdb/EquipmentType/useEquipmentListByTypeName';
import { PopulatedLocation } from '@/rxdb/Locations/useLocations';

import { PanelDocument } from '../../models/models';
import { BlockDocument } from '../../rxdb/Blocks/queryBuilder';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import { MiningCutSequencePassObj } from '../../rxdb/MiningCut/queryBuilder';
import useMiningCuts from '../../rxdb/MiningCut/useMiningCuts';
import usePanels from '../../rxdb/Panels/usePanels';
import { Room } from '../../rxdb/Rooms/queryBuilder';
import useSite from '../../rxdb/Site/useSite';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import useSurveyPoints from '../../rxdb/SurveyPoints/useSurveyPoints';
import { AdvanceErrors } from '../ModifyAdvanceSidePanel/ModifyAdvanceSidePanel';

const useStyles = makeStyles(() =>
  createStyles({
    itemSpacing: {
      padding: '0px 5px',
    },
    topMargin: {
      marginTop: '16px',
    },
    textFieldSpacer: {
      padding: '0px 5px',
    },
    footageText: {
      color: darkTheme.palette.common.white,
      opacity: 0.5,
      fontWeight: 600,
    },
  }),
);

interface Props {
  isChevron: boolean;
  panel?: PanelDocument;
  surveyPoint?: SurveyPointDocument;
  errors: AdvanceErrors;
  advanceFootage?: string;
  block?: BlockDocument;
  room?: Room;
  validateRoomChange: (newRoom: PopulatedLocation) => void;
  validateSequenceChange: (value: string) => void;
  validateAdvanceStartFootageChange: (value: string) => void;
  validateAdvanceEndFootageChange: (value: string) => void;
  selectedMiningCutSequencePass?: MiningCutSequencePassObj;
  validateMiningCutChange: (value: MiningCutSequencePassObj) => void;
  onAdvanceFootageChanged: (value: string) => void;
  isEvenPass: boolean;
  advanceStartFootage?: number | string;
  advanceEndFootage?: number | string;
  editMode?: boolean;
  validationIndex: number;
  setErrors: Dispatch<SetStateAction<AdvanceErrors>>;
  numberOfBuckets: string | number;
  selectedStamler?: EquipmentWithType;
  selectedScoop?: EquipmentWithType;
  validateStamlerChange: (value?: EquipmentWithType) => void;
  validateScoopChange: (value?: EquipmentWithType) => void;
  validateNumberOfBucketsChange: (value: string) => void;
  showAllanRehabFields: boolean;
}

const AdvancePanel: React.FC<Props> = ({
  isChevron,
  panel,
  surveyPoint,
  block,
  room,
  errors,
  validateRoomChange,
  validateSequenceChange,
  validateAdvanceStartFootageChange,
  validateAdvanceEndFootageChange,
  selectedMiningCutSequencePass,
  advanceFootage,
  validateMiningCutChange,
  onAdvanceFootageChanged,
  isEvenPass,
  advanceStartFootage,
  advanceEndFootage,
  editMode = false,
  validationIndex,
  setErrors,
  numberOfBuckets,
  selectedStamler,
  selectedScoop,
  validateStamlerChange,
  validateScoopChange,
  validateNumberOfBucketsChange,
  showAllanRehabFields,
}: Props) => {
  const classes = useStyles();
  const { getMiningCutLabel } = useMiningCuts();
  const { isVanscoy, isCory, isLanigan, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation, useMeters } = useSite();

  const { currentPanel, miningMethod } = useCurrentBorer();
  const { equipmentWithAssignmentStatus: stamlerList } = useEquipmentListByTypeName(
    EquipmentTypeList.Stamler,
  );
  const { equipmentWithAssignmentStatus: scoopList } = useEquipmentListByTypeName(
    EquipmentTypeList.Haulage,
  );

  const { augmentedPanelList, roomList, panelListLoaded, roomListLoaded } = usePanels(
    currentPanel?.miningMethod,
    miningMethod,
  );
  const {
    surveyPointsForPanel,
    surveyPointsForPanelLoaded,
    surveyPointsForRoom,
    surveyPointsForRoomLoaded,
  } = useSurveyPoints(panel?.id, room?.id);

  const getPanelLabel = useCallback(
    (option: PopulatedLocation) => {
      if (isChevron || isCory || isAllan) {
        return option?.panel?.description || '';
      }
      if (option?.panel?.description && option.room?.description)
        return `${option?.panel?.description}-${option.room.description}`;
      if (option.room?.description) return option.room.description;
      return '';
    },
    [isChevron, isCory, isAllan],
  );
  const getRoomLabel = useCallback((option: PopulatedLocation) => {
    if (!option) return '';

    return option?.room?.description.includes('PE')
      ? `${option?.room?.description || ''}${option?.block?.description || ''}${
          option?.panel?.description || ''
        }`
      : `${option?.block?.description || ''}${option?.panel?.description || ''}${
          option?.room?.description || ''
        }`;
  }, []);

  const onPanelChange = useCallback((event: any, value: PopulatedLocation) => {
    validateRoomChange({
      ...value,
    });
  }, []);

  // For Lanigan/Vanscoy - Chevron - Use Panel MiningMethod
  // For Lanigan/Vanscoy - LongRoom - Use room (Section) miningMethod
  // For Cory - ZA & ZB - Use room (breakthrough) miningMethod
  const {
    miningCutListForMiningMethod,
    miningCutListForMiningPattern,
    miningCutsForMiningPatternLoaded,
    miningCutsForMiningMethodLoaded,
  } = useMiningCuts(
    isChevron ? surveyPoint?.miningPattern : room?.miningPattern,
    panel?.miningMethod,
  );

  const passList = useMemo(() => {
    if (isLanigan || isVanscoy || isRocanville) return miningCutListForMiningPattern;
    if ((isCory && isChevron) || isAllan) return miningCutListForMiningPattern;
    if (isCory) return miningCutListForMiningMethod;
    return [];
  }, [
    isVanscoy,
    isLanigan,
    isChevron,
    isCory,
    isAllan,
    miningCutListForMiningMethod,
    miningCutListForMiningPattern,
    isRocanville,
  ]);

  const advanceLengthString = useMemo(() => {
    if (
      advanceStartFootage &&
      advanceEndFootage &&
      !errors.advanceStartFootage &&
      !errors.advanceEndFootage
    ) {
      return `${translate('Advance footage')}: ${Math.abs(
        Number(advanceStartFootage || 0) - Number(advanceEndFootage || 0),
      )} ${distanceUnitAbbreviation}`;
    }
    return `${translate('Advance footage')}:`;
  }, [advanceStartFootage, advanceEndFootage, errors]);

  const sequenceValidation = useCallback(() => {
    if (validationIndex >= 1 && surveyPointsForPanelLoaded && surveyPointsForRoomLoaded) {
      if (!isLanigan && !surveyPointsForPanel.length)
        return `No ${translate('sequence')}'s exist for ${isLanigan ? 'room' : 'panel'}`;
      if (isLanigan && !surveyPointsForRoom.length)
        return `No ${translate('sequence')}'s exist for ${isLanigan ? 'room' : 'panel'}`;
    }
    if (validationIndex >= 2 && surveyPointsForPanelLoaded && surveyPointsForRoomLoaded) {
      if (!surveyPoint) return i18n.t('Required');
    }
    return '';
  }, [
    validationIndex,
    surveyPointsForPanelLoaded,
    surveyPointsForRoomLoaded,
    surveyPointsForPanel,
    surveyPoint,
    surveyPointsForRoom.length,
    isLanigan,
  ]);

  const stepValidation = useCallback(() => {
    if (
      validationIndex >= 2 &&
      miningCutsForMiningPatternLoaded &&
      miningCutsForMiningMethodLoaded
    ) {
      if (!passList.length)
        return `No passes exist for ${isChevron ? translate('sequence') : 'Panel'}`;
      if (validationIndex >= 2 && panel && !passList.length) {
        return `No passes exist for ${isChevron ? translate('sequence') : 'panel'}`;
      }
      if (validationIndex >= 3 && !selectedMiningCutSequencePass) {
        return i18n.t('Required');
      }
    }
    return '';
  }, [
    validationIndex,
    miningCutsForMiningPatternLoaded,
    miningCutsForMiningMethodLoaded,
    panel,
    selectedMiningCutSequencePass,
    passList.length,
    isChevron,
  ]);

  const scoopValidation = useCallback(() => {
    if (validationIndex >= 9 && !selectedScoop) return `Required`;
    return '';
  }, [validationIndex, selectedScoop]);

  const numberOfBucketsValidation = useCallback(() => {
    if (validationIndex >= 10) {
      if (!numberOfBuckets) return `Required`;
      if (!Number(numberOfBuckets) && Number(numberOfBuckets) !== 0)
        return `Must be a valid number`;
      if (Number(numberOfBuckets) < 0) return `Must be greater than or equal to 0`;
      if (!Number.isInteger(Number(numberOfBuckets))) return `Must be a whole number`;
    }
    return '';
  }, [validationIndex, numberOfBuckets]);

  useEffect(
    () =>
      setErrors(prev => ({
        ...prev,
        sequence: sequenceValidation(),
        step: stepValidation(),
        scoop: scoopValidation(),
        numberOfBuckets: numberOfBucketsValidation(),
      })),
    [stepValidation, sequenceValidation, scoopValidation, numberOfBucketsValidation],
  );

  return (
    <Grid container className={classes.topMargin}>
      {isLanigan && (
        <Grid item xs={12} className={classes.itemSpacing}>
          <AutoComplete
            autoSelect={false}
            list={roomList}
            label={i18n.t('Room')}
            getOptionLabel={getRoomLabel}
            onChange={(event, value) => validateRoomChange(value)}
            value={block && panel && room ? { block, panel, room } : undefined}
            showCaret
            data-cy="Advance-Room-Input"
            error={errors.room !== ''}
            errorText={errors.room}
            disabled={editMode}
            loading={!roomListLoaded}
          />
        </Grid>
      )}
      {!isLanigan && (
        <Grid
          item
          xs={(isVanscoy && !isChevron) || isRocanville ? 12 : 6}
          className={classes.itemSpacing}
        >
          <AutoComplete
            autoSelect={false}
            list={augmentedPanelList}
            label={i18n.t('Panel')}
            getOptionLabel={getPanelLabel}
            onChange={onPanelChange}
            value={block || panel || room ? { block, panel, room } : undefined}
            showCaret
            data-cy="Advance-Room-Input"
            error={errors.room !== ''}
            errorText={errors.room}
            disabled={editMode}
            loading={!panelListLoaded}
          />
        </Grid>
      )}
      {(isChevron || isCory || isAllan) && (
        <Grid item xs={isLanigan ? 12 : 6} className={classes.itemSpacing}>
          <AutoComplete
            autoSelect={false}
            list={isLanigan ? surveyPointsForRoom : surveyPointsForPanel}
            label={translate(i18n.t('Sequence'))}
            getOptionLabel={(option: SurveyPointDocument) => {
              return option.description;
            }}
            onChange={(event, value) => {
              validateSequenceChange(value);
            }}
            value={surveyPoint}
            showCaret
            data-cy="Advance-SurveyPoint-Input"
            error={!!errors.sequence}
            errorText={errors.sequence}
            disabled={editMode}
          />
        </Grid>
      )}
      <Grid item xs={12} className={classes.itemSpacing}>
        <GroupedAutoComplete
          autoSelect={false}
          list={passList || []}
          label={translate('Pass')}
          getOptionLabel={getMiningCutLabel}
          onChange={(event, value) => validateMiningCutChange(value)}
          value={selectedMiningCutSequencePass}
          showCaret
          data-cy="Advance-Chevron-Step"
          error={!!errors.step}
          errorText={errors.step}
          groupByProperty="miningPatternName"
          primaryProperty="sequencePassString"
          secondaryProperty="miningPatternName"
          disabled={editMode}
        />
      </Grid>
      {(isChevron || isCory || isAllan) && !editMode && !showAllanRehabFields && (
        <Grid item xs={6} className={classes.itemSpacing}>
          <TextField
            label={useMeters ? translate('Total meters') : translate('Advance footage')}
            unitText={distanceUnitAbbreviation}
            onChange={event => onAdvanceFootageChanged(event.target.value)}
            value={advanceFootage}
            data-cy="AdvanceFootage"
            inputProps={{ inputMode: 'numeric' }}
            error={validationIndex >= 5 && errors.advanceFootage !== ''}
            errorText={errors.advanceFootage}
            disabled={editMode}
          />
        </Grid>
      )}
      {panel && !isCory && !isChevron && !isAllan && !editMode && !showAllanRehabFields && (
        <>
          <Grid item xs={6} className={classes.itemSpacing}>
            <TextField
              label={useMeters ? i18n.t('Start meters') : translate('Start footage')}
              unitText={distanceUnitAbbreviation}
              value={advanceStartFootage}
              onChange={event => validateAdvanceStartFootageChange(event.target.value)}
              disabled={isEvenPass === undefined}
              error={validationIndex >= 6 && errors.advanceStartFootage !== ''}
              errorText={errors.advanceStartFootage}
              data-cy="Advance-Start-Footage-Input"
              inputProps={{ inputMode: 'numeric' }}
            />
          </Grid>
          <Grid item xs={6} className={classes.itemSpacing}>
            <TextField
              label={useMeters ? i18n.t('End meters') : translate('End footage')}
              unitText={distanceUnitAbbreviation}
              value={advanceEndFootage}
              onChange={event => validateAdvanceEndFootageChange(event.target.value)}
              disabled={isEvenPass === undefined}
              error={validationIndex >= 7 && errors.advanceEndFootage !== ''}
              errorText={errors.advanceEndFootage}
              data-cy="Advance-End-Footage-Input"
              inputProps={{ inputMode: 'numeric' }}
            />
          </Grid>
          <Grid item xs={12} className={classes.textFieldSpacer}>
            <Typography variant="caption" color="textPrimary" className={classes.footageText}>
              {advanceLengthString}
            </Typography>
          </Grid>
        </>
      )}
      {showAllanRehabFields && (
        <>
          <Grid item xs={12} className={classes.itemSpacing}>
            <GroupedAutoComplete
              autoSelect={false}
              list={stamlerList}
              label={i18n.t('Stamler (optional)')}
              getOptionLabel={(option: EquipmentWithType) => {
                return option.name;
              }}
              onChange={(event, value) => validateStamlerChange(value)}
              value={selectedStamler}
              showCaret
              data-cy="Advance-Stamler-Input"
              groupByProperty="assignmentString"
              primaryProperty="name"
              secondaryProperty="typeName"
              disabled={editMode}
            />
          </Grid>
          <Grid item xs={12} className={classes.itemSpacing}>
            <GroupedAutoComplete
              autoSelect={false}
              list={scoopList}
              label={i18n.t('Scoop/Hauler')}
              getOptionLabel={(option: EquipmentWithType) => {
                return option.name;
              }}
              onChange={(event, value) => validateScoopChange(value)}
              value={selectedScoop}
              showCaret
              data-cy="Advance-Scoop-Input"
              error={!!errors.scoop}
              errorText={errors.scoop}
              groupByProperty="assignmentString"
              primaryProperty="name"
              secondaryProperty="typeName"
              disabled={editMode}
            />
          </Grid>
          {!editMode && (
            <Grid item xs={6} className={classes.itemSpacing}>
              <TextField
                label={i18n.t('No. Buckets')}
                value={numberOfBuckets}
                onChange={event => validateNumberOfBucketsChange(event.target.value)}
                disabled={isEvenPass === undefined}
                error={!!errors.numberOfBuckets}
                errorText={errors.numberOfBuckets}
                data-cy="Advance-Buckets-Input"
                inputProps={{ inputMode: 'numeric' }}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
export default AdvancePanel;
