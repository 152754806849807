import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface GroundHazardConditionType extends BaseEntity {
  readonly description: string;
  readonly displayName: string;
  readonly site: Site;
  readonly isActive: boolean;
  readonly isSingleEntry: boolean;
  readonly severity: string;
}
export type GroundHazardConditionTypeCollection = RxCollection<GroundHazardConditionType> | null;
export type GroundHazardConditionTypeDocument = RxDocument<GroundHazardConditionType>;

export const groundHazardConditionTypeSchema: RxJsonSchema<GroundHazardConditionType> = {
  type: 'object',
  version: 1,
  description: 'describes a GroundHazardConditionType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    displayName: { type: 'string' },
    site: {
      ref: 'sites',
      type: 'string',
    },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    isActive: { type: 'boolean' },
    isSingleEntry: { type: 'boolean' },
    severity: { type: ['string', 'null'] },
    version: { type: 'number' },
  },
};

export const groundHazardConditionTypeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: GroundHazardConditionTypeDocument) => {
    return {
      ...oldDoc,
      displayName: oldDoc.description,
    };
  },
};

export const groundHazardConditionTypePullQueryBuilder = (
  doc: GroundHazardConditionTypeDocument,
) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`
    query hazardConditionTypeFeed($siteId: ID!, $updatedAt: Float!, $limit: Int!) {
      hazardConditionTypeFeed(siteId: $siteId, lastUpdateAt: $updatedAt, limit: $limit) {
        id
        description
        displayName
        site
        updatedAt
        isActive
        isSingleEntry
        isDeleted
        severity
        version
      }
    }
  `);

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      updatedAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
