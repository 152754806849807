import { formatLocation } from '@nutrien/minesight-utility-module';
import { LAYER_3, LAYER_4 } from '@nutrien/minesight-utility-module/dist/constants/constants';
import { LocationDisplaySummary } from '@nutrien/minesight-utility-module/dist/utilities/siteLocationFormatting/siteLocationFormatting';
import { useCallback, useEffect, useState } from 'react';
import { useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { ProductionDocument } from '../Productions/queryBuilder';
import RxdbCollectionName from '../rxdbCollectionName';
import useSite from '../Site/useSite';
import { AdvanceDocument } from './queryBuilder';

export interface AugmentedAdvance {
  borerShiftAdvanceId: string;
  locationString: string;
  distanceString: string;
}

export const useAdvancesForShift = () => {
  const { shiftPicker } = useMst();
  const { distanceUnitAbbreviation } = useSite();
  const [advancesForShift, setAdvancesForShift] = useState<AugmentedAdvance[]>([]);
  const [advancesForShiftById, setAdvancesForShiftById] = useState<{
    [key: string]: AugmentedAdvance;
  }>({});

  const { result: productionForShiftId, isFetching: productionFetching } =
    useRxData<ProductionDocument>(RxdbCollectionName.BORER_SHIFT_PRODUCTION, collection =>
      collection.find({
        selector: {
          borerShiftId: shiftPicker.currentBorerShiftId,
        },
      }),
    );

  const advanceQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          borerShiftProductionId: { $in: productionForShiftId.map(p => p.id) },
        },
      }),
    [productionForShiftId],
  );

  const { result: advancesForBorer, isFetching: advancesFetching } = useRxData<AdvanceDocument>(
    RxdbCollectionName.BORER_SHIFT_ADVANCE,
    advanceQueryConstructor,
  );

  useEffect(() => {
    const augmentProductionData = async () => {
      if (!productionForShiftId || productionFetching) {
        setAdvancesForShift([]);
        setAdvancesForShiftById({});
      } else {
        const advanceArray: AugmentedAdvance[] = [];
        const advanceObj: { [key: string]: AugmentedAdvance } = {};

        await Promise.all(
          productionForShiftId.map(async prodDoc => {
            const advances = advancesForBorer.filter(
              adv => adv.borerShiftProductionId === prodDoc.id,
            );

            for (const advance of advances) {
              const [cut, loc] = await Promise.all([
                prodDoc.populate('miningCutId'),
                prodDoc.populate('locationId'),
              ]);
              let sequence;
              let pass;
              if (cut) {
                sequence = await cut.populate('sequence');
                pass = await cut.populate('pass');
              }
              let room;
              let surveyPoint;
              let panel;
              let block;

              if (loc) {
                room = await loc.populate('room');
                surveyPoint = await loc.populate('surveyPoint');
                panel = await loc.populate('panel');
                block = await panel.populate('block');
              }
              const location: LocationDisplaySummary = {
                blockDescription: block?.description,
                panelDescription: panel?.description,
                roomDescription: room?.description,
                sequenceDescription: sequence?.description,
                surveyPointDescription: surveyPoint?.description,
                passDescription: pass?.description,
                miningMethodLayer: panel?.miningMethod === 'Chevron' ? LAYER_4 : LAYER_3,
                miningMethodName: panel?.miningMethod,
              };
              const formattedAdvanceDetails = {
                distanceString: `${advance.distance} ${distanceUnitAbbreviation}`,
                borerShiftAdvanceId: advance.id,
                locationString: formatLocation(location, {
                  commaDelimited: false,
                  includeDistance: false,
                  showPass: true,
                  showStep: true,
                  showSequence: panel?.miningMethod === 'Chevron',
                }),
              };

              advanceArray.push(formattedAdvanceDetails);
              advanceObj[advance.id] = formattedAdvanceDetails;
            }
          }),
        );
        setAdvancesForShift(advanceArray);
        setAdvancesForShiftById(advanceObj);
      }
    };
    augmentProductionData();
  }, [
    productionForShiftId,
    productionFetching,
    distanceUnitAbbreviation,
    advancesFetching,
    advancesForBorer,
  ]);

  return {
    advancesForShift,
    advancesForShiftById,
  };
};
