import type { RxDatabase } from 'rxdb';
import { RxGraphQLReplicationState } from 'rxdb/dist/types/plugins/replication-graphql';

import { BorerDatabaseCollections } from '../../models/BorerDatabaseCollections';
import { isJestOrStorybook } from '../../test-helpers/isJestOrStorybook';
import { BorerOperatorChangeCollection } from '../BorerOperatorChangeFeed/queryBuilder';
import { checkForExpiredToken, hasForeignKeyErrors } from '../handleReplicationErrors';

const recordIdsFailedToSyncCount: Record<string, number> = {};

const increaseFailedToSyncCount = (recordId: string) => {
  if (!recordIdsFailedToSyncCount[recordId]) {
    recordIdsFailedToSyncCount[recordId] = 1;
  }
  recordIdsFailedToSyncCount[recordId] += 1;
};

const recordIdHasFailedToSyncMoreThanXTimes = (recordId: string, xTimes = 10) => {
  return recordIdsFailedToSyncCount[recordId] >= xTimes;
};

export const handleOperatorStateFeedEvents = async (
  stateFeedSyncState: RxGraphQLReplicationState<BorerOperatorChangeCollection>,
  borerOperatorChangeState: RxGraphQLReplicationState<BorerOperatorChangeCollection>,
  db: RxDatabase<BorerDatabaseCollections>,
) => {
  // Successfully coded states, hide temp states from scheduler view
  stateFeedSyncState.received$.subscribe(async doc => {
    const borerStateId = doc.borerStateId;
    const matchingTempDocs = await db.collections.borer_operator_change_feed
      ?.find({
        selector: {
          borerStateId,
          showInSchedulerView: true,
        },
      })
      .exec();

    if (matchingTempDocs?.length) {
      for (const tempDoc of matchingTempDocs) {
        await tempDoc.atomicUpdate(oldDoc => {
          const newDoc = { ...oldDoc, showInSchedulerView: false, failedSync: false };
          return newDoc;
        });
      }
    }
  });

  // Failed to code states, show temp states with error, and original states in scheduler view
  if (borerOperatorChangeState)
    borerOperatorChangeState.error$.subscribe(async err => {
      const hasExpiredToken = checkForExpiredToken(err);
      if (hasExpiredToken) return;
      const borerStateId = err.documentsData?.[0]?.borerStateId as string;
      const docId = err.documentsData?.[0]?.id as string;

      if (!borerStateId) throw new Error('Doc missing borerStateId');

      if (
        hasForeignKeyErrors(err) &&
        !recordIdHasFailedToSyncMoreThanXTimes(docId) &&
        !isJestOrStorybook()
      ) {
        // Allow Rxdb to retry (won't retry if failedSync is true)
        increaseFailedToSyncCount(docId);
        return;
      } else {
        // Toggle failedSync to true for temp items
        const tempStateDocsForBorerStateId = await borerOperatorChangeState.collection
          .find({
            selector: {
              borerStateId,
            },
          })
          .exec();

        tempStateDocsForBorerStateId.forEach(doc =>
          doc.atomicUpdate(oldDoc => ({ ...oldDoc, failedSync: true })),
        );

        delete recordIdsFailedToSyncCount[docId];

        // Unhide original delay in scheduler view (now with error)
        const syncStatesForBorerStateId = await stateFeedSyncState.collection
          .find({
            selector: {
              borerStateId,
            },
          })
          .exec();

        syncStatesForBorerStateId.forEach(doc =>
          doc.atomicUpdate(oldDoc => ({ ...oldDoc, showInSchedulerView: true })),
        );
      }
    });
};
