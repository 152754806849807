import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Chip,
  CustomPalette,
  ExpansionPanel,
  i18n,
  Icons,
  MaterialPalette,
  Typography,
} from '@nutrien/cxp-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { LocationDocument } from '../../models/models';
import { GroundHazardCollection, GroundHazardDocument } from '../../rxdb/GroundHazard/queryBuilder';
import { GroundHazardConditionType } from '../../rxdb/HazardConditionType/queryBuilder';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import { useDateFormatters } from '../../utilities/useDateFormatters';
import HazardPanelContent from '../HazardPanelContent';
import LocationDetailsComponent from '../LocationDetailsComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    titleLine: {
      marginBottom: '2px',
    },
    dot: {
      padding: '0px 12px',
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '14px',
    },
    secondaryText: {
      color: theme.palette.text.disabled,
    },
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    icon: {
      color: theme.palette.primary.main,
    },
    smallText: {
      fontSize: '14px',
    },
    loggedAtTime: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      textAlign: 'right',
      '& h5': {
        fontSize: '16px !important',
      },
    },
    fullWidth: {
      width: '100%',
    },
  }),
);

interface Props {
  useShiftDate?: boolean;
  useHistorical?: boolean;
  shiftDateStart?: number;
  shiftDateEnd?: number;
  conditionsAlwaysExpanded?: boolean;
  hideAddPhotoAction?: boolean;
  title?: string;
  newGroundHazardIds?: string[];
  hideCommentsBeforeDate?: number;
}

const RemediatedConditionsCard: React.FC<Props> = ({
  useShiftDate = false,
  useHistorical = false,
  shiftDateStart,
  shiftDateEnd,
  conditionsAlwaysExpanded = true,
  hideAddPhotoAction = false,
  title,
  newGroundHazardIds,
  hideCommentsBeforeDate,
}: Props) => {
  const classes = useStyles();
  const { equipment } = useMst();
  const { formatDate } = useDateFormatters();
  const [groundHazards, setGroundHazards] = useState<GroundHazardDocument[]>();
  const [expandedConditionId, setExpandedConditionId] = useState('');
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );

  const setupGroundHazards = async () => {
    if (groundHazardCollection)
      await groundHazardCollection
        .find()
        .where('remediatedOn')
        .ne(null)
        .$.subscribe(function (gcs) {
          setGroundHazards(gcs);
        });
  };

  const setupGroundHazardsForShiftDate = async () => {
    if (groundHazardCollection) {
      await groundHazardCollection
        .find({
          selector: {
            remediatedOn: { $ne: null },
            createdOnUnix: { $lte: shiftDateEnd },
            borerEquipmentId: { $eq: equipment.selectedBorerId },
            remediatedOnUnix: { $gte: shiftDateStart },
          },
        })
        .$.subscribe(function (gcs) {
          setGroundHazards(gcs);
        });
    }
  };

  const setupForHistoricalView = async () => {
    if (groundHazardCollection) {
      await groundHazardCollection
        .find({
          selector: {
            remediatedOn: { $ne: null },
            borerEquipmentId: { $eq: equipment.selectedBorerId },
            remediatedOnUnix: { $lt: shiftDateStart },
          },
        })
        .$.subscribe(function (gcs) {
          setGroundHazards(gcs);
        });
    }
  };

  const setupForGroundHazardAreaInspection = async () => {
    if (groundHazardCollection) {
      await groundHazardCollection
        .find({
          selector: {
            id: {
              $in: newGroundHazardIds,
            },
          },
        })
        .$.subscribe(function (gcs) {
          setGroundHazards(gcs);
        });
    }
  };

  const conditionTypesQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: conditionTypes }: { result: GroundHazardConditionType[] } = useRxData(
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
    conditionTypesQueryConstructor,
  );

  const locationsQueryConstructor = useCallback((collection: any) => {
    return collection.find();
  }, []);

  const { result: locations }: { result: LocationDocument[] } = useRxData(
    RxdbCollectionName.LOCATIONS,
    locationsQueryConstructor,
  );

  const onExpanded = (expanded: boolean, conditionId: string) => {
    if (expanded) setExpandedConditionId(conditionId);
    else setExpandedConditionId('');
  };

  const getConditionType = (conditionTypeId: string) => {
    return conditionTypes.find(c => c.id === conditionTypeId)?.displayName;
  };

  const getLocation = (locationId: string) => {
    return locations.find(l => l.id === locationId);
  };

  useEffect(() => {
    if (groundHazardCollection && groundHazardCollection !== null) {
      if (!useShiftDate && !useHistorical && !newGroundHazardIds) {
        setupGroundHazards();
      } else if (useShiftDate && !useHistorical && !newGroundHazardIds) {
        setupGroundHazardsForShiftDate();
      } else if (useHistorical) {
        setupForHistoricalView();
      } else if (newGroundHazardIds) {
        setupForGroundHazardAreaInspection();
      }
    }
  }, [groundHazardCollection, shiftDateStart, shiftDateEnd, newGroundHazardIds]);

  return (
    <>
      <Card elevation={1} className={classes.card} divClass={classes.fullWidth}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {`${title || i18n.t('Remediated hazards')} (${groundHazards?.length || '0'})`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {groundHazards
              ?.sort((a, b) => b.updatedAt - a.updatedAt)
              .map(condition => {
                return (
                  <ExpansionPanel
                    className={classes.panel}
                    key={condition.id}
                    id={condition.id}
                    data-cy={`condition-panel-${condition.id}`}
                    onChange={(event: React.ChangeEvent<unknown>, expanded: boolean) => {
                      if (event.target.closest('#AddCommentBtn') === null)
                        onExpanded(expanded, condition.id);
                    }}
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={condition.id === expandedConditionId || conditionsAlwaysExpanded}
                    expansionPanelSummaryProps={{
                      expandIcon: conditionsAlwaysExpanded ? (
                        <div />
                      ) : (
                        <Icons.ChevronDownFeather
                          strokeWidth={1}
                          className={classes.icon}
                          color="primary"
                        />
                      ),
                      children: (
                        <Grid container>
                          <Grid item container xs={8}>
                            <Grid item container xs={12} spacing={1} className={classes.titleLine}>
                              <Typography variant="h5">
                                {getConditionType(condition.conditionType)}
                              </Typography>
                              <span className={classes.dot}>&#183;</span>
                              <Typography className={classes.secondaryText} variant="h5">
                                {condition.inspectionId ? i18n.t('Area Check') : i18n.t('Hazard')}
                              </Typography>
                              {!!newGroundHazardIds &&
                                newGroundHazardIds.includes(condition.id) && (
                                  <>
                                    <span className={classes.dot}>&#183;</span>
                                    <Chip
                                      color="secondaryDark"
                                      label={i18n.t('New')}
                                      textColor={MaterialPalette.common.white}
                                      className={classes.dot}
                                    />
                                  </>
                                )}
                            </Grid>
                            <LocationDetailsComponent
                              groundHazard={condition}
                              location={getLocation(condition.startLocation)}
                              hazardLandmark={condition.startHazardLandmark}
                            />
                          </Grid>
                          <Grid item xs={4} className={classes.loggedAtTime}>
                            <Typography variant="h5">
                              Logged: {formatDate(condition.createdOn)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ),
                    }}
                    panelContent={
                      (condition.id === expandedConditionId || conditionsAlwaysExpanded) && (
                        <HazardPanelContent
                          groundHazard={condition}
                          locations={locations}
                          hideAddPhotoAction={hideAddPhotoAction}
                          hideCommentsBeforeDate={hideCommentsBeforeDate}
                        />
                      )
                    }
                  />
                );
              })}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default RemediatedConditionsCard;
