import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useMemo } from 'react';

import { useCurrentBorer } from '@/rxdb/Equipment/useCurrentBorer';
import { useEquipmentById } from '@/rxdb/Equipment/useEquipmentById';

import { useMst } from '../../mobx-models/Root';
import { useAdvanceIdArray } from '../../rxdb/Advance/useAdvanceIdArray';
import { AugmentedProduction } from '../../rxdb/Productions/useProduction';
import useSite from '../../rxdb/Site/useSite';
import { MiningMethodAllCap } from '../../utilities';
import { BORER_SHIFT_WRITE_PERMISSION, unknown } from '../../utilities/constants';
import useViewingCurrentShift from '../../utilities/hooks/useViewingCurrentShift';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
      width: '100%',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
    chevron: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButton: {
      margin: '4px',
    },
    editBtnContainer: {
      '& > div': {
        minHeight: 'auto !important',
        width: '15vw',
        marginLeft: 'auto',
      },
      '& > div > span': {
        display: 'none',
      },
    },
    divClass: {
      width: '100%',
    },
  }),
);

interface Props {
  productionDocument: AugmentedProduction;
  onEdit: (production: AugmentedProduction) => void;
}

const ProductionCard: React.FC<Props> = ({ productionDocument, onEdit }: Props) => {
  const classes = useStyles();

  const { user } = useMst();
  const userViewingCurrentShift = useViewingCurrentShift();
  const { isVanscoy, isLanigan, isCory, isAllan, isRocanville } = useSiteFeatures();
  const { distanceUnitAbbreviation, useMeters } = useSite();
  const { showAllanRehabFields } = useCurrentBorer();
  const { equipment: stamler } = useEquipmentById(
    productionDocument?.production?.stamlerEquipmentId,
  );
  const { equipment: scoop } = useEquipmentById(productionDocument?.production?.scoopEquipmentId);

  const { calculatedFootage, advances, calculatedNumberOfBuckets } = useAdvanceIdArray(
    productionDocument.advances.map(advance => advance.id),
  );

  const renderTitle = () => {
    return productionDocument.sequence
      ? `${productionDocument.sequence?.description} ${productionDocument.pass?.description}`
      : `${productionDocument.pass?.description}`;
  };

  const miningMethod = useMemo(
    () => productionDocument.miningCut?.miningMethod?.toUpperCase(),
    [productionDocument],
  );

  const panelLabel = useMemo(() => {
    if (miningMethod === MiningMethodAllCap.CHEVRON || isCory || isAllan) {
      return productionDocument.panel?.description || '';
    }
    if (productionDocument.panel?.description && productionDocument.room?.description)
      return `${productionDocument.panel?.description}-${productionDocument.room?.description}`;
    if (productionDocument.room?.description) return productionDocument.room?.description;
    return '';
  }, [isCory, productionDocument, miningMethod, isAllan]);

  const calculatedScoopTonnage = () => {
    if (scoop?.tonnesPerScoop) return Math.round(calculatedNumberOfBuckets * scoop?.tonnesPerScoop);
    return '';
  };

  return (
    <Card className={classes.cardRoot} onClick={() => {}} divClass={classes.divClass}>
      {location && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">{renderTitle()}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.editBtnContainer}>
            {userViewingCurrentShift && user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => onEdit(productionDocument)}
                id="edit-advance-btn"
                css={undefined}
              >
                <Icons.EditFeather color="primary" />
                {`${i18n.t('Edit advance')}`}
              </Button>
            )}
          </Grid>
          {(isVanscoy || isCory || isRocanville || isAllan) && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Panel')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">{panelLabel}</Typography>
              </Grid>
            </>
          )}
          {isLanigan && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Room')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">
                  {productionDocument.room?.description.includes('PE')
                    ? `${productionDocument.room?.description}${productionDocument.block?.description}${productionDocument.panel?.description}`
                    : `${productionDocument.block?.description}${productionDocument.panel?.description}${productionDocument.room?.description}`}
                </Typography>
              </Grid>
            </>
          )}
          {(miningMethod === MiningMethodAllCap.CHEVRON || isCory || isAllan) && (
            <>
              {productionDocument.surveyPoint?.description && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="body2">{translate('Sequence')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="button">
                      {productionDocument.surveyPoint?.description || unknown}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Typography variant="body2">
                  {isVanscoy || isCory || isAllan ? translate('Pass') : i18n.t('Step')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">{renderTitle()}</Typography>
              </Grid>
            </>
          )}
          {miningMethod === MiningMethodAllCap.LONG_ROOM && !isCory && !isAllan && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Pass')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">{renderTitle()}</Typography>
              </Grid>
            </>
          )}
          {miningMethod === MiningMethodAllCap.LONG_ROOM &&
            !showAllanRehabFields &&
            advances.length === 1 &&
            !isCory &&
            !isAllan && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {useMeters ? i18n.t('Start meters') : translate('Start footage')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="button">
                    {productionDocument.advances[0].startDistance} {distanceUnitAbbreviation}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {useMeters ? i18n.t('End meters') : translate('End footage')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="button">
                    {productionDocument.advances[0].endDistance} {distanceUnitAbbreviation}
                  </Typography>
                </Grid>
              </>
            )}
          {(miningMethod !== MiningMethodAllCap.LONG_ROOM ||
            advances.length !== 1 ||
            isCory ||
            isAllan) &&
            !showAllanRehabFields && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    {useMeters ? i18n.t('Total meters') : translate('Total footage')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="button">
                    {`${calculatedFootage}`} {distanceUnitAbbreviation}
                  </Typography>
                </Grid>
              </>
            )}
          {showAllanRehabFields && (
            <>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Stamler')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">
                  {stamler?.shortName || stamler?.longName || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Scoop/Hauler')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">
                  {scoop?.shortName || scoop?.longName || ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('No. Buckets')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">{calculatedNumberOfBuckets}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{i18n.t('Total tonnes')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="button">{calculatedScoopTonnage()}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Card>
  );
};

export default ProductionCard;
