import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';

import { rootStore } from '../../../../mobx-models/Root';

export const DelaySchema = yup
  .object({
    delays: yup.array(
      yup.object({
        startTime: yup
          .object()
          .required()
          .test('isBefore', 'Must be before end', (startTime: Dayjs, context) => {
            const { parent } = context;
            const { endTime } = parent;
            return startTime?.isBefore(endTime);
          })
          .test(
            'isWithinShiftBoundaries',
            'Must be within shift boundaries',
            (startTime: Dayjs) => {
              return (
                dayjs.unix(rootStore.shiftPicker.shiftStartDateUnix).diff(startTime, 'seconds') <=
                  0 &&
                dayjs.unix(rootStore.shiftPicker.shiftEndDateUnix).diff(startTime, 'seconds') >= 0
              );
            },
          ),
        endTime: yup
          .object()
          .required()
          .test('isAfter', 'Must be after start', (endDateTime: Dayjs, context) => {
            const { parent } = context;
            const { startTime } = parent;
            return endDateTime?.isAfter(startTime);
          })
          .test('isWithinShiftBoundaries', 'Must be within shift boundaries', (endTime: Dayjs) => {
            if (endTime) {
              return (
                dayjs.unix(rootStore.shiftPicker.shiftStartDateUnix).diff(endTime, 'seconds') <=
                  0 &&
                dayjs.unix(rootStore.shiftPicker.shiftEndDateUnix).diff(endTime, 'seconds') >= 0
              );
            }
            return true;
          }),
        borerStateTypeId: yup.string().nullable().required('Required'),
        borerShiftAdvanceId: yup.string().optional(),
        cuttingTypeId: yup.string().nullable().optional(),
        cuttingMethodId: yup.string().nullable().optional(),
        advanceId: yup.string().optional(),
        comment: yup.string().optional(),
        id: yup.string().optional(),
        originalId: yup.string().optional(),
      }),
    ),
  })
  .required();
