import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import useBorerShift from '../../rxdb/BorerShift/useBorerShift';
import {
  BorerShiftSignature,
  BorerShiftSignatureCollection,
  BorerShiftSignatureDocument,
} from '../../rxdb/BorerShiftSignature/queryBuilder';
import { Employee } from '../../rxdb/Employees/queryBuilder';
import { useEmployees } from '../../rxdb/Employees/useEmployees';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import { generateBaseEntityWithCreatedOn } from '../../rxdb/rxdbUtilityFunctions';
import {
  Signature,
  SignatureCollection,
  SignatureDocument,
} from '../../rxdb/Signature/rxdbSignatureDefinition';
import { useConstructor, useDateFormatters } from '../../utilities';
import { SIGNATURE_WRITE_PERMISSION } from '../../utilities/constants';
import { SignatureType } from '../../utilities/enums';
import { sortAscendingByUpdatedAt } from '../../utilities/sortHelper';
import { useNotification } from '../../utilities/useNotification';
import AddSignatureModal from '../AddSignatureModal';
import AddSignaturePopover from '../AddSignaturePopover';
import GenericMessageModal from '../GenericMessageModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '16px',
    },
    subCard: {
      margin: '0 !important',
      padding: '16px',
    },
    headerRow: {
      marginBottom: '10px',
    },
    signatureRow: {
      marginTop: '7px',
      marginBottom: '7px',
      minHeight: 25,
    },
    type: {
      color: theme.palette.text.disabled,
    },
    dateTime: {
      '@media(max-width: 768px)': {
        fontSize: '13px !important',
      },
    },
    addSignatureButton: {
      '& svg': {
        stroke: '#000',
      },
    },
    upperCaseFirstLetter: {
      textTransform: 'capitalize',
    },
  }),
);

interface Props {
  shiftDateStart?: number;
}

const signatureTypesToDisplay = [
  SignatureType.MAINTENANCE.toLocaleLowerCase(),
  SignatureType.MINE_STAFF.toLocaleLowerCase(),
  SignatureType.SUPERVISOR.toLocaleLowerCase(),
  SignatureType.VISITOR.toLocaleLowerCase(),
  SignatureType.OPERATOR.toLocaleLowerCase(),
];

const signatureColumnWidths = {
  name: 2,
  time: 2,
  role: 2,
  reviewedCP: 2,
  reviewedSWP: 2,
  reasonForVisit: 2,
};

const SignaturesCard: React.FC<Props> = ({ shiftDateStart }: Props) => {
  const classes = useStyles();
  const { errorNotification, successNotification } = useNotification();
  const { formatDateWithDisappearingDate } = useDateFormatters();
  const [showGenericMessageModal, setShowGenericMessageModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
  const [signatureModalType, setSignatureModalType] = useState<SignatureType>();
  const addSignatureMenuOpen = Boolean(anchorEl);
  const { user, shiftPicker } = useMst();
  const { getCurrentBorerShift } = useBorerShift();

  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [nameNotInList, setNameNotInList] = useState<boolean>(false);
  const [selectedCustomName, setSelectedCustomName] = useState<string>('');
  const [reviewedCuttingPermits, setReviewedCuttingPermits] = useState<boolean>(false);
  const [safeWorkPlanChecked, setSafeWorkPlanChecked] = useState<boolean>(false);
  const [reasonForVisit, setReasonForVisit] = useState<string>('');

  const [borerShiftSignaturesForShift, setBorerShiftSignaturesForShift] = useState<
    BorerShiftSignatureDocument[]
  >([]);
  const [signaturesForShift, setSignaturesForShift] = useState<Map<string, SignatureDocument>>();

  // RXDB - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // collections
  const signatureCollection: SignatureCollection = useRxCollection(RxdbCollectionName.SIGNATURES);
  const borerShiftSignatureCollection: BorerShiftSignatureCollection | null = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
  );

  const { employeesList: employees } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: false,
  });

  const querySignaturesForShift = useCallback(async () => {
    const currentBorerShift = await getCurrentBorerShift();

    if (currentBorerShift === null || !borerShiftSignatureCollection) {
      return setBorerShiftSignaturesForShift([]);
    }

    const queryObject = await borerShiftSignatureCollection.find();
    queryObject.$.subscribe(async collection => {
      const borerShiftSignatures = collection.filter(
        (borerShiftSignature: BorerShiftSignatureDocument) =>
          borerShiftSignature.borerShiftId === shiftPicker.currentBorerShiftId,
      );

      const signatures = await signatureCollection.findByIds(
        borerShiftSignatures.map(bsignature => bsignature.signatureId),
      );

      // Filter out Signatures that do match the expected types
      const filteredBorerShiftSignatures = borerShiftSignatures.filter(
        (borerShiftSignature: BorerShiftSignatureDocument) => {
          const signature: SignatureDocument = signatures?.get(borerShiftSignature.signatureId);

          let validType = true;
          if (
            !signature ||
            signatureTypesToDisplay.indexOf(signature.signatureType.toLowerCase()) === -1
          )
            validType = false;

          return validType;
        },
      );

      setSignaturesForShift(signatures);
      setBorerShiftSignaturesForShift(filteredBorerShiftSignatures);
    });
  }, [
    getCurrentBorerShift,
    signatureCollection,
    borerShiftSignatureCollection,
    shiftPicker.currentBorerShiftId,
  ]);

  useConstructor(async () => {
    querySignaturesForShift();
  });

  useEffect(() => {
    if (signatureCollection) {
      querySignaturesForShift();
    }
  }, [
    signatureCollection,
    borerShiftSignatureCollection,
    shiftDateStart,
    shiftPicker.currentShiftId,
    shiftPicker.currentBorerShiftId,
  ]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openSignatureModal = (signatureType: SignatureType) => {
    setSignatureModalOpen(true);
    setSignatureModalType(signatureType);
  };

  const clearSignatureData = () => {
    setSelectedEmployee(undefined);
    setSelectedCustomName('');
    setNameNotInList(false);
    setReviewedCuttingPermits(false);
    setSafeWorkPlanChecked(false);
    setReasonForVisit('');
  };

  const onUpdateSignature = (
    employee: Employee,
    customName: string,
    employeeNotInList: boolean,
    reviewedCuttingPermitsValue: boolean,
    reviewedSafeWorkPlanValue: boolean,
    reasonForVisitValue: string,
  ) => {
    setSelectedEmployee(employee);
    setSelectedCustomName(customName);
    setNameNotInList(employeeNotInList);
    setReviewedCuttingPermits(reviewedCuttingPermitsValue);
    setSafeWorkPlanChecked(reviewedSafeWorkPlanValue);
    setReasonForVisit(reasonForVisitValue);
  };

  const getEmployeeName = (empId: string) => {
    const found = employees.find(employee => employee.id === empId);
    if (found) {
      return `${found.firstName} ${found.lastName}`;
    }
    return 'Unknown';
  };

  const onSaveSignature = async (
    employee: Employee,
    customName: string,
    reviewedCuttingPermitsValue: boolean,
    signatureData: string,
    reviewedSafeWorkPlan?: boolean | null,
    reasonForVisitValue?: string | null,
  ) => {
    const currentBorerShift = await getCurrentBorerShift();

    if (currentBorerShift === null)
      return errorNotification("Can't save signature because no borer shift found was found");

    // 1. Create Signature Document
    const signature: Signature = {
      ...generateBaseEntityWithCreatedOn(),
      signatureType: signatureModalType?.toLowerCase() || '',
      siteEmployeeId: customName || employee?.id === '-1' ? undefined : employee?.id,
      personnelName: customName,
      signature: signatureData,
    };

    // 2. Create Borer Shift Signature Document
    const borerShiftSignature: BorerShiftSignature = {
      ...generateBaseEntityWithCreatedOn(),
      borerShiftId: currentBorerShift.id,
      signatureId: signature.id,
      reviewedCuttingPermits: reviewedCuttingPermitsValue,
      reviewedSafeWorkPlan: reviewedSafeWorkPlan || null,
      purposeOfVisit: reasonForVisitValue || null,
    };

    // 3. Insert Documents
    if (signatureCollection) {
      signatureCollection.insert(signature);
    }
    if (borerShiftSignatureCollection) {
      borerShiftSignatureCollection.insert(borerShiftSignature);
    }

    successNotification(i18n.t('Signature added'));
    return {
      signatureId: signature.id,
      borerShiftSignatureId: borerShiftSignature.id,
    };
  };

  const onSignaturePopoverOpen = useCallback(
    (signatureType: SignatureType) => {
      handleClose();
      openSignatureModal(signatureType);
    },
    [handleClose],
  );

  return (
    <>
      <Card elevation={1} className={classes.card}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography variant="h3">
                {`${i18n.t('Signatures')} (${borerShiftSignaturesForShift?.length || '0'})`}
              </Typography>
            </Grid>
            <Grid item container xs={4} justify="flex-end">
              {user.hasPermissionTo(SIGNATURE_WRITE_PERMISSION) && (
                <Grid item>
                  <Button
                    className={classes.addSignatureButton}
                    color="primary"
                    size="medium"
                    variant="contained"
                    noMinHeight
                    startAdornment={<Icons.PlusFeather />}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (shiftPicker.hasValidShift()) {
                        setAnchorEl(event.currentTarget);
                      } else {
                        setShowGenericMessageModal(true);
                      }
                    }}
                    data-cy="add-signature-button"
                    id="add-signature-button"
                  >
                    {i18n.t('Add signature')}
                  </Button>
                  <AddSignaturePopover
                    open={addSignatureMenuOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    onOpen={onSignaturePopoverOpen}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={2} className={classes.subCard} data-testid="signatures-list">
              {borerShiftSignaturesForShift?.length > 0 && (
                <Grid item container xs={12} className={classes.headerRow}>
                  <Grid item xs={signatureColumnWidths.name}>
                    <Typography variant="subtitle2" className={classes.type}>
                      {i18n.t('Name')}
                    </Typography>
                  </Grid>
                  <Grid item xs={signatureColumnWidths.time}>
                    <Typography variant="subtitle2" className={classes.type}>
                      {i18n.t('Time')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={signatureColumnWidths.role}
                    className={classes.upperCaseFirstLetter}
                  >
                    <Typography variant="subtitle2" className={classes.type}>
                      {i18n.t('Role')}
                    </Typography>
                  </Grid>
                  <Grid item xs={signatureColumnWidths.reviewedCP}>
                    <Typography variant="subtitle2" className={classes.type}>
                      {i18n.t('Reviewed cutting permits')}
                    </Typography>
                  </Grid>
                  <Grid item xs={signatureColumnWidths.reviewedSWP}>
                    <Typography variant="subtitle2" className={classes.type}>
                      {i18n.t('Reviewed safe work plan')}
                    </Typography>
                  </Grid>
                  <Grid item xs={signatureColumnWidths.reasonForVisit}>
                    <Typography variant="subtitle2" className={classes.type}>
                      {i18n.t('Purpose of visit')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {borerShiftSignaturesForShift
                .sort(sortAscendingByUpdatedAt)
                .map(borerShiftSignature => {
                  const signature = signaturesForShift?.get(borerShiftSignature.signatureId);

                  return (
                    <Grid
                      item
                      container
                      xs={12}
                      key={borerShiftSignature.id}
                      className={classes.signatureRow}
                    >
                      <Grid item xs={signatureColumnWidths.name}>
                        <Typography variant="subtitle2">
                          {signature && signature.siteEmployeeId
                            ? getEmployeeName(signature.siteEmployeeId)
                            : signature?.personnelName}
                        </Typography>
                      </Grid>
                      <Grid item xs={signatureColumnWidths.time}>
                        <Typography variant="subtitle2" className={classes.dateTime}>
                          {signature && formatDateWithDisappearingDate(signature.createdOn)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={signatureColumnWidths.role}
                        className={classes.upperCaseFirstLetter}
                      >
                        <Typography variant="subtitle2" className={classes.type}>
                          {signature?.signatureType}
                        </Typography>
                      </Grid>
                      <Grid item xs={signatureColumnWidths.reviewedCP} container>
                        {borerShiftSignature.reviewedCuttingPermits && (
                          <Icons.CheckCircleIcon
                            color="primary"
                            size="small"
                            style={{ height: 18, marginTop: 2 }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={signatureColumnWidths.reviewedSWP}>
                        <Typography variant="body2" className={classes.type}>
                          {borerShiftSignature.reviewedSafeWorkPlan && (
                            <Icons.CheckCircleIcon
                              color="primary"
                              size="small"
                              style={{ height: 18, marginTop: 2 }}
                              container
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={signatureColumnWidths.reasonForVisit}>
                        <Typography variant="body2" className={classes.type}>
                          {borerShiftSignature.purposeOfVisit || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              {borerShiftSignaturesForShift.length === 0 && (
                <Typography variant="body2" className={classes.type}>
                  {i18n.t('No signatures exist for this shift.')}
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      </Card>
      {signatureModalOpen && (
        <AddSignatureModal
          open={signatureModalOpen}
          type={signatureModalType}
          onCancel={() => {
            setSignatureModalOpen(false);
            clearSignatureData();
          }}
          onSave={onSaveSignature}
          onUpdate={onUpdateSignature}
          employees={employees}
          selectedEmployee={selectedEmployee}
          selectedCustomName={selectedCustomName}
          nameNotInList={nameNotInList}
          reviewedCuttingPermits={reviewedCuttingPermits}
          safeWorkPlanChecked={safeWorkPlanChecked}
          reasonForVisit={reasonForVisit}
          validateSafeWorkPlan
        />
      )}
      {showGenericMessageModal && (
        <GenericMessageModal
          open={showGenericMessageModal}
          maxHeight={220}
          titleText="No shift data exists"
          message={`You cannot add a new signature because no current shift data has been setup for ${shiftPicker.shiftLabelText}`}
          onOk={() => {
            setShowGenericMessageModal(false);
          }}
        />
      )}
    </>
  );
};

export default observer(SignaturesCard);
