import { Grid } from '@material-ui/core';
import { i18n, Icons, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { EmployeeCollection } from '../../rxdb/Employees/queryBuilder';
import { EquipmentDeficiencyAttachmentCollection } from '../../rxdb/EquipmentDeficiencyAttachments/queryBuilder';
import useEquipmentDeficiencyAttachments from '../../rxdb/EquipmentDeficiencyAttachments/useEquipmentDeficiencyAttachments';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import { useOnlineStatus } from '../../utilities';
import { useNotification } from '../../utilities/useNotification';
import { getFormattedImages } from '../../utilities/utilityFunctions';
import AspectRatio from '../AspectRatio';
import { getFileUrl, ImageMetadataWithUrl } from '../CacheUpdateHandler/documentUtilities';
import Thumbnail from '../Thumbnail';
import { useStyles } from './DeficiencyPhotos.styles';

interface Props {
  deficiencyId: string;
  hideAddPhotoAction?: boolean;
  source?: string;
  photoPrefix: string;
}

const HazardPanelPhotos: React.FC<Props> = ({
  deficiencyId,
  hideAddPhotoAction = false,
  photoPrefix,
  source = 'page',
}: Props) => {
  const classes = useStyles();
  const { errorNotification } = useNotification();
  const online = useOnlineStatus();

  const [imageUrls, setImageUrls] = useState<ImageMetadataWithUrl[]>([]);

  const inputKeyName = `selectImageDeficiencyPhotoPanel-${deficiencyId}`;

  const employeeCollection: EmployeeCollection = useRxCollection(RxdbCollectionName.EMPLOYEES);
  const attachmentCollection: EquipmentDeficiencyAttachmentCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT,
  );

  const { createEquipmentDeficiencyAttachments } = useEquipmentDeficiencyAttachments();

  const getDeficiencyImageUrls = async () => {
    // Get image attachment names from data store
    let imagesToShow: ImageMetadataWithUrl[] = [];

    try {
      if (attachmentCollection) {
        const response = await attachmentCollection
          .find({
            selector: {
              equipmentDeficiencyId: deficiencyId,
            },
          })
          .exec();

        imagesToShow = await Promise.all(response.map(async doc => getFileUrl(doc, online)));
        setImageUrls(imagesToShow);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: HazardPanelPhotos.tsx ~ line 99 ~ getDeficiencyImageUrls ~ error',
        error,
      );
      throw error;
    }
  };

  const subscribeToDeficiencyChanges = async () => {
    if (attachmentCollection) {
      attachmentCollection.$.subscribe(() => {
        getDeficiencyImageUrls();
      });
    }
  };

  const onOpenUpload = () => {
    document.getElementById(inputKeyName)?.click();
  };

  const handleImageUploads = async (fileList: FileList) => {
    const files = [...fileList];

    const employee = await employeeCollection?.findOne().where('id').ne('').exec();

    const currentImageUrls = imageUrls;
    setImageUrls([
      ...currentImageUrls,
      ...files.map(file => {
        return {
          url: 'LOADING',
          fileName: `${deficiencyId}/${file.name}`,
          isRecentUpload: true,
        };
      }),
    ]);

    const formattedImages = getFormattedImages(photoPrefix, files, imageUrls);
    try {
      await createEquipmentDeficiencyAttachments(deficiencyId, formattedImages, employee.id);
    } catch (error) {
      console.log('🚀 ~ file: DeficiencyPhotos.tsx ~ line 157 ~ error', error);
      errorNotification(i18n.t('Unable to upload some images.'));
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) handleImageUploads(files);
  };

  // Subscribe to changes
  useEffect(() => {
    if (deficiencyId && attachmentCollection) {
      getDeficiencyImageUrls();
      subscribeToDeficiencyChanges();
    }
  }, [deficiencyId, attachmentCollection]);

  return (
    <>
      {deficiencyId && (
        <Grid
          container
          item
          xs={12}
          data-cy="HazardPanelPhotos"
          spacing={2}
          justify={imageUrls.length % 2 === 1 ? 'flex-end' : 'flex-start'}
        >
          {imageUrls.length > 0 ? (
            imageUrls.map(data => (
              <Thumbnail
                url={data.url}
                key={`deficiency-photos-thumbnail-${data.fileName}`}
                name={data.fileName.split('/')[1]}
                uniqueId={`${source}-${deficiencyId}`}
                attachmentId={data.fileName}
                isRecentUpload={data.isRecentUpload}
              />
            ))
          ) : (
            <Grid item xs={6} />
          )}
          {!hideAddPhotoAction && (
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              onClick={onOpenUpload}
              xs={6}
              id="add-photo-button"
            >
              <div className={classes.photoCardAdd}>
                <AspectRatio ratio={1 / 1}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Icons.PlusCircleFeather color="primary" />
                    <Typography className={classes.photoCardAddText} variant="body1">
                      {i18n.t('Add Photo')}
                    </Typography>
                  </div>
                </AspectRatio>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <input
              type="file"
              accept="image/jpeg"
              onChange={evt => onChange(evt)}
              multiple
              hidden
              id={inputKeyName}
              onClick={event => {
                event.target.value = null;
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HazardPanelPhotos;
