import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import React, { useEffect, useState } from 'react';

import { useMst } from '../../mobx-models/Root';
import { LocationDocument } from '../../models/models';
import useLocations from '../../rxdb/Locations/useLocations';
import { PredictionDocument } from '../../rxdb/Prediction/queryBuilder';
import { BORER_SHIFT_WRITE_PERMISSION } from '../../utilities/constants';
import useViewingCurrentShift from '../../utilities/hooks/useViewingCurrentShift';
import PredictionLocationDetailsComponent from '../PredictionLocationDetailsComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    dot2: {
      padding: '0px 8px',
      color: theme.palette.primary.main,
      fontSize: '30px',
      lineHeight: '30px',
    },
    chevron: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButton: {
      margin: '4px',
    },
    editBtnContainer: {
      alignItems: 'flex-end !important',
      '& > div': {
        minHeight: 'auto !important',
      },
      '& > div > span': {
        display: 'none',
      },
    },
    addOrEditButton: {
      maxWidth: '210px',
      margin: '0 0 0 auto !important',
    },
    locationDetailsColumn: {
      marginTop: '5px',
      '& > div': {
        justifyContent: 'flex-start',
      },
    },
    locationDetailsColumnNoMargin: {
      marginTop: '0px',
      '& > div': {
        justifyContent: 'flex-start',
      },
    },
    noPredictionText: {
      color: 'rgba(255, 255, 255, 0.38)',
      marginLeft: '-5px',
    },
  }),
);

interface Props {
  previousLocation?: LocationDocument;
  predictionDocument?: PredictionDocument;
  onEdit: (prediction: PredictionDocument) => void;
}

const PredictionCard: React.FC<Props> = ({
  previousLocation,
  predictionDocument,
  onEdit,
}: Props) => {
  const classes = useStyles();
  const { user } = useMst();
  const { locationsInitialized, locationsCollection } = useLocations();
  const [endDistance, setEndDistance] = useState<number>();
  const [endLocation, setEndLocation] = useState<LocationDocument>();
  const userViewingCurrentShift = useViewingCurrentShift();

  const [buttonText, setButtonText] = useState<string>();

  const setButtonTextBasedOffPrediction = () => {
    const text = predictionDocument ? `${i18n.t('Edit location')}` : `${i18n.t('Add prediction')}`;
    setButtonText(text);
  };

  const getLocationInfo = async () => {
    setEndLocation(undefined);
    if (predictionDocument) {
      setEndDistance(Number(predictionDocument.endDistance));
      const endLoc = await predictionDocument.populate('endLocation');
      if (endLoc) {
        setEndLocation(endLoc);
      }
    }
  };

  const updatedAt = predictionDocument?.updatedAt;
  useEffect(() => {
    if (predictionDocument && locationsInitialized && locationsCollection) {
      setButtonTextBasedOffPrediction();
      getLocationInfo();
    } else {
      setButtonTextBasedOffPrediction();
    }
  }, [updatedAt, predictionDocument, previousLocation, locationsInitialized]);

  return (
    <Card className={classes.cardRoot}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">{i18n.t("Operator's prediction")}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.editBtnContainer}>
          {userViewingCurrentShift &&
            buttonText &&
            user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => onEdit(predictionDocument)}
                className={classes.addOrEditButton}
                id="add-prediction-button"
              >
                {predictionDocument ? (
                  <Icons.EditFeather color="primary" />
                ) : (
                  <Icons.PlusFeather color="primary" />
                )}
                &nbsp;&nbsp;
                {buttonText}
              </Button>
            )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">{i18n.t('End location')}</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          className={
            endLocation && predictionDocument
              ? classes.locationDetailsColumn
              : classes.locationDetailsColumnNoMargin
          }
        >
          {endLocation && predictionDocument ? (
            <PredictionLocationDetailsComponent
              ignoreStepAndPassColors
              distance={endDistance}
              location={endLocation}
            />
          ) : (
            <Typography variant="body2" className={classes.noPredictionText}>
              <em>No prediction exists</em>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default PredictionCard;
