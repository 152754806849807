import { useCallback, useMemo } from 'react';
import { useRxData } from 'rxdb-hooks';

import RxdbCollectionName from '../rxdbCollectionName';
import { CuttingType } from './queryBuilder';

export const useCuttingType = (selectedCuttingTypeId?: string) => {
  const cuttingTypeQueryConstructor = useCallback(collection => collection.find(), []);

  const { result: cuttingTypes } = useRxData<CuttingType>(
    RxdbCollectionName.CUTTING_TYPE,
    cuttingTypeQueryConstructor,
  );

  const selectedCuttingTypeQueryConstructor = useCallback(
    collection => collection.find({ selector: { id: selectedCuttingTypeId } }),
    [selectedCuttingTypeId],
  );

  const { result: selectedCuttingType } = useRxData<CuttingType>(
    RxdbCollectionName.CUTTING_TYPE,
    selectedCuttingTypeQueryConstructor,
  );

  const normalCuttingBlock = useMemo(
    () => cuttingTypes.find(cuttingType => cuttingType.description?.toLowerCase() === 'normal'),
    [cuttingTypes],
  );

  return {
    cuttingTypes: cuttingTypes.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)),
    selectedCuttingType: selectedCuttingTypeId ? selectedCuttingType[0] : null,
    normalCuttingBlock,
  };
};

export default useCuttingType;
