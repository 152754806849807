import { CardContent, FormControl, FormControlLabel, Grid } from '@material-ui/core';
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  GroupedAutoComplete,
  i18n,
  Radio,
  RadioGroup,
  TextField,
  TimePicker2,
  Typography,
} from '@nutrien/cxp-components';
import { getDurationText } from '@nutrien/minesight-utility-module';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import type { Control, FieldErrors } from 'react-hook-form';
import { Controller, useWatch } from 'react-hook-form';
import { RxDocument } from 'rxdb';

import { CuttingMethod } from '@/rxdb/CuttingMethod/queryBuilder';
import { CuttingType } from '@/rxdb/CuttingType/queryBuilder';

import { AugmentedAdvance } from '../../../../rxdb/Advance/useAdvancesForShift';
import { TempBorerOperatorChangeState } from '../../../../rxdb/BorerOperatorChangeFeed/queryBuilder';
import { BorerStateType } from '../../../../rxdb/BorerStateTypeFeed/queryBuilder';
import { fieldArrayName } from '../DelaysEditDrawer/DelaysEditDrawer';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  state: TempBorerOperatorChangeState;
  index: number;
  numberOfStates: number;
  handleDelaySplit: (delay: TempBorerOperatorChangeState, index: number) => void;
  handleDelayRemove: (delay: TempBorerOperatorChangeState, index: number) => void;
  allStateTypes?: BorerStateType[];
  allStateTypesById?: { [key: string]: BorerStateType };
  control: Control<{ delays: TempBorerOperatorChangeState[] }, any>;
  errors?: FieldErrors<TempBorerOperatorChangeState>;
  advancesForShift: AugmentedAdvance[];
  advancesForShiftById: { [key: string]: AugmentedAdvance };
  lastSyncTime?: number;
  isOnline: boolean;
  cuttingTypes: RxDocument<CuttingType>[];
  cuttingMethods: RxDocument<CuttingMethod>[];
  onApplyCuttingTypeToFutureEvents: (panelIndex: number | null) => void;
  applyCuttingTypeToFutureEventsIndex: number | null;
}

const DelayEditCard: React.FC<Props> = ({
  state,
  index,
  numberOfStates,
  errors,
  handleDelaySplit,
  handleDelayRemove,
  allStateTypes,
  allStateTypesById,
  control,
  advancesForShift,
  advancesForShiftById,
  isOnline,
  lastSyncTime,
  cuttingTypes,
  cuttingMethods,
  onApplyCuttingTypeToFutureEvents,
  applyCuttingTypeToFutureEventsIndex,
}: Props) => {
  const startDateTimeValue: Dayjs | undefined = useWatch({
    control,
    name: `delays.${index}.startTime`,
  });

  const endDateTimeValue: Dayjs | undefined = useWatch({
    control,
    name: `delays.${index}.endTime`,
  });

  const delayEndsBeforeLastSyncTime = useMemo(() => {
    if (!lastSyncTime || !state?.endTime) return false;
    if (state?.endTime?.unix() < Math.floor(lastSyncTime / 1000)) {
      return true;
    }
  }, [state?.endTime, lastSyncTime]);

  const isRunning = useMemo(
    () => allStateTypesById?.[state?.borerStateTypeId]?.isRunning === true,
    [allStateTypesById, state?.borerStateTypeId],
  );

  const duration = useMemo(() => {
    if (endDateTimeValue && startDateTimeValue) {
      return Math.round((endDateTimeValue.unix() - startDateTimeValue.unix()) / 60);
    }
    return 0;
  }, [startDateTimeValue, endDateTimeValue]);

  const spiltDisable = useMemo(() => {
    return (isRunning && (isOnline || delayEndsBeforeLastSyncTime)) || duration <= 5;
  }, [isRunning, isOnline, delayEndsBeforeLastSyncTime, duration]);

  const currentDelay = useWatch({ control, name: `${fieldArrayName}.${index}` });

  const filteredStateTypes = useMemo(() => {
    let allowDelayToBeCodedAsRunning = true;
    if (isOnline || !currentDelay?.startTime) allowDelayToBeCodedAsRunning = false;
    if (lastSyncTime && currentDelay?.startTime?.unix()) {
      if (currentDelay.startTime.unix() < Math.floor(lastSyncTime / 1000))
        allowDelayToBeCodedAsRunning = false;
    }

    return allStateTypes?.filter(stateType => {
      if (allowDelayToBeCodedAsRunning) return true;
      if (isRunning) return true;
      return !stateType.isRunning;
    });
  }, [allStateTypes, isRunning, currentDelay?.startTime, isOnline, lastSyncTime]);

  return (
    <Card
      data-testid={`delay-edit-card-${state.originalId}`}
      style={{ margin: '0px 0px 16px 0px' }}
    >
      <CardContent style={{ paddingBottom: 16 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name={`${fieldArrayName}.${index}.startTime`}
              control={control}
              render={({ field }) => (
                <TimePicker2
                  {...field}
                  label={i18n.t('Start time')}
                  onTimeChanged={(date: Dayjs) => field.onChange(date)}
                  disabled={index === 0 || (isRunning && isOnline)} // can't edit start time of first delay
                  error={!!errors?.startTime}
                  errorText={errors?.startTime?.message || ''}
                  useBrowserTimePicker
                  showSubTextWhenDisabled
                />
              )}
            />
            <Typography>{getDurationText(startDateTimeValue, endDateTimeValue)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={`${fieldArrayName}.${index}.endTime`}
              control={control}
              render={({ field }) => (
                <TimePicker2
                  {...field}
                  label={i18n.t('End time')}
                  onTimeChanged={(date: Dayjs) => field.onChange(date)}
                  disabled={index === numberOfStates - 1 || (isRunning && isOnline)} // can't edit end time of last delay
                  error={!!errors?.endTime}
                  errorText={errors?.endTime?.message || ''}
                  useBrowserTimePicker
                  showSubTextWhenDisabled
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`${fieldArrayName}.${index}.borerStateTypeId`}
              control={control}
              render={({ field }) => (
                <GroupedAutoComplete
                  {...field}
                  label="Type"
                  data-testid={`delay-type-${state.originalId}`}
                  list={filteredStateTypes || []}
                  groupByProperty="categoryName"
                  primaryProperty="description"
                  secondaryProperty="categoryName"
                  autoSelect={false}
                  autoHighlight={false}
                  showCaret
                  getOptionLabel={item => {
                    return item?.description || allStateTypesById?.[item]?.description || '';
                  }}
                  getOptionSelected={(option, value) => option.id === value}
                  onChange={(event, item: BorerStateType) => field.onChange(item?.id)}
                  error={!!errors?.delays?.[index]?.borerStateTypeId}
                  errorText={errors?.delays?.[index]?.borerStateTypeId?.message}
                  disabled={isRunning && isOnline}
                  scrollToTopOnOpen
                />
              )}
            />
          </Grid>
          {isRunning && (
            <>
              <Grid item xs={12}>
                <Controller
                  name={`${fieldArrayName}.${index}.cuttingMethodId`}
                  control={control}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <Typography variant="body2" color="textPrimary">
                        {i18n.t('Method of cutting')}
                      </Typography>
                      <RadioGroup
                        direction="column"
                        onChange={event => {
                          field.onChange(event?.target.value);
                        }}
                        value={field.value}
                      >
                        {cuttingMethods.map(cuttingMethod => (
                          <FormControlLabel
                            key={cuttingMethod.id}
                            value={cuttingMethod.id}
                            control={<Radio css={undefined} color="primary" size="small" />}
                            label={cuttingMethod.description}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={`${fieldArrayName}.${index}.borerShiftAdvanceId`}
                  control={control}
                  render={({ field }) => (
                    <GroupedAutoComplete
                      {...field}
                      freeSolo
                      list={advancesForShift || []}
                      data-testid={`delay-advance-${state.originalId}`}
                      primaryProperty="locationString"
                      secondaryProperty="distanceString"
                      autoSelect={false}
                      autoHighlight={false}
                      label="Advance (optional)"
                      getOptionLabel={(item: string | AugmentedAdvance) => {
                        if (item?.borerShiftAdvanceId) {
                          return (
                            advancesForShiftById?.[item.borerShiftAdvanceId]?.locationString || ' '
                          );
                        }
                        return advancesForShiftById?.[item]?.locationString || '';
                      }}
                      getOptionSelected={(option, value) => value === option.borerShiftAdvanceId}
                      onChange={(event, item: any) => {
                        field.onChange(item?.borerShiftAdvanceId || '');
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={`${fieldArrayName}.${index}.cuttingTypeId`}
                  control={control}
                  render={({ field }) => (
                    <AutoComplete
                      {...field}
                      freeSolo
                      value={field.value}
                      noMinHeight
                      list={cuttingTypes || []}
                      data-testid={`delay-cutting-type-${state.originalId}`}
                      autoSelect={false}
                      autoHighlight={false}
                      label="Cutting type"
                      getOptionLabel={(option: string | CuttingType) => {
                        if ((option as CuttingType)?.description)
                          return (option as CuttingType).description;
                        return cuttingTypes.find(item => item.id === option)?.description || '';
                      }}
                      getOptionSelected={(option, value) => value === option.id}
                      onChange={(event, item: any) => {
                        field.onChange(item?.id || '');
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        if (applyCuttingTypeToFutureEventsIndex === null) {
                          onApplyCuttingTypeToFutureEvents(index);
                        } else {
                          onApplyCuttingTypeToFutureEvents(null);
                        }
                      }}
                      name="cutting-type-apply-checkbox"
                      checked={applyCuttingTypeToFutureEventsIndex === index}
                      disabled={
                        applyCuttingTypeToFutureEventsIndex !== null &&
                        applyCuttingTypeToFutureEventsIndex !== index
                      }
                      css={undefined}
                    />
                  }
                  label={i18n.t('Apply to future events')}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Controller
              name={`${fieldArrayName}.${index}.comment`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Comment (optional)"
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                    field.onChange(event.target.value)
                  }
                  data-cy="operator-state-comment"
                  rows={2}
                  multiline
                  inputProps={{
                    'data-testid': `delay-comment-${index}`,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 5 }}>
            <Button
              variant="outlined"
              color="primary"
              noMinHeight
              disabled={spiltDisable}
              onClick={() => handleDelaySplit(currentDelay, index)}
              style={
                spiltDisable ? { border: '0.5px solid rgba(255, 255, 255, 0.25) !important' } : {}
              }
              id="split-delay-button"
            >
              {i18n.t('Split delay')}
            </Button>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 5 }}>
            {index > 0 && (
              <Button
                variant="outlined"
                color="error"
                noMinHeight
                onClick={() => handleDelayRemove(currentDelay, index)}
                id="split-delay-button"
                disabled={isRunning && (isOnline || delayEndsBeforeLastSyncTime)}
              >
                {i18n.t('Remove')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default observer(DelayEditCard);
