import { createStyles, Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip, CustomPalette, i18n, MaterialPalette } from '@nutrien/cxp-components';
import { useFlags } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';

import { useMst } from '../../mobx-models/Root';
import { useOnlineStatus } from '../../utilities';
import { SHOW_REFRESH_COMPLETE } from '../../utilities/constants';

const useStyles = makeStyles(() =>
  createStyles({
    container: { maxWidth: '340px' },
    paper: {
      zIndex: '2100 !important', // above outage banner layer
      maxWidth: '300px',
      minWidth: '250px',
      padding: '8px',
      backgroundColor: CustomPalette.elevation.dp24Solid,
    },
    chip: {
      marginLeft: '8px',
      backgroundColor: `${MaterialPalette.error.main} !important`,
    },
    button: {
      width: 'fit-content !important',
    },
  }),
);

interface Props {
  id: string;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onLogout: () => void;
}

const UserMenu: React.FC<Props> = ({ id, open, anchorEl, onClose, onLogout }: Props) => {
  const classes = useStyles();
  const { appVersion, user } = useMst();
  const { borerBlockUpdate, borerRefreshButtonEnabled } = useFlags().flags;

  const isOnline = useOnlineStatus();

  const onLogoutClicked = () => {
    onClose();
    onLogout();
  };

  const onRefreshClicked = useCallback(() => {
    sessionStorage.setItem(SHOW_REFRESH_COMPLETE, 'true');
    window.location.reload();
  }, []);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <Button variant="text" color="white" noMinHeight disabled className={classes.button}>
                {user.Profile?.email}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Button
                variant="text"
                color="white"
                noMinHeight
                onClick={() => {
                  appVersion.openUpdateModal();
                  onClose();
                }}
                disabled={!appVersion.hasNewUpdate || !borerBlockUpdate}
                className={classes.button}
                id="update-button"
              >
                {`${i18n.t('Version')} ${appVersion.currentAppVersion}`}
                {appVersion.hasNewUpdate && (
                  <Chip
                    label={
                      borerBlockUpdate ? i18n.t('Update Available') : i18n.t('Update disabled')
                    }
                    size="small"
                    disabled={!borerBlockUpdate}
                    classes={{ root: classes.chip }}
                    textColor={MaterialPalette.common.white}
                  />
                )}
              </Button>
            </div>
          </Grid>
          {borerRefreshButtonEnabled && (
            <Grid item container xs={12}>
              <Button
                variant="text"
                color="white"
                noMinHeight
                onClick={onRefreshClicked}
                className={classes.button}
                id="refresh-button"
              >
                {i18n.t('Refresh page')}
              </Button>
            </Grid>
          )}
          <Grid item container xs={12}>
            <Button
              variant="text"
              color="white"
              noMinHeight
              onClick={onLogoutClicked}
              className={classes.button}
              disabled={!isOnline}
              id="logout-button"
            >
              {i18n.t('Log Out')}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default observer(UserMenu);
