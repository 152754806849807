import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface HazardLog extends BaseEntity {
  readonly groundHazard: string;
  readonly comment: string;
  readonly reportedBy: string;
  readonly createdOn?: string;
  readonly groundControlSets: string[];
  readonly updateLastViewed: boolean | null;
}
export type HazardLogDocument = RxDocument<HazardLog>;
export type HazardLogCollection = RxCollection<HazardLog> | null;

export const hazardLogSchema: RxJsonSchema<HazardLog> = {
  type: 'object',
  version: 1,
  description: 'describes a hazard log object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    groundHazard: { type: 'string' },
    comment: { type: 'string' },
    groundControlSets: {
      type: 'array',
      ref: 'ground_control_sets',
      items: {
        type: 'string',
      },
    },
    reportedBy: {
      ref: 'employees',
      type: 'string',
    },
    createdOn: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updateLastViewed: { type: ['boolean', 'null'] },
  },
};

export const hazardLogsPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = {
      ...defaultDoc,
      updatedAt: dayjs().startOf('day').subtract(90, 'days').valueOf(),
    };
  }

  const query = print(gql`{
      groundHazardLogFeed (borerEquipmentId: "${rootStore.equipment.selectedBorerId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
          id
          comment
          groundHazard
          groundControlSets
          reportedBy
          updatedAt
          isDeleted
          version
          createdOn
        }
      }`);

  return {
    query,
    variables: {},
  };
};

export const hazardLogPushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const newDoc = { ...doc, updateLastViewed: false };
  const query = print(gql`
    mutation CreateGroundHazardLog($hazardLog: CreateGroundHazardLogInput) {
      createGroundHazardLog(input: $hazardLog) {
        id
        siteId
        borerEquipmentId
        groundHazardId
      }
    }
  `);

  const variables = {
    hazardLog: newDoc,
  };

  return {
    query,
    variables,
  };
};

export const groundHazarLogdMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: HazardLogDocument) => {
    return {
      ...oldDoc,
    };
  },
};

export const createHazardLogPushModifier = (doc: any) => {
  const obj = {
    id: doc.id,
    groundHazardId: doc.groundHazard,
    groundControlSets: doc.groundControlSets,
    comment: doc.comment,
    reportedById: doc.reportedBy,
    createdOn: doc.createdOn,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};
