// Ground Control Hazard Assessment verbage
export interface GCHAVerbageObj {
  [key: string]: {
    header: string;
    subHeader: string;
    bulletPoints: string[];
    footer: string;
    signature: string;
    discard: string;
    success: string;
    errorSaving: string;
  };
}

export const GCHAVerbage: GCHAVerbageObj = {
  production: {
    header:
      'I have completed a Work Face Area Inspection (Production) as per the Ground Control Hazard Assessment Standard.',
    subHeader:
      ' This includes, but is not limited to, inspecting work area as defined in the GCHA for:',
    bulletPoints: [
      'Drummy ground',
      'Anomalous geology',
      'Ledges/brows/clays in the back',
      'Sidewall hazards',
      'Ledges & ramps in the floor, etc',
    ],
    footer: 'NOTE: Cutting Permit is required to be completed prior to any cutting',
    signature:
      'Add your signature to verify that the Work Face Area Inspection (Production) is complete. Notify other workers in the area about any new hazards identified.',
    discard: 'Work face area inspection (Production) draft discarded',
    success: 'Work face area inspection (Production) added',
    errorSaving: 'Error saving work face area inspection (Production)',
  },
  other: {
    header:
      'I have completed a Work Area Inspection (Other) as per the Ground Control Hazard Assessment Standard.',
    subHeader:
      'This includes, but is not limited to, inspecting work area as defined in the GCHA for:',
    bulletPoints: [
      'Drummy ground',
      'Anomalous geology',
      'Ledges/brows/clays in the back',
      'Sidewall hazards',
      'Ledges & ramps in the floor, etc',
    ],
    footer: 'NOTE: Panel Drawing must be updated indicating the area inspected.',
    signature:
      'Add your signature to verify that the Work Area Inspection (Other) is complete. Notify other workers in the area about any new hazards identified. I will update the Panel Drawing to indicate the area inspected.',
    discard: 'Work face area inspection (other) draft discarded',
    success: 'Work face area inspection (other) added',
    errorSaving: 'Error saving work face area inspection (other)',
  },
  general: {
    header:
      'I have completed a Work Face Area Inspection as per the Ground Control Hazard Assessment Standard.',
    subHeader:
      ' This includes, but is not limited to, inspecting work area as defined in the GCHA for:',
    bulletPoints: [
      'Drummy ground',
      'Anomalous geology',
      'Ledges/brows/clays in the back',
      'Sidewall hazards',
      'Ledges & ramps in the floor, etc',
      'Area in front of the machine inspected up to next reasonable cutting break',
      'Test holes inspected',
      'Sounding',
      'Visual inspection',
      'Acknowledgment of existing hazards (If any listed)',
    ],
    footer: 'NOTE: Cutting Permit is required to be completed prior to any cutting',
    signature:
      'Add your signature to verify that the Work Face Area Inspection is complete. Notify other workers in the area about any new hazards identified.',
    discard: 'Work face area inspection draft discarded',
    success: 'Work face area inspection added',
    errorSaving: 'Error saving work face area inspection',
  },
};

export const addNewHazardArray = [
  {
    value: 'Nothing new identified',
    forceRemediation: false,
    openNewHazard: false,
  },
  {
    value: 'New hazard present',
    forceRemediation: false,
    openNewHazard: true,
  },
  {
    value: 'New hazard - remediated',
    forceRemediation: true,
    openNewHazard: true,
  },
];

export default GCHAVerbage;
