import { Grid } from '@material-ui/core';
import { Button, Card, i18n, Icons, ToggleButton, Typography } from '@nutrien/cxp-components';
import { Dayjs } from 'dayjs';
import React from 'react';

import { LocationDocument } from '../../models/models';
import { GroundHazardDocument } from '../../rxdb/GroundHazard/queryBuilder';
import { InspectionOptionsDocument } from '../../rxdb/InspectionOptions/queryBuilder';
import { AreaCheck, AreaCheckInspectionCategory } from '../AddAreaCheck/AddAreaCheck';
import HazardPanelContent from '../HazardPanelContent';
import LocationDetailsComponent from '../LocationDetailsComponent';
import SmartToggleButtonGroup from '../SmartToggleButtonGroup/SmartToggleButtonGroup';
import useStyles from './AddAreaCheckCard.styles';

interface Props {
  inspectionCategories: AreaCheckInspectionCategory[];
  areaChecks: AreaCheck[];
  locations: LocationDocument[];
  lastUpdatedAt: Dayjs;
  isLatestCategory: (categoryId: string, displayOrder: number) => boolean;
  hasAssociatedHazards: (id: string) => boolean;
  getAssociatedHazard: (id: string) => GroundHazardDocument | undefined;
  onAddAnotherCheck: (category: AreaCheckInspectionCategory) => void;
  onCommentClicked: (id: string) => void;
  onDeleteCheck: (id: string) => void;
  getSelectedToggleValueFor: (category: AreaCheckInspectionCategory) => InspectionOptionsDocument;
  handleToggleChange: (event: any, value: string) => void;
}

const AddAreaCheckCard: React.FC<Props> = ({
  inspectionCategories,
  areaChecks,
  locations,
  isLatestCategory,
  hasAssociatedHazards,
  getAssociatedHazard,
  onAddAnotherCheck,
  onCommentClicked,
  onDeleteCheck,
  getSelectedToggleValueFor,
  handleToggleChange,
}: Props) => {
  const classes = useStyles();

  const getLocation = (areaCheckId: string): LocationDocument => {
    const gc = getAssociatedHazard(areaCheckId);
    const location = locations.find(x => x.id === gc.startLocation);
    return location;
  };

  const listHazards = (areaCheckInspectionCategory: AreaCheckInspectionCategory) => {
    const category = areaChecks.find(x => x.id === areaCheckInspectionCategory.id);

    if (!category || areaChecks.length === 0 || category?.hazards.length === 0) {
      return <></>;
    }

    const location = getLocation(areaCheckInspectionCategory.id);

    return (
      <>
        <Grid item xs={12} className={classes.commentsContainer}>
          {category?.hazards.map(h => {
            return (
              <HazardPanelContent
                groundHazard={h}
                conditionType={getSelectedToggleValueFor(areaCheckInspectionCategory)?.description}
                key={`${h.id}+${areaCheckInspectionCategory.id}`}
              />
            );
          })}
        </Grid>
        {category?.hazards.length > 0 && (
          <Grid item className={classes.locationDetails}>
            <LocationDetailsComponent location={location} />
          </Grid>
        )}
      </>
    );
  };

  return (
    <Grid container className={classes.content}>
      {inspectionCategories
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((category: AreaCheckInspectionCategory) => {
          return (
            <Grid item xs={12} key={category.id}>
              <Card className={classes.card}>
                <Grid container justify="space-between">
                  <Grid item xs={8}>
                    <Typography variant="h3">{category.category.description}</Typography>
                  </Grid>
                  <Grid item container justify="flex-end" xs={4}>
                    {isLatestCategory(category.category.id, category.displayOrder) &&
                      hasAssociatedHazards(category.id) && (
                        <Grid item className={classes.categoryButtonsContainer}>
                          <Button
                            id="AddAnotherCheckBtn"
                            noMinHeight
                            startAdornment={<Icons.CopyFeather color="primary" />}
                            onClick={() => {
                              onAddAnotherCheck(category);
                            }}
                            variant="text"
                            color="primary"
                          >
                            {i18n.t('Add another check')}
                          </Button>
                        </Grid>
                      )}
                    {hasAssociatedHazards(category.id) && (
                      <>
                        <Grid item>
                          <Button
                            id="AddCommentBtn"
                            noMinHeight
                            color="primary"
                            startAdornment={<Icons.PlusFeather color="primary" />}
                            onClick={() => {
                              onCommentClicked(category.id);
                            }}
                            style={{ maxWidth: '167px' }}
                          >
                            {i18n.t('Add comment')}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {!hasAssociatedHazards(category.id) && !category.isOriginal && (
                      <Grid item>
                        <Button
                          id="deleteCategoryButton"
                          noMinHeight
                          startAdornment={<Icons.Trash2Feather color="error" />}
                          onClick={() => {
                            onDeleteCheck(category.id);
                          }}
                          variant="text"
                          color="primary"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} className={classes.buttonGroupContainer}>
                    <SmartToggleButtonGroup
                      id={`${category.id}`}
                      value={getSelectedToggleValueFor(category).id}
                      onToggleChange={handleToggleChange}
                      onDropdownChange={(event: any, value: any) => {
                        handleToggleChange(event, value.option.id);
                      }}
                      exclusive
                      viewOnly={false}
                      dropdownClassName={classes.dropdown}
                      list={category.options.sort(
                        (a, b) => a.option.displayOrder - b.option.displayOrder,
                      )}
                      dropdownGetValue={(val: any) => {
                        const option = category.options.find(x => x.option.id === val);
                        return option;
                      }}
                      dropDownOptionLabel={(item: any) => {
                        if (item === '' || !item.option) {
                          return '';
                        }
                        return item.option.description;
                      }}
                    >
                      {category.options
                        .sort((a, b) => a.option.displayOrder - b.option.displayOrder)
                        .map(val => {
                          const { option } = val;

                          return (
                            <ToggleButton
                              key={`${category.id}+${option.id}`}
                              value={option.id}
                              aria-label={`${category.id}+${option.id}-toggle`}
                              disabled={hasAssociatedHazards(category.id)}
                              showColorOnDisabled
                              disableRipple
                              color={
                                val.hazardCondition ||
                                option?.description?.toUpperCase() === 'DEFICIENCY'
                                  ? 'error'
                                  : 'success'
                              }
                              onTouchEnd={evt => handleToggleChange(evt, option.id)}
                            >
                              <Typography variant="button">{option.description}</Typography>
                            </ToggleButton>
                          );
                        })}
                    </SmartToggleButtonGroup>
                  </Grid>
                  {listHazards(category)}
                </Grid>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default AddAreaCheckCard;
