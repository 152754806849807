import { ApolloQueryResult } from '@apollo/client';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { client } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import MigrationHelper from '../MigrationHelper';
import RxdbCollectionName from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface BorerStateType extends BaseEntity {
  readonly description: string;
  readonly isRunning: boolean;
  readonly isDefault: boolean;
  readonly isActive: boolean;
  readonly borerStateTypeCategoryId: string | null;
  readonly timeUsageTypeId: string | null;
  readonly isPlanned: boolean | null;
}
export type BorerStateTypeCollection = RxCollection<BorerStateType> | null;
export type BorerStateTypeDocument = RxDocument<BorerStateType>;

export const borerStateTypeSchema: RxJsonSchema<BorerStateType> = {
  type: 'object',
  version: 7,
  description: 'describes a BorerStateType object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    description: { type: 'string' },
    isRunning: { type: 'boolean' },
    isDefault: { type: ['boolean', 'null'] },
    isActive: { type: ['boolean', 'null'] },
    borerStateTypeCategoryId: {
      type: ['string', 'null'],
      ref: RxdbCollectionName.BORER_STATE_TYPE_CATEGORY,
    },
    timeUsageTypeId: {
      type: ['string', 'null'],
    },
    isPlanned: { type: ['boolean', 'null'] },
  },
};

export const borerStateTypeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerStateTypeDocument) => ({
    ...oldDoc,
    rtDuetReasonCodeId: '',
  }),
  2: (oldDoc: BorerStateTypeDocument) => ({
    ...oldDoc,
    isDefault: oldDoc.isDefault || null,
  }),
  3: (oldDoc: BorerStateTypeDocument) => {
    const newDoc = {
      ...oldDoc,
    };
    delete newDoc.rtDuetReasonCodeId;
    return newDoc;
  },
  4: (oldDoc: BorerStateTypeDocument) => oldDoc,
  5: (oldDoc: BorerStateTypeDocument) => oldDoc,
  6: (oldDoc: BorerStateTypeDocument) => ({ ...oldDoc, isDefault: oldDoc.isDefaultNonRunning }),
  7: (oldDoc: BorerStateTypeDocument) => {
    return MigrationHelper.instance.timeUsageTypeIdForStateTypeId(oldDoc.id).then(response => {
      const { timeUsageTypeId, isPlanned } = response;
      return { ...oldDoc, timeUsageTypeId, isPlanned };
    });
  },
};

export const borerStateTypePullQueryBuilder = (doc: BorerStateType) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query borerStateTypeFeed($limit: Int!, $lastUpdateAt: Float!, $siteId: ID!) {
      borerStateTypeFeed(siteId: $siteId, lastUpdateAt: $lastUpdateAt, limit: $limit) {
        id
        updatedAt
        version
        isDeleted
        description
        isRunning
        isDefault
        borerStateTypeCategoryId
        isActive
        timeUsageTypeId
        isPlanned
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      siteId: rootStore.user.siteId,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};

// Below is all related to fetching data between schema v6 and v7
new MigrationHelper();

const listBorerStateTypesQuery = gql`
  query listBorerStateTypes($siteId: ID!) {
    listBorerStateTypes(siteId: $siteId, includeInactive: true) {
      id
      description
      isActive
      isRunning
      borerStateTypeCategory {
        id
        description
      }
      timeUsageType {
        description
        id
      }
      isPlanned
    }
  }
`;

interface ListBorerStateTypesResponse {
  listBorerStateTypes: BorerStateType[];
}

export const listBorerStateTypes = (
  siteId: string,
): Promise<ApolloQueryResult<ListBorerStateTypesResponse>> =>
  client.query({
    query: listBorerStateTypesQuery,
    variables: { siteId },
    fetchPolicy: 'cache-first',
  });
