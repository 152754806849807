import * as Sentry from '@sentry/react';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { getSite } from '../../graphql/Site';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { defaultDoc } from '../Shared/defaultDoc';

export interface Site extends BaseEntity {
  readonly name: string;
  readonly distanceUnit: null | string;
  readonly distanceUnitAbbreviation: null | string;
}

export const siteSchema: RxJsonSchema<Site> = {
  type: 'object',
  version: 2,
  description: 'describes a site object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    name: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    distanceUnit: { type: ['string', 'null'] },
    distanceUnitAbbreviation: { type: ['string', 'null'] },
  },
};

export const fetchSiteUnitsAndPutInSessionStorage = async (siteId: string) => {
  // This ensures the useSiteFeatures hook works as expected
  if (sessionStorage.getItem('siteUnits') === null) {
    const siteDetails = await getSite(siteId);

    sessionStorage.setItem(
      'siteUnits',
      JSON.stringify({
        distanceUnit: siteDetails?.data?.getSite?.distanceUnit,
        distanceUnitAbbreviation: siteDetails?.data?.getSite?.distanceUnitAbbreviation,
      }),
    );
  }
};

export type SiteCollection = RxCollection<Site> | null;
export type SiteDocument = RxDocument<Site>;

export const siteMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SiteDocument) => {
    const { id } = oldDoc;

    // For each document we call the get and migrate with the new distanceUnit and distanceUnitAbbreviation
    return getSite(id).then(response => {
      const { distanceUnit, distanceUnitAbbreviation } = response.data.getSite;
      return { ...oldDoc, distanceUnit, distanceUnitAbbreviation };
    });
  },
  2: async (oldDoc: SiteDocument) => {
    const siteId = rootStore.user.siteId;

    if (siteId === oldDoc.id) {
      console.log('running migration FOR SITE ID:', siteId);
      try {
        await fetchSiteUnitsAndPutInSessionStorage(siteId);
      } catch (error) {
        console.error('🚀 ~ file: queryBuilder.ts:72 ~ error', error);
        Sentry.captureException(error);
      }
    }

    return oldDoc;
  },
};

export const sitePullQueryBuilder = (doc: SiteDocument) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query siteFeed($lastUpdateAt: Float!, $limit: Int!) {
      siteFeed(lastUpdateAt: $lastUpdateAt, limit: $limit) {
        id
        isDeleted
        name
        updatedAt
        version
        distanceUnit
        distanceUnitAbbreviation
      }
    }
  `);

  return {
    query,
    variables: {
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
