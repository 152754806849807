import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, i18n, Icons, MaterialPalette, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, FC, SetStateAction } from 'react';

import { useMst } from '../../mobx-models/Root';
import { ExtendedBorerShiftCrewDocument } from '../../rxdb/BorerShiftCrew/queryBuilder';
import useShifts from '../../rxdb/Shifts/useShifts';
import { useDateFormatters } from '../../utilities';
import { SHIFT_WRITE_PERMISSION } from '../../utilities/constants';
import { TIME_FORMAT } from '../../utilities/useDateFormatters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    type: {
      color: theme.palette.common.white,
    },
    button: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
    },
    iconSpacer: {
      marginRight: 8,
    },
    spacer: {
      marginLeft: 15,
    },
    flexItem: {
      height: 'auto',
      display: 'flex',
      flexGrow: 1,
    },
    crewCardContent: { marginTop: '10px' },
    crewTime: { marginRight: '12px', marginBottom: '10px' },
    operatorLabel: { width: '104px', marginRight: '12px' },
    operatorValue: {
      [theme.breakpoints.down('lg')]: {
        maxWidth: '222px',
      },
    },
  }),
);

interface Props {
  index: number;
  assignCrewToEdit: (crewIndex: number | undefined, crewOrder: number) => void;
  crew: ExtendedBorerShiftCrewDocument;
  setModifyCrewSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  hideAddCrewButton: boolean;
}

const CrewCard: FC<Props> = ({
  index,
  assignCrewToEdit,
  crew,
  setModifyCrewSidePanelOpen,
  hideAddCrewButton,
}: Props) => {
  const classes = useStyles();
  const { formatDateInTZ } = useDateFormatters();
  const { user } = useMst();

  const { currentShiftSelected } = useShifts();

  const onEditCrew = () => {
    assignCrewToEdit(index, index + 1);
    setModifyCrewSidePanelOpen(true);
  };

  const onAddCrew = () => {
    assignCrewToEdit(undefined, 2);
    setModifyCrewSidePanelOpen(true);
  };

  return (
    <Grid item className={classes.flexItem} xs>
      <Card elevation={2} className={classes.subCard} divClass={classes.flexItem}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.type}>
              {i18n.t('Crew ')}
              {crew.version !== 0 ? index + 1 : ''}
            </Typography>
          </Grid>
          <Grid item xs={8} container alignItems="flex-end">
            {user.hasPermissionTo(SHIFT_WRITE_PERMISSION) && currentShiftSelected && (
              <>
                <Grid container direction="row" justify="flex-end">
                  {/* Only show edit button if crew is assigned */}
                  {((crew.version !== 0 && index === 0) || index !== 0) && (
                    <Grid item>
                      <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        noMinHeight
                        startAdornment={<Icons.EditFeather className={classes.iconSpacer} />}
                        onClick={onEditCrew}
                        data-cy="edit-crew-button"
                        id="edit-crew-button"
                      >
                        {i18n.t('Edit crew')}
                      </Button>
                    </Grid>
                  )}
                  {!hideAddCrewButton && index === 0 && (
                    <Grid item className={classes.spacer}>
                      <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        noMinHeight
                        startAdornment={<Icons.PlusFeather className={classes.iconSpacer} />}
                        onClick={crew.version === 0 ? onEditCrew : onAddCrew}
                        data-cy="add-another-crew-button"
                        data-testid="add-another-crew-button"
                        id="add-another-crew-button"
                      >
                        {crew.version === 0 ? i18n.t('Add crew') : i18n.t('Add another crew')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {crew.version !== 0 && (
          <Grid container className={classes.crewCardContent}>
            <Grid item className={classes.crewTime}>
              <Typography variant="caption">
                {`${formatDateInTZ(crew?.start, TIME_FORMAT, false)} -
                ${formatDateInTZ(crew?.end, TIME_FORMAT, false)}`}
              </Typography>
            </Grid>
            <Grid item>
              {crew?.borerShiftCrewMemberInput?.map((employee, empIndex) => (
                <Grid container key={employee?.reactKey} alignItems="center">
                  <Grid item className={classes.operatorLabel}>
                    <Typography variant="subtitle2">
                      {empIndex === 0
                        ? i18n.t('Borer Operator')
                        : i18n.t('Operator {{empIndex}}', { empIndex: empIndex + 1 })}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.operatorValue}>
                    <Typography variant="body1">
                      {`${employee?.firstName} ${employee?.lastName}`}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              {!crew?.borerShiftCrewMemberInput?.length && (
                <Typography variant="body1">{i18n.t('No employees assigned')}</Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Card>
    </Grid>
  );
};

export default observer(CrewCard);
