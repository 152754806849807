import { i18n } from '@nutrien/cxp-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { Employee } from '../../rxdb/Employees/queryBuilder';
import useEmployees from '../../rxdb/Employees/useEmployees';
import useEquipmentDeficiency from '../../rxdb/EquipmentDeficiency/useEquipmentDeficiency';
import { EquipmentDeficiencyAttachmentCollection } from '../../rxdb/EquipmentDeficiencyAttachments/queryBuilder';
import useEquipmentDeficiencyAttachments from '../../rxdb/EquipmentDeficiencyAttachments/useEquipmentDeficiencyAttachments';
import useEquipmentDeficiencyLog from '../../rxdb/EquipmentDeficiencyLog/useEquipmentDeficiencyLog';
import { RxdbCollectionName } from '../../rxdb/rxdbCollectionName';
import { useNotification } from '../../utilities';
import { DeficiencyPanelTypes, DeficiencyStatus } from '../../utilities/enums';
import { getFormattedImages } from '../../utilities/utilityFunctions';
import DeficiencyAddPhotosPanel from '../DeficiencyAddPhotosPanel/DeficiencyAddPhotosPanel';
import DeficiencyDetailsExpansionPanel from '../DeficiencyDetailsExpansionPanel';
import DeficiencyStatusExpansionPanel from '../DeficiencyStatusExpansionPanel';
import GenericSidePanel from '../GenericSidePanel';
import { ImageMetadata } from '../Thumbnail/Thumbnail';

interface Props {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onCancel?: () => void;
  deficiencyId: string;
  attachmentNamePrefix: string;
}

const DeficiencyAddCommentSidePanel: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  onCancel,
  deficiencyId,
  attachmentNamePrefix,
}: Props) => {
  const { employeeListLoading, employeesList } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: false,
  });
  const { createEquipmentDeficiencyLog, deficiencyLogInitialized } = useEquipmentDeficiencyLog();
  const { deficiencyCollectionsInitialized, updateEquipmentDeficiency, getEquipmentDeficiency } =
    useEquipmentDeficiency();
  const { deficiencyAttachmentsInitialized, createEquipmentDeficiencyAttachments } =
    useEquipmentDeficiencyAttachments();
  const attachmentCollection: EquipmentDeficiencyAttachmentCollection = useRxCollection(
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT,
  );

  const { successNotification, errorNotification } = useNotification();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [existingImageNames, setExistingImageNames] = useState<ImageMetadata[]>([]);
  const [undo, setUndo] = useState(false);
  const [expandedPanelId, setExpandedPanel] = useState<DeficiencyPanelTypes | undefined>(
    DeficiencyPanelTypes.DETAILS,
  );

  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  const preFillDeficiencyDetails = async () => {
    // get deficiency
    let doc;
    try {
      doc = await getEquipmentDeficiency(deficiencyId);
    } catch (error) {
      errorNotification('Could not locate deficiency details.');
      onClose();
      return;
    }

    // Set state locally
    if (!doc) return;

    if (doc.isWorkOrderRequired) {
      setWorkOrderRequired(doc.isWorkOrderRequired);
      setStatus(DeficiencyStatus.WOPendingReview);
    }
  };

  const getExistingAttachments = async () => {
    // Get image attachment names from data store
    let filenames: ImageMetadata[] = [];

    try {
      if (attachmentCollection) {
        const response = await attachmentCollection
          .find({
            selector: {
              equipmentDeficiencyId: deficiencyId,
            },
          })
          .exec();

        filenames = response.map(filemeta => ({
          fileName: filemeta.fileName,
        }));
        setExistingImageNames(filenames);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: DeficiencyAddCommentSidePanel.tsx ~ line 93 ~ getExistingAttachments  ~ error',
        error,
      );
      throw error;
    }
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setHasEdits(false);
      setCanSave(false);
      setIsSaving(false);
      setExpandedPanel(DeficiencyPanelTypes.DETAILS);
      setSelectedEmployee(undefined);
      setComment('');
      setStatus(DeficiencyStatus.ActiveDeficiency);
      setWorkOrderRequired(false);
      setSelectedImages([]);
      preFillDeficiencyDetails();
      getExistingAttachments();
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open]);

  // Expansion Panel Controls
  const onExpanded = (expanded: boolean, expansionPanelId: DeficiencyPanelTypes) => {
    if (expanded) setExpandedPanel(expansionPanelId);
    else setExpandedPanel(undefined);
  };

  // Details Panel
  // Employees
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();

  const onSelectEmployee = (employee: Employee) => {
    setSelectedEmployee(employee);
    setHasEdits(true);
  };

  // Comment
  const [comment, setComment] = useState<string>('');

  const onCommentChanged = (value: string) => {
    setComment(value);
    setHasEdits(true);
  };

  // Work Order Checkbox
  const [workOrderRequired, setWorkOrderRequired] = useState<boolean>(false);

  const onWorkOrderRequired = (checked: boolean) => {
    setWorkOrderRequired(checked);
  };

  // Status
  const [status, setStatus] = useState<DeficiencyStatus>(DeficiencyStatus.ActiveDeficiency);

  const onStatusSelected = (newStatus: DeficiencyStatus) => {
    setStatus(newStatus);
  };

  useEffect(() => {
    if (workOrderRequired) setStatus(DeficiencyStatus.WOPendingReview);
    else {
      setStatus(DeficiencyStatus.ActiveDeficiency);
    }
  }, [workOrderRequired]);

  // Photos
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const onFilesSelected = (images: File[]) => {
    setSelectedImages(images);
    setHasEdits(true);
  };

  // Input Validation
  const validateSave = useCallback(() => {
    let validSave = true;

    if (!deficiencyCollectionsInitialized) validSave = false;
    if (!deficiencyLogInitialized) validSave = false;
    if (!deficiencyAttachmentsInitialized) validSave = false;

    // Form Logic
    if (!selectedEmployee) validSave = false;

    setCanSave(validSave);
  }, [
    deficiencyCollectionsInitialized,
    deficiencyLogInitialized,
    deficiencyAttachmentsInitialized,
    selectedEmployee,
    comment,
  ]);

  useEffect(() => {
    validateSave();
  }, [validateSave]);

  // Save Comment or Photos
  const onSave = async () => {
    setIsSaving(true);

    if (!deficiencyId || !selectedEmployee) return;

    // Update deficiency status if required
    if (status === DeficiencyStatus.FullyAddressed || workOrderRequired) {
      try {
        await updateEquipmentDeficiency(deficiencyId, status, workOrderRequired);
      } catch (error) {
        errorNotification('Equipment Deficiency comment could not be created.');
      }
    }

    // Create Log
    if (comment !== '')
      try {
        await createEquipmentDeficiencyLog(comment, selectedEmployee.id, deficiencyId);
      } catch (error) {
        errorNotification('Equipment Deficiency comment could not be created.');
      }

    // Create Attachments
    const formattedImages = getFormattedImages(
      attachmentNamePrefix,
      selectedImages,
      existingImageNames,
    );

    if (selectedImages.length > 0)
      try {
        await createEquipmentDeficiencyAttachments(
          deficiencyId,
          formattedImages,
          selectedEmployee.id,
        );
      } catch (error) {
        errorNotification('Equipment deficiency comment could not be saved. Please try again.');
        return;
      }

    successNotification('Comment added');
    onClose();
  };
  if (!open) return null;
  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={i18n.t('Update deficiency')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Comment draft discarded"
      >
        <DeficiencyDetailsExpansionPanel
          expanded={expandedPanelId === DeficiencyPanelTypes.DETAILS}
          onExpanded={onExpanded}
          employees={employeesList}
          employeesLoading={employeeListLoading}
          onEmployeeSelected={onSelectEmployee}
          selectedEmployee={selectedEmployee}
          onCommentChanged={onCommentChanged}
          comment={comment}
          onWorkOrderRequired={onWorkOrderRequired}
          workOrderRequired={workOrderRequired}
          editingExistingDeficiency
          disableAllInputs={isSaving}
        />
        <DeficiencyAddPhotosPanel
          onExpanded={onExpanded}
          expanded={expandedPanelId === DeficiencyPanelTypes.PHOTOS}
          selectedImages={selectedImages}
          onFilesSelected={onFilesSelected}
          disableAllInputs={isSaving}
        />
        <DeficiencyStatusExpansionPanel
          expanded={expandedPanelId === DeficiencyPanelTypes.STATUS}
          onExpanded={onExpanded}
          selectedStatus={status}
          onStatusSelected={onStatusSelected}
          workOrderRequired={workOrderRequired}
          disableAllInputs={isSaving}
        />
      </GenericSidePanel>
    </>
  );
};

export default DeficiencyAddCommentSidePanel;
