import './index.css';
import './wdyr'; // <--- first import

import { configure } from '@nutrien/cxp-api-fetch-module';
import { withFeatureFlagProvider } from '@nutrien/minesight-utility-module';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import awsconfig from './aws-exports';
import App from './components/App';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { MSTProvider, rootStore } from './mobx-models/Root';
import { RxDBHandler } from './rxdb/useRxDB';
import { isJestOrStorybook } from './test-helpers/isJestOrStorybook';
import { getDefaultFlagValues } from './utilities/getDefaultFlagValues';
import { beforeSend } from './utilities/sentryFunctions';
import { OnlineStatusProvider } from './utilities/useOnlineStatus';

const { APP_VERSION } = process.env;
const { REACT_APP_ENV } = process.env;
const { SENTRY_SAMPLE_RATE } = process.env;
const { SENTRY_DSN } = process.env;
const { CONFIGCAT_SDK_KEY } = process.env;
const { SENTRY_REPLAY_ERROR_SAMPLE_RATE } = process.env;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new OfflineIntegration({
      // limit how many events will be localled saved. Defaults to 30.
      maxStoredEvents: 50,
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: parseFloat(SENTRY_REPLAY_ERROR_SAMPLE_RATE || '0'),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE || '0'),
  environment: REACT_APP_ENV,
  ignoreErrors: [
    'UnhandledRejection: Non-Error promise rejection captured with value: No current user',
  ],
  sampleRate: parseFloat(SENTRY_SAMPLE_RATE || '0'),
  autoSessionTracking: true,
  release: `minesight-borer@${APP_VERSION}`,
  // Prevents messages from being truncated
  maxValueLength: 600,
  beforeSend,
});

Sentry.setContext('version', { version: APP_VERSION || 'Unknown' });

if (!isJestOrStorybook()) {
  Amplify.configure(awsconfig);
  configure({
    awsRegion: awsconfig.aws_cognito_region,
    cognitoRegion: awsconfig.aws_cognito_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
  });
}

if (localStorage.getItem('DEBUG_AMPLIFY') !== null) Amplify.Logger.LOG_LEVEL = 'DEBUG';

(async () => {
  const FeatureFlagProvider = withFeatureFlagProvider({
    defaultFlags: getDefaultFlagValues(),
    configCatSDKKey: CONFIGCAT_SDK_KEY || '',
    debug: localStorage.getItem('DEBUG') === 'true',
  });

  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <FeatureFlagProvider>
        <MSTProvider value={rootStore}>
          <OnlineStatusProvider>
            <RxDBHandler>
              <App />
            </RxDBHandler>
          </OnlineStatusProvider>
        </MSTProvider>
      </FeatureFlagProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  );
})();
