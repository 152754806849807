import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface GroundControlType extends BaseEntity {
  readonly options: string;
  readonly description: string;
  readonly displayOrder: number;
  readonly requiresQuantity: boolean;
}
export type GroundControlTypeCollection = RxCollection<GroundControlType> | null;
export type GroundControlTypeDocument = RxDocument<GroundControlType>;

export const groundControlTypesSchema: RxJsonSchema<GroundControlType> = {
  type: 'object',
  version: 0,
  description: 'describes a Ground Control Type object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    displayOrder: { type: 'number' },
    options: { type: 'string' },
    requiresQuantity: { type: 'boolean' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const groundControlTypesPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`{
      groundControlTypeFeed (siteId: "${rootStore.user.siteId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
        id
        description
        displayOrder
        options
        requiresQuantity
        updatedAt
        isDeleted
        version
      }
    }`);
  return {
    query,
    variables: {},
  };
};

export default {};
