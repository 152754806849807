import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_HIGH } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface GroundControlSet extends BaseEntity {
  readonly type: string;
  readonly quantity: number;
  readonly optionValues: string[];
  readonly groundHazardId: string;
}

export type GroundControlSetDocument = RxDocument<GroundControlSet>;
export type GroundControlSetCollection = RxCollection<GroundControlSet> | null;

export const groundControlSetSchema: RxJsonSchema<GroundControlSet> = {
  type: 'object',
  version: 0,
  description: 'describes a Ground Control Set object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    type: { type: 'string' },
    quantity: { type: ['number', 'null'] },
    optionValues: { type: 'array' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    groundHazardId: { type: 'string' },
  },
};

export const groundControlSetsPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }
  const query = print(gql`{
    groundControlSetFeed (siteId: "${rootStore.user.siteId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_HIGH}){
        id
        isDeleted
        optionValues
        quantity
        type
        updatedAt
        version
      }
  }`);
  return {
    query,
    variables: {},
  };
};

export const groundControlSetsPullModifier = (doc: any) => {
  return {
    ...doc,
    optionValues: JSON.parse(JSON.parse(doc.optionValues)),
  };
};

export const groundControlSetPushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const query = print(gql`
    mutation CreateGroundControlSet($groundControlSet: CreateGroundControlSetInput) {
      createGroundControlSet(input: $groundControlSet)
    }
  `);

  const variables = {
    groundControlSet: doc,
  };
  return {
    query,
    variables,
  };
};

export const groundControlSetPushModifier = (doc: GroundControlSet) => {
  const optionValues = JSON.stringify(doc.optionValues);

  const result = {
    id: doc.id,
    type: doc.type,
    quantity: doc.quantity,
    optionValues,
    createdOn: dayjs(doc.updatedAt).toISOString(),
    groundHazardId: doc.groundHazardId,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    result[property] = undefined;
  });

  return result;
};
