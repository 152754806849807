import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { defaultDoc } from '../Shared/defaultDoc';

export interface BorerShiftSignature extends BaseEntity {
  readonly borerShiftId: string;
  readonly signatureId: string;
  readonly reviewedCuttingPermits: boolean | null;
  readonly createdOn: string;
  readonly purposeOfVisit: string | null;
  readonly reviewedSafeWorkPlan: boolean | null;
}
export type BorerShiftSignatureDocument = RxDocument<BorerShiftSignature>;
export type BorerShiftSignatureCollection = RxCollection<BorerShiftSignature> | null;

export const borerShiftSignatureSchema: RxJsonSchema<BorerShiftSignature> = {
  type: 'object',
  version: 1,
  description: 'describes a BorerShiftSignature object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    signatureId: { ref: RxdbCollectionName.SIGNATURES, type: 'string' },
    reviewedCuttingPermits: { type: ['boolean', 'null'] },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
    createdOn: { type: 'string' },
    purposeOfVisit: { type: ['string', 'null'] },
    reviewedSafeWorkPlan: { type: ['boolean', 'null'] },
  },
};

export const borerShiftSignatureMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftSignatureDocument) => {
    const newDoc = { ...oldDoc, purposeOfVisit: null, reviewedSafeWorkPlan: null };
    return newDoc;
  },
};

export const borerShiftSignaturePullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }
  const query = print(gql`
    query borerShiftSignatureFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftSignatureFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        isDeleted
        updatedAt
        version
        borerShiftId
        signatureId
        reviewedCuttingPermits
        createdOn
        purposeOfVisit
        reviewedSafeWorkPlan
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const borerShiftSignaturePushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const query = print(gql`
    mutation createBorerShiftSignature($signature: CreateBorerShiftSignatureInput) {
      createBorerShiftSignature(input: $signature)
    }
  `);

  const variables = {
    signature: doc,
  };
  return {
    query,
    variables,
  };
};

export const borerShiftSignaturePushModifier = (doc: BorerShiftSignature) => {
  const obj = {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    createdOn: doc.createdOn,
    reviewedCuttingPermits: doc.reviewedCuttingPermits,
    signatureId: doc.signatureId,
    purposeOfVisit: doc.purposeOfVisit,
    reviewedSafeWorkPlan: doc.reviewedSafeWorkPlan,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};
