import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, darkTheme, i18n, Icons, TextField, Typography } from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { Advance } from '../../rxdb/Advance/queryBuilder';
import useSite from '../../rxdb/Site/useSite';
import { numberWithSuffix } from '../../utilities/useNumberFormatter';
import PanelBreak from '../PanelBreak';
import useValidation from './useValidation';

export enum ProductionPanelOperation {
  ResetAdvanceInformation,
  AddAdvance,
  DeleteAdvance,
}

const useStyles = makeStyles(() =>
  createStyles({
    noSpacer: {
      padding: '0px 5px',
    },
    textFieldSpacer: {
      padding: '0px 5px',
    },
    alignLeft: {
      alignSelf: 'flex-start',
      justifySelf: 'flex-start',
    },
    helperText: {
      marginLeft: '0 px !important',
    },
    deleteAdvanceButton: {
      marginTop: '10px',
      marginRight: '10px',
      alignSelf: 'center',
    },
    footageText: {
      color: darkTheme.palette.common.white,
      opacity: 0.5,
      fontWeight: 600,
    },
  }),
);

export interface AdvanceFootageError {
  startFootage: string;
  endFootage: string;
  footage: string;
  numberOfBuckets: string;
}

export interface AdvanceErrorObj {
  [key: string]: AdvanceFootageError;
}
interface Props {
  advance: Advance;
  setAdvanceFootageErrors: Dispatch<SetStateAction<AdvanceErrorObj>>;
  advanceFootageErrors: AdvanceErrorObj;
  advancesToEdit: Advance[];
  index: number;
  setDeleteAdvanceModalOpen: Dispatch<SetStateAction<boolean>>;
  isChevron: boolean;
  setAdvancesToEdit: Dispatch<SetStateAction<Advance[]>>;
  setAdvanceToDelete: Dispatch<SetStateAction<Advance | undefined>>;
  isEvenPass: boolean;
  showAllanRehabFields: boolean;
}

const AdvanceSubPanel: React.FC<Props> = ({
  advance,
  advanceFootageErrors,
  setAdvanceFootageErrors,
  index,
  advancesToEdit,
  setDeleteAdvanceModalOpen,
  isChevron,
  setAdvancesToEdit,
  setAdvanceToDelete,
  isEvenPass,
  showAllanRehabFields,
}: Props) => {
  const classes = useStyles();
  const { isLanigan, isCory, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();
  const [startTouched, setStartTouched] = useState(false);
  const [endTouched, setEndTouched] = useState(false);
  const [footageTouched, setFootageTouched] = useState(false);

  const errors = useValidation(
    advance,
    startTouched,
    endTouched,
    isEvenPass,
    isChevron || isCory || isAllan,
  );

  useEffect(() => {
    setAdvanceFootageErrors(prev => {
      const clone = { ...prev };
      clone[advance.id] = {
        startFootage: errors.startFootage,
        endFootage: errors.endFootage,
        footage: errors.footage,
        numberOfBuckets: errors.numberOfBuckets,
      };

      return clone;
    });
  }, [errors.startFootage, errors.endFootage, errors.footage, errors.numberOfBuckets, advance.id]);

  const onAdvanceFootageChanged = (value: string) => {
    const newAdvances = [...advancesToEdit];
    setFootageTouched(true);

    const intVal = Number(value);
    if (value === '') newAdvances[index].distance = '';
    else newAdvances[index].distance = intVal;
    setAdvancesToEdit(newAdvances);
  };

  const onStartFootageChanged = (value: string) => {
    setStartTouched(true);
    setAdvancesToEdit(prev => {
      const clone = [...prev];

      const intVal = Number(value);
      if (value === '') clone[index].startDistance = '';
      else clone[index].startDistance = intVal;

      if (clone[index].startDistance && clone[index].endDistance) {
        clone[index].distance = Math.abs(
          Number(clone[index].startDistance || 0) - Number(clone[index].endDistance || 0),
        );
      }
      return clone;
    });
  };

  const onEndFootageChanged = (value: string) => {
    setEndTouched(true);
    setAdvancesToEdit(prev => {
      const clone = [...prev];

      const intVal = Number(value);
      if (value === '') clone[index].endDistance = '';
      else clone[index].endDistance = intVal;

      if (clone[index].startDistance && clone[index].endDistance) {
        clone[index].distance = Math.abs(
          Number(clone[index].startDistance || 0) - Number(clone[index].endDistance || 0),
        );
      }
      return clone;
    });
  };

  const onNumberOfBucketsChanged = (value: string) => {
    setStartTouched(true);
    setAdvancesToEdit(prev => {
      const clone = [...prev];
      clone[index].numberOfBuckets = value;
      return clone;
    });
  };

  const showDeleteButton = useMemo(() => advancesToEdit.length > 1, [advancesToEdit]);

  const calculatedAdvanceFootage = useMemo(() => {
    if (
      (Number(advance.startDistance) || Number(advance.startDistance) === 0) &&
      (Number(advance.endDistance) || Number(advance.endDistance) === 0)
    ) {
      return Math.abs(Number(advance.startDistance || 0) - Number(advance.endDistance || 0));
    }
    return '';
  }, [advance.startDistance, advance.endDistance]);

  const advanceLengthString = useMemo(() => {
    if (
      (calculatedAdvanceFootage || calculatedAdvanceFootage === 0) &&
      (advance.startDistance || advance.startDistance === 0) &&
      (advance.endDistance || advance.endDistance === 0) &&
      !errors.startFootage &&
      !errors.endFootage &&
      !errors.footage
    ) {
      return `${numberWithSuffix(index + 1)} ${translate(
        'advance footage',
      )}: ${calculatedAdvanceFootage} ${distanceUnitAbbreviation}`;
    }
    return `${numberWithSuffix(index + 1)} ${translate('advance footage')}:`;
  }, [
    calculatedAdvanceFootage,
    index,
    errors,
    advance.startDistance,
    advance.endDistance,
    distanceUnitAbbreviation,
  ]);

  const inputWidth = useMemo(() => {
    if (isLanigan) {
      return showDeleteButton ? 9 : 12;
    }
    return 6;
  }, [isLanigan, showDeleteButton]);

  return (
    <Grid container key={`${advance.id}`} justify="flex-start">
      <PanelBreak />
      {(isChevron || isCory || isAllan) && !isRocanville && !showAllanRehabFields && (
        <>
          <Grid item xs={inputWidth} className={classes.noSpacer}>
            <TextField
              label={`${numberWithSuffix(index + 1)} ${translate('advance footage')}`}
              required
              unitText={distanceUnitAbbreviation || ''}
              onChange={event => onAdvanceFootageChanged(event.target.value)}
              value={advance.distance}
              data-cy="AdvanceFootage"
              inputProps={{ inputMode: 'numeric' }}
              error={footageTouched && !!advanceFootageErrors[advance.id]?.footage}
              errorText={footageTouched && advanceFootageErrors[advance.id]?.footage}
            />
          </Grid>
          {showDeleteButton && (
            <Grid item xs={2} className={classes.deleteAdvanceButton}>
              <Button
                id="deleteCategoryButton"
                noMinHeight
                startAdornment={<Icons.MinusCircleFeather color="error" />}
                onClick={() => {
                  setDeleteAdvanceModalOpen(true);
                  setAdvanceToDelete(advance);
                }}
                variant="text"
                color="primary"
              />
            </Grid>
          )}
        </>
      )}
      {(!(isChevron || isCory || isAllan) || isRocanville) && (
        <>
          <Grid
            item
            xs={showDeleteButton ? 5 : 6}
            className={showDeleteButton ? classes.noSpacer : classes.textFieldSpacer}
          >
            <TextField
              label={translate('Start footage')}
              unitText={distanceUnitAbbreviation || ''}
              onChange={event => onStartFootageChanged(event.target.value)}
              value={advance.startDistance}
              data-cy="AdvanceFootage"
              inputProps={{ inputMode: 'numeric' }}
              error={startTouched && !!errors.startFootage}
              errorText={errors.startFootage}
            />
          </Grid>
          <Grid item xs={showDeleteButton ? 5 : 6} className={classes.noSpacer}>
            <TextField
              label={translate('End footage')}
              unitText={distanceUnitAbbreviation || ''}
              onChange={event => onEndFootageChanged(event.target.value)}
              value={advance.endDistance}
              data-cy="AdvanceFootage"
              inputProps={{ inputMode: 'numeric' }}
              error={endTouched && !!errors.endFootage}
              errorText={errors.endFootage}
            />
          </Grid>
          {showDeleteButton && (
            <Grid item xs={1} className={classes.deleteAdvanceButton}>
              <Button
                id="deleteCategoryButton"
                noMinHeight
                startAdornment={<Icons.MinusCircleFeather color="error" />}
                onClick={() => {
                  setDeleteAdvanceModalOpen(true);
                  setAdvanceToDelete(advance);
                }}
                variant="text"
                color="primary"
              />
            </Grid>
          )}
          <Grid item xs={12} className={showDeleteButton ? '' : classes.textFieldSpacer}>
            <Typography variant="caption" color="textPrimary" className={classes.footageText}>
              {advanceLengthString}
            </Typography>
          </Grid>
        </>
      )}
      {showAllanRehabFields && (
        <Grid
          item
          container
          xs={12}
          className={showDeleteButton ? classes.noSpacer : classes.textFieldSpacer}
          direction="row"
        >
          <Grid item xs={6}>
            <TextField
              label={i18n.t('No. Buckets')}
              value={advance.numberOfBuckets}
              onChange={event => onNumberOfBucketsChanged(event.target.value)}
              error={!!errors.numberOfBuckets}
              errorText={errors.numberOfBuckets}
              data-cy="Advance-Buckets-Input"
              inputProps={{ inputMode: 'numeric' }}
            />
          </Grid>
          {showDeleteButton && (
            <Grid item xs={1} className={classes.deleteAdvanceButton}>
              <Button
                id="deleteCategoryButton"
                noMinHeight
                startAdornment={<Icons.MinusCircleFeather color="error" />}
                onClick={() => {
                  setDeleteAdvanceModalOpen(true);
                  setAdvanceToDelete(advance);
                }}
                variant="text"
                color="primary"
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default AdvanceSubPanel;
