import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import type { RxDatabase } from 'rxdb';
import { v4 as uuidv4 } from 'uuid';

import { CuttingMethod } from '@/rxdb/CuttingMethod/queryBuilder';
import { CuttingType } from '@/rxdb/CuttingType/queryBuilder';
import { GroundControlType } from '@/rxdb/GroundControlTypes/queryBuilder';
import { GroundHazardConditionType } from '@/rxdb/HazardConditionType/queryBuilder';
import { PanelDrawing } from '@/rxdb/PanelDrawing/queryBuilder';
import RxdbManager from '@/rxdb/RxdbManager';

import type { BorerDatabaseCollections } from '../models/BorerDatabaseCollections';
import { Advance } from '../rxdb/Advance/queryBuilder';
import { Block } from '../rxdb/Blocks/queryBuilder';
import { BorerActivity } from '../rxdb/BorerActivity/queryBuilder';
import { BorerActivityType } from '../rxdb/BorerActivityType/queryBuilder';
import { BorerOperatorState } from '../rxdb/BorerOperatorStateFeed/queryBuilder';
import { BorerShiftCrew } from '../rxdb/BorerShiftCrew/queryBuilder';
import { BorerStateType } from '../rxdb/BorerStateTypeFeed/queryBuilder';
import { Crew } from '../rxdb/Crew/queryBuilder';
import { DelayActivityTypeCategory } from '../rxdb/DelayActivityTypeCategory/queryBuilder';
import { Department } from '../rxdb/Department/queryBuilder';
import { DocumentType } from '../rxdb/DocumentTypes/queryBuilder';
import { Employee } from '../rxdb/Employees/queryBuilder';
import initializeDB from '../rxdb/initializeDB';
import { Location } from '../rxdb/Locations/queryBuilder';
import { MiningCut } from '../rxdb/MiningCut/queryBuilder';
import { MiningPattern } from '../rxdb/MiningPattern/queryBuilder';
import { Panel } from '../rxdb/Panels/queryBuilder';
import { Pass } from '../rxdb/Passes/queryBuilder';
import { Prediction } from '../rxdb/Prediction/queryBuilder';
import { Production } from '../rxdb/Productions/queryBuilder';
import { Room } from '../rxdb/Rooms/queryBuilder';
import RxdbCollectionName from '../rxdb/rxdbCollectionName';
import { generateBaseEntity, generateBaseEntityWithCreatedOn } from '../rxdb/rxdbUtilityFunctions';
import { Sequence } from '../rxdb/Sequences/queryBuilder';
import { Site } from '../rxdb/Site/queryBuilder';
import { SurveyPoint } from '../rxdb/SurveyPoints/queryBuilder';
import {
  ALLAN_FIXED_SITE_ID,
  EMPTY_GUID,
  LANIGAN_FIXED_SITE_ID,
  ROCANVILLE_FIXED_SITE_ID,
} from '../utilities/constants';
import {
  AWS_DATE_TIME_FORMAT,
  formatDateToUnix,
  SCHEDULER_DATE_FORMAT,
} from '../utilities/useDateFormatters';
import { mockBorerId, mockBorerShiftId } from './rootStoreMock';

export type RxdbDatabase = RxDatabase<BorerDatabaseCollections>;
dayjs.extend(timezone);
const miningPatternId = uuidv4();
const longRoomMiningPatternId = uuidv4();
export const mockSiteId = LANIGAN_FIXED_SITE_ID;
export const mockBorerRoomId = uuidv4();

export const mockBlock1: Block = {
  ...generateBaseEntity(),
  description: 'MB',
  isActive: true,
};

export const mockBlock2: Block = {
  ...generateBaseEntity(),
  description: 'MB2',
  isActive: true,
};

export const mockDefaultBlock: Block = {
  ...generateBaseEntity(),
  description: ' ',
  isActive: true,
};

export const mockChevronPanel1: Panel = {
  ...generateBaseEntity(),
  description: 'MP1Chevron',
  block: mockBlock1.id,
  miningMethod: 'Chevron',
  completedOn: null,
};

export const mockChevronPanel2: Panel = {
  ...generateBaseEntity(),
  description: 'MP2',
  block: mockBlock2.id,
  miningMethod: 'Chevron',
  completedOn: null,
};

export const mockChevronPanelDefaultBlock: Panel = {
  ...generateBaseEntity(),
  description: 'MP1',
  block: mockDefaultBlock.id,
  miningMethod: 'Chevron',
  completedOn: null,
};

export const mockLongroomPanel1: Panel = {
  ...generateBaseEntity(),
  description: 'mockLongroomPanel1',
  block: mockBlock1.id,
  miningMethod: 'Long room',
  completedOn: null,
};

export const mockChevronMiningPattern: MiningPattern = {
  id: miningPatternId,
  description: 'Chevron',
  miningMethod: 'Chevron',
  updatedAt: 1666038668089,
  isDeleted: false,
  version: 1,
};

export const mockLongroomMiningPattern: MiningPattern = {
  id: longRoomMiningPatternId,
  description: 'Long room',
  miningMethod: 'Long room',
  updatedAt: 1666038668089,
  isDeleted: false,
  version: 1,
};

export const mockRoom1: Room = {
  ...generateBaseEntity(),
  description: 'mockRoom1',
  isActive: true,
  panel: mockChevronPanel1.id,
  miningPattern: mockChevronMiningPattern.id,
  length: 100,
};
export const mockRoom2: Room = {
  ...generateBaseEntity(),
  description: 'mockR2',
  isActive: true,
  panel: mockChevronPanel1.id,
  miningPattern: mockChevronMiningPattern.id,
  length: 100,
};

export const mockRoom3: Room = {
  ...generateBaseEntity(),
  description: 'mockR3',
  isActive: true,
  panel: mockLongroomPanel1.id,
  miningPattern: mockLongroomMiningPattern.id,
  length: 100,
};

export const mockRoom4: Room = {
  ...generateBaseEntity(),
  description: 'mockR4',
  isActive: true,
  panel: mockChevronPanel2.id,
  miningPattern: mockChevronMiningPattern.id,
  length: 44,
};

export const mockSp1: SurveyPoint = {
  ...generateBaseEntity(),
  room: mockRoom1.id,
  description: '100',
  isActive: true,
  miningPattern: mockChevronMiningPattern.id,
};

export const mockSp2: SurveyPoint = {
  ...generateBaseEntity(),
  room: mockRoom4.id,
  description: '44',
  isActive: true,
  miningPattern: mockChevronMiningPattern.id,
};

export const mockDocType: DocumentType = {
  ...generateBaseEntity(),
  description: 'mockDocType',
  displayOrder: 1,
  siteId: mockSiteId,
  isRequired: true,
  miningMethod: 'Chevron',
};
export const mockSafeWorkChevPlanDocType: DocumentType = {
  ...generateBaseEntity(),
  description: 'Safe Work Plan',
  displayOrder: 2,
  siteId: mockSiteId,
  isRequired: true,
  miningMethod: 'Chevron',
};
export const mockSafeWorkLRPlanDocType: DocumentType = {
  ...generateBaseEntity(),
  description: 'Safe Work Plan',
  displayOrder: 2,
  siteId: mockSiteId,
  isRequired: true,
  miningMethod: 'Long room',
};
export const mockLocation1: Location = {
  ...generateBaseEntity(),
  startMeters: null,
  endMeters: null,
  panel: mockChevronPanel1.id,
  room: mockRoom1?.id,
  surveyPoint: mockSp1?.id,
  sequence: null,
  pass: null,
};

export const mockLocation2: Location = {
  ...generateBaseEntity(),
  startMeters: null,
  endMeters: null,
  panel: mockChevronPanel2.id,
  room: mockRoom2?.id,
  surveyPoint: mockSp2?.id,
  sequence: null,
  pass: null,
};

export const mockLocation3: Location = {
  ...generateBaseEntity(),
  startMeters: null,
  endMeters: null,
  panel: mockChevronPanelDefaultBlock.id,
  room: mockRoom2?.id,
  surveyPoint: mockSp2?.id,
  sequence: null,
  pass: null,
};

// Delay Activity Type Category
export const mockDelayActivityTypeCategory1: DelayActivityTypeCategory = {
  ...generateBaseEntity(),
  description: 'Delay Activity Type Category 1',
};
export const mockRunningStateType1: BorerStateType = {
  ...generateBaseEntity(),
  description: 'Running',
  isRunning: true,
  isDefault: false,
  isActive: true,
  borerStateTypeCategoryId: mockDelayActivityTypeCategory1.id,
  timeUsageTypeId: EMPTY_GUID,
  isPlanned: null,
};

export const mockNonRunningStateType: BorerStateType = {
  ...generateBaseEntity(),
  description: 'Not running',
  isRunning: false,
  isDefault: true,
  isActive: true,
  borerStateTypeCategoryId: mockDelayActivityTypeCategory1.id,
  timeUsageTypeId: EMPTY_GUID,
  isPlanned: null,
};

export const mockStateType3: BorerStateType = {
  ...generateBaseEntity(),
  description: 'Sample delay type description',
  isRunning: false,
  isDefault: false,
  isActive: true,
  borerStateTypeCategoryId: mockDelayActivityTypeCategory1.id,
  timeUsageTypeId: EMPTY_GUID,
  isPlanned: null,
};

export const mockStateType4: BorerStateType = {
  ...generateBaseEntity(),
  description: 'Borer electricity outage',
  isRunning: false,
  isDefault: false,
  isActive: true,
  borerStateTypeCategoryId: mockDelayActivityTypeCategory1.id,
  timeUsageTypeId: EMPTY_GUID,
  isPlanned: null,
};

// Borer activity types
export const mockActivityType1: BorerActivityType = {
  ...generateBaseEntityWithCreatedOn(),
  description: 'Sample Activity Type 1',
  name: 'Sample Activity Type 1',
  isActive: true,
  delayActivityTypeCategory: mockDelayActivityTypeCategory1.id,
};

export const mockActivityType2: BorerActivityType = {
  ...generateBaseEntityWithCreatedOn(),
  description: 'Sample Activity Type 2',
  name: 'Sample Activity Type 2',
  isActive: true,
  delayActivityTypeCategory: mockDelayActivityTypeCategory1.id,
};

// Borer activites
export const mockActivity1: BorerActivity = {
  ...generateBaseEntityWithCreatedOn(),
  borerShiftCrewId: undefined,
  start: dayjs(new Date().setHours(8, 35, 0, 0)).format(SCHEDULER_DATE_FORMAT),
  end: dayjs(new Date().setHours(10, 0, 0, 0)).format(SCHEDULER_DATE_FORMAT),
  comment: 'Sample Activity Comment 1',
  activityTypeId: mockActivityType1.id,
  borerShiftId: mockBorerShiftId,
  isFaceChangeCrew: false,
};

// Predictions
export const mockPredictionDoc: Prediction = {
  ...generateBaseEntity(),
  endLocation: mockLocation1.id,
  endDistance: 333,
  borerShiftId: mockBorerShiftId,
  footage: 333,
  location: mockLocation1.id,
};

export const mockPredictionDoc2: Prediction = {
  ...generateBaseEntity(),
  endLocation: mockLocation2.id,
  endDistance: 444,
  borerShiftId: mockBorerShiftId,
  footage: 444,
  location: mockLocation2.id,
};

const mockStartTime1 = dayjs()
  .set('hour', 8)
  .set('minute', 0)
  .set('seconds', 0)
  .format(AWS_DATE_TIME_FORMAT);
const mockEndTime1 = dayjs(mockStartTime1).add(3.5, 'hour').format(AWS_DATE_TIME_FORMAT);

export const mockChevronSequence1: Sequence = {
  ...generateBaseEntity(),
  miningMethod: 'Chevron',
  miningStyle: 'Chevron',
  description: 'M-Chev-S1',
  isActive: true,
};
export const mockLongroomSequence1: Sequence = {
  ...generateBaseEntity(),
  miningMethod: 'Long room',
  miningStyle: 'Long room',
  description: 'M-LR-S1',
  isActive: true,
};

export const mockChevronPass1: Pass = {
  ...generateBaseEntity(),
  description: 'MP1',
  isActive: true,
  miningMethod: 'Chevron',
  miningStyle: 'Chevron',
};

export const mockLongroomPass1: Pass = {
  ...generateBaseEntity(),
  description: 'MLRP1',
  isActive: true,
  miningMethod: 'Long room',
  miningStyle: 'Long room',
};

export const mockChevronMiningCut1: MiningCut = {
  ...generateBaseEntity(),
  density: 10,
  expectedLength: 10,
  order: 1,
  miningMethod: 'Chevron',
  miningStyle: 'Chevron',
  sequence: mockChevronSequence1.id,
  miningPattern: miningPatternId,
  pass: mockChevronPass1.id,
  isActive: true,
};

export const mockChevronMiningCut2: MiningCut = {
  ...generateBaseEntity(),
  density: 4,
  expectedLength: 14,
  order: 1,
  miningMethod: 'Chevron',
  miningStyle: 'Chevron',
  sequence: mockChevronSequence1.id,
  miningPattern: miningPatternId,
  pass: mockChevronPass1.id,
  isActive: true,
};

export const mockLongroomMiningCut1: MiningCut = {
  ...generateBaseEntity(),
  density: 10,
  expectedLength: 10,
  order: 1,
  miningMethod: 'Long room',
  miningStyle: 'Long room',
  sequence: mockLongroomSequence1.id,
  miningPattern: miningPatternId,
  pass: mockLongroomPass1.id,
  isActive: true,
};

// Chevron
export const mockBorerShiftProduction1: Production = {
  ...generateBaseEntity(),
  miningCutId: mockChevronMiningCut1.id,
  borerShiftId: mockBorerShiftId,
  locationId: mockLocation1.id,
};

export const mockBorerShiftChevronProduction2: Production = {
  ...generateBaseEntity(),
  miningCutId: mockChevronMiningCut2.id,
  borerShiftId: mockBorerShiftId,
  locationId: mockLocation2.id,
};

export const mockBorerShiftChevronProduction3: Production = {
  ...generateBaseEntity(),
  miningCutId: mockChevronMiningCut2.id,
  borerShiftId: mockBorerShiftId,
  locationId: mockLocation3.id,
};

// Longroom
export const mockBorerShiftProduction2: Production = {
  ...generateBaseEntity(),
  miningCutId: mockLongroomMiningCut1.id,
  borerShiftId: mockBorerShiftId,
  locationId: mockLocation1.id,
};

export const mockBorerShiftAdvance1: Advance = {
  ...generateBaseEntityWithCreatedOn(),
  borerShiftProductionId: mockBorerShiftProduction1.id,
  distance: 120,
};

// Default block

export const mockBorerShiftAdvance2: Advance = {
  ...generateBaseEntityWithCreatedOn(),
  borerShiftProductionId: mockBorerShiftProduction2.id,
  distance: 500,
  startDistance: 0,
  endDistance: 500,
};

export const mockBorerShiftAdvance3: Advance = {
  ...generateBaseEntityWithCreatedOn(),
  borerShiftProductionId: mockBorerShiftChevronProduction3.id,
  distance: 444,
  startDistance: 0,
  endDistance: 444,
};

export const mockBorerShiftAdvance4: Advance = {
  ...generateBaseEntityWithCreatedOn(),
  borerShiftProductionId: mockBorerShiftChevronProduction2.id,
  distance: 444,
  startDistance: 0,
  endDistance: 444,
};

export const mockBorerState1: BorerOperatorState = {
  ...generateBaseEntity(),
  borerOperatorStateRequestId: uuidv4(),
  borerStateId: uuidv4(),
  borerStateTypeId: mockRunningStateType1.id,
  comment: 'test running comment',
  startTime: mockStartTime1,
  startTimeUnix: formatDateToUnix(mockStartTime1),
  endTime: mockEndTime1,
  endTimeUnix: formatDateToUnix(mockEndTime1),
  borerShiftAdvanceId: '',
  showInSchedulerView: true,
  cuttingMethodId: null,
  cuttingTypeId: null,
};
export const mockBorerState2: BorerOperatorState = {
  ...generateBaseEntity(),
  id: uuidv4(),
  borerOperatorStateRequestId: uuidv4(),
  borerStateId: uuidv4(),
  borerStateTypeId: mockNonRunningStateType.id,
  comment: 'test uncoded',
  startTime: dayjs(new Date().setHours(11, 35, 0, 0)).format(SCHEDULER_DATE_FORMAT),
  startTimeUnix: dayjs(new Date().setHours(11, 35, 0, 0)).unix(),
  endTime: dayjs(new Date().setHours(12, 30, 0, 0)).format(SCHEDULER_DATE_FORMAT),
  endTimeUnix: dayjs(new Date().setHours(12, 30, 0, 0)).unix(),
  borerShiftAdvanceId: '',
  showInSchedulerView: true,
  cuttingMethodId: null,
  cuttingTypeId: null,
};

export const mockBorerShiftCrew1: BorerShiftCrew = {
  ...generateBaseEntity(),
  borerShiftId: mockBorerShiftId,
  crewNumber: 1,
  start: dayjs(new Date().setUTCHours(8 + 6, 0, 0, 0)).format(SCHEDULER_DATE_FORMAT),
  end: dayjs(new Date().setUTCHours(20 + 6, 0, 0, 0)).format(SCHEDULER_DATE_FORMAT),
  hidden: false,
  notUsed: false,
};

export const mockConstructionDepartment: Department = {
  ...generateBaseEntity(),
  description: 'construction',
};

export const mockCrew: Crew = {
  ...generateBaseEntity(),
  departmentId: mockConstructionDepartment.id,
  description: 'Test Crew',
  number: 'Test crew number',
  isActive: true,
};

export const mockEmployee1: Employee = {
  ...generateBaseEntity(),
  siteId: mockSiteId,
  firstName: 'Test',
  lastName: 'Employee',
  email: 'dalkjsalkd@asd.com',
  crew: null,
  department: null,
  position: null,
  phoneNumber: '123456789',
  isActive: true,
};

export const mockEmployee2: Employee = {
  ...generateBaseEntity(),
  siteId: mockSiteId,
  firstName: 'Test2',
  lastName: 'Employee2',
  email: 'dalkjsalkd@asd.com',
  crew: mockCrew.id,
  department: mockConstructionDepartment.id,
  position: null,
  phoneNumber: '123456789',
  isActive: true,
};

export const mockPanelDrawing1: PanelDrawing = {
  ...generateBaseEntity(),
  borerShiftId: mockBorerShiftId,
  drawingData: '{}',
  miningMethod: 'Chevron',
};
export const mockPanelDrawing2: PanelDrawing = {
  ...generateBaseEntity(),
  borerShiftId: mockBorerShiftId,
  drawingData: '{}',
  miningMethod: 'Long room',
};

export const mockRocanvilleEmployee: Employee = {
  ...generateBaseEntity(),
  siteId: ROCANVILLE_FIXED_SITE_ID,
  firstName: 'Ricky',
  lastName: 'Rocanville',
  email: 'dalkjsalkd@asd.com',
  crew: mockCrew.id,
  department: mockConstructionDepartment.id,
  position: null,
  phoneNumber: '123456789',
  isActive: true,
};

export const mockSite: Site = {
  ...generateBaseEntity(),
  id: mockSiteId,
  name: 'Mock Lanigan site',
  distanceUnit: 'feet',
  distanceUnitAbbreviation: 'ft',
};
export const mockRocanvilleSite: Site = {
  ...generateBaseEntity(),
  id: ROCANVILLE_FIXED_SITE_ID,
  name: 'Rocanville',
  distanceUnit: 'feet',
  distanceUnitAbbreviation: 'ft',
};
export const mockAllanSite: Site = {
  ...generateBaseEntity(),
  id: ALLAN_FIXED_SITE_ID,
  name: 'Allan',
  distanceUnit: 'meters',
  distanceUnitAbbreviation: 'm',
};

export const mockConditionType: GroundHazardConditionType = {
  ...generateBaseEntity(),
  description: 'Test condition',
  displayName: 'Test condition',
  isSingleEntry: true,
  isActive: true,
  severity: '1',
  site: ROCANVILLE_FIXED_SITE_ID,
};

export const mockBorerNoLocation = {
  id: '00000000-0000-0000-0000-000000000001',
  equipmentTypeId: '0b580569-f46a-45ab-ad26-ff642ade90c2',
  blockId: '',
  panelId: '',
  roomId: '',
  updatedAt: 1665783758436,
  version: 31,
  shortName: 'test-borer-no-location',
  longName: 'test-borer-no-location',
  surveyPointId: '',
  isActive: true,
  assignedBorerEquipmentId: null,
  isDeleted: false,
};

export const mockGroundControlTye: GroundControlType = {
  ...generateBaseEntity(),
  description: 'Test ground control type',
  displayOrder: 0,
  options: JSON.stringify(
    JSON.stringify([
      {
        description: 'Type',
        displayOrder: 1,
        type: 'Scale',
        displayType: 'Toggle',
        id: '932e47e6-4c91-47dc-8532-918144267806',
        values: [
          {
            value: 'Hand',
            displayOrder: 1,
            type: 'Scale',
            id: '5233bfa3-af1a-4f92-968c-ad0e2784a0cb',
          },
          {
            value: 'Mechanical',
            displayOrder: 2,
            type: 'Scale',
            id: 'b4a17197-8f78-4465-9e56-98f1df32d172',
          },
        ],
      },
    ]),
  ),
  requiresQuantity: false,
};

export const mockCuttingTypeDocument: CuttingType = {
  ...generateBaseEntity(),
  description: 'Anomalous cutting',
  displayOrder: 0,
};
export const mockCuttingMethodDocument: CuttingMethod = {
  ...generateBaseEntity(),
  description: 'Normal',
  displayOrder: 0,
};

export type RxdbCollectionBoolean = {
  [key in RxdbCollectionName]?: boolean;
};

export interface MockRxdbInstanceOptions {
  isChevron?: boolean;
  overrideNightShift?: boolean;
  overrideDayShift?: boolean;
  borerNoLocation?: boolean;
}
export const setupMockRxdbInstance = async (
  skipCollection?: RxdbCollectionBoolean,
  options?: MockRxdbInstanceOptions,
): Promise<RxDatabase<BorerDatabaseCollections, any, any> | undefined> => {
  // Clear all existing data, since UUIDs will change
  let { db } = await initializeDB();
  await db?.remove();
  ({ db } = await initializeDB());

  // Attach db instance to RxdbManager, as some queries require it
  new RxdbManager(false, db);

  // Mock data setup

  if (!skipCollection?.sites) {
    await db?.collections.sites?.upsert(mockSite);
    await db?.collections.sites?.upsert(mockRocanvilleSite);
    await db?.collections.sites?.upsert(mockAllanSite);
  }

  // equipment
  if (!skipCollection?.equipment) {
    await db?.collections.equipment?.upsert({
      id: '00000000-0000-0000-0000-000000000002',
      equipmentTypeId: '0b580569-f46a-45ab-ad26-ff642ade90c2',
      blockId: '',
      panelId: '',
      roomId: '',
      updatedAt: 1665783758900,
      version: 35,
      shortName: 'test-borer-no-location-2',
      longName: 'test-borer-no-location-2',
      surveyPointId: '',
      isActive: true,
      assignedBorerEquipmentId: null,
      isDeleted: false,
    });

    await db?.collections.equipment?.upsert(mockBorerNoLocation);

    await db?.collections.equipment?.upsert({
      id: mockBorerId,
      equipmentTypeId: '0b580569-f46a-45ab-ad26-ff642ade90c2',
      blockId: '0cad95d2-c28f-680d-f8b8-4b617dc18e7b',
      panelId: options?.isChevron ? mockChevronPanel1.id : mockLongroomPanel1.id,
      roomId: mockBorerRoomId,
      updatedAt: 1665783758435,
      version: 31,
      shortName: 'test-borer',
      longName: 'test-borer',
      surveyPointId: 'd0d0450d-744e-4c13-865f-b2fb01f1c225',
      isActive: true,
      assignedBorerEquipmentId: null,
      isDeleted: false,
    });
  }

  await db?.collections.rooms?.upsert({
    id: mockBorerRoomId,
    description: 'test_room',
    updatedAt: 1666038668089,
    isActive: true,
    isDeleted: false,
    length: 10,
    panel: '00000000-0000-0000-0000-000000000001',
    version: 1,
    miningPattern: miningPatternId,
  });

  await db?.collections.mining_pattern?.upsert(mockChevronMiningPattern);

  if (!skipCollection?.ground_control_types) {
    await db?.collections.ground_control_types?.upsert(mockGroundControlTye);
  }

  if (!skipCollection?.document_upload) {
    await db?.collections.document_upload?.upsert({
      id: '00000000-0000-0000-0000-000000000000',
      isDeleted: false,
      updatedAt: 1666038668089,
      version: 1,
      attachmentId: '7b19dcd5-bced-44ab-bf13-3cf0c01c1907',
      borerDocumentTypeId: '8abb2807-2370-4c57-a6a3-42984b47e959',
      description: 'test_borer_document',
      fileName: 'test.jpg',
      isActive: true,
      isStopCarryForward: false,
      miningMethod: null,
      roomId: mockBorerRoomId,
      siteId: mockSiteId,
      stopcarryforwardRoomId: null,
      stopcarryforwardSurveyPointId: null,
      surveyPointId: null,
      createdOn: '2022-10-17T20:31:08.089Z',
    });

    await db?.collections.document_upload?.upsert({
      id: '00000000-0000-0000-0000-000000000003',
      isDeleted: false,
      updatedAt: 1666038668089,
      version: 1,
      attachmentId: '7b19dcd5-bced-44ab-bf13-3cf0c01c1907',
      borerDocumentTypeId: '8abb2807-2370-4c57-a6a3-42984b47e959',
      description: 'test_global_document',
      fileName: 'test.jpg',
      isActive: true,
      isStopCarryForward: false,
      miningMethod: 'Chevron',
      roomId: null,
      siteId: mockSiteId,
      stopcarryforwardRoomId: null,
      stopcarryforwardSurveyPointId: null,
      surveyPointId: '00000000-0000-0000-0000-000000000sp1',
      createdOn: '2022-10-17T20:31:08.089Z',
    });
  }

  if (!skipCollection?.panels) {
    await db?.collections.panels?.upsert(mockChevronPanel1);
    await db?.collections.panels?.upsert(mockChevronPanel2);
    await db?.collections.panels?.upsert(mockLongroomPanel1);
    await db?.collections.panels?.upsert(mockChevronPanelDefaultBlock);
  }

  if (!skipCollection?.blocks) {
    await db?.collections.blocks?.upsert(mockBlock1);
    await db?.collections.blocks?.upsert(mockBlock2);
    await db?.collections.blocks?.upsert(mockDefaultBlock);
    await db?.collections.panels?.upsert(mockChevronPanelDefaultBlock);
  }

  if (!skipCollection?.rooms) {
    await db?.collections.rooms?.upsert(mockRoom1);
    await db?.collections.rooms?.upsert(mockRoom2);
    await db?.collections.rooms?.upsert(mockRoom3);
    await db?.collections.rooms?.upsert(mockRoom4);
  }
  await db?.collections.survey_points?.upsert(mockSp1);
  await db?.collections.survey_points?.upsert(mockSp2);

  await db?.collections.locations?.upsert(mockLocation1);
  await db?.collections.locations?.upsert(mockLocation2);
  await db?.collections.locations?.upsert(mockLocation3);

  await db?.collections.document_types?.upsert(mockDocType);
  await db?.collections.document_types?.upsert(mockSafeWorkChevPlanDocType);
  await db?.collections.document_types?.upsert(mockSafeWorkLRPlanDocType);

  if (!skipCollection?.document_upload) {
    await db?.collections.document_upload?.upsert({
      ...generateBaseEntityWithCreatedOn(),
      attachmentId: '7b19dcd5-bced-44ab-bf13-3cf0c01c1903',
      borerDocumentTypeId: mockDocType.id,
      description: 'test_borer_prediction_document',
      fileName: 'test_2.jpg',
      isActive: true,
      isStopCarryForward: false,
      miningMethod: null,
      roomId: mockRoom1.id,
      siteId: mockSiteId,
      stopcarryforwardRoomId: null,
      stopcarryforwardSurveyPointId: null,
      surveyPointId: mockSp1.id,
    });
  }

  //  Delay Activity Type Category
  await db?.collections.delay_activity_type_category?.upsert(mockDelayActivityTypeCategory1);

  // Borer state types
  if (!skipCollection?.borer_state_type) {
    await db?.collections.borer_state_type?.upsert(mockRunningStateType1);
    await db?.collections.borer_state_type?.upsert(mockNonRunningStateType);
    await db?.collections.borer_state_type?.upsert(mockStateType3);
    await db?.collections.borer_state_type?.upsert(mockStateType4);
  }

  // Borer states
  if (!skipCollection?.borer_operator_state_feed) {
    await db?.collections.borer_operator_state_feed?.upsert(mockBorerState1);
    await db?.collections.borer_operator_state_feed?.upsert(mockBorerState2);
  }
  // Borer activity types
  await db?.collections.borer_activity_type?.upsert(mockActivityType1);
  await db?.collections.borer_activity_type?.upsert(mockActivityType2);

  // Borer activities
  if (!skipCollection?.borer_activity) {
    await db?.collections.borer_activity?.upsert(mockActivity1);
  }
  // Borer shift crew
  if (!skipCollection?.borer_shift_crew) {
    await db?.collections?.borer_shift_crew?.upsert(mockBorerShiftCrew1);
  }

  // Crew and department
  if (!skipCollection?.crews) {
    await db?.collections.crews?.upsert(mockCrew);
  }
  if (!skipCollection?.department) {
    await db?.collections.department?.upsert(mockConstructionDepartment);
  }

  // Employee
  if (!skipCollection?.employees) {
    await db?.collections.employees?.upsert(mockEmployee1);
    await db?.collections.employees?.upsert(mockEmployee2);
    await db?.collections.employees?.upsert(mockRocanvilleEmployee);
  }

  if (!skipCollection?.sequences) {
    await db?.collections.sequences?.upsert(mockChevronSequence1);
    await db?.collections.sequences?.upsert(mockLongroomSequence1);
  }

  if (!skipCollection?.passes) {
    await db?.collections.passes?.upsert(mockChevronPass1);
    await db?.collections.passes?.upsert(mockLongroomPass1);
  }
  if (!skipCollection?.mining_cuts) {
    await db?.collections.mining_cuts?.upsert(mockChevronMiningCut1);
    await db?.collections.mining_cuts?.upsert(mockLongroomMiningCut1);
  }

  if (!skipCollection?.borer_shift_production) {
    await db?.collections.borer_shift_production?.upsert(mockBorerShiftProduction1);
    await db?.collections.borer_shift_advance?.upsert(mockBorerShiftAdvance1);
  }
  if (!skipCollection?.ground_hazard_condition_types) {
    await db?.collections.ground_hazard_condition_types?.upsert(mockConditionType);
  }

  if (!skipCollection?.panel_drawings) {
    await db?.collections.panel_drawings?.upsert(mockPanelDrawing1);
    await db?.collections.panel_drawings?.upsert(mockPanelDrawing2);
  }

  if (!skipCollection?.cutting_method) {
    await db?.collections.cutting_method?.upsert(mockCuttingMethodDocument);
  }

  if (!skipCollection?.cutting_type) {
    await db?.collections.cutting_type?.upsert(mockCuttingTypeDocument);
  }

  new RxdbManager(false, db);
  return db;
};
