import { ApolloClient, createHttpLink, gql, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

import { apiConfig } from '../config/api.config';

const cache = new InMemoryCache();

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}?opname=${operationName}`, options);
};

const httpLink = createHttpLink({
  uri: apiConfig.graphUrl,
  fetch: customFetch,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    console.log('before credentials');
    const credentials = await Auth.currentSession();
    console.log('after credentials');
    const token = credentials.getIdToken().getJwtToken();
    console.log('🚀 ~ file: Client.tsx:22 ~ authLink ~ token:', token);

    return {
      headers: {
        ...headers,
        authorization: token ? `${token}` : '',
      },
    };
  } catch (err) {
    console.log(
      '🚀 ~ file: Client.tsx:35 ~ err: WE WILL PROBBALY SEE THIS, and then 401 errors',
      err,
    );
    return {
      headers: {
        ...headers,
        authorization: '',
      },
    };
  }
});
console.log('🚀 ~ file: Client.tsx:32 ~ authLink ~ authLink:', authLink);
console.log('🚀 ~ file: Client.tsx:52 ~ link:', authLink.concat(httpLink));

const client = new ApolloClient({
  // Provide required constructor fields
  cache,
  link: authLink.concat(httpLink),
  // Provide some optional constructor fields
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

console.log('🚀 ~ file: Client.tsx:45 ~ client:', client);

export { client, gql };

export default { client, gql };
