import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_50 } from '../../utilities/constants';
import { RxdbCollectionName } from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface InspectionResult extends BaseEntity {
  readonly inspectionDetail: string;
  readonly borerShiftId: string;
  readonly inspectionId: string;
  readonly signatureId: string | null;
  readonly createdOn: string;
  readonly locationId: string | null;
}
export type InspectionResultCollection = RxCollection<InspectionResult> | null;
export type InspectionResultDocument = RxDocument<InspectionResult>;

export const inspectionResultSchema: RxJsonSchema<InspectionResult> = {
  type: 'object',
  version: 1,
  description: 'describes a InspectionCategory object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    inspectionDetail: { type: 'string' },
    borerShiftId: { ref: RxdbCollectionName.BORER_SHIFT, type: 'string' },
    inspectionId: { ref: RxdbCollectionName.INSPECTIONS, type: 'string' },
    signatureId: {
      ref: RxdbCollectionName.SIGNATURES,
      type: ['string', 'null'],
    },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    createdOn: { type: 'string' },
    locationId: { ref: RxdbCollectionName.LOCATIONS, type: ['string', 'null'] },
  },
  indexes: ['updatedAt'],
};

export const inspectionResultMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: InspectionResult) => ({ ...oldDoc, locationId: null }),
};

export const inspectionResultPullQueryBuilder = (doc: InspectionResultDocument) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }
  const query = print(gql`
    query inspectionResultFeed($equipmentId: ID!, $updatedAt: Float!, $limit: Int!) {
      inspectionResultFeed(
        borerEquipmentId: $equipmentId
        lastUpdateAt: $updatedAt
        limit: $limit
      ) {
        id
        inspectionDetail
        borerShiftId
        inspectionId
        isDeleted
        signatureId
        updatedAt
        version
        createdOn
        locationId
      }
    }
  `);

  return {
    query,
    variables: {
      equipmentId: rootStore.equipment.selectedBorerId,
      updatedAt: doc.updatedAt,
      limit: SYNC_LIMIT_50,
    },
  };
};

export const inspectionResultPushQueryBuilder = (doc: InspectionResultDocument) => {
  doc = doc[0];

  const query = print(gql`
    mutation setInspectionResult(
      $id: ID!
      $createdOn: AWSDateTime
      $borerShiftId: ID!
      $inspectionDetail: String!
      $inspectionId: ID!
      $signatureId: ID
      $locationId: ID
      $isDeleted: Boolean!
      $version: Int!
    ) {
      setInspectionResult(
        input: {
          id: $id
          borerShiftId: $borerShiftId
          createdOn: $createdOn
          inspectionId: $inspectionId
          signatureId: $signatureId
          inspectionDetail: $inspectionDetail
          locationId: $locationId
          isDeleted: $isDeleted
          version: $version
        }
      )
    }
  `);

  const variables = {
    ...doc,
  };

  return {
    query,
    variables,
  };
};

export const inspectionResultPushModifier = (doc: InspectionResultDocument) => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    createdOn: doc.createdOn,
    inspectionId: doc.inspectionId,
    signatureId: doc.signatureId,
    inspectionDetail: doc.inspectionDetail,
    locationId: doc.locationId,
    isDeleted: doc.isDeleted || false,
    version: doc.version || 1,
  };
};

export default {};
