import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { formatDateToUnix } from '../../utilities/useDateFormatters';
import { HazardLog } from '../GroundHazardLog/queryBuilder';
import defaultDoc from '../Shared/defaultDoc';

export interface GroundHazard extends BaseEntity {
  readonly attachments?: (string | null)[];
  readonly conditionType: string;
  readonly startLocation?: string;
  readonly endLocation?: string;
  readonly groundControlSets?: string[];
  readonly hazardLogs?: (HazardLog | null)[];
  readonly remediatedOn: string | null;
  readonly remediatedOnUnix: number | null;
  readonly createdBy: string;
  readonly createdOn?: string;
  readonly createdOnUnix: number;
  readonly modifiedBy: string;
  readonly modifiedOn?: string;
  readonly borerEquipmentId: string | null;
  readonly inspectionId?: string | null;
  readonly updateLastViewed?: boolean | null;
  readonly startHazardLandmark: string | null;
  readonly endHazardLandmark: string | null;
}

export type GroundHazardDocument = RxDocument<GroundHazard>;
export type GroundHazardCollection = RxCollection<GroundHazard> | null;

export const groundHazardSchema: RxJsonSchema<GroundHazard> = {
  type: 'object',
  version: 3,
  description: 'describes a hazard object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    attachments: {
      type: 'array',
      ref: 'ground_hazard_attachments',
      items: {
        type: 'string',
      },
    },
    conditionType: {
      ref: 'ground_hazard_condition_types',
      type: 'string',
    },
    hazardLogs: {
      type: 'array',
      ref: 'hazard_logs',
      items: {
        type: 'string',
      },
    },
    groundControlSets: {
      type: ['array', 'null'],
      items: {
        type: ['string', 'null'],
      },
    },
    startLocation: {
      ref: 'locations',
      type: 'string',
    },
    endLocation: {
      ref: 'locations',
      type: ['string', 'null'],
    },
    createdBy: {
      ref: 'employees',
      type: 'string',
    },
    modifiedBy: {
      ref: 'employees',
      type: 'string',
    },
    remediatedOn: { type: ['string', 'null'] },
    remediatedOnUnix: { type: ['number', 'null'] },
    createdOn: { type: 'string' },
    createdOnUnix: { type: 'number' },
    modifiedOn: { type: 'string' },
    updatedAt: { type: 'number', multipleOf: 1 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerEquipmentId: { type: ['string', 'null'] },
    inspectionId: { type: ['string', 'null'] },
    updateLastViewed: { type: ['boolean', 'null'] },
    startHazardLandmark: { type: ['string', 'null'] },
    endHazardLandmark: { type: ['string', 'null'] },
  },
};

export const groundHazardMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: GroundHazardDocument) => {
    return {
      ...oldDoc,
      inspectionId: null,
    };
  },
  2: (oldDoc: GroundHazardDocument) => oldDoc,
  3: (oldDoc: GroundHazardDocument) => ({
    ...oldDoc,
    startHazardLandmark: null,
    endHazardLandmark: null,
  }),
};

export const groundHazardsPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = {
      ...defaultDoc,
      updatedAt: dayjs().startOf('day').subtract(90, 'days').valueOf(),
    };
  }
  const query = print(gql`{
        groundHazardFeed (borerEquipmentId: "${rootStore.equipment.selectedBorerId}", lastUpdateAt: ${doc.updatedAt}, limit: ${SYNC_LIMIT_LOW}){
          attachments
          conditionType
          createdBy
          createdOn
          endLocation
          hazardLogs
          id
          isDeleted
          remediatedOn
          modifiedBy
          modifiedOn
          startLocation
          updatedAt
          version
          borerEquipmentId
          inspectionId
          startHazardLandmark
          endHazardLandmark
        }
      }`);
  return {
    query,
    variables: {},
  };
};

export const groundHazardPullModifier = (doc: any) => {
  const result = {
    ...doc,
    createdOnUnix: formatDateToUnix(doc.createdOn),
    remediatedOnUnix: doc.remediatedOn !== null ? formatDateToUnix(doc.remediatedOn) : null,
  };
  return result;
};

export const groundHazardPushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const newDoc = { ...doc, updateLastViewed: false };

  const query = print(gql`
    mutation setGroundHazard($groundHazard: SetGroundHazardInput) {
      setGroundHazard(input: $groundHazard) {
        borerEquipmentId
        remediatedOn
        createdOn
        siteId
        conditionType {
          description
          displayName
          id
        }
        createdBy
        groundControls
        id
        modifiedOn
        hazardLogs {
          comment
          id
          updatedAt
          createdOn
        }
        startLocation {
          endMeters
          id
          startMeters
          surveyPoint {
            description
          }
          sequence {
            description
          }
          room {
            description
          }
          pass {
            description
          }
          panel {
            description
            miningMethod {
              name
              layer
              zone
            }
            block {
              description
            }
          }
        }
        id
        createdOn
        siteId
      }
    }
  `);

  const variables = {
    groundHazard: newDoc,
  };
  return {
    query,
    variables,
  };
};

export const groundHazardPushModifier = (doc: any) => {
  const docToPush: any = {
    id: doc.id,
    conditionTypeId: doc.conditionType,
    startLocationId: doc.startLocation,
    endLocationId: doc.endLocation,
    remediatedOn: doc.remediatedOn,
    createdOn: dayjs(doc.updatedAt).toISOString(),
    version: doc.version,
    borerEquipmentId: doc.borerEquipmentId,
    inspectionId: doc.inspectionId,
    startHazardLandmark: doc.startHazardLandmark,
    endHazardLandmark: doc.endHazardLandmark,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    docToPush[property] = undefined;
  });

  return docToPush;
};
