import { hasPermissionFor, UserPermissionTypes } from '@nutrien/minesight-utility-module';

import { listEquipment } from '@/graphql/Equipment';
import { EMPTY_GUID, PAGINATION_LIMIT_1000 } from '@/utilities/constants';

import { rootStore } from '../mobx-models/Root';
import { BorerStateType, listBorerStateTypes } from './BorerStateTypeFeed/queryBuilder';

// A class to help augment data during migrations by performing one API call

interface PartialEquipment {
  id: string;
  comment: string | null;
  status: {
    id: string;
  };
  tonnesPerScoop: number | null;
}
export default class MigrationHelper {
  private static _instance: MigrationHelper;

  borerStateTypes: BorerStateType[];

  partialEquipmentList: { [key: string]: PartialEquipment };

  constructor() {
    this.borerStateTypes = [];
    this.partialEquipmentList = {};
    MigrationHelper._instance = this;
  }

  public static get instance(): MigrationHelper {
    return MigrationHelper._instance;
  }

  public static get borerStateTypes(): BorerStateType[] {
    return this.borerStateTypes;
  }

  public static get getEquipmentDetails(): PartialEquipment | undefined {
    return this.getEquipmentDetails;
  }

  fetchBorerStateTypes = async () => {
    try {
      if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');
      const stateTypes = await listBorerStateTypes(rootStore.user.siteId);
      this.borerStateTypes = stateTypes.data.listBorerStateTypes;
    } catch (error) {
      console.log('🚀 ~ file: MigrationHelper.ts:47 ~ error', error);
    }
  };

  timeUsageTypeIdForStateTypeId = async (
    stateTypeId: string,
  ): Promise<{ timeUsageTypeId: string | null; isPlanned: boolean | null }> => {
    if (!this.borerStateTypes.length) await this.fetchBorerStateTypes();
    return {
      timeUsageTypeId:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.timeUsageType?.id ||
        null,
      isPlanned:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.isPlanned || null,
    };
  };

  fetchAllEquipment = async () => {
    let lastResultId = EMPTY_GUID;
    let equipmentCount = PAGINATION_LIMIT_1000;

    try {
      if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');

      console.log(
        '🚀 ~ file: MigrationHelper.ts:80 ~ MigrationHelper ~ fetchAllEquipment= ~   hasPermissionFor(UserPermissionTypes.EQUIPMENT_STATUS_INACTIVE_WRITE):',
        hasPermissionFor(UserPermissionTypes.EQUIPMENT_STATUS_INACTIVE_WRITE),
      );
      while (equipmentCount === PAGINATION_LIMIT_1000) {
        const result = await listEquipment(
          rootStore.user.siteId,
          PAGINATION_LIMIT_1000,
          lastResultId,
          hasPermissionFor(UserPermissionTypes.EQUIPMENT_STATUS_INACTIVE_WRITE),
        );
        console.log(
          '🚀 ~ file: MigrationHelper.ts:81 ~ MigrationHelper ~ fetchAllEquipment= ~ result:',
          result,
        );

        const equipment = result?.data?.listEquipment;
        equipment?.forEach(eq => {
          this.partialEquipmentList[eq.id] = eq;
        });

        if (!equipment) {
          equipmentCount = 0;
        } else {
          equipmentCount = equipment?.length;
          lastResultId = equipment[equipment.length - 1]?.id;
        }
      }
    } catch (err) {
      console.log('🚀 ~ file: MigrationHelper.ts:80 ~ etchAllEquipment= ~ err:', err);
    }
  };

  getEquipmentDetails = async (equipmentId: string): Promise<PartialEquipment | undefined> => {
    if (!this.partialEquipmentList[equipmentId]) await this.fetchAllEquipment();
    return this.partialEquipmentList[equipmentId];
  };
}
