import { Grid } from '@material-ui/core';
import { ExpansionPanel, Typography } from '@nutrien/cxp-components';
import React from 'react';

import { EquipmentDocument } from '../../rxdb/Equipment/queryBuilder';
import { EquipmentTypeDocument } from '../../rxdb/EquipmentType/queryBuilder';
import { WorkOrderDocument } from '../../rxdb/WorkOrders/queryBuilder';
import { useDateFormatters } from '../../utilities';
import { MMM_D_DATE_FORMAT, MMM_D_YYYY_DATE_FORMAT } from '../../utilities/useDateFormatters';
import useStyles from './WorkOrderExpansionPanel.styles';

interface Props {
  workOrder: WorkOrderDocument;
  equipment: EquipmentDocument;
  equipmentType: EquipmentTypeDocument;
  index: number;
  expandedWorkOrderId?: string;
  onExpanded: (expanded: boolean, workOrderId: string) => void;
}

const WorkOrderExpansionPanel: React.FC<Props> = ({
  workOrder,
  index,
  expandedWorkOrderId,
  equipmentType,
  onExpanded,
  equipment,
}: Props) => {
  const classes = useStyles();
  const { formatDateInTZ } = useDateFormatters();
  const isCompleted = workOrder.completedDate !== null;

  const expansionPanelChanged = (event: React.ChangeEvent<unknown>, expanded: boolean) => {
    onExpanded(expanded, workOrder.id);
  };

  return (
    <ExpansionPanel
      className={classes.panel}
      key={workOrder.id}
      id={workOrder.id}
      index={index}
      data-cy={`active-work-order-panel-${workOrder.id}`}
      onChange={expansionPanelChanged}
      TransitionProps={{ unmountOnExit: true }}
      expanded={workOrder.id === expandedWorkOrderId}
      expansionPanelSummaryProps={{
        children: (
          <Grid container>
            <Grid item container xs={9}>
              <Grid item container xs={12} spacing={1} className={classes.titleLine}>
                <Typography variant="h5">
                  {equipmentType?.description} {equipment.shortName || equipment.longName}
                </Typography>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Typography variant="caption" color="textSecondary">
                  {isCompleted ? workOrder.description : workOrder.status}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              {isCompleted ? (
                <Typography variant="h5" className={classes.textTitle}>
                  Completed: {formatDateInTZ(workOrder.completedDate, MMM_D_DATE_FORMAT)}
                </Typography>
              ) : (
                <Typography variant="h5" className={classes.textTitle}>
                  Last updated: {formatDateInTZ(workOrder.changedDate, MMM_D_DATE_FORMAT)}
                </Typography>
              )}
            </Grid>
          </Grid>
        ),
      }}
      panelContent={
        <Grid container className={classes.commentsContainer} spacing={2}>
          <Grid item xs={3}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{workOrder.description}</Typography>
          </Grid>
          {workOrder.isWorkRequest ? (
            <>
              <Grid item xs={3}>
                <Typography>WR</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>WR{workOrder.ticketNumber}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Priority</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{workOrder.priority}</Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3}>
                <Typography>WO</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>WO{workOrder.ticketNumber}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Typography>Scheduled start</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {formatDateInTZ(workOrder.scheduledStartDate, MMM_D_YYYY_DATE_FORMAT)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Asset ID</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{workOrder.assetIdentifier}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Equipment type</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{equipmentType?.description} </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Equipment name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {equipmentType?.description} {equipment.longName}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

export default WorkOrderExpansionPanel;
