import dayjs from 'dayjs';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_HIGH } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Location extends BaseEntity {
  readonly startMeters: number | null;
  readonly endMeters?: number | null;
  readonly panel: string;
  readonly room?: string | null;
  readonly surveyPoint?: string | null;
  readonly sequence?: string | null; // only chevron (layer 4)
  readonly pass?: string | null; // optional on all types
}
export type LocationCollection = RxCollection<Location> | null;
export type LocationDocument = RxDocument<Location>;

export const locationSchema: RxJsonSchema<Location> = {
  type: 'object',
  version: 0,
  description: 'describes a location object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    startMeters: { type: ['number', 'null'] },
    endMeters: { type: ['number', 'null'] },
    panel: {
      ref: 'panels',
      type: ['string', 'null'],
    },
    room: {
      ref: 'rooms',
      type: ['string', 'null'],
    },
    surveyPoint: {
      ref: 'survey_points',
      type: ['string', 'null'],
    },
    sequence: {
      ref: 'sequences',
      type: ['string', 'null'],
    },
    pass: {
      ref: 'passes',
      type: ['string', 'null'],
    },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
};

export const locationsPullQueryBuilder = (doc: Location) => {
  if (!doc) {
    doc = {
      ...defaultDoc,
      updatedAt: dayjs().startOf('day').subtract(90, 'days').valueOf(),
    };
  }

  const query = `query locationFeedQuery($siteId: ID!, $limit: Int!, $lastUpdateAt: Float!) {
    locationFeed(limit: $limit, siteId: $siteId, lastUpdateAt: $lastUpdateAt) {
        id
        startMeters
        endMeters
        panel
        pass
        room
        sequence
        surveyPoint
        updatedAt
        isDeleted
        version
    }
  }`;

  return {
    query,
    variables: {
      limit: SYNC_LIMIT_HIGH,
      siteId: rootStore.user.siteId,
      lastUpdateAt: doc.updatedAt,
    },
  };
};

export const locationPushQueryBuilder = (doc: Location) => {
  doc = doc[0];

  const query = `
      mutation SetLocation($location: SetLocationInput) {
        setLocation(input: $location)
      }`;

  const variables = {
    location: doc,
  };

  return {
    query,
    variables,
  };
};

export const createLocationPushModifier = (doc: Location) => {
  const obj = {
    id: doc.id,
    panelId: doc.panel,
    passId: doc.pass,
    roomId: doc.room,
    sequenceId: doc.sequence,
    surveyPointId: doc.surveyPoint,
    startMeters: doc.startMeters,
    endMeters: doc.endMeters,
    createdOn: dayjs(doc.updatedAt).toISOString(),
    modifiedOn: dayjs(doc.updatedAt).toISOString(),
    version: doc.version,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
