import { i18n } from '@nutrien/cxp-components';
import { useEffect, useState } from 'react';

interface ErrorObj {
  startDistance: string;
  endDistance: string;
}

export const isValidDistance = (distance: number | string | undefined | null): boolean => {
  if (distance !== '0' && distance !== 0 && !distance) {
    return false;
  }
  if (Number(distance < 0)) {
    return false;
  }
  if (!Number.isInteger(Number(distance))) return false;

  if (!RegExp('^[0-9]+$').test(`${distance}`)) return false;
  return true;
};

export const startValidation = (startDistance: number | string | undefined) => {
  if (!startDistance && startDistance !== 0) {
    return i18n.t('Required');
  }
  if (!isValidDistance(startDistance)) {
    return i18n.t('Must be numeric');
  }
  if (Number(startDistance) % 1 !== 0) {
    return i18n.t('Must be a whole #');
  }
  return '';
};

export const endValidation = (
  startDistance: number | string | null,
  endDistance: number | string | null,
  isEven: boolean,
) => {
  if (!endDistance && endDistance !== 0) {
    return i18n.t('Required');
  }
  if (!isValidDistance(endDistance)) {
    return i18n.t('Must be numeric');
  }
  if (Number(endDistance) % 1 !== 0) {
    return i18n.t('Must be a whole #');
  }
  if (
    (Number(startDistance) || Number(startDistance) === 0) &&
    isEven &&
    Number(endDistance) > Number(startDistance)
  ) {
    return i18n.t('Enter value ≤ start');
  }
  if (
    (Number(startDistance) || Number(startDistance) === 0) &&
    !isEven &&
    Number(endDistance) < Number(startDistance)
  ) {
    return i18n.t('Enter value ≥ start');
  }

  return '';
};

export const useValidation = (
  startDistance: number | string | null,
  endDistance: number | string | null,
  showStartValidation: boolean,
  showEndValidation: boolean,
  isEven: boolean,
): ErrorObj => {
  const [errors, setErrors] = useState({
    startDistance: '',
    endDistance: '',
  });

  useEffect(() => {
    if (showStartValidation) {
      setErrors(prev => ({ ...prev, startDistance: startValidation(advance) }));
    }
  }, [showStartValidation, advance]);

  useEffect(() => {
    if (showEndValidation) {
      setErrors(prev => ({
        ...prev,
        endDistance: endValidation(advance, isEven),
      }));
    }
  }, [showEndValidation, advance, isEven]);

  return errors;
};

export default useValidation;
