import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { defaultDoc } from '../Shared/defaultDoc';

export const shiftPullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = `
    query shiftFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      shiftFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        siteId: $siteId
      ) {
        id
        isDeleted
        version
        updatedAt
        name
        day
        start
        end
      }
    }
  `;

  return {
    query,
    variables: {
      siteId: rootStore.user.siteId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export interface Shift extends BaseEntity {
  readonly name: string;
  readonly day: string;
  readonly start: string;
  readonly end: string;
}

export const shiftSchema: RxJsonSchema<Shift> = {
  type: 'object',
  version: 0,
  description: 'describes an shift object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    name: { type: 'string' },
    day: { type: 'string' },
    start: { type: 'string' },
    end: { type: 'string' },
  },
};

export type ShiftCollection = RxCollection<Shift> | null;
export type ShiftDocument = RxDocument<Shift>;

export default {};
