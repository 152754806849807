import { createStyles, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  AutoComplete,
  CustomPalette,
  ExpansionPanel,
  GroupedAutoComplete,
  i18n,
  TextField,
} from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { ReactElement, useCallback, useMemo } from 'react';

import { PopulatedLocation } from '@/rxdb/Locations/useLocations';

import { MiningCutSequencePassObj } from '../../rxdb/MiningCut/queryBuilder';
import useMiningCuts from '../../rxdb/MiningCut/useMiningCuts';
import usePanels from '../../rxdb/Panels/usePanels';
import useSite from '../../rxdb/Site/useSite';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import useSurveyPoints from '../../rxdb/SurveyPoints/useSurveyPoints';
import { MiningMethodAllCap } from '../../utilities';
import { LocationPanelData } from './EditPredictionSidePanel';
import { validatePredictionFootage } from './PredictionValidator';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    itemSpacing: {
      padding: '0px 5px',
    },
  }),
);

interface Props {
  title: string;
  onUpdate: (panelData: LocationPanelData) => void;
  expanded: boolean;
  onExpanded: (expanded: boolean) => void;
  locationPanelData: LocationPanelData;
}

const PredictionLocationExpansionPanel = ({
  title,
  onUpdate,
  expanded,
  onExpanded,
  locationPanelData,
}: Props): ReactElement<Props> => {
  const classes = useStyles();
  const { isLanigan, isCory, isVanscoy, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();
  const { getMiningCutLabel } = useMiningCuts();
  const { augmentedPanelList, roomList } = usePanels(
    locationPanelData?.panel?.miningMethod,
    undefined,
    true,
  );

  const isChevron = useMemo(() => {
    return locationPanelData?.panel?.miningMethod.toUpperCase() === MiningMethodAllCap.CHEVRON;
  }, [locationPanelData?.panel]);

  // Validate Sequence (SurveyPoint in BE)
  const validateSequenceChange = (value: any) => {
    onUpdate({
      block: locationPanelData.block,
      panel: locationPanelData.panel,
      room: locationPanelData.room,
      surveyPoint: value,
      sequence: locationPanelData.sequence,
      pass: locationPanelData.pass,
      miningCutSeqPassObj: locationPanelData.miningCutSeqPassObj,
      footage: locationPanelData.footage,
      errors: {
        ...locationPanelData?.errors,
        sequence: !value ? 'Required' : '',
        room: !locationPanelData?.panel ? 'Required' : '',
      },
    });
  };

  const validateMiningCutChange = (value: MiningCutSequencePassObj) => {
    onUpdate({
      block: locationPanelData.block,
      panel: locationPanelData.panel,
      room: locationPanelData.room,
      surveyPoint: locationPanelData.surveyPoint,
      sequence: locationPanelData.sequence,
      pass: value?.pass,
      miningCutSeqPassObj: value,
      footage: locationPanelData.footage,
      errors: {
        ...locationPanelData?.errors,
        sequence:
          locationPanelData?.panel?.miningMethod === 'Chevron' && !locationPanelData?.surveyPoint
            ? 'Required'
            : '',
        room: !locationPanelData?.panel ? 'Required' : '',
        step: !value ? 'Required' : '',
      },
    });
  };

  const onAdvanceFootageChanged = (value: string) => {
    let footageError = '';

    if (validatePredictionFootage(value)) {
      footageError = '';
    } else {
      footageError = i18n.t('Invalid advance footage.');
    }

    onUpdate({
      block: locationPanelData.block,
      panel: locationPanelData.panel,
      room: locationPanelData.room,
      surveyPoint: locationPanelData.surveyPoint,
      sequence: locationPanelData.sequence,
      pass: locationPanelData.pass,
      miningCutSeqPassObj: locationPanelData.miningCutSeqPassObj,
      footage: value,
      errors: {
        ...locationPanelData.errors,
        advanceFootage: footageError,
      },
    });
  };

  const onRoomChanged = (newRoom: PopulatedLocation) => {
    onUpdate({
      block: newRoom?.block,
      panel: newRoom?.panel,
      room: newRoom?.room,
      surveyPoint: undefined,
      sequence: undefined,
      pass: undefined,
      miningCutSeqPassObj: undefined,
      footage: locationPanelData?.footage,
      errors: {
        ...locationPanelData?.errors,
        room: !newRoom?.panel ? 'Required' : '',
        sequence:
          newRoom?.panel?.miningMethod !== locationPanelData?.panel?.miningMethod
            ? ''
            : locationPanelData?.errors?.sequence,
        step:
          newRoom?.panel?.miningMethod !== locationPanelData?.panel?.miningMethod
            ? ''
            : locationPanelData?.errors?.step,
      },
    });
  };

  const getPanelLabel = useCallback(
    (option: PopulatedLocation) => {
      if (isChevron || isCory) {
        return option?.panel?.description || '';
      }
      if (option?.panel?.description && option.room?.description)
        return `${option?.panel?.description}-${option.room.description}`;
      if (option.room?.description) return option.room.description;
      return '';
    },
    [isChevron, isCory],
  );
  const getRoomLabel = useCallback((option: PopulatedLocation) => {
    if (!option) return '';
    return option?.room?.description.includes('PE')
      ? `${option?.room?.description || ''}${option?.block?.description || ''}${
          option?.panel?.description || ''
        }`
      : `${option?.block?.description || ''}${option?.panel?.description || ''}${
          option?.room?.description || ''
        }`;
  }, []);

  // For Lanigan/Vanscoy - Chevron - Use Panel MiningMethod
  // For Lanigan/Vanscoy - LongRoom - Use room (Section) miningMethod
  // For Cory - ZA & ZB - Use room (breakthrough) miningMethod
  const { miningCutListForMiningMethod, miningCutListForMiningPattern } = useMiningCuts(
    isChevron
      ? locationPanelData?.surveyPoint?.miningPattern
      : locationPanelData?.room?.miningPattern,
    locationPanelData?.panel?.miningMethod,
  );

  const { surveyPointsForPanel, surveyPointsForRoom } = useSurveyPoints(
    locationPanelData?.panel?.id,
    locationPanelData?.room?.id,
  );

  const passList = useMemo(() => {
    if (isLanigan || isVanscoy || isRocanville) return miningCutListForMiningPattern;
    if (isCory && isChevron) return miningCutListForMiningPattern;
    if (isCory || isAllan) return miningCutListForMiningMethod;
    return [];
  }, [
    isVanscoy,
    isChevron,
    isLanigan,
    isCory,
    miningCutListForMiningMethod,
    miningCutListForMiningPattern,
    isRocanville,
    isAllan,
  ]);

  return (
    <>
      <ExpansionPanel
        className={classes.panel}
        title={title}
        key="prediction-end-location-ex-panel"
        data-cy="prediction-end-location-ex-panel"
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={() => onExpanded(!expanded)}
        panelContent={
          <>
            {locationPanelData && (
              <Grid container spacing={2}>
                {isLanigan ? (
                  <Grid item xs={12} className={classes.itemSpacing}>
                    <AutoComplete
                      key={`room-autocomplete-${title}`}
                      autoSelect={false}
                      autoHighlight={false}
                      list={roomList}
                      label={i18n.t('Room')}
                      getOptionLabel={getRoomLabel}
                      required
                      onChange={(event, value) => onRoomChanged(value)}
                      value={
                        locationPanelData.block && locationPanelData.panel && locationPanelData.room
                          ? {
                              block: locationPanelData.block,
                              panel: locationPanelData.panel,
                              room: locationPanelData.room,
                            }
                          : undefined
                      }
                      showCaret
                      data-cy="Advance RoomInput"
                      error={locationPanelData.errors?.room !== ''}
                      errorText={locationPanelData.errors?.room}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={(isRocanville || isVanscoy) && !isChevron ? 12 : 6}
                    className={classes.itemSpacing}
                  >
                    <AutoComplete
                      key={`room-autocomplete-${title}`}
                      autoSelect={false}
                      autoHighlight={false}
                      list={augmentedPanelList}
                      label={isRocanville ? i18n.t('Room') : i18n.t('Panel')}
                      required
                      getOptionLabel={getPanelLabel}
                      onChange={(event, value: PopulatedLocation) => onRoomChanged(value)}
                      value={
                        locationPanelData.block || locationPanelData.panel || locationPanelData.room
                          ? {
                              block: locationPanelData.block,
                              panel: locationPanelData.panel,
                              room: locationPanelData.room,
                            }
                          : undefined
                      }
                      showCaret
                      data-cy="Prediction-PanelInput"
                      error={locationPanelData.errors?.room !== ''}
                      errorText={locationPanelData.errors?.room}
                    />
                  </Grid>
                )}
                {(isChevron || isCory) && (
                  <Grid item xs={isLanigan ? 12 : 6} className={classes.itemSpacing}>
                    <AutoComplete
                      autoSelect={false}
                      list={isLanigan ? surveyPointsForRoom : surveyPointsForPanel}
                      label={translate('Sequence')}
                      required
                      getOptionLabel={(option: SurveyPointDocument) => {
                        return option.description;
                      }}
                      onChange={(event, value) => validateSequenceChange(value)}
                      value={locationPanelData.surveyPoint}
                      showCaret
                      data-cy="Advance SurveyPointInput"
                      error={locationPanelData.errors?.sequence !== ''}
                      errorText={locationPanelData.errors?.sequence}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <GroupedAutoComplete
                    autoSelect={false}
                    list={passList || []}
                    label={translate('Pass')}
                    required
                    getOptionLabel={getMiningCutLabel}
                    onChange={(event, value) => validateMiningCutChange(value)}
                    value={locationPanelData.miningCutSeqPassObj}
                    showCaret
                    data-cy="Advance Chevron-Step"
                    error={locationPanelData.errors?.step !== ''}
                    errorText={locationPanelData.errors?.step}
                    groupByProperty="miningPatternName"
                    primaryProperty="sequencePassString"
                    secondaryProperty="miningPatternName"
                  />
                </Grid>
                <Grid item xs={6} className={classes.itemSpacing}>
                  <TextField
                    label={i18n.t('End distance')}
                    required
                    unitText={distanceUnitAbbreviation}
                    onChange={event => onAdvanceFootageChanged(event.target.value)}
                    value={locationPanelData.footage}
                    data-cy="AdvanceFootage"
                    inputProps={{ inputMode: 'numeric' }}
                    error={
                      locationPanelData.errors?.advanceEndFootage !== '' ||
                      locationPanelData.errors?.advanceFootage !== ''
                    }
                    errorText={
                      locationPanelData.errors?.advanceEndFootage ||
                      locationPanelData.errors?.advanceFootage
                    }
                  />
                </Grid>
              </Grid>
            )}
          </>
        }
      />
    </>
  );
};

export default PredictionLocationExpansionPanel;
