import type { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { Fab, Grid, useTheme, Zoom } from '@material-ui/core';
import { Card, i18n, Icons, Menu } from '@nutrien/cxp-components';
import { usePermissions, UserPermissionTypes } from '@nutrien/minesight-utility-module';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';

import { useMst } from '../../../mobx-models/Root';
import useBorerActivity from '../../../rxdb/BorerActivity/useBorerActivity';
import { TempBorerOperatorChangeState } from '../../../rxdb/BorerOperatorChangeFeed/queryBuilder';
import useBorerOperatorChange from '../../../rxdb/BorerOperatorChangeFeed/useBorerOperatorChange';
import useBorerOperatorState from '../../../rxdb/BorerOperatorStateFeed/useBorerOperatorState';
import useGeneratedOfflineBlock from '../../../rxdb/BorerOperatorStateFeed/useGeneratedOfflineBlock';
import { useOnlineStatus } from '../../../utilities';
import { ActivityDelayMapper } from '../../../utilities/activityDelayMapper';
import useViewingCurrentShift from '../../../utilities/hooks/useViewingCurrentShift';
import { createDayJsUnix } from '../../../utilities/useDateFormatters';
import AddBorerActivitySidePanel from '../../AddBorerActivitySidePanel';
import DelaysOfflineBar from '../../DelaysOfflineBar';
import DelaysEditDrawer from './DelaysEditDrawer';
import DelaysSchedulerView from './DelaysSchedulerView';
import { IAppointment } from './DelaysSchedulerView/ActivitiesSchedulerHelpers';
import useStyles from './PiSightPage.styles';

interface Props {
  lastSyncTime?: number;
}

const PiSightPage: React.FC<Props> = ({ lastSyncTime }: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const theme = useTheme();
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentModel | null>(null);
  const [editingOngoingAppointment, setEditingOngoingAppointment] = useState(false);
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  const { currentShiftBorerOperatorStates, getStatesForParentBorerStateId } =
    useBorerOperatorState();
  const { tempAugmentedStates, getTempStatesForParentBorerStateId } = useBorerOperatorChange();

  const isOnline = useOnlineStatus();
  const adminPermissions = usePermissions(UserPermissionTypes.ADMIN_SITE_CONFIGURATION_WRITE);

  const { generatedBlock } = useGeneratedOfflineBlock(
    isOnline,
    shiftPicker.isCurrentShiftSelected(),
    [...tempAugmentedStates, ...currentShiftBorerOperatorStates],
    lastSyncTime,
  );

  const { augmentedActivitiesForShift } = useBorerActivity();
  const viewingCurrentShift = useViewingCurrentShift();
  const [loading, setLoading] = useState(false);
  const [failedToSyncMenuOpen, setFailedSyncMenuOpen] = useState(false);

  const [addActivityOpen, setAddActivityOpen] = useState<boolean>(false);

  const [editDelayDrawerOpen, setEditDelayDrawerOpen] = useState(false);
  const [initalBorerStates, setInitialBorerStates] = useState<TempBorerOperatorChangeState[]>();

  const onCloseBorerDelaySidePanel = useCallback(() => {
    setEditDelayDrawerOpen(false);
  }, []);

  const onCancelBorerDelaySidePanel = useCallback(() => {
    setEditDelayDrawerOpen(false);
  }, []);

  const onOpenBorerDelaySidePanel = useCallback(() => {
    setEditDelayDrawerOpen(true);
  }, []);

  const onOpenActivitySidePanel = useCallback(() => {
    setAddActivityOpen(true);
  }, []);

  const onCloseOrCancelActivitySidePanel = useCallback(() => {
    setAddActivityOpen(false);
  }, []);

  const handleCloseFailedToSyncMenu = useCallback(() => {
    setFailedSyncMenuOpen(false);
    setSelectedAppointment(null);
  }, []);

  const onScheduleItemClicked = useCallback(
    async (
      appointment?: IAppointment | AppointmentModel | null,
      forceEditDelay = false,
      clickEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      if (!appointment) return;
      // Only admins can code past shifts
      if (viewingCurrentShift || adminPermissions) {
        setSelectedAppointment({ ...appointment });

        if (appointment.failedSync && !forceEditDelay) {
          setCursorX(clickEvent?.clientX || 0);
          setCursorY(clickEvent?.clientY || 0);
          setFailedSyncMenuOpen(true);
          return;
        }

        setEditingOngoingAppointment(false);

        if (appointment.typeId === 1) {
          // Borer State
          const [existingStates, existingTempStates] = await Promise.all([
            getStatesForParentBorerStateId(appointment?.borerOperatorStateId),
            getTempStatesForParentBorerStateId(appointment?.borerOperatorStateId),
          ]);

          let initialStates = [...existingStates, ...existingTempStates];

          if (appointment.isGeneratedState && appointment.borerStateTypeId) {
            // When selecting generated block (or temp block that was previously generated)
            initialStates.push(appointment);
          } else if (
            generatedBlock?.[0]?.borerOperatorStateId === appointment?.borerOperatorStateId
          ) {
            // When selecting item with same borerStateId as generated block
            initialStates = [...initialStates, ...generatedBlock];
          }

          const augmentedStates = await Promise.all(
            initialStates
              ?.sort((a, b) => a.startTimeUnix - b.startTimeUnix)
              .map(async (state, index) => {
                let endTime = state.endTimeUnix;
                if (!endTime) setEditingOngoingAppointment(true);

                if (!endTime && state.isRunning === true) {
                  if (index === initialStates.length - 1) {
                    // Last running block - Temp state
                    endTime = dayjs(lastSyncTime).unix();
                  } else {
                    // Use generated block end time
                    endTime = initialStates[index + 1].startTimeUnix;
                  }
                } else if (!endTime && state.populate) {
                  // Last running block - borerOperatorState
                  const stateType = await state.populate('borerStateTypeId');
                  if (stateType?.isRunning === true) endTime = dayjs(lastSyncTime).unix();
                }
                if (!endTime) {
                  // For most recent delay (generated block included)
                  endTime = dayjs().unix();
                }

                return {
                  startTime: createDayJsUnix(state.startTimeUnix),
                  endTime: createDayJsUnix(endTime),
                  borerStateTypeId: state.borerStateTypeId,
                  comment: state.comment || '',
                  existingTempStateId: state.existingTempStateId,
                  borerShiftAdvanceId: state.borerShiftAdvanceId || '',
                  failedSync: state.failedSync,
                  isGeneratedState: state.isGeneratedState,
                  isTempState: state.isTempState,
                  cuttingTypeId: state.cuttingTypeId,
                  cuttingMethodId: state.cuttingMethodId,
                  // Since RHF over-writes id
                  originalId: state.id,
                  id: state.id,
                };
              }) || [],
          );

          setInitialBorerStates(augmentedStates);
          setEditDelayDrawerOpen(true);
        } else if (appointment.typeId === 2) {
          // Activity
          setAddActivityOpen(true);
        }
      }
    },
    [
      viewingCurrentShift,
      adminPermissions,
      getTempStatesForParentBorerStateId,
      getStatesForParentBorerStateId,
      lastSyncTime,
      generatedBlock,
    ],
  );

  const schedulerData = useMemo((): AppointmentModel[] => {
    // If both operator state and temp states have the same borerStateId
    // We are waiting for the temp states to be marked as hidden
    // (This prevents render glitch where comments won't show)
    const hasMatchingBorerStates = currentShiftBorerOperatorStates.find(bState =>
      tempAugmentedStates.find(
        tempState =>
          !tempState.failedSync && tempState.borerOperatorStateId === bState.borerOperatorStateId,
      ),
    );
    if (hasMatchingBorerStates) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    // Attach on-click listener to delay and activity data
    const mappedDelays = [
      ...tempAugmentedStates,
      ...currentShiftBorerOperatorStates,
      ...generatedBlock,
    ].map(state => {
      return {
        ...state,
        onClick: onScheduleItemClicked,
      };
    });

    const mappedActivities = augmentedActivitiesForShift.map(x =>
      ActivityDelayMapper.MapActivityDocumentToAppointment(x, onScheduleItemClicked),
    );
    return [...mappedDelays, ...mappedActivities] as AppointmentModel[];
  }, [
    currentShiftBorerOperatorStates,
    augmentedActivitiesForShift,
    tempAugmentedStates,
    onScheduleItemClicked,
    generatedBlock,
  ]);

  return (
    <Grid container className={classes.root}>
      {!isOnline && viewingCurrentShift && (
        <Grid item xs={12}>
          <DelaysOfflineBar lastSyncTime={lastSyncTime} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Card elevation={1} className={classes.card}>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              {shiftPicker.isDayShift() ? (
                <Grid item xs={12} className={classes.topMargin}>
                  <DelaysSchedulerView schedulerData={schedulerData} loading={loading} />
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.nightShiftScheduleContainer}>
                  <DelaysSchedulerView
                    schedulerData={schedulerData}
                    isNightShift
                    loading={loading}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {(viewingCurrentShift || adminPermissions) && (
        <Zoom
          key="primary"
          in={!addActivityOpen}
          timeout={{
            enter: theme.transitions.duration.enteringScreen,
            exit: 100,
          }}
          style={{
            transitionDelay: `${200}ms`,
          }}
          unmountOnExit
        >
          <Fab
            aria-label="add-delay"
            className={classes.fab}
            color="primary"
            onClick={() => {
              setSelectedAppointment(null);
              onOpenActivitySidePanel();
            }}
            id="add-delay"
          >
            <Icons.PlusFeather color="white" />
          </Fab>
        </Zoom>
      )}
      <DelaysEditDrawer
        selectedAppointment={selectedAppointment}
        open={editDelayDrawerOpen}
        onClose={onCloseBorerDelaySidePanel}
        onCancel={onCancelBorerDelaySidePanel}
        onOpen={onOpenBorerDelaySidePanel}
        initialValues={initalBorerStates}
        isOnlineWhenDrawerOpened={isOnline}
        lastSyncTime={lastSyncTime}
        editingOngoingAppointment={editingOngoingAppointment}
        shiftEndDateUnix={shiftPicker.shiftEndDateUnix}
      />
      <AddBorerActivitySidePanel
        open={addActivityOpen}
        onClose={onCloseOrCancelActivitySidePanel}
        onCancel={onCloseOrCancelActivitySidePanel}
        onOpen={onOpenActivitySidePanel}
        prevAppointment={selectedAppointment}
      />
      <Menu
        id="failed-to-sync-menu"
        open={failedToSyncMenuOpen}
        onClose={handleCloseFailedToSyncMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: cursorY, left: cursorX }}
        data={[
          {
            Rows: [
              {
                Title: i18n.t('Edit delay'),
                MenuHandler: () => {
                  setFailedSyncMenuOpen(false);
                  onScheduleItemClicked(selectedAppointment, true);
                },
              },
            ],
          },
        ]}
      />
    </Grid>
  );
};

export default observer(PiSightPage);
