import { print } from 'graphql';
import gql from 'graphql-tag';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface DailySafetyTopic extends BaseEntity {
  comment: string;
  shiftCommentTypeId: string;
  shiftId: string;
}
export type DailySafetyTopicCollection = RxCollection<DailySafetyTopic> | null;
export type DailySafetyTopicDocument = RxDocument<DailySafetyTopic>;

export const dailySafetyTopicSchema: RxJsonSchema<DailySafetyTopic> = {
  type: 'object',
  version: 0,
  description: 'describes a dailySafetyTopic object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    comment: { type: 'string' },
    shiftCommentTypeId: { type: 'string' },
    shiftId: { type: 'string' },
  },
};

export const dailySafetyTopicsPullQueryBuilder = (doc: DailySafetyTopic) => {
  if (!doc) {
    doc = { ...defaultDoc, updatedAt: 0 };
  }

  const query = print(gql`
    query borerShiftDailySafetyTopicCommentFeed(
      $borerEquipmentId: ID!
      $lastUpdateAt: Float!
      $limit: Int!
    ) {
      borerShiftDailySafetyTopicCommentFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        isDeleted
        updatedAt
        version
        comment
        shiftCommentTypeId
        shiftId
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export default {};
