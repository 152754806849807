import { types } from 'mobx-state-tree';

import { ApiStatus } from '../../utilities/apiStatus';

const GroundControlOptionValue = types.model({
  id: types.string,
  value: types.string,
  displayOrder: types.number,
  type: types.string,
});

const GroundControlOption = types.model({
  id: types.string,
  description: types.string,
  displayOrder: types.number,
  type: types.string,
  displayType: types.string,
  values: types.array(GroundControlOptionValue),
});

const GroundControl = types.model({
  id: types.identifier,
  description: types.string,
  displayOrder: types.number,
  requiresQuantity: types.boolean,
  options: types.array(GroundControlOption),
});

export const GroundControls = types
  .model({
    GroundControlOptions: types.map(GroundControl),
    GroundControlOptionsArray: types.array(types.reference(GroundControl)),
    GroundControlOptionsFetchStatus: types.enumeration('STATUS', [
      ApiStatus.NOT_CALLED,
      ApiStatus.LOADING,
      ApiStatus.ERROR,
      ApiStatus.SUCCESS,
    ]),
  })
  .views(self => ({
    getGroundControls: (): any[] => {
      if (self.GroundControlOptionsArray) {
        return self.GroundControlOptionsArray;
      }
      return [];
    },
    getGroundControlById: (id: string): any => {
      if (self.GroundControlOptionsArray) {
        return self.GroundControlOptions.get(id);
      }
      return undefined;
    },
    getOptionById: (groundControlId: string, optionId: string) => {
      const gc = self.GroundControlOptions.get(groundControlId);
      return gc?.options.find(x => x.id === optionId);
    },
    getOptionByName: (groundControlId: string, name: string) => {
      const gc = self.GroundControlOptions.get(groundControlId);
      return gc?.options.find(x => x.description === name);
    },
    getOptionValueById: (groundControlId: string, optionId: string, valueId: string): any => {
      const gc = self.GroundControlOptions.get(groundControlId);
      const option = gc?.options.find(x => x.id === optionId);
      return {
        description: option?.description,
        value: option?.values.find(x => x.id === valueId).value,
      };
    },
  }));

export default GroundControls;
