import React from 'react';

const ErrorBoundaryFallback = () => {
  setTimeout(() => {
    window.location.reload();
  }, 10000);

  return (
    <div
      style={{
        color: 'white',
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>Minesight Borer has encounter an error.</h1>
      <h2>The error has been reported, borer will automatically refresh in 10 seconds.</h2>
    </div>
  );
};

export default ErrorBoundaryFallback;
