import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutoComplete,
  CustomPalette,
  ExpansionPanel,
  i18n,
  TextField,
} from '@nutrien/cxp-components';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { EmployeeSummary } from '@/rxdb/Employees/useEmployees';

import { Employee } from '../../rxdb/Employees/queryBuilder';
import { GroundHazardConditionType } from '../../rxdb/HazardConditionType/queryBuilder';
import { HazardPanelTypes } from '../../utilities';
import { sortAlphabeticallyByProperty, sortByFirstName } from '../../utilities/sortHelper';

interface Props {
  names: EmployeeSummary[];
  onNameSelected: (employee: Employee) => void;
  selectedName: string | Employee;
  selectedCondition: string | GroundHazardConditionType;
  conditions: GroundHazardConditionType[];
  onConditionSelected: (condition: GroundHazardConditionType) => void;
  conditionDisabled: boolean;
  onCommentTextChanged: (commentText: string) => void;
  commentText: string;
  validationIndex: number;
  setValidationIndex: Dispatch<SetStateAction<number>>;
  isExistingHazard?: boolean;
  onExpanded: (isExpanded: boolean, expandedPanelId: HazardPanelTypes) => void;
  expanded: boolean;
  currentExpandedPanelId: HazardPanelTypes | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
  }),
);

const AddCommentDetailsPanel: React.FC<Props> = ({
  names,
  conditions,
  onConditionSelected,
  selectedCondition,
  conditionDisabled,
  onNameSelected,
  selectedName,
  onCommentTextChanged,
  commentText,
  isExistingHazard = false,
  validationIndex,
  setValidationIndex,
  expanded,
  onExpanded,
  currentExpandedPanelId,
}: Props) => {
  const classes = useStyles();

  const [errors, setErrors] = useState({
    name: '',
    condition: '',
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const checkForRequiredFields = () => {
    return selectedName && selectedCondition;
  };

  useEffect(() => {
    setIsEditing(false);
  }, [currentExpandedPanelId]);

  useEffect(() => {
    if (validationIndex >= 1) {
      if (!selectedName) {
        setErrors(prev => ({ ...prev, name: 'Required.' }));
        setIsEditing(true);
      } else {
        setErrors(prev => ({ ...prev, name: '' }));
      }
    }
  }, [validationIndex, selectedName]);

  useEffect(() => {
    if (validationIndex >= 2) {
      if (!selectedCondition) {
        setErrors(prev => ({ ...prev, condition: 'Required.' }));
        setIsEditing(true);
      } else {
        setErrors(prev => ({ ...prev, condition: '' }));
      }
    }
  }, [validationIndex, selectedCondition]);

  //
  const onPanelChange = (event: React.ChangeEvent<unknown>, isExpanding: boolean) => {
    setValidationIndex(prev => (prev > 2 ? prev : 2));
    if (!isExpanding && checkForRequiredFields() && !isEditing) {
      onExpanded(false, HazardPanelTypes.DETAILS);
    } else {
      onExpanded(true, HazardPanelTypes.DETAILS);
    }
  };

  const onNameChange = (event: any, value: any) => {
    onNameSelected(value);
  };

  const onConditionChange = (event: any, value: any) => {
    onConditionSelected(value);
    setValidationIndex(prev => (prev > 2 ? prev : 2));
  };

  const onCommentBlur = () => {
    setValidationIndex(prev => (prev > 2 ? prev : 2));
    setIsEditing(false);
  };

  return (
    <ExpansionPanel
      className={classes.panel}
      title={i18n.t('Details')}
      key="hazard-details"
      data-cy="hazard-details"
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded || !checkForRequiredFields() || isEditing}
      onChange={onPanelChange}
      panelContent={
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              list={names.sort(sortByFirstName)}
              label={i18n.t('Your name')}
              required
              getOptionLabel={(employee: Employee) => {
                return employee.firstName ? `${employee.firstName} ${employee.lastName}` : '';
              }}
              onChange={onNameChange}
              data-testid="add-hazard-name"
              value={selectedName}
              showCaret
              autoSelect={false}
              error={errors.name !== ''}
              errorText={errors.name}
            />
          </Grid>
          {!isExistingHazard && (
            <Grid item xs={12}>
              <AutoComplete
                list={sortAlphabeticallyByProperty(conditions, 'displayName')}
                label={i18n.t('Condition')}
                required
                getOptionLabel={(condition: any) => condition.displayName}
                onChange={onConditionChange}
                value={selectedCondition}
                showCaret
                data-testid="add-hazard-condition"
                disabled={conditionDisabled}
                error={errors.condition !== ''}
                errorText={errors.condition}
                autoSelect={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label={i18n.t('Comments (optional)')}
              required
              multiline
              data-testid="add-hazard-comment"
              rows={4}
              onChange={event => {
                onCommentTextChanged(event.target.value);
              }}
              onFocus={() => {
                setIsEditing(true);
              }}
              onBlur={onCommentBlur}
              value={commentText}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default AddCommentDetailsPanel;
