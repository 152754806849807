import { print } from 'graphql';
import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { RxdbCollectionName } from '../rxdbCollectionName';
import defaultDoc from '../Shared/defaultDoc';

export interface EquipmentDeficiency extends BaseEntity {
  readonly description: string;
  readonly equipmentId: string;
  readonly isWorkOrderRequired: boolean;
  readonly borerEquipmentId: string;
  readonly siteId: string;
  readonly fullyAddressedDate: string | null;
  readonly approvalDate: string | null;
  readonly denialDate: string | null;
  readonly createdOn: string | null;
}
export type EquipmentDeficiencyCollection = RxCollection<EquipmentDeficiency> | null;
export type EquipmentDeficiencyDocument = RxDocument<EquipmentDeficiency>;

export const equipmentDeficiencySchema: RxJsonSchema<EquipmentDeficiency> = {
  type: 'object',
  version: 0,
  description: 'describes a EquipmentDeficiency object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    description: { type: 'string' },
    equipmentId: {
      ref: RxdbCollectionName.EQUIPMENT,
      type: 'string',
    },
    isWorkOrderRequired: { type: 'boolean' },
    isDeleted: { type: 'boolean' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    version: { type: 'number' },
    borerEquipmentId: { type: 'string' },
    siteId: { type: 'string' },
    fullyAddressedDate: { type: ['string', 'null'] },
    approvalDate: { type: ['string', 'null'] },
    denialDate: { type: ['string', 'null'] },
    createdOn: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

export const equipmentDeficiencyPullQueryBuilder = (doc: EquipmentDeficiencyDocument) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }
  const query = print(gql`
    query equipmentDeficiencyFeed($lastUpdateAt: Float!, $borerEquipmentId: ID!, $limit: Int!) {
      equipmentDeficiencyFeed(
        borerEquipmentId: $borerEquipmentId
        lastUpdateAt: $lastUpdateAt
        limit: $limit
      ) {
        id
        description
        isWorkOrderRequired
        isDeleted
        updatedAt
        version
        borerEquipmentId
        siteId
        fullyAddressedDate
        approvalDate
        denialDate
        createdOn
        equipmentId
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const equipmentDeficiencyPushQueryBuilder = (doc: EquipmentDeficiencyDocument) => {
  doc = doc[0];

  const query = print(gql`
    mutation setEquipmentDeficiency(
      $id: ID!
      $description: String!
      $borerEquipmentId: ID!
      $isWorkOrderRequired: Boolean!
      $siteId: ID!
      $fullyAddressedDate: AWSDateTime
      $createdOn: AWSDateTime
      $version: Int!
      $isDeleted: Boolean!
      $equipmentId: ID!
    ) {
      setEquipmentDeficiency(
        input: {
          id: $id
          description: $description
          borerEquipmentId: $borerEquipmentId
          isWorkOrderRequired: $isWorkOrderRequired
          siteId: $siteId
          fullyAddressedDate: $fullyAddressedDate
          createdOn: $createdOn
          version: $version
          isDeleted: $isDeleted
          equipmentId: $equipmentId
        }
      ) {
        id
        isWorkOrderRequired
        siteId
      }
    }
  `);

  const variables = {
    ...doc,
  };

  return {
    query,
    variables,
  };
};

export const equipmentDeficiencyPushModifier = (doc: EquipmentDeficiencyDocument) => {
  const obj = {
    ...doc,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
