import postal from 'postal';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AppBar from '../AppBar';
import AppLoadingDialog from '../AppLoadingDialog';
import InvalidTokenDialog from '../InvalidTokenDialog';
import TabBarConfig from '../TabBar/TabBarConfig.json';
const whiteListedPaths = TabBarConfig.items.map(route => route.path);

interface Props {
  children?: ReactNode;
  loadingModalOpen: boolean;
}

const AppBarController = ({ children, loadingModalOpen }: Props) => {
  const [invalidTokenModalOpen, setInvalidTokenModalOpen] = useState(false);

  const location = useLocation();

  const showAppbar = useMemo(() => {
    if (whiteListedPaths.includes(location.pathname)) return true;
    return false;
  }, [location]);

  useEffect(() => {
    const postalSub = postal.subscribe({
      channel: 'token',
      topic: 'token.invalid',
      callback: () => setInvalidTokenModalOpen(true),
    });

    return () => postalSub.unsubscribe();
  }, []);

  const closeInvalidTokenDialog = useCallback(() => setInvalidTokenModalOpen(false), []);
  return (
    <>
      {showAppbar && <AppBar />}
      {invalidTokenModalOpen && (
        <InvalidTokenDialog closeInvalidTokenDialog={closeInvalidTokenDialog} />
      )}
      {loadingModalOpen && <AppLoadingDialog />}
      {children}
    </>
  );
};

export default AppBarController;
