import { cloneDeep } from '@apollo/client/utilities';
import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialPalette } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { v4 } from 'uuid';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftCrewType } from '../../rxdb/BorerShift/queryBuilder';
import { ExtendedBorerShiftCrewDocument } from '../../rxdb/BorerShiftCrew/queryBuilder';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';
import { USER_TIMEZONE } from '../../utilities/useDateFormatters';
import CrewCard from '../CrewCard';
import ModifyCrewSidePanel from '../ModifyCrewSidePanel';
import { generateDefaultEmployee } from '../ModifyCrewSidePanel/ModifyCrewSidePanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },
    button: {
      '& svg': {
        stroke: MaterialPalette.primary.main,
      },
    },
    iconSpacer: {
      marginRight: 8,
    },
    spacer: {
      marginLeft: 15,
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
);

const ParentCrewCard: FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const [modifyCrewSidePanelOpen, setModifyCrewSidePanelOpen] = useState(false);
  const [currentBorerShiftCrews, setCurrentBorerShiftCrews] = useState<
    ExtendedBorerShiftCrewDocument[]
  >([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [crewNumber, setCrewNumber] = useState(1);
  const [crewToEdit, setCrewToEdit] = useState<BorerShiftCrewType | undefined | null>();
  const {
    getBorerShiftCrews,
    borerShiftCrewInitialized,
    borerShiftCrewCollection,
    borerShiftCrewMemberCollection,
    borerShiftCrewMemberInitialized,
  } = useBorerShiftCrew();

  const assignCrewToEdit = (crewIndex: number | undefined, crewOrder: number | undefined) => {
    if (crewIndex !== undefined && currentBorerShiftCrews[crewIndex]?.version) {
      setCrewToEdit(
        cloneDeep({
          borerShiftCrewMemberInput: currentBorerShiftCrews[crewIndex].borerShiftCrewMemberInput,
          borerShiftId: shiftPicker.currentBorerShiftId || '',
          startDateTime: dayjs.tz(currentBorerShiftCrews[crewIndex]?.start, USER_TIMEZONE),
          endDateTime: dayjs.tz(currentBorerShiftCrews[crewIndex]?.end, USER_TIMEZONE),
          id: currentBorerShiftCrews[crewIndex]?.id,
          version: currentBorerShiftCrews[crewIndex]?.version,
          crewNumber: currentBorerShiftCrews[crewIndex]?.crewNumber,
          index: crewIndex,
        }),
      );
      setCrewNumber(currentBorerShiftCrews[crewIndex]?.crewNumber);
    } else {
      setCrewToEdit(undefined);
      setCrewNumber(crewOrder);
    }
  };

  const generateDefaultCrew = (): BorerShiftCrewType => {
    return {
      borerShiftCrewMemberInput: [
        generateDefaultEmployee(),
        generateDefaultEmployee(),
        generateDefaultEmployee(),
      ],
      borerShiftId: shiftPicker.currentBorerShiftId || '',
      endDateTime: shiftPicker.selectedShift?.shiftEnd || '',
      startDateTime: shiftPicker.selectedShift?.shiftStart || '',
      id: v4(),
      version: 0,
      crewNumber: crewNumber || 1,
    };
  };

  const setup = async (silentLoad = false) => {
    if (shiftPicker.currentBorerShiftId && borerShiftCrewInitialized) {
      if (!silentLoad) setInitialLoad(true);
      const { borerShiftCrews } = await getBorerShiftCrews(shiftPicker.currentBorerShiftId);

      if (borerShiftCrews?.[0]) {
        setCurrentBorerShiftCrews(
          borerShiftCrews
            .sort((a, b) => a.crewNumber - b.crewNumber)
            .filter((crew, index) => index <= 1),
        );
      } else {
        setCurrentBorerShiftCrews([generateDefaultCrew()]);
      }
      if (!silentLoad) setInitialLoad(false);
    }
  };

  useEffect(() => {
    let subscription: Subscription;
    if (borerShiftCrewInitialized) {
      setup();
      if (borerShiftCrewCollection) {
        subscription = borerShiftCrewCollection.$.subscribe(() => {
          setup(false);
        });
      }
    }
    return () => {
      if (subscription?.unsubscribe) {
        subscription.unsubscribe();
      }
    };
  }, [borerShiftCrewInitialized, shiftPicker.currentBorerShiftId]);

  useEffect(() => {
    let subscription: Subscription;
    if (borerShiftCrewMemberInitialized) {
      if (borerShiftCrewMemberCollection) {
        subscription = borerShiftCrewMemberCollection.$.subscribe(() => {
          setup(true);
        });
      }
    }
    return () => {
      if (subscription?.unsubscribe) {
        subscription.unsubscribe();
      }
    };
  }, [borerShiftCrewMemberInitialized, shiftPicker.currentBorerShiftId]);

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const { search } = location;
    const { addCrew } = queryString.parse(search);
    if (addCrew) {
      setModifyCrewSidePanelOpen(true);
      history.push('/shift-start');
    }
  }, [location]);

  return (
    <>
      <Grid container alignItems="stretch" direction="row" spacing={1}>
        {initialLoad && <CircularProgress className={classes.spinner} />}
        {!initialLoad &&
          currentBorerShiftCrews.map((borerShiftCrew, index) => {
            return (
              (!borerShiftCrew?.hidden && (
                <CrewCard
                  crew={borerShiftCrew}
                  index={index}
                  assignCrewToEdit={assignCrewToEdit}
                  setModifyCrewSidePanelOpen={setModifyCrewSidePanelOpen}
                  hideAddCrewButton={currentBorerShiftCrews.length > 1}
                  key={borerShiftCrew.id}
                />
              )) ||
              null
            );
          })}
      </Grid>
      <ModifyCrewSidePanel
        open={modifyCrewSidePanelOpen}
        setModifyCrewSidePanelOpen={setModifyCrewSidePanelOpen}
        crewToEdit={crewToEdit}
        crewNumber={crewNumber}
        triggerRefresh={setup}
      />
    </>
  );
};

export default observer(ParentCrewCard);
