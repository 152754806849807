import { useCallback, useEffect } from 'react';
import { useRxData } from 'rxdb-hooks';

import { rootStore } from '../../mobx-models/Root';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { Site } from './queryBuilder';

export const useSite = () => {
  useEffect(() => {
    rootStore.user.refreshSiteId();
  }, []);

  const siteId = rootStore.user.siteId;

  const getSiteDetailsQuery = useCallback(
    collection =>
      collection.findOne({
        selector: {
          id: siteId,
        },
      }),

    [siteId],
  );
  const { result: siteDetailsArray } = useRxData<Site>(
    RxdbCollectionName.SITES,
    getSiteDetailsQuery,
  );
  const siteDetails = siteDetailsArray[0];

  return {
    distanceUnitAbbreviation: siteDetails?.distanceUnitAbbreviation || '',
    useMeters: siteDetails?.distanceUnitAbbreviation?.toLowerCase() === 'm',
  };
};

export default useSite;
