import { Grid, Typography } from '@material-ui/core';
import { Button, Dialog, i18n, Icons } from '@nutrien/cxp-components';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { useMst } from '../../mobx-models/Root';
import useShifts from '../../rxdb/Shifts/useShifts';
import { useOnlineStatus } from '../../utilities';
import useEventTracking, { TrackingEventType } from '../../utilities/hooks/useEventTracking';
import Spinner from '../Spinner';
import { useStyles } from './MissingDataDialog.styles';

// Triggered when most recent shift isn't available
// OR when async-migration is in progress
const MissingDataDialog: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const { mostRecentAvailableShift } = useShifts();
  const [showLogout, setShowLogout] = useState(false);
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const { trackEvent } = useEventTracking();
  const history = useHistory();
  const isOnline = useOnlineStatus();

  // Show logout button if more than 15 seconds have passed
  useEffect(() => {
    setTimeout(() => {
      setShowLogout(true);
    }, 15000);
  }, []);

  const onRedirectToPreviousShift = () => {
    if (mostRecentAvailableShift?.start) {
      shiftPicker.setShift(dayjs(mostRecentAvailableShift.start), mostRecentAvailableShift.name);
    }
  };

  const mostRecentAvailableShiftText = useMemo(() => {
    if (mostRecentAvailableShift?.start && mostRecentAvailableShift?.name) {
      return `(${dayjs(mostRecentAvailableShift?.start).format('M/DD')} ${
        mostRecentAvailableShift?.name
      })`;
    }
    return '';
  }, [mostRecentAvailableShift]);

  const logoutUser = async () => {
    setLogoutInProgress(true);
    await trackEvent(TrackingEventType.LOGOUT_CLICKED, {
      location: 'LoadingScreen',
    });
    history.push('/logout');
    setLogoutInProgress(false);
  };

  return (
    <Dialog
      open
      titleText={isOnline ? i18n.t('Loading recent data') : i18n.t('Missing data')}
      backButton={false}
      disableEnforceFocus
      maxWidth="sm"
      maxHeight="350px"
      dialogContentClassName={classes.dialogContent}
      data-testid="loading-shift-data-dialog"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
      dialogActions={
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={6}>
            {mostRecentAvailableShift && (
              <Button
                onClick={onRedirectToPreviousShift}
                variant="contained"
                color="primary"
                noMinHeight
                id="view-previous-shift-button"
              >
                {i18n.t('Last available shift ')}
                {mostRecentAvailableShiftText}
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {!mostRecentAvailableShift && showLogout && (
              <Button
                color="primary"
                variant="outlined"
                onClick={logoutUser}
                id="logout-user"
                noMinHeight
                isLoading={logoutInProgress}
              >
                Logout
              </Button>
            )}
          </Grid>
        </Grid>
      }
    >
      <Grid container justify="center" alignItems="center" className={classes.content} spacing={2}>
        <Grid item xs={12} className={classes.icon}>
          {isOnline ? <Spinner /> : <Icons.WifiOffFeather color="error" size="large" />}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {isOnline
              ? i18n.t('Please wait while the iPad syncs new data')
              : i18n.t('Please return the iPad to network to download new data.')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {i18n.t(
              'This modal will automatically close when the iPad is connected to the network and the most recent data is loaded.',
            )}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default MissingDataDialog;
