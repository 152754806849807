import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import { useEffect, useMemo, useState } from 'react';
import { RxCollection } from 'rxdb';
import { useRxCollection, useRxData } from 'rxdb-hooks';

import { useMst } from '../../mobx-models/Root';
import { MiningMethod } from '../../utilities/constants';
import { Block } from '../Blocks/queryBuilder';
import { EquipmentType } from '../EquipmentType/queryBuilder';
import { PopulatedLocation } from '../Locations/useLocations';
import { Panel } from '../Panels/queryBuilder';
import useProduction from '../Productions/useProduction';
import { Room } from '../Rooms/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { SurveyPointDocument } from '../SurveyPoints/queryBuilder';
import { Equipment, EquipmentCollection } from './queryBuilder';

export const useCurrentBorer = () => {
  const { equipment } = useMst();
  const { isCory, isAllan } = useSiteFeatures();
  const { mostRecentProductionLocationInformation } = useProduction();

  const equipmentCollection: EquipmentCollection | null = useRxCollection(
    RxdbCollectionName.EQUIPMENT,
  );

  const [borerInitialized, setBorerInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (equipmentCollection && equipment.selectedBorerId) {
      setBorerInitialized(true);
    }
  }, [equipmentCollection, equipment.selectedBorerId]);

  // Get the current borer
  const borerQuery = useRxData<Equipment>(
    RxdbCollectionName.EQUIPMENT,
    (collection: RxCollection) =>
      collection.findOne().where('id').equals(equipment.selectedBorerId),
  );
  const currentBorer = borerQuery.result[0];

  const borerEquipmentType = useRxData<EquipmentType>(
    RxdbCollectionName.EQUIPMENT_TYPE,
    (collection: RxCollection) =>
      collection.findOne().where('id').equals(currentBorer?.equipmentTypeId),
  );

  // Borer Location Details
  // Get the current block
  const blockQuery = useRxData<Block>(RxdbCollectionName.BLOCKS, (collection: RxCollection) =>
    collection.findOne().where('id').equals(currentBorer?.blockId),
  );
  const currentBlock = blockQuery.result[0];

  // get the current panel
  const panelQuery = useRxData<Panel>(RxdbCollectionName.PANELS, (collection: RxCollection) =>
    collection.findOne().where('id').equals(currentBorer?.panelId),
  );
  const currentPanel = panelQuery.result[0];

  // Get the room if the borer ever changes
  const roomQuery = useRxData<Room>(RxdbCollectionName.ROOMS, (collection: RxCollection) =>
    collection.findOne().where('id').equals(currentBorer?.roomId),
  );
  const currentRoom = roomQuery.result[0];

  // Get the survey point if the borer ever changes
  const surveyPointQuery = useRxData<SurveyPointDocument>(
    RxdbCollectionName.SURVEY_POINTS,
    (collection: RxCollection) =>
      collection.findOne().where('id').equals(currentBorer?.surveyPointId),
  );
  const currentSurveyPoint = surveyPointQuery.result[0];

  const miningMethod = useMemo(() => {
    if (isCory || isAllan) {
      return MiningMethod.CHEVRON;
    } else if (currentPanel?.miningMethod) {
      return currentPanel.miningMethod;
    } else if (equipment.selectedBorerMiningMethod) {
      return equipment.selectedBorerMiningMethod;
    }
  }, [currentPanel, equipment.selectedBorerMiningMethod, isAllan, isCory]);

  const recentBorerLocation: PopulatedLocation = useMemo(() => {
    if (
      mostRecentProductionLocationInformation?.panel?.id &&
      mostRecentProductionLocationInformation?.panel?.miningMethod === currentPanel?.miningMethod
    ) {
      return mostRecentProductionLocationInformation;
    } else {
      // Fall back to assigned location
      return {
        block: currentBlock,
        panel: currentPanel,
        room: currentRoom,
        surveyPoint: currentSurveyPoint,
        pass: undefined,
        sequence: undefined,
        miningMethod: currentPanel?.miningMethod,
      };
    }
  }, [
    currentBlock,
    currentPanel,
    currentRoom,
    currentSurveyPoint,
    mostRecentProductionLocationInformation,
  ]);

  const isRehabBorer = borerEquipmentType.result[0]?.description === 'Rehab Machine';
  const isSandvikBorer = currentBorer?.longName?.toLowerCase().includes('sandvik');
  const showAllanRehabFields = useMemo(() => isAllan && isRehabBorer, [isAllan, isRehabBorer]);

  return {
    miningMethod,
    borerInitialized,
    currentBlock,
    currentPanel,
    currentRoom,
    currentBorer,
    currentSurveyPoint,
    isSandvikBorer,
    recentBorerLocation,
    isRehabBorer,
    showAllanRehabFields,
  };
};

export default { useCurrentBorer };
