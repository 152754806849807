import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import { defaultDoc } from '../Shared/defaultDoc';

export interface Signature extends BaseEntity {
  readonly siteEmployeeId?: string;
  readonly personnelName?: string;
  readonly signature: string;
  readonly signatureType: string;
  readonly createdOn: string;
}
export type SignatureDocument = RxDocument<Signature>;
export type SignatureCollection = RxCollection<Signature> | null;

export const signatureSchema: RxJsonSchema<Signature> = {
  type: 'object',
  version: 1,
  description: 'describes a Signature object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    signatureType: { type: 'string' },
    siteEmployeeId: { type: ['string', 'null'] },
    personnelName: { type: ['string', 'null'] },
    signature: { type: 'string' },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    createdOn: { type: 'string' },
  },
};

export const signaturePullQueryBuilder = (doc: any) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query signatureFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      signatureFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        id
        version
        updatedAt
        signatureType
        signature
        personnelName
        isDeleted
        siteEmployeeId
        createdOn
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const signaturePushQueryBuilder = (doc: any) => {
  doc = doc[0];

  const query = print(gql`
    mutation CreateSignature($signature: CreateSignatureInput) {
      createSignature(input: $signature)
    }
  `);

  // this is temporary to be able to test MDP-6745 in other environments
  const breakSignatureFeed = localStorage.getItem('breakSignatureFeed') === 'true';
  const variables = {
    signature: breakSignatureFeed ? {} : doc,
  };

  return {
    query,
    variables,
  };
};

export const createSignaturePushModifier = (doc: Signature) => {
  const result = {
    id: doc.id,
    createdOn: doc.createdOn,
    siteEmployeeId: doc.siteEmployeeId,
    personnelName: doc.personnelName,
    signatureType: doc.signatureType,
    signature: doc.signature,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev'];
  propertyBlackList.forEach(property => {
    result[property] = undefined;
  });

  return result;
};

export const signatureMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: SignatureDocument) => {
    const newDoc = {
      ...oldDoc,
      siteEmployeeId: oldDoc.employeeId,
    };

    delete newDoc.employeeId;
    return newDoc;
  },
};
