import { Dayjs } from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import RxdbCollectionName from '../rxdbCollectionName';

// Note: This feed contains pending requests until they are returned in the BorerOperatorStateFeed
// At that point, these documents have been encoded and can be removed from this collection.
export interface BorerOperatorChangeState {
  borerStateTypeId: string;
  startTime: string;
  endTime: string | null;
  comment: string;
  borerShiftAdvanceId: string | null;
  originalId: string;
  failedSync: boolean | null;
  isGeneratedState: boolean | null;
  cuttingTypeId: string | null;
  cuttingMethodId: string | null;
}

export interface BorerOperatorChange extends BaseEntity {
  borerStateId: string;
  states: BorerOperatorChangeState[];
  createdOn: string;
  borerEquipmentId: string;
  showInSchedulerView: boolean;
  failedSync: boolean | null;
  isGeneratedState: boolean | null;
}

// Data interface used in the edit drawer
export interface TempBorerOperatorChangeState {
  startTime?: Dayjs;
  endTime?: Dayjs;
  borerStateTypeId: string;
  comment: string;
  borerShiftAdvanceId?: string;
  existingTempStateId?: string;
  failedSync: boolean | null;
  id: string;
  cuttingTypeId: string | null;
  cuttingMethodId: string | null;
  // Since RHF replaces ID
  originalId: string;
  isGeneratedState: boolean | null;
}

export interface TempBorerOperatorChange {
  borerStateId: string;
  states: TempBorerOperatorChangeState[];
  createdOn: string;
  borerEquipmentId: string;
  showInSchedulerView: boolean;
  failedSync: boolean | null;
}

export type BorerOperatorChangeCollection = RxCollection<BorerOperatorChange> | null;
export type BorerOperatorChangeDocument = RxDocument<BorerOperatorChange>;

export const borerOperatorChangeSchema: RxJsonSchema<BorerOperatorChange> = {
  type: 'object',
  version: 9,
  description: 'describes an BorerOperatorChange object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    updatedAt: { type: 'number' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    borerStateId: {
      type: 'string',
      maxLength: 36,
    },
    createdOn: { type: 'string' },
    states: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          borerStateTypeId: { type: 'string', ref: RxdbCollectionName.BORER_STATE_TYPE },
          borerShiftAdvanceId: {
            type: ['string', 'null'],
            ref: RxdbCollectionName.BORER_SHIFT_ADVANCE,
          },
          startTime: { type: 'string' },
          endTime: { type: ['string', 'null'] },
          comment: { type: 'string' },
          originalId: { type: ['string', 'null'], maxLength: 36 },
          cuttingTypeId: { type: ['string', 'null'], maxLength: 36 },
          cuttingMethodId: { type: ['string', 'null'], maxLength: 36 },
        },
      },
    },
    failedSync: { type: ['boolean', 'null'] },
    borerEquipmentId: { type: 'string' },
    showInSchedulerView: { type: 'boolean' },
    isGeneratedState: { type: ['boolean', 'null'] },
  },
};

export const borerOperatorChangeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerOperatorChangeDocument) => {
    return {
      ...oldDoc,
      borerShiftAdvanceId: null,
    };
  },
  2: (oldDoc: BorerOperatorChangeDocument) => oldDoc,
  3: (oldDoc: BorerOperatorChangeDocument) => oldDoc,
  4: (oldDoc: BorerOperatorChangeDocument) => ({ ...oldDoc, showInSchedulerView: true }),
  5: (oldDoc: BorerOperatorChangeDocument) => oldDoc,
  6: (oldDoc: BorerOperatorChangeDocument) => oldDoc,
  7: (oldDoc: BorerOperatorChangeDocument) => ({ ...oldDoc, isGeneratedState: false }),
  8: (oldDoc: BorerOperatorChangeDocument) => oldDoc,
  9: (oldDoc: BorerOperatorChangeDocument) => ({
    ...oldDoc,
    states: oldDoc.states.map(state => ({ ...state, cuttingTypeId: null, cuttingMethodId: null })),
  }),
};

export const borerOperatorChangePushQueryBuilder = (doc: TempBorerOperatorChange[]) => {
  const docToPush = doc[0];

  const query = print(gql`
    mutation submitBorerOperatorStateRequest($input: SubmitBorerOperatorStateRequestInput!) {
      submitBorerOperatorStateRequest(input: $input) {
        borerEquipmentId
        borerStateId
        id
        status
      }
    }
  `);

  const variables = {
    input: docToPush,
  };

  return {
    query,
    variables,
  };
};

export const borerOperatorChangePushModifier = (
  doc: TempBorerOperatorChange,
): BorerOperatorChangeState | null => {
  // If we are toggling item as hidden, no need to send a change request to BE
  if (doc.showInSchedulerView === false) return null;

  // Another local property - if we toggle one of the states to failed,
  // don't send a change request to BE
  if (doc.failedSync) return null;

  // Remove originalId, showInSchedulerView, failedSync, and isGeneratedState (local reference only)
  const remappedStatesWithoutId = doc.states.map(state => ({
    borerStateTypeId: state.borerStateTypeId,
    borerShiftAdvanceId: state.borerShiftAdvanceId,
    startTime: state.startTime,
    endTime: state.endTime,
    comment: state.comment,
    cuttingTypeId: state.cuttingTypeId,
    cuttingMethodId: state.cuttingMethodId,
  }));

  const obj = {
    ...doc,
    states: remappedStatesWithoutId,
  };

  // Remove temp properties
  delete obj.showInSchedulerView;
  delete obj.failedSync;
  delete obj.isGeneratedState;

  const propertyBlackList = [
    'updatedAt',
    '_deleted',
    '_attachments',
    '_rev',
    'isDeleted',
    'version',
    '_meta',
  ];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export default {};
